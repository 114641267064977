import Vue from 'vue';
import {MutationTree} from 'vuex';
import {ModuleState, mutationTypes} from '@/modules/settings/shared/state';

export const mainMutations: MutationTree<ModuleState> = {
    [mutationTypes.SETTING_GET_REQUEST](state: ModuleState): void {
        Vue.set(state, 'loading', true);
    },

    [mutationTypes.SETTING_GET_ERROR](state: ModuleState): void {
        Vue.set(state, 'loading', false);

    },

    [mutationTypes.SETTING_STORE_SUCCESS](state: ModuleState): void {
        Vue.set(state, 'loading', false);
    },
};
