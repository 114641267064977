import { MutationTree } from 'vuex';
import { ModuleState } from '@/shared/state/template/module-state';
import { salesModule } from '../module';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import {SalesState} from '@/modules/sales/shared/state/state';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import {Sale} from '@/modules/sales/shared';

export const mutationsTypes = salesModule.mutationsTypes;

export const salesMutations: MutationTree<any> = {
    [mutationsTypes.FETCH_BY_STATUS_REQUEST](state: SalesState) {
        state.loadingItem = true;
        state.error = '';
        state.errorMessages = {};
    },
    [mutationsTypes.FETCH_BY_STATUS_ERROR](state: SalesState, payload: ErrorPayload) {
        state.error = payload.message;
        state.errorMessages = payload.errors;
        state.loadingItem = false;
    },
    [mutationsTypes.FETCH_BY_STATUS_SUCCESS](state: SalesState, payload: ModuleFetchPayload<Sale>) {
        state.loadingItem = false;
        state.data = payload.data || [];
    },

    [mutationsTypes.SET_SALE_DRAGGED](state: SalesState, value) {
        state.isAnySaleDragged = false;
    },

};
