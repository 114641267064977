
/**
 *  Place where we should assign sub-modules VueXstate to main-module VueXstate
 */
import { notificationsActions } from './actions/notifications';
import { notificationsModule } from './module';
import { notificationsMutations } from './mutations/notifications';
import NotificationsState from './state';


/**
 * This module we will use to declare state. Check ../../index.ts and @/store.ts!
 */
notificationsModule.state = new NotificationsState();
notificationsModule.additionalMutations = notificationsMutations;
notificationsModule.additionalActions = notificationsActions;

const module = notificationsModule.module;

export default module;

