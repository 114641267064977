import {httpClient} from '@/shared/services';

import {apiConstants} from '../config';

const resendInvite = (id: number) => {
    const url = `${apiConstants.userInvites}/${id}/resend`;

    return httpClient.post(url).then((res) => res.data);
};

export {resendInvite};
