const routes = [
    {
        path: '/investments',
        component: () =>
            import(
                /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/investments.vue'
            ),
        children: [
            {
                path: 'generator',
                name: 'investment-generator',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/generator/generator.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.generator',
                },
            },
            {
                path: 'subjects',
                name: 'subjects-list',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/subjects/list.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.subjects.view',
                },
            },
            // {
            //     path: 'prospects',
            //     name: 'prospects-list',
            //     component: () =>
            //         import(
            //             /* webpackChunkName: "investmentsRoutes" */ '@/modules/prospects/components/list.vue'
            //         ),
            //     // meta: {
            //     //     requiredPermission: 'documents.view',
            //     // },
            // },

            {
                path: 'subjects/operations/move/summary',
                name: 'move-subjects-summary',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/subjects/summary/move.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.subjects.move',
                },
            },
            {
                path: 'subjects/operations/copy/summary',
                name: 'copy-subjects-summary',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/subjects/summary/copy.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.subjects.copy',
                },
            },
            {
                path: 'subjects/operations/remove/summary',
                name: 'remove-subjects-summary',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/subjects/summary/remove.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.subjects.delete',
                },
            },
            {
                path: 'subjects/operations/edit/summary',
                name: 'edit-subjects-summary',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/subjects/summary/edit.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.subjects.edit',
                },
            },
            {
                path: 'subjects/operations/edit',
                name: 'edit-subjects-operation',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/subjects/edit.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.subjects.edit',
                },
            },
            {
                path: 'subjects/:id',
                name: 'subject-details',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/objects/subjects/details.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'investments.subjects.view',
                },
            },
            {
                path: ':id',
                name: 'investment-details',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/details.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'investments.view',
                },
            },
            {
                path: 'settings/tags',
                name: 'setting-tags',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/settings/tags/list.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.tags.view',
                },
            },
            {
                path: 'settings/objects',
                name: 'setting-objects',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/settings/objects/list.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.objects.view',
                },
            },
            {
                path: 'settings/subject-properties',
                name: 'setting-items-properties',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/settings/subject-properties/list.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.subjects.properties.view',
                },
            },
            {
                path: 'settings/subject',
                name: 'setting-items',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/settings/subject/list.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.subjects.view',
                },
            },
            {
                path: 'settings/rooms',
                name: 'setting-rooms',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/settings/rooms/list.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.subjects.rooms.view',
                },
            },
            {
                path: 'settings/prospect',
                name: 'prospect-settings',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/settings/prospect-settings/list.vue'
                        ),
            },
            {
                path: '',
                name: 'investments-list',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/investments/components/list.vue'
                    ),
                meta: {
                    requiredPermission: 'investments.view',
                },
            },
        ],
    },
];

export default routes;
