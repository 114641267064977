import {MutationTree} from 'vuex';
import {investmentSubjectOperationsModule} from '@/modules/investments/shared/state/submodules';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {InvestmentSubjectOperationsState} from '@/modules/investments/shared/state/state/subject-operations';

export const mutationsTypes = investmentSubjectOperationsModule.mutationsTypes;

export const subjectOperationsMutations: MutationTree<InvestmentSubjectOperationsState> = {
    [mutationsTypes.OPERATION_REQUEST](state: InvestmentSubjectOperationsState) {
        state.loadingItem = true;
        state.progress = 1;
        state.error = '';
        state.errorMessages = {};
    },

    [mutationsTypes.OPERATION_ERROR](
        state: InvestmentSubjectOperationsState,
        payload: ErrorPayload,
    ) {
        state.error = payload.message;
        state.errorMessages = payload.errors;
        state.loadingItem = false;
    },

    [mutationsTypes.OPERATION_REMOVE_CHECK_ERROR](
        state: InvestmentSubjectOperationsState,
        payload: ErrorPayload,
    ) {
        state.listError = payload.message;
        state.listErrorMessages = payload.errors;
        state.loadingItem = false;
    },

    [mutationsTypes.OPERATION_SUCCESS](state: InvestmentSubjectOperationsState) {
        state.loadingItem = false;
    },

    'LARAVEL-ECHO_.subject_operation_edit_step'(
        state: InvestmentSubjectOperationsState,
        {current, total}: {current: number, total: number}
    ) {
        state.progress = (current / total) * 100;
    },
};
