
export class ConsentDataRequest {
    public id?: number = 0;
    public name?: string  = '';
    public symbol?: string = '';
    public content?: string = '';
    // tslint:disable-next-line:variable-name
    public is_required?: boolean = false;
    // tslint:disable-next-line:variable-name
    public answer_positive?: string = 'Tak';
    // tslint:disable-next-line:variable-name
    public answer_negative?: string = 'Nie';
}

