import {httpClient} from '@/shared/services';
import {apiConstants} from '../../config';
import {SearchParams} from '@/modules/contractors/shared/models/search-params';
import { AxiosResponse } from 'axios';
import { GusResultsPayload } from '../../payloads/gus-results-payload';

const searchContractors = (params: SearchParams) => {
    const url = apiConstants.gusSearch + params.location + '/' + params.search;

    return httpClient.get(url).then((res: AxiosResponse<GusResultsPayload>) => res.data);
};

const setGuardianForContractor = (
    contractorId: number,
    quardianId: number,
) => {
    return httpClient
        .post(`/api/v1/contractors/${contractorId}/user`, {
            user_id: quardianId,
        });
};


export { searchContractors, setGuardianForContractor };
