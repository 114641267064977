import {ActionTree} from 'vuex';
import { RootState } from '@/shared/types';
import { ModuleState } from '@/shared/state/template/module-state';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ErrorHandler } from '@/shared/state/template/helpers';
import {Ticket} from '@/modules/tickets/shared/models/ticket';
import { ticketsModule } from '@/modules/tickets/shared';
import { fetchByStatus } from '@/modules/tickets/shared';

export const actionTypes = ticketsModule.actionsTypes;

export const mutationsTypes = ticketsModule.mutationsTypes;

export const ticketsActions: ActionTree<ModuleState<Ticket, any>, RootState> = {
    async [actionTypes.FETCH_BY_STATUS]({commit}, id: number) {
        commit(mutationsTypes.FETCH_BY_STATUS_REQUEST);

        const result: any = await fetchByStatus(id).catch((e: ErrorPayload) => {
            ErrorHandler(e, mutationsTypes.FETCH_BY_STATUS_ERROR);
        });

        if (result.data && result.status === 200) {
            commit(mutationsTypes.FETCH_BY_STATUS_SUCCESS, {res: result.data, id});
        }
        return result;
    }
};
