import {ActionTree, Module, ModuleTree, MutationTree} from 'vuex';
import {ModuleState} from './module-state';
import {DataRequest, ItemData, RootState} from '@/shared/types';
import {ModuleMutations} from './module-mutations';
import {ModuleActions} from './module-actions';
import {reflectKeys} from '@/shared/services';
import {ModuleProps} from './module-props';
import {
    coreMutationTypes,
    readActionTypes,
    readMutationTypes,
    writeActionTypes,
    writeMutationTypes,
} from '@/shared/state/template/module-types';


/**
 * Root class of ModuleCreator
 * Used for simplify creating new modules
 */
export class ModuleCreator<T extends ItemData, R extends DataRequest, S = ModuleState<T, R>> {

    /** State - can be replaced by custom with additional variables */
    public state: S | ModuleState<T, R> = new ModuleState<T, R>();

    /** additional mutationTypes - MUST BE USED IF YOU ADDED ADDITIONAL MUTATIONS */
    public additionalMutationTypes: string[] = [];

    /** additional actionTypes - MUST BE USED IF YOU ADDED ADDITIONAL ACTIONS */
    public additionalActionTypes: string[] = [];

    /** additional actions */
    public additionalActions: ActionTree<any, RootState> = {};

    /** additional mutations */
    public additionalMutations: MutationTree<any> = {};

    /** submodules */
    public modules: ModuleTree<RootState> = {};

    /** get array of mutationsTypes */
    get mutationsTypes(): { [k: string]: string } {
        let mutationTypes = [...this.coreMutationTypes, ...this.readMutationTypes, ...this.additionalMutationTypes];
        if (!this.moduleProps.readOnly) {
            mutationTypes = [...mutationTypes, ...this.writeMutationTypes];
        }
        return reflectKeys(
            mutationTypes,
            this.moduleProps.prefix.toUpperCase(),
        );
    }

    /** get array of actionsTypes */
    get actionsTypes(): { [k: string]: string } {
        let actionTypes = [...this.readActionTypes, ...this.additionalActionTypes];
        if (!this.moduleProps.readOnly) {
            actionTypes = [...actionTypes, ...this.writeActionTypes];
        }
        return reflectKeys(
            actionTypes,
            this.moduleProps.prefix.toUpperCase(),
        );
    }

    get mutations(): MutationTree<ModuleState<T, R>> {
        return this.mutationsCreator ? this.mutationsCreator.mutations : {};
    }

    get actions(): ActionTree<ModuleState<T, R>, RootState> {
        return this.actionsCreator ? this.actionsCreator.actions : {};
    }


    /** get generated vuex {@link https://vuex.vuejs.org/guide/modules.html Module} */
    get module(): Module<any, RootState> {
        this.mutationsCreator =
            new ModuleMutations<T, R>(this.moduleProps, this.mutationsTypes);
        this.actionsCreator =
            new ModuleActions<ModuleState<T, R>, T, R>(this.moduleProps, this.actionsTypes, this.mutationsTypes);
        return {
            mutations: {...this.mutations, ...this.additionalMutations},
            actions: {...this.actions, ...this.additionalActions},
            state: this.state,
            modules: this.modules,
        };
    }

    private mutationsCreator?: ModuleMutations<T, R>;

    private actionsCreator?: ModuleActions<ModuleState<T, R>, T, R>;

    private coreMutationTypes: string[] = coreMutationTypes;

    private readMutationTypes: string[] = readMutationTypes;

    private writeMutationTypes: string[] = writeMutationTypes;

    private readActionTypes: string[] = readActionTypes;

    private writeActionTypes: string[] = writeActionTypes;

    /**
     * Create new ModuleCreator instance
     * @param {ModuleProps} moduleProps - props of module
     */
    constructor(public moduleProps: ModuleProps) {
    }
}

