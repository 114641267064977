
// import { notificationsModule } from '@/modules/notifications/shared';
import { NotificationDataRequest, Notification } from '@/modules/notifications/shared';
import { ModuleCreator } from '@/shared/state/template/module';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component
export default class NotificationsBell extends Vue {
    public store = this.$store.state.notificationsState;
    public updateCountAction!: string;

    get current() {
        return this.store.current;
    }

    get badgeContent() {
        if (!this.newNotificationsCount) {
            return null;
        }
        if (this.newNotificationsCount > 99) {
            return '99+';
        } else {
            return this.newNotificationsCount;
        }
    }

    get newNotificationsCount() {
        return this.store.newNotificationsCount;
    }

    set newNotificationsCount(val: number) {
        this.store.newNotificationsCount =  val;
    }

    get queue() {
        return this.$store.state.notificationsState.notificationsQueue;
    }


    @Watch('queue', { deep: true })
    public onQueueChange(val: Notification[]) {
        if (val.length > 0) {
            this.$store.commit('SHOW_SNACKBAR', {
                type: 'info',
                text: `Nowe powiadomienie`,
                duration: 1000,
            });
            this.newNotificationsCount++;
            const audio = new Audio('/assets/notification.mp3');
            audio.play();
        }
    }
}
