
import { parseDate } from '@/shared/helpers';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
@Component({})
export default class SearchDetailsItem extends Vue {
    @Prop(String) public title!: string;
    @Prop(String) public subtitle!: string;
    @Prop(String) public routeText!: string;
    @Prop(Boolean) public active!: boolean;
    @Prop(Boolean) public loading!: boolean;
    @Prop({type: Boolean, default: true}) public showNavChip!: boolean;
    @Prop(String) public link!: string;
    @Prop(String) public lastUpdated!: string;
    public parseDate = parseDate;
}
