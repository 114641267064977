
import { Component } from 'vue-property-decorator';
import SearchResult from '../search-result';
import SearchDetailsItem from '../../details/details-item.vue';
import { InvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import ContractorSearchDetails from './contractor.vue';
import SelectableTable from '@/shared/components/search/details/selectable-table.vue';
import { callSuper } from '@/shared/helpers';


@Component({
    components: {
        SearchDetailsItem,
        SelectableTable,
        ContractorSearchDetails,
    },
})
export default class InvestmentSearchResult extends SearchResult<InvestmentSubject> {
    public itemKey = 'contractor';
    public detailsList = ['contacts', 'notes', 'invoices'];

    public getRealName(name: string) {
        if (name === 'notes') { return 'contractorNotes'; } else { return name; }
    }
}
