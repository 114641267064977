const routes = [
    {
        path: '/tickets',
        component: () => import(/* webpackChunkName: "investmentsRoutes" */ '@/modules/tickets/tickets.vue'),
        children: [
            {
                path: '',
                name: 'tickets-list',
                component: () =>
                    import(/* webpackChunkName: "investmentsRoutes" */ '@/modules/tickets/components/tickets.vue'),
                // import(/* webpackChunkName: "investmentsRoutes" */ '@/modules/tickets/components/list.vue'),
                meta: {
                    requiredPermission: 'tickets.view',
                },
            },
            {
                path: ':id',
                name: 'ticket-details',
                component: () =>
                    import(/* webpackChunkName: "investmentsRoutes" */ '@/modules/tickets/components/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'tickets.view',
                },
            },
            {
                path: 'settings/statuses',
                name: 'statuses-list',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/tickets/components/settings/status/list.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'tickets.statuses.view',
                },
            },
            {
                path: 'settings/priorities',
                name: 'priorities-list',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/tickets/components/settings/priorities/list.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'tickets.priorities.view',
                },
            },
            {
                path: 'settings/tags',
                name: 'tags-list',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/tickets/components/settings/tags/list.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'tickets.tags.view',
                },
            },
            {
                path: 'settings/categories',
                name: 'tickets-categories-list',
                component: () =>
                    import(
                        /* webpackChunkName: "investmentsRoutes" */ '@/modules/tickets/components/settings/categories/list.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'tickets.categories.view',
                },
            },
        ],
    },
];

export default routes;
