import {ActionTree} from 'vuex';
import {RootState} from '@/shared/types';
import {ModuleState} from '@/shared/state/template/module-state';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import {investmentSubjectOperationsModule} from '@/modules/investments/shared/state/submodules';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {
    copySubjects,
    editSubjects,
    moveSubjects,
    removeSubjects,
} from '@/modules/investments/shared/services/subjects/subjects';
import {
    InvestmentSubjectEditOperationDataRequest,
    InvestmentSubjectMoveOperationDataRequest,
} from '@/modules/investments/shared/requests/subjects/operations';
import {InvestmentSubjectOperationDataRequest} from '@/modules/investments/shared/requests/subjects/investment-subject-operation-data-request';
import router from '@/router';
import {mutationTypes as authTypes} from '@/modules/auth/shared/state';
import {InvestmentSubjectCopyOperationDataRequest} from '@/modules/investments/shared/requests/subjects/operations/investment-subject-copy-operation-data-request';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import {AxiosResponse} from 'axios';

export const actionsTypes = investmentSubjectOperationsModule.actionsTypes;

export const mutationTypes = investmentSubjectOperationsModule.mutationsTypes;

export const subjectOperationsActions: ActionTree<ModuleState<InvestmentSubject, InvestmentSubjectOperationDataRequest>,
    RootState> = {
    async [actionsTypes.EDIT_SUBJECTS]({commit}, data: InvestmentSubjectEditOperationDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: number | void = await editSubjects(data)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            });

        if (result && result === 204) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.REMOVE_SUBJECTS]({commit}, data: InvestmentSubjectOperationDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: number | void = await removeSubjects(data)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            });


        if (result && result === 204) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.MOVE_SUBJECTS]({commit}, data: InvestmentSubjectMoveOperationDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: number | void = await moveSubjects(data)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            });

        if (result && result === 204) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.COPY_SUBJECTS]({commit}, data: InvestmentSubjectCopyOperationDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: AxiosResponse<ModuleFetchPayload<InvestmentSubject>> | void = await copySubjects(data)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            }) as AxiosResponse<ModuleFetchPayload<InvestmentSubject>>;

        if (result && result.data && result.status === 200) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result.data;
    },

    async [actionsTypes.EDIT_SUBJECTS_CHECK]({commit}, data: InvestmentSubjectEditOperationDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: number | void = await editSubjects(data, true)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            });

        if (result && result === 200) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.REMOVE_SUBJECTS_CHECK]({commit}, data: InvestmentSubjectOperationDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: number | void = await removeSubjects(data, true)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_REMOVE_CHECK_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            });


        if (result && result === 200) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.MOVE_SUBJECTS_CHECK]({commit}, data: InvestmentSubjectMoveOperationDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: number | void = await moveSubjects(data, true)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            });

        if (result && result === 200) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.COPY_SUBJECTS_CHECK]({commit}, data: InvestmentSubjectCopyOperationDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: AxiosResponse<ModuleFetchPayload<InvestmentSubject>> | void = await copySubjects(data, true)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            }) as AxiosResponse<ModuleFetchPayload<InvestmentSubject>>;

        if (result && result.status === 200) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result.status;
    },
};
