
import { parseDate, parsePrice } from '@/shared/helpers';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AddressField from '@/shared/components/elements/address.vue';
import { invoicePaymentMethods, invoiceTypes } from '@/shared/config/invoices';
import { costStatuses } from '@/shared/config/costs';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';

@Component({
    components: {
        AddressField,
        InvestmentTagChip,
    },
})
export default class CostSearchDetails extends Vue {
    @Prop(Object) public item!: null | any;
    @Prop(Number) public value!: number;
    public parsePrice = parsePrice;
    public parseDate = parseDate;

    public getPaymentMethodName(id: string) {
        return invoicePaymentMethods.find((el) => el.id === id)?.name;
    }

    public statusType(statusType: string) {
        return costStatuses.find((obj) => obj.id === statusType);
    }

    get index() {
        return this.value;
    }
    set index(val: number) {
        this.$emit('input', val);
    }

    public redirect(link: string) {
        this.$router.push(link);
        this.$emit('redirection');
    }
}
