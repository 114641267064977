import {HeaderFieldType} from '@/shared/types';

export const changesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Kod', value: 'code' },
    { text: 'Jednostka', value: 'unit' },
    { text: 'Kategoria', value: 'category'},
    { text: 'Cena netto', value: 'price'},
    { text: 'Cena brutto', value: 'price_with_tax'},

    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const categoriesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const catalogsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Opis', value: 'description' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];


export const catalogsListHeadersAdd: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Jednostka', value: 'unit' },
    { text: 'Cena netto (początkowa)', value: 'price', except: true},
    { text: 'Cena netto ', value: 'catalog_price', except: true},
    { text: 'Cena brutto ', value: 'price_with_tax' },
];


export const changesValuationHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },

    // { text: 'Akcje', value: 'actions', align: 'center', sortable: false }, ---> uncomment when it's done
];
