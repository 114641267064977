import { httpClient } from '@/shared/services';

// SUBSCRIPTIONS SERVICES

// GET

export const getOrder = (id: number) => {
    const url = `/api/v1/orders/${id}`;

    return httpClient.get(url).then((response) => response.data);
};

export const getOrderStatus = (orderId: string | Array<string | null>) => {
    const url = `/api/v1/orders/${orderId}/status`;
    return httpClient.get(url).then((res) => res.data);
};

export const getPayLink = (orderId: number) => {
    const url = `/api/v1/orders/${orderId}/pay`;
    return httpClient.get(url).then((res) => res.data);
};
