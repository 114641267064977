import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import { Client } from '../models/client';
import { ClientDataRequest } from '../requests/client-data-request';

const clientProps = new ModuleProps('client',
    {one: 'klient',
        two: 'klientów',
        five: 'klientów',
        accusative: 'klienta',
    });

clientProps.hasDialog = false;
clientProps.mockURLName = 'admin/client';

const clientModule = new ModuleCreator<Client, ClientDataRequest>(clientProps);

clientModule.additionalActionTypes = [
    'GET_CLIENT',
    'UPDATE_CLIENT'
];



export {clientModule};
