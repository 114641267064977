import { httpClient } from '@/shared/services';
import { apiConstants } from '../config';
import {SettingsDataRequest} from '@/modules/settings/shared/requests/settings-data-request';

const updateSetting = (data: SettingsDataRequest) => {
    const url = apiConstants.settings;

    return httpClient.post(url, data).then((res) => res.data);
};

export { updateSetting };
