import {actions, mutations, ModuleState, userInvitesState} from './shared/state';
import {Module} from 'vuex';
import {RootState} from '@/shared/types';

const state: ModuleState = {
    loading: false,
    loadingItem: false,
    loadingList: false,
    removing: false,
    users: [],
    roles: [],
    error: '',
    errorMessages: {},
    listError: '',
    listErrorMessages: {},
    meta: null,
    current: null,
    permissionsConfigList: []
};

const usersModule: Module<ModuleState, RootState> = {
    state,
    mutations,
    actions,
    modules: {
        userInvitesState,
    }
};

export default usersModule;
