import { httpClient } from '@/shared/services';

export const enableSettings = (data: any) => {
    const url = 'api/v1/notifications/settings/enable';

    return httpClient.post(url, data);
};
export const disableSettings = (data: any) => {
    const url = 'api/v1/notifications/settings/disable';

    return httpClient.post(url, data);
};
