

/**
 * Roles data mutations
 */
import { RolesPayload } from '@/modules/admin/shared/payloads/roles-payload';
import { ModuleState } from '@/modules/auth/shared/state';
import { indexData } from '@/shared/helpers';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { FetchParams } from '@/shared/types';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { Client } from '../models/client';
import { clientModule } from './module';

export const mutationsTypes = clientModule.mutationsTypes;

export const clientMutations: MutationTree<ModuleState> = {

};
