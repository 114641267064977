import {MutationTree} from 'vuex';
import {rentCostsModule} from '@/modules/rent-costs/shared/state/module';
import {RentcostsState} from '@/modules/rent-costs/shared/state/state';
export const mutationsTypes = rentCostsModule.mutationsTypes;

export const rentCostsMutations: MutationTree<any> = {
    [mutationsTypes.UPDATE_EDIT_FORM_DIALOG](state: RentcostsState, value: boolean): void {
        state.editFormDialog = value;
    },
};
