import { ModuleProps } from '@/shared/state/template/module-props';
import { ModuleCreator } from '@/shared/state/template/module';
import { AcceptanceTemplate } from '@/modules/acceptances/shared/models/acceptance-template';
import { AcceptanceTemplateDataRequest } from '@/modules/acceptances/shared/requests/acceptance-template-data-request';
import { AcceptanceTemplatePointDataRequest } from '@/modules/acceptances/shared/requests/acceptance-template-point-data-request';
import { AcceptanceTemplatePoint } from '@/modules/acceptances/shared/models/acceptance-template-point';
import { AcceptancePointCategory } from '../models/acceptance-point-category';
import { AcceptancePointCategoryDataRequest } from '../requests/acceptance-point-category-data-request.';
import { AcceptanceNote } from '../models/acceptance-note';
import { AcceptanceNoteDataRequest } from '../requests/acceptance-note-data-request';
import { AcceptanceTemplateList } from '../models/acceptance-template-list';
import { AcceptanceTemplateListDataRequest } from '../requests/acceptance-template-list-data-request';
import AcceptanceList from '../models/acceptance-list';
import { DataRequest } from '@/shared/types';
import {AcceptancePoint} from '@/modules/acceptances/shared/models/acceptance-point';
import {AcceptancePointDataRequest} from '@/modules/acceptances/shared/requests/acceptance-point-data-request';

const acceptanceTemplatesProps = new ModuleProps('templates',
    {
        one: 'szablon protokołu odbioru',
        two: 'szablony protokołu odbioru',
        five: 'szablonów protokołu odbioru',
    }, ['acceptances']);

acceptanceTemplatesProps.hasDialog = false;
acceptanceTemplatesProps.customStateName = 'templatesState';
acceptanceTemplatesProps.replaceItem = true;
acceptanceTemplatesProps.customDetailsRoute = 'acceptance-template-details';

export const acceptanceTemplatesModule = new ModuleCreator<AcceptanceTemplate,
    AcceptanceTemplateDataRequest>(acceptanceTemplatesProps);

const acceptanceTemplatePointsProps = new ModuleProps('points',
    {
        one: 'punkt',
        two: 'punkty',
        five: 'punktów',
    }, ['acceptances', 'templates']);

acceptanceTemplatePointsProps.hasDialog = true;
acceptanceTemplatePointsProps.customStateName = 'templatePointsState';


export const acceptanceTemplatePointsModule = new ModuleCreator<AcceptanceTemplatePoint,
    AcceptanceTemplatePointDataRequest>(acceptanceTemplatePointsProps);



const acceptancePointsCategoriesProps = new ModuleProps('point-categories',
    {
        one: 'kategoria punktów',
        two: 'kategorie punktów',
        five: 'kategorii punktów',
        accusative: 'kategorię punktów',
    }, ['acceptances']);

acceptancePointsCategoriesProps.customStateName = 'acceptancePointsCategoriesState';

export const acceptancePointsCategoriesModule = new ModuleCreator<AcceptancePointCategory,
    AcceptancePointCategoryDataRequest>(acceptancePointsCategoriesProps);


const acceptanceNotesProps = new ModuleProps('notes',
    {
        one: 'uwaga',
        two: 'uwagi',
        five: 'uwag',
        accusative: 'uwagę',
    }, ['acceptances']);
acceptanceNotesProps.customStateName = 'acceptanceNotesState';

export const acceptanceNotesModule = new ModuleCreator<AcceptanceNote, AcceptanceNoteDataRequest>(acceptanceNotesProps);

const acceptanceTemplateListProps = new ModuleProps('lists',
    {
        one: 'lista',
        two: 'listy',
        five: 'list',
        accusative: 'listę',
    }, ['acceptances', 'templates']);
acceptanceTemplateListProps.customStateName = 'acceptanceTemplateListState';

export const acceptanceTemplateListModule = new ModuleCreator<AcceptanceTemplateList,
    AcceptanceTemplateListDataRequest>(acceptanceTemplateListProps);

const acceptanceListProps = new ModuleProps('lists',
        {
            one: 'lista',
            two: 'listy',
            five: 'list',
            accusative: 'listę',
        }, ['acceptances']);

export const acceptanceListModule = new ModuleCreator<AcceptanceList,
        DataRequest>(acceptanceListProps);

const acceptancePointProps = new ModuleProps('points',
        {
            one: 'punkt',
            two: 'punkty',
            five: 'punktów',
        }, ['acceptances']);

export const acceptancePointsModule = new ModuleCreator<AcceptancePoint,
        AcceptancePointDataRequest>(acceptancePointProps);
