import {ModuleProps} from '@/shared/state/template/module-props';
import {DataRequest, ModuleConjugation} from '@/shared/types';
import {ModuleCreator} from '@/shared/state/template/module';
import {InvestmentSubjectType} from '@/modules/investments/shared/models/subjects/investment-subject-type';
import {InvestmentSubject} from '@/modules/investments/shared/models/investment-subject';
import {InvestmentSubjectDataRequest} from '@/modules/investments/shared/requests/investment-subject-data-request';
import {InvestmentSubjectRoom} from '@/modules/investments/shared/models/subjects/investment-subject-room';
import {InvestmentSubjectRoomType} from '@/modules/investments/shared/models/subjects/rooms/investment-room-type';
import {
    InvestmentSubjectMediaFileDataRequest
} from '@/modules/investments/shared/requests/subjects/investment-subject-media-file-data-request';
import {InvestmentSubjectProperty} from '@/modules/investments/shared/models/subjects/investment-subject-property';
import {InvestmentSubjectMediaFile} from '@/modules/investments/shared/models/subjects/investment-subject-media-file';
import {
    InvestmentSubjectPropertyDataRequest
} from '@/modules/investments/shared/requests/subjects/investment-subject-property-data-request';
import {InvestmentSubjectTypeDataRequest} from '../../requests/subjects/investment-subject-type-data-request';
import {InvestmentSubjectPlan} from '@/modules/investments/shared/models/investment-subject-plan';

import { InvestmentSubjectChangesValuation } from '../../models/subjects/investment-subject-changes-valuation';
import {
    InvestmentSubjectChangesValuationDataRequest
} from '../../requests/subjects/investment-subject-changes-valuation-data-request';

import {
    InvestmentSubjectPlanDataRequest
} from '@/modules/investments/shared/requests/investment-subject-plan-data-request';
import {OperationType} from '@/shared/state/template/module-types';
import {ProspectSetting} from '@/modules/investments/shared/models/prospect-setting';
import {ProspectSettingDataRequest} from '@/modules/investments/shared/requests/prospect-setting-data-request';
import { InvestmentSubjectReservation } from '../../models/subjects/investment-subject-reservation';
import { InvestmentSubjectReservationDataRequest } from '../../requests/subjects/investment-subject-reservation-data-request';

import { InvestmentSubjectCatalogChange } from '../../models/subjects/investment-subject-catalog-change';
import { InvestmentSubjectCatalogChangeDataRequest } from '../../requests/subjects/investment-subject-catalog-change-data-request';

const investmentSubjectsProps = new ModuleProps('subjects',
    {
        one: 'przedmiot inwestycji',
        two: 'przedmioty inwestycji',
        five: 'przedmiotów inwestycji',
    },
    ['investments', 'objects'],
);
investmentSubjectsProps.loadListOnCreate = false;
investmentSubjectsProps.hasDialog = false;
investmentSubjectsProps.fullscreenForm = true;
investmentSubjectsProps.addParentProperty = true;
investmentSubjectsProps.permissionBaseName = 'investments.subjects';
investmentSubjectsProps.rootParentOperations = [
    OperationType.Store,
    OperationType.Update,
    OperationType.Show,
    OperationType.Delete,
];

const investmentSubjectsModule = new ModuleCreator<InvestmentSubject,
    InvestmentSubjectDataRequest>(investmentSubjectsProps);

investmentSubjectsModule.additionalMutationTypes = [
    'UPDATE_PROSPECT_EDITED',
    'UPDATE_PROSPECT_FORM_DIALOG',
];

const investmentSubjectOperationsProps = new ModuleProps('subjects',
    {
        one: 'przedmiot inwestycji',
        two: 'przedmioty inwestycji',
        five: 'przedmiotów inwestycji',
    },
    ['investments'],
);
investmentSubjectOperationsProps.hasDialog = false;
investmentSubjectOperationsProps.fullscreenForm = true;
investmentSubjectOperationsProps.customStateName = 'subjectOperationsState';

const investmentSubjectOperationsModule = new ModuleCreator<InvestmentSubject,
    InvestmentSubjectDataRequest>(investmentSubjectOperationsProps);

investmentSubjectOperationsModule.additionalMutationTypes = [
    'OPERATION_REQUEST',
    'OPERATION_ERROR',
    'OPERATION_SUCCESS',
    'OPERATION_REMOVE_CHECK_ERROR',
];

investmentSubjectOperationsModule.additionalActionTypes = [
    'MOVE_SUBJECTS',
    'COPY_SUBJECTS',
    'EDIT_SUBJECTS',
    'REMOVE_SUBJECTS',
    'MOVE_SUBJECTS_CHECK',
    'COPY_SUBJECTS_CHECK',
    'EDIT_SUBJECTS_CHECK',
    'REMOVE_SUBJECTS_CHECK',
];

const investmentSubjectMediaFilesProps = new ModuleProps('mediaFiles',
    {
        one: 'przedmiot inwestycji',
        two: 'przedmioty inwestycji',
        five: 'przedmiotów inwestycji',
    }, ['investments', 'subjects']);

investmentSubjectMediaFilesProps.parentIdInURLOperations = [OperationType.Index, OperationType.Store];

const investmentSubjectTypesProps = new ModuleProps('types',
    {
        one: 'typ przedmiotu inwestycji',
        two: 'typy przedmiotów inwestycji',
        five: 'typów przedmiotów inwestycji',
    } as ModuleConjugation,
    ['investments', 'subjects'],
);

investmentSubjectTypesProps.parentIdInURLOperations = [];
investmentSubjectTypesProps.hasProtectedIncrement = true;

const investmentSubjectPropertyTypesProps = new ModuleProps('types',
    {
        one: 'typ właściwości przedmiotu inwestycji',
        two: 'typy właściwości przedmiotów inwestycji',
        five: 'typów właściwości przedmiotów inwestycji',
    } as ModuleConjugation,
    ['investments', 'subjects', 'properties'],
);

const investmentSubjectPropertiesProps = new ModuleProps(
    'properties',
    {
        one: 'właściwość przedmiotu inwestycji',
        two: 'właściwości przedmiotu inwestycji',
        five: 'właściwości przedmiotu inwestycji',
    } as ModuleConjugation,
    ['investments', 'subjects'],
);


const investmentSubjectRoomsProps = new ModuleProps('rooms',
    {
        one: 'pomieszczenie przedmiotu',
        two: 'pomieszczenia przedmiotu',
        five: 'pomieszczeń przedmiotu',
    } as ModuleConjugation,
    ['investments', 'subjects'],
);

investmentSubjectRoomsProps.addParentProperty = true;

const investmentSubjectRoomsModule = new ModuleCreator<InvestmentSubjectRoom, DataRequest>(investmentSubjectRoomsProps);

investmentSubjectRoomsModule.additionalMutationTypes = [
    'UPDATE_POINT_SELECTING',
];

const investmentSubjectRoomTypesProps = new ModuleProps('types',
    {
        one: 'typ pomieszczenie',
        two: 'typy pomieszczenia',
        five: 'typów pomieszczeń',
    } as ModuleConjugation,
    ['investments', 'subjects', 'rooms'],
);

investmentSubjectRoomTypesProps.parentIdInURLOperations = [];
investmentSubjectRoomTypesProps.rootParentOperations = [];

const prospectSettingsProps = new ModuleProps('prospect-settings',
    {
        one: 'pole prospektu',
        two: 'pola prospektu',
        five: 'pól prospektu',
    } as ModuleConjugation,
    ['investments', 'subjects'],
);

prospectSettingsProps.parentIdInURLOperations = [];
prospectSettingsProps.rootParentOperations = [];
// prospectSettingsProps.hasProtectedIncrement = true;
prospectSettingsProps.customStateName = 'prospectSettingsState';


const investmentSubjectPlansProps = new ModuleProps('plans',
    {
        one: 'plan',
        two: 'plany',
        five: 'planów',
    },
    ['investments', 'subjects'],
);

const investmentSubjectChangesValuationProps = new ModuleProps('changesValuation',
{
    one: 'wycena zmian',
    two: 'wyceny zmian',
    five: 'wycen zmian'
} as ModuleConjugation,
['investments', 'subjects']
);
investmentSubjectChangesValuationProps.customStateName = 'subjectChangesValuationState';

const investmentSubjectCatalogChangesProps = new ModuleProps('catalogChanges',
{
    one: 'zmiana katalogu',
    two: 'zmiany katalogu',
    five: 'zmian katalogu'
} as ModuleConjugation,
['investments', 'subjects']
);
investmentSubjectCatalogChangesProps.customStateName = 'subjectCatalogChangesState';

const investmentSubjectChangesValuationModule = new ModuleCreator<InvestmentSubjectChangesValuation,
    InvestmentSubjectChangesValuationDataRequest>(investmentSubjectChangesValuationProps);

const investmentSubjectCatalogChangesModule = new ModuleCreator<InvestmentSubjectCatalogChange,
    InvestmentSubjectCatalogChangeDataRequest>(investmentSubjectCatalogChangesProps);

investmentSubjectChangesValuationModule.additionalMutationTypes = [
    'UPDATE_ADD_FORM_DIALOG',
    'UPDATE_ADD_FORM_EDITED',
    'UPDATE_FORM_CHANGE_DIALOG',

    'SEND_REQUEST',
    'SEND_ERROR',
    'SEND_SUCCESS',

    'DOWNLOAD_REQUEST',
    'DOWNLOAD_SUCCESS',
    'DOWNLOAD_ERROR',

    'PREVIEW_REQUEST',
    'PREVIEW_SUCCESS',
    'PREVIEW_ERROR',
];

investmentSubjectChangesValuationModule.additionalActionTypes = [
    'SEND_EMAIL',
    'DOWNLOAD',
    'PREVIEW'
];

const investmentSubjectReservationProps = new ModuleProps('reservation',
{
    one: 'rezerwacja',
    two: 'rezerwacje',
    five: 'rezerwacji'
} as ModuleConjugation,
['investments', 'subjects']
);


export const investmentSubjectReservationModule = new ModuleCreator<InvestmentSubjectReservation,
    InvestmentSubjectReservationDataRequest>(investmentSubjectReservationProps);



export {investmentSubjectsModule, investmentSubjectOperationsModule};

export const investmentSubjectMediaFilesModule = new ModuleCreator<InvestmentSubjectMediaFile,
InvestmentSubjectMediaFileDataRequest>(investmentSubjectMediaFilesProps);

export const investmentSubjectTypesModule = new ModuleCreator<InvestmentSubjectType,
InvestmentSubjectTypeDataRequest>(investmentSubjectTypesProps);

export const investmentSubjectPropertiesModule = new ModuleCreator<InvestmentSubjectProperty,
    InvestmentSubjectPropertyDataRequest>(investmentSubjectPropertiesProps);

export const investmentSubjectPropertyTypesModule = new ModuleCreator<InvestmentSubjectType,
DataRequest>(investmentSubjectPropertyTypesProps);

export {investmentSubjectRoomsModule};

export const investmentSubjectRoomTypesModule = new ModuleCreator<InvestmentSubjectRoomType,
DataRequest>(investmentSubjectRoomTypesProps);

export const investmentSubjectPlansModule = new ModuleCreator<InvestmentSubjectPlan,
InvestmentSubjectPlanDataRequest>(investmentSubjectPlansProps);

export {investmentSubjectChangesValuationModule};

export {investmentSubjectCatalogChangesModule};


export const prospectSettingsModule = new ModuleCreator<ProspectSetting,
    ProspectSettingDataRequest<any>>(prospectSettingsProps);

