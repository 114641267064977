
import {Component, Prop} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {actionsTypes} from '@/modules/media/shared/state';
import ImageInput from '@/shared/components/elements/image-input.vue';
import {callSuper, dataURItoBlob} from '@/shared/helpers';
import {errorsToArray, logger} from '@/shared/services';
import {MediaFileDataRequest} from '@/modules/media/shared/requests/media-file-data-request';
import {FileProgress} from '@/modules/media/shared/models/file-progress';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import {mediaPermissions} from '@/shared/config/media';
import { bytesToSize } from '../shared/helpers';

import PreventUnload from '@/shared/PreventUnload.vue';
import { arrayBuffer } from 'stream/consumers';
import mime from 'mime';

@Component({
    components: {
        ImageInput,
        FormActions,
        PreventUnload
    },
})
export default class MediaFileUploadForm extends FormComponent<File> {
    @Prop({type: Array<string>, default: () => []}) public allowedExtensions!: string[];
    public store = this.$store.state.mediaState;
    public storeAction = actionsTypes.UPLOAD_MEDIA;
    public updateAction = actionsTypes.UPDATE_MEDIA;
    public dataURItoBlob = dataURItoBlob;
    public bytesToSize = bytesToSize;
    public mediaPermissions = mediaPermissions;
    public croppedImage: string | ArrayBuffer | null = '';
    public editedIndex: number = 0;
    public cropDialog: boolean = false;
    public filePermissions: { [k: string]: string } = {};
    public showWarning: boolean = false;


    get files() {
        return this.store.files;
    }

    set files(val: File[]) {
        this.store.files = val;
    }

    get filesQueue() {
        return this.$store.state.mediaState.filesQueue;
    }

    get fileErrors() {
        return this.$store.state.mediaState.fileErrors;
    }

    public fileLoading(name: string) {
        const item = this.filesQueue.find((e: FileProgress) => e.id === name);
        return item ? item.loading : false;
    }

    public fileError(name: string) {
        return this.store.fileErrors[name] ? this.store.fileErrors[name].message : '';
    }

    public permissionType(index: string) {
        return mediaPermissions.find(({value}) => this.filePermissions[index] === value);
    }

    public fileErrorMessages(name: string) {
        return this.store.fileErrors[name] ? errorsToArray(this.store.fileErrors[name].errors) : [];
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj plik' : 'Dodaj pliki';
    }

    public initForm() {
        this.form = this.$refs.createMediaForm;
    }

    get editedFileName() {
        return this.files[this.editedIndex] ? this.files[this.editedIndex].name : '';
    }

    public updateFile(cropped: string) {
        if (cropped) {
            const name = this.files[this.editedIndex].name;
            this.files[this.editedIndex] = this.dataURItoBlob(cropped, name);
        } else {
            this.removeFile(this.editedIndex);
        }
    }

    public removeFile(index: number) {
        this.files.splice(index, 1);
    }

    public openCropDialog(index: number) {
        const reader = new FileReader();
        reader.onload = () => {
            this.croppedImage = reader.result;
            this.editedIndex = index;
            this.cropDialog = true;
        };
        reader.readAsDataURL(this.files[index]);
    }

    public validateFileInput(e: any) {
        this.valid = true;
        this.showWarning = false;

        e.forEach((el: any, index: number) => {
            this.filePermissions[el.name + index] = '1';
            if (
                !(this.allowedExtensions.length <= 0)
                &&
                !this.allowedExtensions.includes(mime.getExtension(el.type) as string)) {
                    this.valid = false;
                    this.showWarning = true;
            }
        });

        return this.valid;
    }

    get usedMediaSize() {
        return this.store.meta ? this.store.meta.used_media_size_bytes : 0;
    }

    get maxMediaSize() {
        return this.store.meta ? this.store.meta.max_media_size_bytes : 0;
    }

    public filesMaxSize(files: File[]) {
        const sizes = files.map(({size}) => size).reduce((a, b) => a + b, 0);
        return (this.usedMediaSize + sizes) < this.maxMediaSize || `Za mało miejsca na przestrzeni mediów na żeby przesłać`;
    }

    public async submitForm() {
        this.busy = true;
        const files = this.files
            .map((e, index) => new MediaFileDataRequest((e.name + index), e, this.filePermissions[e.name + index]));

        if (!this.valid) {
            return;
        }

        this.showWarning = false;

        this.$store.dispatch(this.storeAction, files)
            .then((response: MediaFile[]) => {
                const match = response.filter((e: MediaFile, index: number) =>
                    e.name === this.files[index].name);
                if (match.length === files.length) {
                    this.files = [];
                    this.busy = false;
                    this.valid = true;
                    this.$emit('saved', response);
                }
            })
            .catch((err) => logger.error(err));
    }

    public created() {
        //
    }

}
