import { render, staticRenderFns } from "./address.vue?vue&type=template&id=d6e5f356&scoped=true&"
import script from "./address.vue?vue&type=script&lang=ts&"
export * from "./address.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6e5f356",
  null
  
)

export default component.exports