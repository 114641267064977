import {VisualizationView} from '@/modules/visualizations/shared/models/visualization-view';
import {VisualizationViewDataRequest} from '@/modules/visualizations/shared/requests/visualization-view-data-request';
import {ModuleState} from '@/shared/state/template/module-state';

export class ViewsState extends ModuleState<VisualizationView, VisualizationViewDataRequest> {
    public editorDialog: boolean = false;
    public currentView: number = 0;
    public editedItemEditor: VisualizationViewDataRequest = {} as VisualizationViewDataRequest;
    public changeHistory: string[] = [];
}
