import {ActionTree} from 'vuex';
import {RootState} from '@/shared/types';
import {
    downloadCatalog,
    sendCatalog,
    previewCatalog,
} from '@/modules/changes/shared/services';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {CatalogsState} from '../state/catalogs';
import {mutationTypes} from '../mutations/catalogs';
import {catalogsModule} from '../submodules';
import {CatalogEmailRequest} from '@/modules/changes/shared/requests/catalog-email-request';
import {CatalogDataRequest} from '@/modules/changes/shared/requests/catalog-data-request';
import {ErrorHandler} from '@/shared/state/template/helpers';

const actionsTypes = catalogsModule.actionsTypes;

export const catalogsActions: ActionTree<CatalogsState, RootState> = {
    async [actionsTypes.SEND_EMAIL]({commit}, data: CatalogEmailRequest) {
        commit(mutationTypes.SEND_REQUEST);

        const result = await sendCatalog(data).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.SEND_ERROR, {
                type: 'error',
                text: 'Błąd podczas wysyłania',
                permanent: true,
                errors: e.errors,
            }),
        );

        if (result) {
            commit('SHOW_SNACKBAR', {type: 'success', text: 'Pomyślnie wysłano fakturę'});
            commit(mutationTypes.SEND_SUCCESS);
        }

        return result;
    },

    async [actionsTypes.DOWNLOAD]({commit}, id: number) {
        commit(mutationTypes.DOWNLOAD_REQUEST);

        const result: Blob = await downloadCatalog(id).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.DOWNLOAD_ERROR, {
                type: 'error',
                text: 'Błąd podczas pobierania',
                permanent: true,
                errors: e.errors,
            }),
        );

        if (result) {
            commit('SHOW_SNACKBAR', {type: 'success', text: 'Pomyślnie pobrano fakturę'});
            commit(mutationTypes.DOWNLOAD_SUCCESS);
        }

        return result;
    },

    async [actionsTypes.PREVIEW]({commit}, data: CatalogDataRequest) {
        commit(mutationTypes.PREVIEW_REQUEST);

        const result: Blob = await previewCatalog(data).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.PREVIEW_ERROR, {
                type: 'error',
                text: 'Błąd podczas pobierania',
                permanent: true,
                errors: e.errors,
            }),
        );

        if (result) {
            commit('SHOW_SNACKBAR', {type: 'success', text: 'Pomyślnie pobrano podgląd katalogu'});
            commit(mutationTypes.PREVIEW_SUCCESS);
        }

        return result;
    },
};
