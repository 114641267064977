import { RelatedInvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import { RelatedInvestmentSubjectDataRequest } from '../../requests/related-investment-subjects-data-request';
import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { OperationType } from '@/shared/state/template/module-types';

const relatedInvestmentSubjectsProps = new ModuleProps(
    'relatedInvestmentSubjects',
    {
        one: 'powiązany przedmiot inwestycji',
        two: 'powiązane przedmioty inwestycji',
        five: 'powiązanych przedmiotów inwestycji',
        accusative: 'powiązanie'
    },
    ['contractors'],
);

relatedInvestmentSubjectsProps.customStateName = 'relatedInvestmentSubjectsState';
relatedInvestmentSubjectsProps.customDetailsRoute = 'subjects-details';
relatedInvestmentSubjectsProps.mockURLName = 'subjects';
relatedInvestmentSubjectsProps.parentIdInURLOperations = [OperationType.Index,
    OperationType.Update, OperationType.Delete];
relatedInvestmentSubjectsProps.permissionBaseName = 'investments.subjects';
relatedInvestmentSubjectsProps.additionalPermissionBaseNames = ['contractors', 'investments'];

const relatedInvestmentSubjectsModule = new ModuleCreator<RelatedInvestmentSubject
    , RelatedInvestmentSubjectDataRequest> (
    relatedInvestmentSubjectsProps,
);

export {relatedInvestmentSubjectsModule};
