import Vue from 'vue';
import {MutationTree} from 'vuex';
import {ModuleState} from '@/modules/admin/shared/state/state';
import {mutationTypes} from '@/modules/admin/shared/state/types';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {indexData} from '@/shared/helpers';
import {RolesPayload} from '@/modules/admin/shared/payloads/roles-payload';
import {ShowRolePayload} from '@/modules/admin/shared/payloads/show-role-payload';
import {FetchParams} from '@/shared/types';

/**
 * Roles data mutations
 */

export const rolesMutations: MutationTree<ModuleState> = {
    /** users data request */
    [mutationTypes.ROLES_DATA_REQUEST](state: ModuleState, params: FetchParams): void {
        Vue.set(state, params && params.simple ? 'loadingList' : 'loading', true);
        Vue.set(state, 'roles', []);
        Vue.set(state, 'listError', null);
        Vue.set(state, 'listErrorMessages', {});
    },

    /** users data success */
    [mutationTypes.ROLES_DATA_SUCCESS](state: ModuleState, payload: RolesPayload): void {
        Vue.set(state, payload.simple ? 'loadingList' : 'loading', false);
        Vue.set(state, 'roles', indexData(payload.data, payload.meta));
        if (!payload.simple) {
            Vue.set(state, 'meta', payload.meta);
        }
    },

    /** users data error */
    [mutationTypes.ROLES_DATA_ERROR](state: ModuleState, payload: ErrorPayload): void {
        Vue.set(state, 'loading', false);
        Vue.set(state, 'roles', []);
        Vue.set(state, 'listError', payload.message);
        Vue.set(state, 'listErrorMessages', payload.errors);
    },

    [mutationTypes.ROLE_GET_REQUEST](state: ModuleState): void {
        Vue.set(state, 'loadingItem', true);
        Vue.set(state, 'current', null);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
    },

    [mutationTypes.ROLE_GET_SUCCESS](state: ModuleState, payload: ShowRolePayload): void {
        Vue.set(state, 'loadingItem', false);
        Vue.set(state, 'current', payload.data);
    },

    [mutationTypes.ROLE_GET_ERROR](state: ModuleState, payload: ErrorPayload): void {
        Vue.set(state, 'loadingItem', false);
        Vue.set(state, 'current', null);
        Vue.set(state, 'error', payload.message);
        Vue.set(state, 'errorMessages', payload.errors);
    },

    [mutationTypes.ROLE_REMOVE_REQUEST](state: ModuleState): void {
        Vue.set(state, 'removing', true);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
    },

    [mutationTypes.ROLE_REMOVE_ERROR](state: ModuleState, payload: ErrorPayload): void {
        Vue.set(state, 'removing', false);
        Vue.set(state, 'error', payload.message);
        Vue.set(state, 'errorMessages', payload.errors);
    },

    [mutationTypes.ROLE_REMOVE_SUCCESS](state: ModuleState): void {
        Vue.set(state, 'removing', false);
    },
};
