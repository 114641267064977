const routes = [
    {
        path: '/lands',
        component: () => import(/* webpackChunkName: "landsRoutes" */ './land.vue'),
        children: [
            {
                path: '',
                name: 'lands-list',
                component: () =>
                    import(/* webpackChunkName: "landsRoutes" */ './components/list.vue'),
                meta: {
                    requiredPermission: 'lands.view',
                },
            },

            {
                path: ':id',
                name: 'land-details',
                component: () =>
                    import(/* webpackChunkName: "landsRoutes" */ './components/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'lands.view',
                },
            },
        ],
    },
];

export default routes;
