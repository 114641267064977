import {ModuleCreator} from '@/shared/state/template/module';
import {ModuleProps} from '@/shared/state/template/module-props';
import {Sale} from '../models/sale';
import {SaleDataRequest} from '@/modules/sales/shared';

const salesProps = new ModuleProps('sales', {
    one: 'lead sprzedażowy',
    two: 'leady sprzedażowe',
    five: 'lead sprzedażowych',
});
salesProps.hasDialog = false;

const salesModule = new ModuleCreator<Sale, SaleDataRequest>(salesProps);

salesModule.additionalMutationTypes = [
    'FETCH_BY_STATUS_REQUEST',
    'FETCH_BY_STATUS_ERROR',
    'FETCH_BY_STATUS_SUCCESS',
    'SET_SALE_DRAGGED',
];

salesModule.additionalActionTypes = [
    'FETCH_BY_STATUS',
];

export {salesModule};
