import {
    rentMediaFilesModule,
    rentPropertiesModule,
    rentPropertyTypesModule,
    rentRoomsModule,
    rentRoomTypesModule,
    rentEquipmentModule,
    rentsModule,
    rentTypesModule,
    rentPlansModule,
    rentDocumentsModule,
    rentDocumentAttachmentsModule,
    rentDocumentCategoriesModule,
    rentDocumentNotesModule,
    rentDocumentLogsModule,
} from '@/modules/rents/shared/state/state';
import {indexData} from '@/shared/helpers';

import {rentsActions} from '@/modules/rents/shared/state/actions/rents';
import {rentsMutations} from '@/modules/rents/shared/state/mutations/rents';
import {RentRoomsState} from '@/modules/rents/shared/state/state/rooms';
import {rentRoomsMutations} from '@/modules/rents/shared/state/mutations/rooms';

rentsModule.additionalMutations = rentsMutations;
rentsModule.additionalActions = rentsActions;

const rentTypesState = rentTypesModule.module;

const rentPropertiesState = rentPropertiesModule.module;

const propertyTypesState = rentPropertyTypesModule.module;

rentRoomsModule.state = new RentRoomsState();

rentRoomsModule.additionalMutations = rentRoomsMutations;

const rentEquipmentState = rentEquipmentModule.module;

const rentRoomsState = rentRoomsModule.module;

const roomTypesState = rentRoomTypesModule.module;

const rentPlansState = rentPlansModule.module;

const rentDocumentsState = rentDocumentsModule.module;
const rentDocumentAttachmentsState = rentDocumentAttachmentsModule.module;
const rentDocumentCategoriesState = rentDocumentCategoriesModule.module;
const rentDocumentNotesState = rentDocumentNotesModule.module;
const rentDocumentLogsState = rentDocumentLogsModule.module;

// Settings
const rentMediaFilesState = rentMediaFilesModule.module;

rentsModule.modules = {
    rentTypesState,
    rentRoomsState,
    roomTypesState,
    rentEquipmentState,
    propertyTypesState,
    rentPropertiesState,
    rentMediaFilesState,
    rentPlansState,
    rentDocumentsState,
    rentDocumentAttachmentsState,
    rentDocumentCategoriesState,
    rentDocumentNotesState,
    rentDocumentLogsState,
};

rentsModule.additionalMutations = rentsMutations;

const module = rentsModule.module;

export default module;
