import {ActionTree} from 'vuex';
import {RootState} from '@/shared/types';
import {mainActions} from '@/modules/settings/shared/state/actions/main';
import {ModuleState} from '@/modules/settings/shared/state/state';
import {dictionariesActions} from '@/modules/settings/shared/state/actions/dictionaries';
import {searchActions} from '@/modules/settings/shared/state/actions/search';

/**
 * Users data actions
 */
export const actions: ActionTree<ModuleState, RootState> = {
    ...mainActions,
    ...dictionariesActions,
    ...searchActions,
};
