const routes = [
    {
        name: 'first-setup',
        path: '/setup',
        component: () => import(/* webpackChunkName: "wizardRoutes" */ '@/modules/wizard/components/wizard.vue'),
        meta: {
            hideNavigationBar: true,
            expandView: true,
        },
    },
];

export default routes;
