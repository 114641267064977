import { HeaderFieldType } from '@/shared/types';

export const salesListHeaders: HeaderFieldType[] = [
    {
        text: 'Nr',
        align: 'left',
        sortable: false,
        value: 'id',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Priorytet', value: 'priority', align: 'center' },
    { text: 'Osoba zgłaszająca', value: 'assigned_user_id', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const saleStatusesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Domyślny', value: 'default', align: 'center' },
    { text: 'Zamykający', value: 'is_open', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const saleTasksListsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const kanbanTables: HeaderFieldType[] = [
    {
        text: 'Do zrobienia', value: 'sales'
    },
    {
        text: 'W trakcie realizacji', value: 'sales'
    },
    {
        text: 'Ukończone', value: 'sales'
    },
];
