const routes = [
    {
        path: '/investments/changes',
        component: () => import(/* webpackChunkName: "changesRoutes" */ '@/modules/changes/changes.vue'),
        children: [
            {
                path: '',
                name: 'changes-list',
                component: () =>
                    import(/* webpackChunkName: "changesRoutes" */ '@/modules/changes/components/changes/list.vue'),
                meta: {
                    requiredPermission: 'changes.view',
                },
            },
            {
                path: 'categories',
                name: 'categories-list',
                component: () =>
                    import(/* webpackChunkName: "changesRoutes" */ '@/modules/changes/components/categories/list.vue'),
                meta: {
                    requiredPermission: 'changes.categories.view',
                },
            },
            {
                path: 'catalogs',
                name: 'catalogs-list',
                component: () => import(/* webpackChunkName: "changesRoutes" */ '@/modules/changes/components/catalogs/list.vue'),
                meta: {
                    requiredPermission: 'changes.catalogs.view',
                },
            },
            {
                path: 'catalogs/:id',
                name: 'catalog-details',
                component: () => import(/* webpackChunkName: "changesRoutes" */ '@/modules/changes/components/catalogs/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'changes.catalogs.view',
                },
            },
        ],
    },
];

export default routes;
