import { ActionTree } from 'vuex';
import { RootState } from '@/shared/types';
import { actionsTypes } from '@/modules/admin/shared/state/types';
import { ModuleState } from '@/modules/admin/shared/state';

export const subscriptionsActions: ActionTree<ModuleState, RootState> = {
           async [actionsTypes.SNACKBAR_TRIGGER]({ commit }, params: any) {
               commit('SHOW_SNACKBAR', params);
           },
       };
