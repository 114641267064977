import {MutationTree} from 'vuex';
import {WidgetsState} from '@/modules/widgets/shared/state/state';
import {widgetsModule} from '@/modules/widgets/shared/state/module';

export const widgetsMutationTypes = widgetsModule.mutationsTypes;

export const widgetsMutations: MutationTree<WidgetsState> = {
    [widgetsMutationTypes.UPDATE_SEETINGS_DIALOG](state: WidgetsState, value: boolean): void {
        state.settingsFormDialog = value;
    },
};
