import { MutationTree } from 'vuex';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ConsentProcessesState } from '../states/consent-processes';
import { consentProcessesModule } from '../submodules';

export const mutationsTypes = consentProcessesModule.mutationsTypes;

export const consentProcessesMutations: MutationTree<any> = {
    [mutationsTypes.FETCH_ITEMS_REQUEST](state: ConsentProcessesState) {
        state.loadingItems = true;
        state.itemsError = '';
        state.itemsErrorMessages = {};
    },
    [mutationsTypes.FETCH_ITEMS_ERROR](state: ConsentProcessesState, payload: ErrorPayload) {
        state.itemsError = payload.message;
        state.itemsErrorMessages = payload.errors;
        state.loadingItems = false;
    },
    [mutationsTypes.FETCH_ITEMS_SUCCESS](state: ConsentProcessesState, payload: any) {
        state.loadingItems = false;
        state.items = payload.res.data;
        state.itemsMeta = payload.res.meta;
    },
};
