import { charNumerals } from '@/shared/config/form-rules';
import { numeralVariations } from '@/shared/helpers';
import { acceptedExtensions } from '@/shared/config/media';
import { maxFileSize } from '@/environment/environment';

const isEmail = (text: string) => /.+@.+\..+/.test(text);
const isPhone = (text: string) => /^\d{9}$/.test(text);
const isInteger = (text: string) => /(^\d+$)|(^-\d+$)/.test(text);
const isWebsite = (text: string) =>
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(text);
const isFax = (text: string) => /\+?[0-9]{7,}/.test(text);
const isNotEmptyArray = (array: [any]) => array.length > 0;
const arrayTest = (array: [string], fun: (text: string) => boolean) => {
    return array.find((text) => !fun(text)) ? false : true;
};
const isGoodPassword = (text: string) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(text);
const isPostCode = (text: string) => /^[0-9]{2}-[0-9]{3}/.test(text);

export class FormRules {
    public requiredSelect: object[] = [
        (v: string) => (!!v && (v.length > 0 || Number(v) > 0)) || 'To pole jest wymagane',
    ];

    public requiredNumberSelect: object[] = [
        (v: string) => (Number(v) >= 0) || 'To pole jest wymagane',
    ];

    public email: object = (v: string) => !!v || 'E-mail jest wymagany';

    public emailEmpty: object = (v: string) => !v || isEmail(v) || 'E-mail musi być poprawny';

    public websiteEmpty: object = (v: string) => !v || isWebsite(v) || 'Strona internetowa musi być poprawna';

    public faxEmpty: object = (v: string) => !v || isFax(v) || 'Fax musi być poprawny';

    public arrayEmpty: object = (v: [string]) => !v || isNotEmptyArray(v) || 'Pole jest wymagane';

    public arrayEmailEmpty: object = (v: [string]) => !v || arrayTest(v, isEmail) || 'E-maile muszą być poprawne';

    public phone: object = (v: string) => !!v || 'Telefon jest wymagany';

    public phoneEmpty: object = (v: string) => !v || isPhone(v) || 'Telefon musi być poprawny';

    public arrayPhoneEmpty: object = (v: [string]) => !v || arrayTest(v, isPhone) || 'Telefony muszą być poprawne';

    public password: object = (v: string) => !!v  || 'Hasło jest wymagane';

    public goodPassword: object = (v: string) => isGoodPassword(v) || 'Hasło jest nieprawidłowe';

    public passwordEmpty: object = (v: string) => (!v || (v && v.length >= 8)) || 'Hasło musi mieć minimum 8 znaków';

    public required: object = (v: string) => (!!v || String(v) === '0') || 'To pole jest wymagane';

    public notLesserThanAndEqualZero: object = (v: number) => !(v <= 0) || 'Nieprawidłowa liczba';

    public notLesserThanZero: object = (v: number) => !(v < 0) || 'Nieprawidłowa liczba';

    public hasForbiddenValue(forbiddenValues: string[]) {
        return (v: any) => (!v || (
            !forbiddenValues.map(
                (e) => e.toLocaleLowerCase()
            ).includes(typeof v === 'string' ? v.toLocaleLowerCase() : ''))) || `To pole ma nieprawidłową wartość`;
    }

    public requiredNumber: object = (v: string) => (!!v || String(v) !== '0') || 'To pole jest wymagane';

    public maximumNumber(max: number): object {
        return (v: string) => (!v || Number(v) <= max) || `Maksymalna wartość pola wynosi ${max}`;
    }

    public minimumText(count: number): object {
        return (v: string) => (!v || (v && v.length >= count)) || `To pole musi posiadać minimum ${numeralVariations(count, charNumerals)}`;
    }

    public maximumText(count: number): object {
        return (v: string) => (!v || (v && v.length <= count)) || `To pole może posiadać maksymalnie ${numeralVariations(count, charNumerals)}`;
    }

    public maximumWysiwygText(count: number): object {
        return (v: string) => (!v || (v && v.replace(/<[^>]+>/g, '').length <= count)) || `To pole może posiadać maksymalnie ${numeralVariations(count, charNumerals)}`;
    }

    public fixedLengthText(count: number, ingoreSpaces: boolean = false): object {
        if (ingoreSpaces) {
            return (v: string) => {
                v = v?.replace(/\s/g, '') || '';
                return (!v || (v && v.length === count)) || `To pole musi posiadać dokładnie ${numeralVariations(count, charNumerals)}`;
            };

        } else {
            return (v: string) => (!v || (v && v.length === count)) || `To pole musi posiadać dokładnie ${numeralVariations(count, charNumerals)}`;
        }
    }

    public multipleFixedLengthText(count: number[], ingoreSpaces: boolean = false): object {
        return (v: string) =>
            (!v || (v && count.includes(ingoreSpaces && v ? v.replace(/\s/g, '').length : v.length))) || (() => {
                let msg: string = '';
                count.forEach((el: any, index: number) => {
                    if (index === 0) {
                        msg += el;
                    } else if (index === count.length - 1) {
                        msg += ` lub ${numeralVariations(el, charNumerals)}`;
                    } else { msg += `, ${el}`; }
                });
                return `To pole musi posiadać dokładnie ${msg}`;
            })();


    }

    public numberType: object = (v: string) => !v || !/[^\d]/.test(v) || 'Pole może posiadać tylko cyfry';

    public integerType: object = (v: string) => !v || isInteger(v) || 'Pole musi zawierać liczbę całkowitą';

    public priceType: object = (v: string) =>
        (!v || String(v) !== '0' || (!/^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/.test(v) || !/[^\d]/.test(v))) || 'Pole musi być ceną'

    public fileType: object = (files: File[]) => {
        const types = [];
        for (const file of files) {
            const hasAcceptedExtension = acceptedExtensions.find((extension) => {
                return file.name.toLocaleLowerCase().endsWith(extension);
            });
            types.push(hasAcceptedExtension);
        }
        return !types.includes(undefined) || `Plik ${files[types.indexOf(undefined)].name} posiada nieobsługiwane rozszerzenie pliku`;
    }

    public filesSize: object = (files: File[]) => {
        const fileSize = files.find(({ size }) => {
            return size > maxFileSize;
        });
        return !fileSize || `Plik ${files[files.indexOf(fileSize)].name} jest za duży`;
    }

    public postCode: object = (v: string) => !v || isPostCode(v) || 'Kod pocztowy musi być poprawny';
}
