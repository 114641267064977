import {MutationTree} from 'vuex';
import {costsModule} from '@/modules/costs/shared/state/module';
import {CostsState} from '@/modules/costs/shared/state/state';


export const mutationsTypes = costsModule.mutationsTypes;

export const costsMutations: MutationTree<any> = {
    [mutationsTypes.UPDATE_EDIT_FORM_DIALOG](state: CostsState, value: boolean): void {
        state.editFormDialog = value;
    },
};
