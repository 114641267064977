import {ActionTree} from 'vuex';
import {actionsTypes, ModuleState, mutationTypes} from '@/modules/settings/shared/state';
import {FetchParams, RootState} from '@/shared/types';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {fetchTaxRates, fetchUnits} from '@/modules/settings/shared/services/dictionaries';
import {UnitsPayload} from '@/modules/settings/shared/payloads/units-payload';
import {TaxRatesPayload} from '@/modules/settings/shared/payloads/tax-rates-payload';

export const dictionariesActions: ActionTree<ModuleState, RootState> = {
    async [actionsTypes.FETCH_UNITS]({commit}, params: FetchParams) {
        commit(mutationTypes.FETCH_UNITS_REQUEST);

        const result: UnitsPayload = await fetchUnits(params).catch((response: ErrorPayload) => {
            commit(mutationTypes.FETCH_UNITS_ERROR, response);
        });

        if (result) {
            commit(mutationTypes.FETCH_UNITS_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.FETCH_TAX_RATES]({commit}, params: FetchParams) {
        commit(mutationTypes.FETCH_TAX_RATES_REQUEST);

        const result: TaxRatesPayload = await fetchTaxRates(params).catch((response: ErrorPayload) => {
            commit(mutationTypes.FETCH_TAX_RATES_ERROR, response);
        });

        if (result) {
            commit(mutationTypes.FETCH_TAX_RATES_SUCCESS, result);
        }

        return result;
    },
};
