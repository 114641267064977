import {SearchItem} from '@/shared/types';
import {InvoiceDataRequest} from '@/modules/invoices/shared/requests/invoice-data-request';
import {Invoice} from '@/modules/invoices/shared/models/invoice';
import {ModuleState} from '@/shared/state/template/module-state';

export class InvoicesState extends ModuleState<Invoice, InvoiceDataRequest> {
    public sendingItem: boolean = false;
    public searching: boolean = false;
    public approvingItem: boolean = false;
    public loadingAddress: boolean = false;
    public downloadingItem: boolean = false;
    public itemsErrors: object = {};
    public searchingResults: SearchItem[] = [];
}
