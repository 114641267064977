import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { ContractorType } from '../../../models/settings/contractors-type';
import { ContractorTypeDataRequest } from '../../../requests/settings/contactors-type-data-request';

const contractorsTypesProps = new ModuleProps(
    'types',
    {
        one: 'typ kontrahenta',
        two: 'typy kontrahentów',
        five: 'typów kontrahentów',
    },
    ['contractors'],
);

contractorsTypesProps.customStateName = 'contractorsTypesModuleState';
contractorsTypesProps.hasProtectedIncrement = true;

export const contractorsTypesModule = new ModuleCreator<
    ContractorType,
    ContractorTypeDataRequest
>(contractorsTypesProps);
