import {actions, mutations, getters, ModuleState} from '@/modules/auth/shared/state';
import {Module} from 'vuex';
import { RootState } from '@/shared/types';

import { confirmPasswordState } from '@/modules/auth/shared/state/confirm-password';

const state: ModuleState = {
    loading: false,
    menuLoading: localStorage.getItem('token') !== null,
    loggedIn: localStorage.getItem('token') !== null,
    user: null,
    access_token: localStorage.getItem('token') || '',
    hostname: localStorage.getItem('hostname') || '',
    calming: false,
    timer: 60,
    loadingExtend: false,
    extendError: '',
    redirect: false,
    error: '',
};

const authModule: Module<ModuleState, RootState> = {
    state,
    mutations,
    actions,
    getters,
    modules: {
        confirmPasswordState
    }
};

export default authModule;
