/** Actions types constants */
import {ActionTree} from 'vuex';
import {checkUser, loginUser, logoutUser} from '@/modules/auth/shared/services';
import {LoginPayload} from '@/modules/auth/shared/payloads/login-payload';
import {LoginRequest} from '@/modules/auth/shared/requests/login-request';
import {LogoutPayload} from '@/modules/auth/shared/payloads/logout-payload';
import {mutationTypes} from '@/modules/auth/shared/state/mutations';
import {reflectKeys} from '@/shared/services';
import {RootState} from '@/shared/types';
import {UserPayload} from '@/modules/auth/shared/payloads/user-payload';
import {ModuleState} from '@/modules/auth/shared/state/types';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {User} from '../models/user';
import {laravelEchoConfig} from '@/environment/environment';

/** Prefix for mutation types and actiontypes */
const namespacedPrefix = '[AUTH]';

/** Used to find ON_USER_LOGGED action names in all modules */
const onUserLoggedRegex = /(\[(.*?)\] )ON_USER_LOGGED$/gm;

const afterLogin = (user: User, dispatch: any, commit: any, that: any) => {

    // dispatching of all ON_USER_LOGGED actions
    // @ts-ignore-next-line
    Object.keys(that._actions).forEach((actionName: string) => {
            if (actionName.match(onUserLoggedRegex)) {
                dispatch(actionName, user);
            }
        }
    );

    const activeServiceWorker = navigator.serviceWorker.controller;
    if (activeServiceWorker) {

        Notification.requestPermission().then((status: NotificationPermission) => {
            if (status === 'granted') {
                const config = laravelEchoConfig;
                activeServiceWorker.postMessage({
                    type: 'PUSHER_AUTH',
                    config,
                    authToken: localStorage.getItem('token'),
                    channel: `private-client.${user.client_id}.user.${user.id}`
                });
            }
        });
    }

    //  websockets listeners setup
    // @ts-ignore-next-line
    that._vm.$echo
        .private(`client.${user.client_id}.user.${user.id}`)
        .listenToAll((event: string, data: any) => {
            const mutationName = `LARAVEL-ECHO_${event === '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
                ? 'NOTIFICATION'
                : event
            }`;

            // @ts-ignore-next-line
            if (mutationName in that._mutations) {
                commit(mutationName, data);
            }
        });
    that._vm.$echo
        .channel(`client.${user.client_id}`)
        .listenToAll((event: string, data: any) => {
            const mutationName = `LARAVEL-ECHO_${event === '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
                ? 'NOTIFICATION'
                : event
                }`;

            // @ts-ignore-next-line
            if (mutationName in that._mutations) {
                commit(mutationName, data);
            }
        });
};

export const actionsTypes = reflectKeys(
    ['CHECK_USER', 'LOGIN_USER', 'LOGOUT_USER'],
    namespacedPrefix,
);

/**
 * Users data actions
 */
export const actions: ActionTree<ModuleState, RootState> = {
    /** check user */
    async [actionsTypes.CHECK_USER]({commit, rootState, dispatch}) {
        commit(mutationTypes.CHECK_USER_REQUEST);

        const result: UserPayload = await checkUser().catch((e: ErrorPayload) => {
            commit(mutationTypes.AUTH_ERROR, e);
        });

        if (result) {
            commit(mutationTypes.CHECK_USER_SUCCESS, result);
            afterLogin(result.data, dispatch, commit, this);
        }

        return result;
    },

    async [actionsTypes.ON_USER_LOGGED]({ rootState }, user) {
        rootState.testMode = user.test_mode;
        const localTestMode = localStorage.getItem('testMode');

        if (rootState.testMode === 'switching') {
            return;
        }

        if (!localTestMode || localTestMode !== rootState.testMode) {
            localStorage.setItem('testMode', rootState.testMode as string);
            localStorage.setItem('showTestModeBannerAgain', 'true');
        }
    },

    /** login user */
    async [actionsTypes.LOGIN_USER]({commit, dispatch}, data: LoginRequest) {

        const result: LoginPayload = await loginUser(data).catch((e: ErrorPayload) => {
            commit(mutationTypes.AUTH_ERROR, e);
        });

        if (result) {
            commit(mutationTypes.LOGIN_SUCCESS, result);
            afterLogin(result.user, dispatch, commit, this);
        }

        return result;
    },

    /** logout user */
    async [actionsTypes.LOGOUT_USER]({commit}) {
        commit(mutationTypes.AUTH_REQUEST);

        const result: LogoutPayload = await logoutUser().catch((e: ErrorPayload) => {
            commit(mutationTypes.AUTH_ERROR, e);
        });

        if (result) {
            commit(mutationTypes.LOGOUT_SUCCESS, result);
        }

        return result;
    },
};
