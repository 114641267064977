import {httpClient} from '@/shared/services';
import {AxiosResponse} from 'axios';
import {ModuleFetchPayload, ModuleShowPayload} from '@/shared/state/template/module-payloads';
import {PaymentMethod} from '@/modules/admin/shared/models/subscriptions/payment-method';
import {SubscriptionPlan} from '@/modules/admin/shared/models/subscriptions/subscription-plan';
import {ClientDataRequest} from '@/modules/admin/shared/requests/client-data-request';

export const getPlans = () => {
    const url = '/api/v1/admin/plans';
    return httpClient.get(url).then((response: AxiosResponse<ModuleFetchPayload<SubscriptionPlan>>) => response.data);
};

export const getDeveloperBillingAddress = () => {
    const url = '/api/v1/admin/client';
    return httpClient.get(url).then((response: AxiosResponse<ModuleShowPayload<ClientDataRequest>>) => response.data);
};

export const getPaymentMethods = () => {
    const url = '/api/v1/payments/methods';
    return httpClient.get(url).then((response: AxiosResponse<ModuleFetchPayload<PaymentMethod>>) => response.data);
};

export const getSummaryData = (subscriptionId: number) => {
    const url = subscriptionId ? `/api/v1/subscriptions/${subscriptionId}/renew` : `/api/v1/subscriptions/summary`;
    return httpClient.get(url).then((response) => response.data);
};

export const setPlan = (id: number) => {
    const url = `/api/v1/subscriptions/update-plan`;

    return httpClient.post(url, {
        plan_id: id,
    });
};

export const setBillingAddress = (data: object, subscriptionId: number) => {
    const url = subscriptionId
        ? `/api/v1/subscriptions/update-billing-address/renew`
        : `/api/v1/subscriptions/update-billing-address`;

    return httpClient.post(url, data);
};

export const setNewBillingAddress = (data: object) => {
    const url = '/api/v1/admin/client';
    return httpClient.put(url, data);
};

export const setPaymentMethod = (id: number, subscriptionId: string) => {
    const url = subscriptionId
        ? `/api/v1/subscriptions/update-payment-method/renew`
        : `/api/v1/subscriptions/update-payment-method`;

    return httpClient.post(url, {
        payment_method_id: id,
    });
};

export const postSubscription = (subscriptionId: number) => {
    const url = subscriptionId ? `/api/v1/subscriptions/${subscriptionId}/renew` : `/api/v1/subscriptions`;

    return httpClient.post(url).then((res) => res.data);
};
