import { Rent } from '@/modules/rents/shared/models/rent';
import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { OperationType } from '@/shared/state/template/module-types';
import { RentDataRequest } from '@/modules/rents/shared/requests/rent-data-request';

const relatedRentsProps = new ModuleProps(
    'relatedRents',
    {
        one: 'najem',
        two: 'najmy',
        five: 'najmów',
    },
    ['renters'],
);

relatedRentsProps.customStateName = 'relatedRentsState';
relatedRentsProps.parentIdInURLOperations = [OperationType.Index,
    OperationType.Update, OperationType.Store, OperationType.Delete, ];
relatedRentsProps.mockURLName = 'rents';

const relatedRentsModule = new ModuleCreator<Rent, RentDataRequest> (
    relatedRentsProps,
);

export {relatedRentsModule};
