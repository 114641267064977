

/**
 *  Dependencies list:
 *  - shared
 *  - module types
 */
import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';

import { PersonnelRole } from '../../models/personnel-role';
import { PersonnelRoleDataRequest } from '../../requests/personnel-role-data-request';

const RolesProps = new ModuleProps('roles', {
  one: 'Funkcja',
  two: 'Funkcje',
  five: 'Funkcji',
  accusative: 'Funkcja',
}, ['personnel']);

RolesProps.customStateName = 'rolesState';
const rolesModule = new ModuleCreator<PersonnelRole, PersonnelRoleDataRequest>(RolesProps);

export { rolesModule };
