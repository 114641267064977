const routes = [
    {
        path: '/invoices',
        component: () => import(/* webpackChunkName: "invoicesRoutes" */ '@/modules/invoices/invoices.vue'),
        children: [
            {
                path: ':id',
                name: 'invoice-details',
                component: () =>
                    import(/* webpackChunkName: "invoicesRoutes" */ '@/modules/invoices/components/details.vue'),
                props: true,
                meta: {
                    requiredPermission: 'invoices.view',
                },
            },
            {
                path: ':invoiceId/logs',
                name: 'invoice-logs',
                component: () =>
                    import(/* webpackChunkName: "invoicesRoutes" */ '@/modules/invoices/components/logs/list.vue'),
                props: true,
                meta: {
                    requiredPermission: 'invoices.logs.view',
                },
            },
            {
                path: '',
                name: 'invoices-list',
                component: () => import(/* webpackChunkName: "invoicesRoutes" */ '@/modules/invoices/components/list.vue'),
                props: true,
                meta: {
                    requiredPermission: 'invoices.view',
                },
            },
        ],
    },
];

export default routes;
