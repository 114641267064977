import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ListPayload, ModuleFetchPayload, ModuleShowPayload } from '@/shared/state/template/module-payloads';
import { indexData } from '@/shared/helpers';
import { ModuleState } from '@/shared/state/template/module-state';
import { DataRequest, FetchParams, ItemData, MetaItem, PickerData } from '@/shared/types';
import { MutationTree } from 'vuex';
import { ModuleProps } from '@/shared/state/template/module-props';
import { logger } from '@/shared/services';

/**
 * Part of {@link ModuleCreator} - class for generating vuex mutations
 */
export class ModuleMutations<T extends ItemData, R extends DataRequest> {
    constructor(private readonly moduleProps: ModuleProps, private readonly mutationTypes: { [k: string]: string }) {}

    get mutations(): MutationTree<ModuleState<T, R>> {
        let mutations = { ...this.coreMutations, ...this.readMutations };
        if (!this.moduleProps.readOnly) {
            mutations = { ...mutations, ...this.writeMutations };
        }
        return mutations;
    }

    get readMutations(): MutationTree<ModuleState<T, R>> {
        return {
            [this.mutationTypes.DATA_REQUEST](state: ModuleState<T, R>, simple: boolean): void {
                if (simple) {
                    state.loadingList = true;
                    state.dataList = [];
                } else {
                    state.loading = true;
                    state.data = [];
                }
                state.listError = '';
                state.listErrorMessages = {};
            },

            [this.mutationTypes.DATA_LIST_SUCCESS](state: ModuleState<T, R>, payload: ListPayload): void {
                state.loadingList = false;
                state.dataList = payload.data;
            },

            [this.mutationTypes.DATA_SUCCESS](state: ModuleState<T, R>, payload: ModuleFetchPayload<T>): void {
                state.loading = false;
                state.data = indexData(payload.data, payload.meta);
                state.meta = payload.meta;
            },

            [this.mutationTypes.PICKER_DATA_REQUEST](
                state: ModuleState<T, R>,
                { pickerId, page }: { pickerId: string; page: number },
            ): void {
                const picker = state.pickers.find((el) => el.id === pickerId);
                if (!picker) {
                    logger.error(new Error(`${state.constructor.name} picker with Id ${pickerId} does not exist`));
                    return;
                }

                picker.pickerLoading = true;
                if (page - 1 !== picker.pickerLastPage) {
                    picker.pickerData = [];
                }
                picker.pickerError = '';
                picker.pickerErrorMessages = {};
            },

            [this.mutationTypes.PICKER_DATA_SUCCESS](
                state: ModuleState<T, R>,
                { pickerId, result }: { pickerId: string; result: ModuleFetchPayload<T> },
            ): void {
                const picker = state.pickers.find((el) => el.id === pickerId);
                if (!picker) {
                    logger.error(new Error(`${state.constructor.name} picker with Id ${pickerId} does not exist`));
                    return;
                }

                picker.pickerLoading = false;
                if (result.data && result.meta) {
                    picker.pickerData.push(...result.data);
                    picker.pickerLastPage = result.meta?.current_page;
                }
                picker.pickerMeta = result.meta;
            },

            [this.mutationTypes.PICKER_DATA_ERROR](
                state: ModuleState<T, R>,
                { pickerId, response }: { pickerId: string; response: ErrorPayload },
            ): void {
                const picker = state.pickers.find((el) => el.id === pickerId);
                if (!picker) {
                    logger.error(new Error(`${state.constructor.name} picker with Id ${pickerId} does not exist`));
                    return;
                }

                picker.pickerLoading = false;
                picker.pickerError = response.message;
                picker.pickerErrorMessages = response.errors;
            },

            [this.mutationTypes.DATA_ERROR](state: ModuleState<T, R>, payload: ErrorPayload): void {
                state.loadingList = false;
                state.loading = false;
                state.data = [];
                state.dataList = [];
                state.meta = {} as MetaItem;
                state.listError = payload.message;
                state.listErrorMessages = payload.errors;
            },

            [this.mutationTypes.GET_REQUEST](state: ModuleState<T, R>): void {
                state.loadingItem = true;
                state.current = {} as T;
                state.error = '';
                state.errorMessages = {};
            },

            [this.mutationTypes.GET_SUCCESS](state: ModuleState<T, R>, payload: ModuleShowPayload<T>): void {
                state.loadingItem = false;
                state.current = payload.data;
            },

            [this.mutationTypes.GET_ERROR](state: ModuleState<T, R>, payload: ErrorPayload): void {
                state.loadingItem = false;
                state.current = {} as T;
                state.error = payload.message;
                state.errorMessages = payload.errors;
            },
        };
    }

    get coreMutations(): MutationTree<ModuleState<T, R>> {
        return {
            [this.mutationTypes.UPDATE_EDITED](state: ModuleState<T, R>, newVal?: T | R): void {
                state.editedItem = newVal;
            },

            [this.mutationTypes.UPDATE_CURRENT](state: ModuleState<T, R>, newVal?: T | null): void {
                state.current = newVal;
            },

            [this.mutationTypes.UPDATE_FORM_DIALOG](state: ModuleState<T, R>, newVal: boolean): void {
                state.formDialog = newVal;
            },

            [this.mutationTypes.UPDATE_SHOW_DIALOG](state: ModuleState<T, R>, newVal: boolean): void {
                state.showDialog = newVal;
            },

            [this.mutationTypes.UPDATE_ERROR_TITLE](state: ModuleState<T, R>, newVal: string): void {
                state.error = newVal;
            },

            [this.mutationTypes.UPDATE_ERROR_MESSAGES](
                state: ModuleState<T, R>,
                newVal: { [k: string]: string },
            ): void {
                state.errorMessages = newVal;
            },

            [this.mutationTypes.UPDATE_LIST_ERROR_TITLE](state: ModuleState<T, R>, newVal: string): void {
                state.error = newVal;
            },

            [this.mutationTypes.UPDATE_LIST_ERROR_MESSAGES](
                state: ModuleState<T, R>,
                newVal: { [k: string]: string },
            ): void {
                state.listErrorMessages = newVal;
            },

            [this.mutationTypes.UPDATE_REMOVE_DIALOG](state: ModuleState<T, R>, newVal: boolean): void {
                state.removeDialog = newVal;
            },

            [this.mutationTypes.UPDATE_RELATED_DIALOG](state: ModuleState<T, R>, newVal: boolean): void {
                state.relatedDialog = newVal;
            },

            [this.mutationTypes.UPDATE_RESTORE_DIALOG](state: ModuleState<T, R>, newVal: boolean): void {
                state.restoreDialog = newVal;
            },

            [this.mutationTypes.CHANGE_PAGE](state: ModuleState<T, R>, newVal: number): void {
                state.page = newVal;
            },

            [this.mutationTypes.UPDATE_PARAMS](state: ModuleState<T, R>, newVal: FetchParams): void {
                state.fetchParams = newVal;
            },
        };
    }

    get writeMutations(): MutationTree<ModuleState<T, R>> {
        const moduleProps: ModuleProps = this.moduleProps;
        return {
            [this.mutationTypes.UPDATE_REQUEST](state: ModuleState<T, R>): void {
                state.loadingItem = true;
                state.error = '';
                state.errorMessages = {};
            },

            [this.mutationTypes.UPDATE_SUCCESS](state: ModuleState<T, R>, payload: ModuleShowPayload<T>): void {
                state.loadingItem = false;
                if (payload.data && payload.data.index && moduleProps.replaceItem) {
                    state.data[payload.data.index - 1] = payload.data;
                }
            },

            [this.mutationTypes.UPDATE_ERROR](state: ModuleState<T, R>, payload: ErrorPayload): void {
                state.loadingItem = false;
                if (payload.status !== 401 && payload.message !== 'Nieautoryzowany dostęp') {
                    state.error = payload.message;
                    payload.errors.id = payload.id;
                    Object.keys(payload.errors).forEach((key) => {
                        if (key.includes('.')) {
                            const prefix = key.split('.')[0];
                            for (const k in payload.errors) {
                                if (k.startsWith(prefix) && !k.includes('.') && payload.errors[k] instanceof Array) {
                                    payload.errors[k].push(...payload.errors[k]);
                                } else {
                                    payload.errors[prefix] = payload.errors[k];
                                    delete payload.errors[k];
                                    break;
                                }
                            }
                        }
                    });
                    state.errorMessages = payload.errors;
                }
            },

            [this.mutationTypes.STORE_REQUEST](state: ModuleState<T, R>): void {
                state.loadingItem = true;
                state.error = '';
                state.errorMessages = {};
            },

            [this.mutationTypes.STORE_SUCCESS](state: ModuleState<T, R>): void {
                state.loadingItem = false;
                state.editedItem = {} as R;
            },

            [this.mutationTypes.STORE_ERROR](state: ModuleState<T, R>, payload: ErrorPayload): void {
                state.loadingItem = false;
                if (payload.status !== 401 && payload.message !== 'Nieautoryzowany dostęp') {
                    state.error = payload.message;
                    state.errorMessages = payload.errors;
                }
            },

            [this.mutationTypes.REMOVE_REQUEST](state: ModuleState<T, R>): void {
                state.removing = true;
                state.error = '';
                state.errorMessages = {};
            },

            [this.mutationTypes.REMOVE_ERROR](state: ModuleState<T, R>, payload: ErrorPayload): void {
                state.removing = false;
                if (payload.status !== 401 && payload.message !== 'Nieautoryzowany dostęp') {
                    state.error = payload.message;
                    state.errorMessages = payload.errors;
                }
            },

            [this.mutationTypes.REMOVE_SUCCESS](state: ModuleState<T, R>): void {
                state.removing = false;
            },

            [this.mutationTypes.REGISTER_PICKER](state: ModuleState<T, R>, id: string): void {
                state.pickers.push(new PickerData(id));
            },
            [this.mutationTypes.UNREGISTER_PICKER](state: ModuleState<T, R>, id: string): void {
                state.pickers = state.pickers.filter((picker: PickerData) => picker.id !== id);
            },
        };
    }
}
