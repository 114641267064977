import {httpClient} from '@/shared/services';

import {apiConstants} from '../config';
import {RoleDataRequest} from '@/modules/admin/shared/requests/role-data-request';
import {FetchParams} from '@/shared/types';
import {objectToQueryString} from '@/shared/helpers';

const fetchRoles = (params: FetchParams) => {
    const url = apiConstants.roles + '?' + objectToQueryString(params);

    return httpClient.get(url).then((res) => res.data);
};

const getRole = (id: number) => {
    const url = apiConstants.roles + '/' + id;

    return httpClient.get(url).then((res) => res.data);
};

const updateRole = (id: number, data: RoleDataRequest) => {
    const url = apiConstants.roles + '/' + id;

    return httpClient.put(url, data).then((res) => res.data);
};

const storeRole = (data: RoleDataRequest) => {
    const url = apiConstants.roles;

    return httpClient.post(url, data).then((res) => res.data);
};

const removeRole = (id: number) => {
    const url = apiConstants.roles + '/' + id;

    return httpClient.delete(url).then((res) => res.status);
};

export {fetchRoles, getRole, storeRole, removeRole, updateRole};
