import store from '@/store';
import { consentsModule } from './shared';
const {mutationsTypes} = consentsModule ;

/**
 *  Module routes:
 *  - module router
 *      - list
 *      - details
 *  TODO: - add routes to main-navigation
 */
const routes = [
    {
        path: '/consents',
        component: () => import(/* webpackChunkName: "consentRoutes" */ './consent.vue'),
        children: [
            {
                path: '',
                name: 'consents-list',
                component: () =>
                    import(/* webpackChunkName: "consentRoutes" */ './components/list.vue'),
            },
            {
                path: 'processes',
                name: 'consents-processes-list',
                component: () =>
                    import(/* webpackChunkName: "consentRoutes" */ './components/processes/list.vue'),
            },
            {
                path: 'processes/:id',
                name: 'consent-process-details',
                component: () =>
                    import(/* webpackChunkName: "consentRoutes" */ './components/processes/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
            },

            {
                path: ':id',
                name: 'consent-details',
                component: () =>
                    import(/* webpackChunkName: "consentRoutes" */ './components/details/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
            },
            {
                path: '/data-transfer-agreement   ',
                name: 'data-transfer-agreement',
                beforeEnter: (to: any, from: any) => {
                    store.commit(mutationsTypes.SHOW_DATA_TRANSFER_AGREEMENT_DIALOG);

                    return false;
                },
                requiredPermission: 'admin.client.edit',
            }
        ],
    },
];

export default routes;
