import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { logger } from '@/shared/services';
import { DataRequest, FetchParams, ItemData, MetaItem, SaveResponse } from '@/shared/types';
import { actionsTypes as settingsActions } from '@/modules/settings/shared/state';
import { parseDate, parseDateTime, parsePrice } from '@/shared/helpers';
import { ModuleProps } from '@/shared/state/template/module-props';
import { FooterProps } from '@/shared/components/layout/list/models/footer-props';
import { actions } from '@/modules/auth/shared/state';

@Component
export default class ListComponent<T extends ItemData, R extends DataRequest> extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    /* Additional Methods */

    public parseDate = parseDate;
    public parseDateTime = parseDateTime;
    public parsePrice = parsePrice;

    /* Table Component Variables */

    public headers!: object[]; // Abstract
    public itemsCount: number[] = [10, 25, 50];
    public rows: number = 0;
    public search: string = '';
    public selected: T[] = [];
    public select: boolean = false;

    /* Store Variables */

    public store!: any; // Abstract
    public fetchAction!: string; // Abstract
    public removeAction!: string; // Abstract
    public mutationTypes!: { [k: string]: string }; // Abstract
    public removeItemsAction: string = '';
    public restoreAction: string = '';
    public footer: FooterProps = new FooterProps(this.itemsCount);

    /* Edited Item */

    get current() {
        return this.store ? this.store.current : null;
    }

    set current(value) {
        this.$store.commit(this.mutationTypes.UPDATE_CURRENT, value);
    }

    get editedItem(): R {
        return this.store ? this.store.editedItem : {};
    }

    set editedItem(value) {
        this.$store.commit(this.mutationTypes.UPDATE_EDITED, value);
    }

    get edit() {
        return !!this.editedId && this.editedId > 0;
    }

    /* Dialog States */

    get showDialog() {
        return this.store ? this.store.showDialog : false;
    }

    set showDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_SHOW_DIALOG, value);
    }

    get restoreDialog() {
        return this.store.restoreDialog;
    }

    set restoreDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_RESTORE_DIALOG, value);
    }

    get removeDialog() {
        return this.store ? this.store.removeDialog : false;
    }

    set removeDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_REMOVE_DIALOG, value);
    }

    get relatedDialog() {
        return this.store ? this.store.relatedDialog : false;
    }

    set relatedDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_RELATED_DIALOG, value);
    }

    get formDialog() {
        return this.store ? this.store.formDialog : null;
    }

    set formDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_FORM_DIALOG, value);
    }

    get page() {
        return this.store ? this.store.page : 1;
    }

    set page(val) {
        this.$store.commit(this.mutationTypes.CHANGE_PAGE, val);
    }

    get fetchParams() {
        return this.store ? this.store.fetchParams : {} as FetchParams;
    }

    set fetchParams(val) {
        this.$store.commit(this.mutationTypes.UPDATE_PARAMS, val);
    }

    /* Other */

    public main: boolean = true;
    public showId: number = 0;
    public props!: ModuleProps; // Abstract
    public destroyObject: boolean = false;
    public id?: number = 0;
    private timer: any = 0;

    /* Search Text Type Delay */

    private typingDelayTime: number = 600;

    /* Loaders */

    get loading() {
        return this.store ? this.store.loading : false;
    }

    get restoring() {
        return this.store.restoring;
    }

    get removing() {
        return this.store ? this.store.removing : false;
    }

    get footerProps() {
        this.footer.disableItemsPerPage = this.paginate || this.loading;
        this.footer.disablePagination = this.paginate || this.loading;
        return this.footer;
    }

    get paginate() {
        return this.$store.state.settingsState.loading;
    }

    /* Things from Server */

    get currentId(): number {
        return this.current ? this.current.id : 0;
    }

    get editedId(): number | string | undefined {
        return this.editedItem ? this.editedItem.id : 0;
    }

    get items(): T[] {
        return this.store ? this.store.data : [];
    }

    get error(): string {
        return this.store ? this.store.error : '';
    }

    set error(value) {
        this.$store.commit(this.mutationTypes.UPDATE_ERROR_TITLE, value);
    }


    get errorMessages(): object {
        return this.store.errorMessages;
    }

    set errorMessages(value) {
        this.$store.commit(this.mutationTypes.UPDATE_ERROR_MESSAGES, value);
    }

    get listError(): string {
        return this.store ? this.store.listError : '';
    }

    set listError(value) {
        this.$store.commit(this.mutationTypes.UPDATE_LIST_ERROR_TITLE, value);
    }

    get listErrorMessages(): object {
        return this.store ? this.store.listErrorMessages : {};
    }

    set listErrorMessages(value) {
        this.$store.commit(this.mutationTypes.UPDATE_LIST_ERROR_MESSAGES, value);
    }

    /* Pagination Variables */

    get per_page(): number {
        return this.meta ? this.meta.per_page : 10;
    }

    set per_page(rowCount: number) {
        if (this.meta && rowCount !== this.per_page) {
            this.$store.dispatch(settingsActions.UPDATE_SETTING, { key: this.meta.pagination_key, value: rowCount })
                .then(() => {
                    this.page = 1;
                    this.fetchData();
                });
        }
    }

    get from(): number {
        return this.meta ? this.meta.from : 1;
    }

    get current_page(): number {
        return this.meta ? this.meta.current_page : 1;
    }

    get last_page(): number {
        return this.meta ? this.meta.last_page : 0;
    }

    get total(): number {
        return this.meta ? this.meta.total : 0;
    }

    get meta(): MetaItem | null {
        return this.store ? (this.store.meta as MetaItem) : null;
    }

    /* Methods for Showing Item */

    public showItem(item: T) {
        if (this.main) {
            if (this.props) {
                if (!this.props.loadDialog) {
                    this.current = Object.assign({}, item);
                } else {
                    this.showId = item.id;
                }
            } else {
                this.current = Object.assign({}, item);
            }
            this.showDialog = true;
        } else {
            this.$emit('itemShowed', String(item.id));
        }
    }

    public goToItem(routeName: string, routeParams: any, item?: T) {
        if (item) {
            this.current = item;
        }
        this.$router.push({ name: routeName, params: routeParams })
            .catch((err: any) => logger.error(err));
    }

    /* Dialogs methods */

    public createItem(item?: R) {
        this.setDefaultData(item || {} as R);
        this.formDialog = true;
    }

    public editItem(item: T | R) {
        this.editedItem = Object.assign({}, item as R);
        if (this.props.hasFormDialog) {
            this.formDialog = true;
        }
    }

    public deleteItem(item: T) {
        this.current = Object.assign({}, item);
        this.removeDialog = true;
    }

    public restoreItem(item: T) {
        this.current = Object.assign({}, item);
        this.restoreDialog = true;
    }

    /* Method called after item saving */

    public itemSaved(e?: SaveResponse<T>) {
        this.formDialog = false;

        if (!this.edit && !this.search) {
            const futurePage = Math.ceil((this.total + 1) / this.per_page);
            this.page = (futurePage > this.last_page) ? this.last_page + 1 : this.last_page;
        }

        if (this.main) {
            this.setDefaultData();
            this.fetchData();
        } else {
            this.$emit('itemChanged');
        }
    }

    /* Closing formDialog */

    public closeForm() {
        this.formDialog = false;
        this.error = '';
        this.errorMessages = {};
    }

    /* Closing removeDialog */

    public closeRemove() {
        this.removeDialog = false;
        this.error = '';
        this.errorMessages = {};
        this.setDefaultData();
    }

    /* Closing removeDialog */

    public closeRelated() {
        this.relatedDialog = false;
        this.error = '';
        this.errorMessages = {};
        this.setDefaultData();
    }

    get selectedIds(): number[] {
        return this.selected.length > 0 ? this.selected.map((e: any) => e.id) : [];
    }

    public removeItemsRequest() {
        if (this.removeItemsAction) {
            this.$store.dispatch(this.removeItemsAction, this.selectedIds)
                .then((response) => {
                    if (response) {
                        this.store.removeDialog = false;
                        this.select = false;
                        this.selected = [];

                        this.page = 1;

                        this.fetchData();
                    }
                })
                .catch((err) => logger.error(err));
        }
    }

    /* Setting default data */

    public setDefaultData(itemTemplate?: R) {
        if (this.props && this.props.hasParent && this.props.addParentProperty) {
            const request: DataRequest = {} as DataRequest;
            request[this.props.parentId] = Number(this.id);
            this.editedItem = Object.assign(itemTemplate || {}, request as R);
        } else {
            this.editedItem = Object.assign(itemTemplate || {}, {} as R);
        }
    }

    /* Fetching Data from Server and checking User Session state if error dropped */

    public fetchData(callback?: (data: T[]) => void) {
        if (this.fetchAction) {
            this.selected = [];
            const params = this.parseParams(this.fetchParams ? this.fetchParams : {} as FetchParams);

            this.$store.dispatch(this.fetchAction, params)
                .catch((error) => {
                    logger.error(error);
                }).then((response) => {
                    if (response) {
                        if (callback) {
                            callback(response.data);
                        } else {
                            this.$emit('loaded', response.data);
                        }
                    }
                });
        }
    }

    /* Restoring Item */

    public restoreItemRequest() {
        if (this.restoreAction) {
            this.$store.dispatch(this.restoreAction, Number(this.currentId))
                .then((response) => {
                    if (response && (response.status < 300 || (Number(response) < 300 && Number(response) > 0))) {
                        this.restoreDialog = false;
                        this.fetchData();
                    }
                })
                .catch((err) => logger.error(err));
        }
    }

    /* Removing Item */

    public removeItemRequest(callback?: (response: number) => void) {
        if (this.removeAction) {
            this.$store.dispatch(this.removeAction, this.destroyObject ? this.store.current : Number(this.currentId))
                .then((response) => {
                    if (response && (response.status < 300 || (Number(response) < 300 && Number(response) > 0))) {
                        this.store.removeDialog = false;

                        if (!callback) {
                            if (this.meta && (this.meta.to - this.meta.from) < 1 && this.page === this.last_page) {
                                this.page--;
                            }

                            this.fetchData();
                        } else {
                            callback(response);
                        }
                    }
                    if (!response) {
                        this.store.removeDialog = false;
                        this.store.relatedDialog = true;
                        this.store.removing = false;
                        this.fetchData();
                    }
                })
                .catch((err) => logger.error(err));
        }
    }

    /* Adding Filters to to URL Params */

    public assignFilters(filters: object) {
        if (!this.fetchParams.filters) {
            this.fetchParams.filters = {};
        }

        this.fetchParams.filters = Object.assign(this.fetchParams.filters, filters);
        this.fetchData();
    }

    /* Parsing URL Params */

    public parseParams(params: FetchParams) {
        params.page = this.page;

        if (!params.filters) {
            params.filters = {};
        }

        if (this.search) {
            params.filters = Object.assign(params.filters, { search: this.search });
        } else {
            // @ts-ignore
            delete params.filters.search;
        }

        if (this.id !== 0) {
            params.id = this.id;
        }

        return params;
    }

    /* Checking user permissions */

    public isPermitted(actionName: string) {
        const permissions = this.props.permissionBaseNames.map((baseName) => `${baseName}.${actionName}`);
        return this.permissionCheck(...permissions);
    }

    /* Watchers */

    @Watch('page')
    public changePage() {
        clearTimeout(this.timer);

        this.timer = window.setTimeout(() => {
            if (this.fetchParams.filters && !this.fetchParams.filters.search) {
                this.fetchData();
            }
        }, this.typingDelayTime);
    }

    @Watch('search')
    public OnSearchChange() {
        clearTimeout(this.timer);

        this.timer = window.setTimeout(() => {
            if (this.page !== 1) {
                this.page = 1;
            }
            this.fetchData();
        }, this.typingDelayTime);
    }
}
