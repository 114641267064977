import {costsModule} from './module';
import {
    costAttachmentsModule,
    costCategoriesModule, costExamplePositionsModule, costPositionsModule, costTypesModule,

} from '@/modules/costs/shared/state/submodules';
import {  CostsState } from './state';
import {costsActions} from '@/modules/costs/shared/state/actions/costs';
import {costsMutations} from '@/modules/costs/shared/state/mutations/costs';

const categoriesState = costCategoriesModule.module;
const examplePositionsState = costExamplePositionsModule.module;
const attachmentsState = costAttachmentsModule.module;
const typesState = costTypesModule.module;
const positionsState = costPositionsModule.module;

costsModule.state = new CostsState();

costsModule.additionalActions = costsActions;

costsModule.additionalMutations = costsMutations;

costsModule.modules = {
    categoriesState,
    examplePositionsState,
    attachmentsState,
    typesState,
    positionsState,
};

export default costsModule.module;
