import { actions } from './actions';
import { mutations } from './mutations';
import { rentersModule } from './module';
import { rentersTypesModule } from './settings/renter-types/module';
import { notesTypesModule } from './notes/module';
import { relatedRentsModule } from './related-rents/module';

const rentersTypesModuleState = rentersTypesModule.module;
const notesTypesModuleState = notesTypesModule.module;
const relatedRentsState = relatedRentsModule.module;

rentersModule.modules = {
    rentersTypesModuleState,
    notesTypesModuleState,
    relatedRentsState,
};

const module = rentersModule.module;

module.actions = {
    ...module.actions,
    ...actions,
};

module.mutations = {
    ...module.mutations,
    ...mutations,
};

export default module;
