
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component({
        props: {
            showAlert: Boolean,
        },
    })
    export default class PreventUnload extends Vue {
        private showAlert!: boolean;

        public handleUnload(event: any) {
            if (!this.showAlert) {
                return;
            }
            event.returnValue = true;
            return true;
        }
        public mounted() {
            window.addEventListener('beforeunload', this.handleUnload);
        }
        public beforeDestroy() {
            window.removeEventListener('beforeunload', this.handleUnload);
        }
    }
