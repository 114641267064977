import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {Widget} from '@/modules/widgets/shared/models/widget';
import {WidgetDataRequest} from '@/modules/widgets/shared/requests/widget-data-request';
import {OperationType} from '@/shared/state/template/module-types';

const widgetsProps = new ModuleProps('widgets',
    {one: 'widget',
        two: 'widgety',
        five: 'widgetów',
    }, );

widgetsProps.hideSnackbarOperations = [OperationType.Update];


const widgetsModule = new ModuleCreator<Widget, WidgetDataRequest>(widgetsProps);

widgetsModule.additionalMutationTypes = ['UPDATE_SEETINGS_DIALOG'];

export {widgetsModule};

