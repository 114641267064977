import { HeaderFieldType } from '@/shared/types';

export const contractorsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Skrócona nazwa', value: 'short_name' },
    { text: 'Typ', value: 'type' },
    { text: 'Specjalizacja', value: 'specialization'},
    { text: 'Telefony', value: 'phones' },
    { text: 'E-maile', value: 'emails' },
    { text: 'Opiekun', value: 'user' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const contractorsTypesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const contactListHeaders: HeaderFieldType[] = [
           {
               text: 'Lp',
               align: 'left',
               sortable: false,
               value: 'index',
           },
           { text: 'Nazwa', value: 'nameWithRouting' },
           { text: 'Stanowisko', value: 'position' },
           { text: 'Akcje', value: 'actions', align: 'right' },
       ];

export const positionTypesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const notesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const contractorsSpecializationsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];
