import { DirectiveOptions } from 'vue';

const onInput = async (event: any, delay?: number) => {
    setTimeout(() => {
        event.target.addEventListener('click', blockInput, { capture: true });
    }, 1);

    setTimeout(
        () => {
            event.target.removeEventListener('click', blockInput, { capture: true });
        },
        delay ? delay : 300,
    );
};
const blockInput = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
};

export const clickDelayDirective: DirectiveOptions = {
    bind(el, { value }) {
        if (value) {
           if (typeof value !== 'number') {
                throw new Error(`V-click-delay value must be number, ${typeof value} given`);
            }
        }
        el.addEventListener(
            'click',
            (e) => {
                onInput(e, value);
            },
            { capture: true },
        );
    },
    unbind(el, { value }) {
        el.removeEventListener(
            'click',
            (e) => {
                onInput(e, value);
            },
            { capture: true },
        );
    },
};
