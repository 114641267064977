import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { ContractorSpecialization } from '../../../models/settings/contractors-specialization';
import {ContractorSpecializationDataRequest} from '../../../requests/settings/contractors-specialization-data-request';

const contractorsSpecializationsProps = new ModuleProps(
    'specializations',
    {
        one: 'specjalizacja kontrahenta',
        two: 'specjalizacje kontrahentów',
        five: 'specjalizacji kontrahentów',
    },
    ['contractors'],
);

contractorsSpecializationsProps.customStateName = 'contractorsSpecializationsModuleState';

export const contractorsSpecializationsModule = new ModuleCreator<
    ContractorSpecialization,
    ContractorSpecializationDataRequest
>(contractorsSpecializationsProps);
