
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Tutorial } from '../shared/models/Tutorial';
import { TutorialState } from '../shared/tutorialStateTypes';

@Component({})
export default class TutorialDialog extends Vue {
    public state: TutorialState = this.$store.state.tutorialState;
    public current = {};
    public activeMenu: boolean = true;
    public activeShortDescription: boolean = true;
    public dialog: boolean = false;
    public loading: boolean = false;

    get showDocs() {
        return this.state.showDocumentation;
    }

    set showDocs(val: boolean) {
        this.$set(this.state, 'showDocumentation', val);
    }

    get areDocsDocked() {
        return this.state.showDocumentation && this.state.isDocked;
    }

    get tutorialUrls() {
        return this.state.tutorialUrls;
    }

    get moduleTutorials() {
        return this.state.moduleTutorials;
    }

    get currentTutorial() {
        const currentTutorial = this.state.currentTutorial;
        if (!currentTutorial) {
            return '';
        }
        if (currentTutorial.description_short) {
            return currentTutorial;
        }
    }

    get hasTutorials() {
        return this.tutorialUrls && this.tutorialUrls.length && this.moduleTutorials && this.moduleTutorials.length;
    }

    public openDocs(filterByCurrentModule = true) {
        this.state.filterByCurrentModule = filterByCurrentModule;

        if (this.state.minimalized) {
            this.state.minimalized = false;
        }

        this.showDocs = true;
    }

    public openTutorial(id: any) {
        this.loading = true;
        this.$store.dispatch('fetchModuleTutorial', id).finally(() => {
            this.loading = false;
        });
        this.activeMenu = false;
    }

    public back() {
        this.activeShortDescription = true;
        this.$store.commit('[TUTORIAL] SET_CURRENT_TUTORIAL', null);
    }

    public moreInfo() {
        if (!this.currentTutorial) {
            return;
        }
        const index = this.state.activeTabs.findIndex((el) => el.id === (this.currentTutorial as Tutorial).id);

        if (index === -1) {
            this.state.activeTabs.push(this.currentTutorial as Tutorial);
            this.state.activeTab = this.state.activeTabs.length - 1;
        } else {
            this.state.activeTab = index;
        }

        if (!this.showDocs || (this.showDocs && this.state.minimalized)) {
            this.openDocs();
        }
    }
}
