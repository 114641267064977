import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {WizardDataRequest} from '@/modules/wizard/shared/requests/wizard-data-request';
import {Wizard} from '@/modules/wizard/shared/models/wizard';
import {WizardState} from '@/modules/wizard/shared/state/state';

const wizardProps = new ModuleProps('wizard',
    {
        one: '',
        two: '',
        five: '',
        accusative: '',
    });

const wizardModule = new ModuleCreator<Wizard, WizardDataRequest>(wizardProps);

wizardModule.state = new WizardState();
export {wizardModule};

