import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {Visualization} from '@/modules/visualizations/shared/models/visualization';
import {VisualizationDataRequest} from '@/modules/visualizations/shared/requests/visualization-data-request';


const visualizationsProps = new ModuleProps('visualizations',
    {one: 'wizualizacja',
        two: 'wizualizacje',
        five: 'wizualizacji',
        accusative: 'wizualizację',
    });

visualizationsProps.hasDialog = false;

const visualizationsModule = new ModuleCreator<Visualization, VisualizationDataRequest>(visualizationsProps);

visualizationsModule.additionalActionTypes = [
    'CHANGE_VISIBILITY',
];

visualizationsModule.additionalMutationTypes = [
    'VISIBILITY_REQUEST',
    'VISIBILITY_SUCCESS',
    'VISIBILITY_ERROR',
    'UPDATE_SHARE',
    'UPDATE_CODE',
];

export {visualizationsModule};

