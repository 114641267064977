import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';

import { Notification } from '../models/notification';
import { NotificationDataRequest } from '../requests/notification-data-request';

const notificationsProps = new ModuleProps('notifications', {
    one: 'Powiadomienie',
    two: 'Powiadomienia',
    five: 'Powiadomień',
    accusative: 'Powiadomienie',
});

const notificationsModule = new ModuleCreator<Notification, NotificationDataRequest>(notificationsProps);

notificationsModule.additionalMutationTypes = [
    'TOGGLE_NEW_REMINDER_DIALOG',
    'FETCH_NOTIFICATION_GROUPS_REQUEST',
    'FETCH_NOTIFICATION_GROUPS_SUCCESS',
    'FETCH_NOTIFICATION_GROUPS_ERROR',
    'FETCH_EXTRA_NOTIFICATION_GROUPS_REQUEST',
    'FETCH_EXTRA_NOTIFICATION_GROUPS_SUCCESS',
    'FETCH_EXTRA_NOTIFICATION_GROUPS_ERROR',
    'FETCH_EXTRA_NOTIFICATIONS_REQUEST',
    'FETCH_EXTRA_NOTIFICATIONS_SUCCESS',
    'FETCH_EXTRA_NOTIFICATIONS_ERROR',
    'UPDATE_COUNTER',
    'SETTINGS_REQUEST',
    'SETTINGS_REQUEST_ERROR',
    'SETTINGS_REQUEST_SUCCESS',
];

notificationsModule.additionalActionTypes = [
    'FETCH_NOTIFICATION_GROUPS',
    'FETCH_EXTRA_NOTIFICATION_GROUPS',
    'FETCH_EXTRA_NOTIFICATIONS',
    'ON_USER_LOGGED',
    'ENABLE_SETTINGS',
    'DISABLE_SETTINGS',
];

export { notificationsModule };
