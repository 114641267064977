
import { consentProcessesActions } from './actions/consent-processes';
import { consentsActions } from './actions/consents';
import { consentsModule } from './module';
import { consentsMutations } from './mutations/consents';
import { consentProcessesMutations } from './mutations/constent-processes';
import { ConsentsState } from './state';
import { ConsentProcessesState } from './states/consent-processes';
import { consentProcessesModule } from './submodules';

consentProcessesModule.state = new ConsentProcessesState();
consentProcessesModule.additionalActions = consentProcessesActions;
consentProcessesModule.additionalMutations = consentProcessesMutations;
const processesState = consentProcessesModule.module;

consentsModule.state = new ConsentsState();
consentsModule.additionalActions = consentsActions;
consentsModule.additionalMutations = consentsMutations;
consentsModule.modules = {
    processesState,
};

const module = consentsModule.module;

export default module;

