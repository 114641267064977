const routes = [
    {
        path: '/personnel',
        component: () => import(/* webpackChunkName: "employeesRoutes" */ './employees.vue'),
        children: [
            {
                path: 'employees',
                name: 'employees-list',
                component: () =>
                    import(/* webpackChunkName: "employeesRoutes" */ './components/list.vue'),
            },

            {
                path: 'employees/:id',
                name: 'employee-details',
                component: () =>
                    import(/* webpackChunkName: "employeesRoutes" */ './components/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
            },
            {
                path: 'roles',
                name: 'roles-list',
                component: () =>
                    import(/* webpackChunkName: "employeesRoutes" */ './components/settings/roles/list.vue'),
            },
            {
                path: 'employment-types',
                name: 'employment-types-list',
                component: () =>
                    import(/* webpackChunkName: "employeesRoutes" */ './components/settings/employment-types/list.vue'),
            },
        ],
    },

];

export default routes;
