import {ListFilter} from '@/shared/types';

export const rentsListFilters: ListFilter[] = [
    {
        name: 'status',
        type: 'select',
        label: 'Status',
        description: 'Wybierz status',
        data: [
            {name: 'free', label: 'Wolny', value: false},
            {name: 'reserved', label: 'Zarezerwowany', value: false},
            {name: 'sold', label: 'Sprzedany', value: false},
        ],
    },
    {
        name: 'price', type: 'range', label: 'Koszt', description: 'Wybierz zakres ceny',
    },
];


export const rentDocumentsListFilters: ListFilter[] = [
    {
        name: 'status',
        type: 'select',
        label: 'Status',
        description: 'Wybierz status',
        data: [
            {name: 'no-attachment', label: 'Dokument do załączenia', value: false},
            {name: 'active', label: 'Aktualny', value: false},
            {name: 'expired', label: 'Wygasły', value: false},
            {name: 'unactive', label: 'Nie aktywny', value: false},
            {name: 'will-expire-soon', label: 'Kończy się', value: false},
        ],
    },
    {
        name: 'revision_status',
        type: 'select',
        label: 'Przegląd',
        description: 'Wybierz status',
        data: [
            {name: 'not-scheduled', label: 'Do umówienia', value: false},
            {name: 'scheduled', label: 'Umówiony', value: false},
            {name: 'expired', label: 'Wygasły', value: false},
        ],
    },
    {
        name: 'category_id',
        type: 'select',
        label: 'Kategoria',
        description: 'Wybierz kategorie',
        data: [

        ],
    },
    // {
    //     name: 'date_of_issue',
    //     type: 'date',
    //     label: 'Data wystawienia',
    //     description: 'Wybierz zakres dat (od - do)',
    // },
    // {
    //     name: 'due_date',
    //     type: 'date',
    //     label: 'Data ważności',
    //     description: 'Wybierz zakres dat (od - do)',
    // },
];

