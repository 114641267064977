import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {CalendarEvent} from '@/modules/calendar/shared/models/calendar-event';
import {CalendarEventDataRequest} from '@/modules/calendar/shared/requests/calendar-event-data-request';
import {OperationType} from '@/shared/state/template/module-types';

const calendarProps = new ModuleProps('events',
    {one: 'wydarzenie',
        two: 'wydarzenia',
        five: 'wydarzeń',
    }, ['calendar']);

calendarProps.hideSnackbarOperations = [OperationType.Update];
const calendarModule = new ModuleCreator<CalendarEvent, CalendarEventDataRequest>(calendarProps);

export {calendarModule};

