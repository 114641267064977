import {ModuleState, mutations, actions} from './shared/state';
import {Module} from 'vuex';
import {RootState} from '@/shared/types';

const state: ModuleState = {
    loading: false,
    loadingUnits: false,
    loadingTaxRates: false,
    searchingResults: [],
    searching: false,
    units: [],
    taxRates: [],
};

const settingsModule: Module<ModuleState, RootState> = {
    state,
    mutations,
    actions,
};

export default settingsModule;
