import { MutationTree } from 'vuex';
import { ModuleState } from '@/shared/state/template/module-state';
import { ticketsModule } from '../module';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import {TicketsState} from '@/modules/tickets/shared/state/state';

export const mutationsTypes = ticketsModule.mutationsTypes;

export const ticketsMutations: MutationTree<TicketsState> = {
    [mutationsTypes.FETCH_BY_STATUS_REQUEST](state: TicketsState) {
        state.loadingItem = true;
        state.error = '';
        state.errorMessages = {};
    },
    [mutationsTypes.FETCH_BY_STATUS_ERROR](state: TicketsState, payload: ErrorPayload) {
        state.error = payload.message;
        state.errorMessages = payload.errors;
        state.loadingItem = false;
    },
    [mutationsTypes.FETCH_BY_STATUS_SUCCESS](state: TicketsState, payload: any) {
        state.loadingItem = false;
        state.data = payload.res.data;

    },

    [mutationsTypes.SET_ANY_DRAGGED](state: TicketsState, value: boolean) {
        state.isAnyTicketDragged = value;
    },

};
