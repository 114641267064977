import {TicketStatus} from '@/modules/tickets/shared';

export class TicketDataRequest {
    public id: number = 0;
    public name?: string = '';
    public color?: string = '';
    public description: string = '';
    public priority: object = {};
    public status: object = {};
    public tags: any[] = [];
    public assignable: any = {};
    // tslint:disable-next-line:variable-name
    public assignable_id: number|null = 0;
    // tslint:disable-next-line:variable-name
    public assignable_type: string|null = '';
    // tslint:disable-next-line:variable-name
    public assigned_user_id: number = 0;
    // tslint:disable-next-line:variable-name
    public due_date?: string = '';
    // tslint:disable-next-line:variable-name
    public ticket_priority_id: number = 0;
    // tslint:disable-next-line:variable-name
    public ticket_status_id: number = 0;
    // tslint:disable-next-line:variable-name
    public ticket_category_id: number = 0;
    // tslint:disable-next-line:variable-name
    public ticket_status?: TicketStatus;
}
