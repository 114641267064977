import {Component, Inject, Prop, Vue, Watch} from 'vue-property-decorator';
import {logger} from '@/shared/services';
import {parseDate, parseDateTime, parsePrice} from '@/shared/helpers';
import {FetchParams, ItemData} from '@/shared/types';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';

@Component
export default class DetailsComponent<T extends ItemData> extends Vue {
    @Inject() public readonly permissionCheck!: (arg1: string) => boolean;
    @Prop(String) public id!: string;
    public store!: any; // Abstract
    public showAction!: string; // Abstract
    public mutationTypes: {[k: string]: any} = {};
    public fetchParams: FetchParams | any = null;
    public parseDate = parseDate;
    public parsePrice = parsePrice;
    public parseDateTime = parseDateTime;

    public main: boolean = true;

    public created() {
        //
    }

    get item() {
        return this.store && this.store.current ? (this.store.current as T) : ({} as T);
    }

    set item(value) {
        this.$store.commit(this.mutationTypes.UPDATE_CURRENT, value);
    }

    get loading() {
        return this.store ? this.store.loadingItem : false;
    }

    get title() {
        return this.item ? Object(this.item).name : '';
    }

    @Watch('id')
    public OnIdChanged(newVal: number, oldVal: number) {
        if (Number(this.id) > 0 && newVal !== oldVal) {
            this.fetchDetails();
        }
    }

    public fetchDetails() {
        this.setFetchParams();
        this.$store
            .dispatch(this.showAction, this.fetchParams ? this.fetchParams : this.id)
            .then((response: ModuleShowPayload<T>) => {
                this.fetchCallback(response);
            })
            .catch((err) => logger.error(err));
    }

    public setFetchParams() {
        //
    }

    public fetchCallback(response: ModuleShowPayload<T>) {
        //
    }
}
