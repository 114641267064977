import {ActionTree, MutationTree} from 'vuex';
import {acceptancesModule} from '@/modules/acceptances/shared/state/module';
import {ModuleState} from '@/shared/state/template/module-state';
import {RootState} from '@/shared/types';
import {AcceptanceDataRequest} from '@/modules/acceptances/shared/requests/acceptance-data-request';
import {Acceptance} from '@/modules/acceptances/shared/models/acceptance';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {ErrorHandler} from '@/shared/state/template/helpers';
import {approveAcceptance} from '@/modules/acceptances/shared/services/acceptances';
import {AxiosResponse} from 'axios';

export const actionsTypes = acceptancesModule.actionsTypes;

export const mutationTypes = acceptancesModule.mutationsTypes;

export const acceptancesActions: ActionTree<ModuleState<Acceptance, AcceptanceDataRequest>, RootState> = {
    async [actionsTypes.APPROVE]({commit}, data: AcceptanceDataRequest) {
        commit(mutationTypes.APPROVE_REQUEST);

        const result: AxiosResponse | void = await approveAcceptance(data).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.APPROVE_ERROR),
        );


        if (result) {
            commit(mutationTypes.APPROVE_SUCCESS, result);
        }

        return result;
    }
};
