import {httpClient} from '@/shared/services';
import {apiConstants} from '@/modules/rents/shared/config';
import {RentEditDataRequest} from '@/modules/rents/shared/requests/rent-edit-data-request';
import {RentDataRequest} from '@/modules/rents/shared/requests/rent-data-request';

export const editRents = (data: RentEditDataRequest, check?: boolean) => {
    const url = apiConstants.rents + '/edit' + (check ? '/check' : '');
    return httpClient.post(url, data).then((response) => response.status);
};

export const removeRents = (data: RentDataRequest, check?: boolean) => {
    const url = apiConstants.rents + '/remove' + (check ? '/check' : '');
    return httpClient.post(url, data).then((response) => response.status);
};
