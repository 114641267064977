import { httpClient } from '@/shared/services';
import { AxiosRequestConfig } from 'axios';
import { apiConstants } from '../config/api-constants';

export const downloadDocument = (id: number) => {
    const url = apiConstants.documents + '/' + id + '/print';
    const config: AxiosRequestConfig = {
        responseType: 'arraybuffer',
        headers: {
            Accept: 'application/pdf',
        },
    };

    return httpClient.get(url, config).then((res) => res.data);
};
