import {MutationTree} from 'vuex';
import {investmentSubjectRoomsModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentSubjectRoomsState} from '@/modules/investments/shared/state/state/rooms';

export const mutationsTypes = investmentSubjectRoomsModule.mutationsTypes;

export const subjectRoomsMutations: MutationTree<InvestmentSubjectRoomsState> = {
    [mutationsTypes.UPDATE_POINT_SELECTING](state: InvestmentSubjectRoomsState, newVal: boolean) {
        state.pointSelecting = newVal;
    },
};
