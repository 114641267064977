import { DataRequest, FetchParams, ItemData, RootState } from '@/shared/types';
import { ModuleFetchPayload, ModuleShowPayload } from '@/shared/state/template/module-payloads';
import { ModuleProps } from '@/shared/state/template/module-props';
import { ModuleServices } from '@/shared/state/template/module-services';
import { ActionTree } from 'vuex';
import { ErrorHandler } from '@/shared/state/template/helpers';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { OperationType } from '@/shared/state/template/module-types';

/**
 * Part of {@link ModuleCreator} - class for generating vuex actions
 */
export class ModuleActions<S, T extends ItemData, R extends DataRequest> {
    constructor(
        private readonly moduleProps: ModuleProps,
        private readonly actionsTypes: { [k: string]: string },
        private readonly mutationsTypes: { [k: string]: string },
    ) {}

    get actions(): ActionTree<S, RootState> {
        let actions = this.readActions;
        if (!this.moduleProps.readOnly) {
            actions = { ...actions, ...this.writeActions };
        }
        return actions;
    }

    get readActions(): ActionTree<S, RootState> {
        const mutationsTypes = this.mutationsTypes;
        const moduleProps = this.moduleProps;
        return {
            async [this.actionsTypes.FETCH_DATA]({ rootGetters, commit }, params: FetchParams) {
                if (moduleProps.skipAuthOperations.includes(OperationType.Index) || rootGetters.isLoggedIn) {
                    commit(mutationsTypes.DATA_REQUEST, params.simple);

                    const result: ModuleFetchPayload<T> | void = await new ModuleServices<T, R>(
                        moduleProps.url(OperationType.Index, params.id),
                    )
                        .fetchItems(params)
                        .catch((response: ErrorPayload) => ErrorHandler(response, mutationsTypes));

                    if (result) {
                        commit(!params.simple ? mutationsTypes.DATA_SUCCESS : mutationsTypes.DATA_LIST_SUCCESS, result);
                    }

                    return result;
                }
            },

            async [this.actionsTypes.FETCH_PICKER](
                { rootGetters, commit },
                { pickerId, params }: { pickerId: string; params: FetchParams },
            ) {
                if (moduleProps.skipAuthOperations.includes(OperationType.Index) || rootGetters.isLoggedIn) {
                    commit(mutationsTypes.PICKER_DATA_REQUEST, { pickerId, page: params.page });

                    const result: ModuleFetchPayload<T> | void = await new ModuleServices<T, R>(
                        moduleProps.url(OperationType.Index, params.id),
                    )
                        .fetchItems(params)
                        .catch((response: ErrorPayload) => {
                            ErrorHandler(response);
                            commit(mutationsTypes.PICKER_DATA_ERROR, { pickerId, response });
                        });

                    if (result) {
                        commit(mutationsTypes.PICKER_DATA_SUCCESS, { pickerId, result });
                    }

                    return result;
                }
            },

            async [this.actionsTypes.SHOW_ITEM]({ rootGetters, commit }, data: DataRequest | number | string) {
                if (moduleProps.skipAuthOperations.includes(OperationType.Show) || rootGetters.isLoggedIn) {
                    commit(mutationsTypes.GET_REQUEST);
                    let id = 0;
                    let url = moduleProps.url(OperationType.Show);
                    if (typeof data !== 'number' && typeof data !== 'string') {
                        url = moduleProps.url(OperationType.Show, data[moduleProps.parentId]);
                        id = Number(data.id);
                    } else {
                        id = Number(data);
                    }

                    const result: ModuleShowPayload<T> | void = await new ModuleServices<T, R>(url)
                        .getItem(id)
                        .catch((response: ErrorPayload) => ErrorHandler(response, mutationsTypes.GET_ERROR));

                    if (result) {
                        commit(mutationsTypes.GET_SUCCESS, result);
                    }

                    return result;
                }
            },
        };
    }

    get writeActions(): ActionTree<S, RootState> {
        const mutationsTypes = this.mutationsTypes;
        const moduleProps = this.moduleProps;
        return {
            async [this.actionsTypes.STORE_ITEM]({ commit, rootGetters }, data: R) {
                if (moduleProps.skipAuthOperations.includes(OperationType.Store) || rootGetters.isLoggedIn) {
                    commit(mutationsTypes.STORE_REQUEST);

                    const url = moduleProps.url(OperationType.Store, data[moduleProps.parentId]);

                    delete data.id;

                    const result: ModuleShowPayload<T> | void = await new ModuleServices<T, R>(url)
                        .storeItem(data)
                        .catch((response: ErrorPayload) => ErrorHandler(response, mutationsTypes.STORE_ERROR));

                    if (result) {
                        if (!moduleProps.hideSnackbarOperations.includes(OperationType.Store)) {
                            commit('SHOW_SNACKBAR', {
                                type: 'success',
                                text: `Utworzono ${moduleProps.names.accusative || moduleProps.names.one}`,
                            });
                        }
                        commit(mutationsTypes.STORE_SUCCESS);
                    }

                    return result;
                }
            },

            async [this.actionsTypes.UPDATE_ITEM]({ commit, rootGetters }, data: R) {
                if (moduleProps.skipAuthOperations.includes(OperationType.Update) || rootGetters.isLoggedIn) {
                    commit(mutationsTypes.UPDATE_REQUEST);

                    const url = moduleProps.url(
                        OperationType.Update,
                        data.hasOwnProperty(moduleProps.parentId) ? data[moduleProps.parentId] : '',
                    );

                    const result: ModuleShowPayload<T> | void = await new ModuleServices<T, R>(url)
                        .updateItem(data)
                        .catch((response: ErrorPayload) => ErrorHandler(response, mutationsTypes.UPDATE_ERROR));

                    if (result) {
                        if (!moduleProps.hideSnackbarOperations.includes(OperationType.Update)) {
                            commit('SHOW_SNACKBAR', {
                                type: 'success',
                                text: `Zaktualizowano ${moduleProps.names.accusative || moduleProps.names.one}`,
                            });
                        }
                        if (result.data) {
                            result.data.index = data.index;
                        }
                        commit(mutationsTypes.UPDATE_SUCCESS, result);
                    }

                    return result;
                }
            },

            async [this.actionsTypes.REMOVE_ITEM]({ commit, rootGetters }, data: DataRequest | number) {
                if (moduleProps.skipAuthOperations.includes(OperationType.Delete) || rootGetters.isLoggedIn) {
                    let id = 0;
                    let url = moduleProps.url(OperationType.Delete);
                    if (typeof data !== 'number') {
                        url = moduleProps.url(OperationType.Delete, data[moduleProps.parentId]);
                        id = Number(data.id);
                    } else {
                        id = data;
                    }

                    commit(mutationsTypes.REMOVE_REQUEST);
                    const result: number | void = await new ModuleServices<T, R>(url)
                        .removeItem(id)
                        .catch((response: ErrorPayload) => ErrorHandler(response, mutationsTypes));

                    if (result < 300) {
                        if (!moduleProps.hideSnackbarOperations.includes(OperationType.Delete)) {
                            commit('SHOW_SNACKBAR', {
                                type: 'success',
                                text: `Usunięto ${moduleProps.names.accusative || moduleProps.names.one}`,
                            });
                        }
                        commit(mutationsTypes.REMOVE_SUCCESS, result);
                    }

                    return result;
                }
            },
        };
    }
}
