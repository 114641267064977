import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { Sale } from '../models/sale';
import { SaleDataRequest } from '@/modules/sales/shared';
import {SaleTask} from '@/modules/sales/shared/models/task';
import {SaleTaskDataRequest} from '@/modules/sales/shared/requests/task-data-request';

const tasksProps = new ModuleProps('tasks', {
  one: 'Zadanie',
  two: 'Zadania',
  five: 'Zadań',
}, ['sales']);
tasksProps.hasDialog = false;

const tasksModule = new ModuleCreator<SaleTask, SaleTaskDataRequest>(tasksProps);

export { tasksModule };
