import { detailsConfig } from '@/shared/config/search';
import { httpClient } from '@/shared/services';
import _ from 'lodash';
import { countBy, isArray } from 'lodash';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class SearchResult<t> extends Vue {
    @Prop(Number) public id!: number;
    @Prop(Object) public type!: any;
    @Prop(String) public name!: string;
    @Prop(Number) public value!: number;
    @Prop(Boolean) public isActive!: boolean;
    @Prop(Boolean) public hitsLoading!: boolean;
    @Prop(Object) public items: any = null;

    // result item module name
    public  itemKey!: string;
    //  array of module names
    public detailsList!: any; // Abstract
    // override to add selectables outside dynamic component
    public selectableRefNames: string[] = ['item-details'];

    public item: t | null = null;
    public loading = false;
    public itemGroupsIndexes = [];
    public detailsConfig = detailsConfig;
    private noListRefsNames!: string[];
    private selectables!: any[];
    public getRealName?(name: string): string;

    get currentDetailIndex() {
        return this.value;
    }
    set currentDetailIndex(val: any) {
        this.$emit('input', val);
    }

    get detailsListSort() {
        if (this.items && this.items.sort) {
            return this.items.sort;
        } else {
            return this.detailsList.map((el: string) => [el, null]);
        }
    }

    public created() {
        this.noListRefsNames = this._.cloneDeep(this.selectableRefNames);
    }
    public mounted() {
        this.item = this.items[this.itemKey];
        this.$nextTick(() => {
            this.currentDetailIndex = 0;
            this.setupRefNames();
        });
    }

    public redirect(link: string) {
        this.$router.push(link);
        this.$emit('redirection');
    }
    public onSelectableClick(evt: any, toggle: () => {}) {
        let emiterNode = {} as Node;
        for (const node of evt.path) {
            if (node.classList.contains('search__selectable')) {
                emiterNode = node as Node;
                break;
            }
        }
        this.$nextTick(() => {
            this.getSelectableIndex(emiterNode, (index: number) => {
                this.currentDetailIndex = index;
                toggle();
            });
        });
    }

    private setupRefNames() {
        this.$nextTick(() => {
            if (this.items) {
                let newSelectables = [
                    ...this.selectableRefNames,
                    ...this.detailsListSort.map((el: any) => el[0] + '-list'),
                ];

                for (const detailName of this.detailsListSort.map((el: any) => el[0])) {
                    if (
                        this.items[detailName] &&
                        this.items[detailName].length > 0 &&
                        !newSelectables.includes(detailName)
                    ) {
                        const index = newSelectables.indexOf(`${detailName}-list`) + 1;
                        newSelectables = this.insert(newSelectables, index, detailName);
                    }
                }
                this.selectableRefNames = newSelectables;
                this.setupKeyboardNavigation();
            }
        });
    }
    private setupKeyboardNavigation() {
        this.$nextTick(() => {
            this.selectables = [];

            this.selectableRefNames.forEach((name: string) => {
                const ref = this.getRefByName(name);


                if (isArray(ref)) {
                    ref.forEach((element) => {
                        this.selectables.push(element);
                    });
                    if (!name.includes('-list') &&
                        isArray((this.$refs[`${name}-list`] as Vue[])) &&
                        (this.$refs[`${name}-list`] as Vue[])[0].$refs.showMore) {
                        this.selectables.push((this.$refs[`${name}-list`] as Vue[])[0].$refs.showMore);
                    }
                } else if (ref) {
                    if (name === 'itemDetailsSelectables') {
                        (Object.keys(ref.$refs)).forEach((key) => {
                            this.selectables.push(ref.$refs[key]);
                        });
                    } else {
                        this.selectables.push(ref);
                    }
                }
            });
        });
    }
    private getRefByName(name: string): Vue | null {
        if (!name.includes('-list') && !this.noListRefsNames.includes(name)) {
            if (!(this.$refs[`${name}-list`] as Vue)) {
                return null;
            }
            return (this.$refs[`${name}-list`] as Vue[])[0]
                .$refs[this.getRealName ? this.getRealName(name) : name] as Vue;
        } else {
            return this.$refs[name] as Vue;
        }

    }
    private getSelectableIndex(node: Node, cb: (index: number) => void) {
        document
            .querySelectorAll('.search__selectable, .search__selectable > .search__selectable')
            .forEach((selectableNode: Node, index: number, arr) => {
                if (node.isEqualNode(selectableNode)) {
                    cb(index);
                }
            });
    }
    private scrollToElement(el: any) {
        let offset = 0;
        let element: any = el instanceof Vue ? el.$el : el;
        this.$nextTick(() => {
            const container = document.querySelector('.search-details-container');
            if (element.nodeName === 'TR') {
                element = element.parentNode;
            }

            while (element.parentNode && !element.parentNode.classList.contains('search-details-container')) {
                offset += element.offsetTop;
                element = element.parentNode;
            }
            container?.scroll({ top: offset - 200, behavior: 'smooth' });
        });
    }
    private toggleSelectElement(el: any) {
        if (!el) {
            return;
        }
        this.$nextTick(() => {
            this.$nextTick(() => {
                let element: HTMLElement;
                if (el instanceof Vue) {
                    element = el.$el;
                } else {
                    element = el;
                }
                if (!element.classList.contains('search__selectable-selected')) {
                    const currentlySelected = document.querySelector('.search__selectable-selected');
                    if (currentlySelected && !currentlySelected.isEqualNode(element)) {
                        currentlySelected.dispatchEvent(
                            new Event('toggle', { bubbles: true, cancelable: true, composed: true }),
                        );
                    }
                    element.dispatchEvent(new Event('toggle', { bubbles: true, cancelable: true, composed: true }));
                    this.$nextTick(() => {
                        if (this.isActive) {
                            element.focus({ preventScroll: true });
                            this.scrollToElement(el);
                        }
                    });
                }
            });
        });
    }
    private insert(arr: any[], index: number, item: any) {
        arr.push(null);
        return arr
            .reduce((s, a, i) => {
                i === index ? s.push(item, a) : s.push(a);
                return s;
            }, [])
            .filter((el: any) => el !== null);
    }

    @Watch('value', { immediate: true })
    private move(index: number) {
        if (index === null) {
            return;
        }
        this.$nextTick(() => {
            if (this.selectables) {
                if (index >= this.selectables.length) {
                    this.currentDetailIndex = this.selectables.length - 1;
                    return;
                }
                this.$nextTick(() => {
                    this.toggleSelectElement(this.selectables[index]);
                });
            } else {
                const interval = setInterval(() => {
                    this.$nextTick(() => {
                        if (this.selectables) {
                            clearInterval(interval);
                            this.toggleSelectElement(this.selectables[index]);
                        }
                    });
                }, 50);
            }
        });
    }
    @Watch('items', {immediate: true})
    private onItemsChange(val: any) {
        if (val && !this._.isEmpty(val)) {
            this.$nextTick(() => {
                let el: HTMLElement | null;
                const interval = setInterval(() => {
                    el = document.querySelector('.search__selectable-selected');
                    if (!el) { return; }
                    clearInterval(interval);
                    Promise.all(
                        // @ts-ignore-next-line
                        el.getAnimations({ subtree: true })
                            .map((animation) => animation.finished)
                    ).catch((err) => {/***/ }).finally(() => {
                        this.$nextTick(() => {
                            this.setupKeyboardNavigation();
                            this.scrollToElement(el);
                        });
                    });
                }, 200);

            });
        }
    }

}
