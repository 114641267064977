import { ModuleProps } from '@/shared/state/template/module-props';
import { ModuleCreator } from '@/shared/state/template/module';
import { Category } from '@/modules/changes/shared/models/category';
import { CategoryDataRequest } from '@/modules/changes/shared/requests/category-data-request';
import { Catalog } from '@/modules/changes/shared/models/catalog';
import { CatalogDataRequest } from '@/modules/changes/shared/requests/catalog-data-request';
import { Change } from '@/modules/changes/shared/models/change';
import { ChangeDataRequest } from '../requests/change-data-request';

const categoriesProps = new ModuleProps('categories',
    {
        one: 'kategoria lokatorska',
        two: 'kategorie lokatorskie',
        five: 'kategorii lokatorskich',
        accusative: 'kategorię lokatorską',
    }, ['changes']);

categoriesProps.hasDialog = false;
categoriesProps.customStateName = 'categoriesState';
export const categoriesModule = new ModuleCreator<Category, CategoryDataRequest>(categoriesProps);

const catalogsProps = new ModuleProps('catalogs',
    {
        one: 'katalog',
        two: 'katalogi',
        five: 'katalogów',
        accusative: 'katalog',
    }, ['changes']);

catalogsProps.hasDialog = false;
catalogsProps.customStateName = 'catalogsState';

const catalogsModule = new ModuleCreator<Catalog, CatalogDataRequest>(catalogsProps);

catalogsModule.additionalMutationTypes = [
    'UPDATE_ADD_FORM_DIALOG',
    'UPDATE_ADD_FORM_EDITED',
    'UPDATE_FORM_CHANGE_DIALOG',

    'SEND_REQUEST',
    'SEND_ERROR',
    'SEND_SUCCESS',

    'DOWNLOAD_REQUEST',
    'DOWNLOAD_SUCCESS',
    'DOWNLOAD_ERROR',

    'PREVIEW_REQUEST',
    'PREVIEW_SUCCESS',
    'PREVIEW_ERROR',
];

catalogsModule.additionalActionTypes = [
    'SEND_EMAIL',
    'DOWNLOAD',
    'PREVIEW'
];


const catalogChangesProps = new ModuleProps('changes',
    {
        one: 'zmiana katalogu',
        two: 'zmiany katalogu',
        five: 'zmian katalogu',
        accusative: 'zmianę katalogu',
    }, ['changes', 'catalogs']);


catalogChangesProps.customStateName = 'catalogChangesState';


const catalogChangesModule = new ModuleCreator<Change, ChangeDataRequest>(catalogChangesProps);

export {
    catalogsModule, catalogChangesModule
};


