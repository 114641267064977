import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { SaleTasksList } from '@/modules/sales/shared/models/list';
import { SaleTasksListDataRequest } from '@/modules/sales/shared/requests/list-data-request';

const tasksListsProps = new ModuleProps(
    'tasks-lists',
    {
        one: 'lista zadań',
        two: 'listy zadań',
        five: 'list zadań',
        accusative: 'listę zadań',
    },
    ['sales'],
);

tasksListsProps.customStateName = 'tasksListsState';

const tasksListsModule = new ModuleCreator<SaleTasksList, SaleTasksListDataRequest>(tasksListsProps);

tasksListsProps.hasDialog = false;
tasksListsProps.customStateName = 'tasksListsState';

const tasksListTasksProps = new ModuleProps(
    'tasks',
    {
        one: 'zadanie',
        two: 'zadania',
        five: 'zadań',
    },
    ['sales', 'tasks-lists'],
);

tasksListTasksProps.hasDialog = false;
tasksListTasksProps.parentIdInURLOperations = [];
tasksListTasksProps.customStateName = 'tasksListTasksState';

const tasksListTasksModule = new ModuleCreator<SaleTasksList, SaleTasksListDataRequest>(tasksListTasksProps);

export { tasksListsModule, tasksListTasksModule };


