import { ErrorPayload } from '@/shared/payloads/error-payload';
import { MutationTree } from 'vuex';
import { NotificationGroup } from '../../models/notification-group';
import { notificationsModule } from '../module';
import NotificationsState from '../state';

export const mutationsTypes = notificationsModule.mutationsTypes;

export const notificationsMutations: MutationTree<any> = {
    [mutationsTypes.TOGGLE_NEW_REMINDER_DIALOG](state: NotificationsState, id: number) {
        if (id) {
            state.reminderDialogItemId = id;
        }
        state.showReminderDialog = !this.showReminderDialog;
    },
    [mutationsTypes.FETCH_NOTIFICATION_GROUPS_REQUEST](state: NotificationsState, params) {
        state.error = '';
        state.errorMessages = {};
        state.loading = true;

    },
    [mutationsTypes.FETCH_NOTIFICATION_GROUPS_SUCCESS](state: NotificationsState, payload: any) {
        if (payload.read) {
            state.readNotifications = payload.res.data;
        } else {
            state.unreadNotifications = payload.res.data;
        }
        state.meta = payload.res.meta;
        state.loading = false;
    },
    [mutationsTypes.FETCH_NOTIFICATION_GROUPS_ERROR](state: NotificationsState, payload: ErrorPayload) {
        state.error = payload.message;
        state.errorMessages = payload.errors;
        state.loading = false;
    },
    [mutationsTypes.FETCH_EXTRA_NOTIFICATION_GROUPS_REQUEST](state: NotificationsState, params) {
        state.error = '';
        state.errorMessages = {};
        state.loadingExtra = true;

    },
    [mutationsTypes.FETCH_EXTRA_NOTIFICATION_GROUPS_SUCCESS](state: NotificationsState, payload: any) {
        if (payload.read) {
            state.readNotifications.push(...payload.res.data);
        } else {
            state.unreadNotifications.push(...payload.res.data);
        }
        state.meta = payload.res.meta;
        state.loadingExtra = false;
    },
    [mutationsTypes.FETCH_EXTRA_NOTIFICATION_GROUPS_ERROR](state: NotificationsState, payload: ErrorPayload) {
        state.error = payload.message;
        state.errorMessages = payload.errors;
        state.loadingExtra = false;
    },
    [mutationsTypes.FETCH_EXTRA_NOTIFICATIONS_REQUEST](state: NotificationsState, { id, type }) {
        if (!state.groupsLoading.some((group: any) => group.id === id && group.type === type)) {
            state.groupsLoading.push({ id, type });
        }

        state.error = '';
        state.errorMessages = {};
    },
    [mutationsTypes.FETCH_EXTRA_NOTIFICATIONS_SUCCESS](state: NotificationsState, payload: any) {
        if (payload.read) {
            state.readNotifications
                .find((group: NotificationGroup) => payload.id === group.id && payload.type === group.type)
                ?.notifications.push(...payload.res.data);
        } else {
            state.unreadNotifications
                .find((group: NotificationGroup) => payload.id === group.id && payload.type === group.type)
                ?.notifications.push(...payload.res.data);
        }

        state.groupsLoading = state.groupsLoading.filter(
            (group: any) => !(group.id === payload.id && group.type === payload.type),
        );
    },
    [mutationsTypes.FETCH_EXTRA_NOTIFICATIONS_ERROR](state: NotificationsState, payload: any) {
        state.error = payload.message;
        state.errorMessages = payload.errors;
        state.groupsLoading = state.groupsLoading.filter(
            (group: any) => !(group.id === payload.id && group.type === payload.type),
        );
    },
    [mutationsTypes.UPDATE_COUNTER](state: NotificationsState, newValue: number) {
        state.newNotificationsCount = newValue;
    },

    [mutationsTypes.SETTINGS_REQUEST](state: NotificationsState) {
        state.settingsLoading = true;
    },
    [mutationsTypes.SETTINGS_REQUEST_SUCCESS](state: NotificationsState) {
        state.settingsLoading = false;
    },
    [mutationsTypes.SETTINGS_REQUEST_ERROR](state: NotificationsState, payload: any) {
        state.settingsLoading = false;
        state.error = payload.message;
        state.errorMessages = payload.errors;
    },

    'LARAVEL-ECHO_NOTIFICATION'(state: NotificationsState, data: any) {
        state.notificationsQueue.push(data);
        state.current = data;
    },
};
