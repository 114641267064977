import { ModuleProps } from '@/shared/state/template/module-props';
import { ModuleCreator } from '@/shared/state/template/module';
import { RentCostCategory } from '../models/rent-cost-category';
import { RentCostCategoryDataRequest } from '../requests/rent-cost-category-data-request';
import { RentCostAttachmentDataRequest } from '../requests/rent-cost-attachment-data-request';
import { RentCostAttachment } from '../models/rent-cost-attachment';
import RentCostType from '../models/rent-cost-type';
import { RentCostTypeDataRequest } from '../requests/rent-cost-type-data-request';
import { RentCostPosition } from '../models/rent-cost-position';
import { RentCostPositionDataRequest } from '../requests/rent-cost-position-data-request';
import { OperationType } from '@/shared/state/template/module-types';
import { RentCostExamplePositionDataRequest } from '../requests/rent-cost-example-position-data-request';
import { RentCostExampleIssuer } from '../models/rent-cost-example-issuer';
import { RentCostExampleIssuerDataRequest } from '../requests/rent-cost-example-issuer-data-request';

const rentCostCategoriesProps = new ModuleProps('categories', {
    one: 'kategoria',
    two: 'kategorie',
    five: 'kategorii',
    accusative: 'kategorię',
}, ['rentcosts']
);
rentCostCategoriesProps.customStateName = 'categoriesState';

export const rentCostCategoriesModule = new ModuleCreator<RentCostCategory,
    RentCostCategoryDataRequest>(rentCostCategoriesProps);


const rentCostExamplePositionsProps = new ModuleProps('example-positions', {
    one: 'słownik',
    two: 'slowniki',
    five: 'słowników',
},
    ['rentcosts']
);
rentCostExamplePositionsProps.customStateName = 'examplePositionsState';

export const rentCostExamplePositionsModule =
    new ModuleCreator<RentCostExamplePositionDataRequest,
        RentCostExamplePositionDataRequest>(rentCostExamplePositionsProps);

const rentCostExampleIssuersProps = new ModuleProps('example-issuers', {
    one: 'słownik',
    two: 'słowniki',
    five: 'słownikow',
}, ['rentcosts']
);

rentCostExampleIssuersProps.customStateName = 'exampleIssuersState';

export const rentCostExampleIssuersModule =
    new ModuleCreator <RentCostExampleIssuer,
        RentCostExampleIssuerDataRequest>(rentCostExampleIssuersProps);

const rentCostAttachmentsProps = new ModuleProps('attachments', {
    one: 'załącznik',
    two: 'załączniki',
    five: 'załączników',
}, ['rentcosts']);
rentCostAttachmentsProps.customStateName = 'attachmentsState';
rentCostAttachmentsProps.parentIdInURLOperations = [OperationType.Store, OperationType.Index];

export const rentCostAttachmentsModule = new ModuleCreator<RentCostAttachment,
    RentCostAttachmentDataRequest>(rentCostAttachmentsProps);


const rentCostTypesProps = new ModuleProps('types', {
    one: 'rodzaj',
    two: 'rodzaje',
    five: 'rodzajów',
}, ['rentcosts']);
rentCostTypesProps.customStateName = 'typesState';

export const rentCostTypesModule = new ModuleCreator<RentCostType,
    RentCostTypeDataRequest>(rentCostTypesProps);


const rentCostPositionsProps = new ModuleProps('positions', {
    one: 'pozycja',
    two: 'pozycje',
    five: 'pozycji',
    accusative: 'pozycję',
}, ['rentcosts']);
rentCostPositionsProps.customStateName = 'positionsState';
rentCostPositionsProps.parentIdInURLOperations = [OperationType.Store, OperationType.Index];

export const rentCostPositionsModule = new ModuleCreator<RentCostPosition,
    RentCostPositionDataRequest>(rentCostPositionsProps);
