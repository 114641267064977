const routes = [
    {
        path: '/costs',
        component: () =>
            import(/* webpackChunkName: "costsRoutes" */ '@/modules/costs/costs.vue'),
        children: [
            {
                path: '',
                name: 'costs-list',
                component: () => import(/* webpackChunkName: "costsRoutes" */
                    '@/modules/costs/components/list.vue')
                ,
                props: true,
                meta: {
                    requiredPermission: 'costs.view',
                },
            },
            {
                path: 'categories',
                name: 'costs-categories-list',
                component: () =>
                    import(/* webpackChunkName: "costsRoutes" */ '@/modules/costs/components/settings/categories/list.vue'),
                meta: {
                    requiredPermission: 'costs.categories.view',
                },
            },
            {
                path: 'example-positionts',
                name: 'costs-example-positionts-list',
                component: () =>
                    import(/* webpackChunkName: "costsRoutes" */ '@/modules/costs/components/settings/example-positions/list.vue'),
                meta: {
                    requiredPermission: 'costs.example-positions.view',
                },
            },
            {
                path: 'types',
                name: 'costs-types-list',
                component: () => import(/* webpackChunkName: "costsRoutes" */ '@/modules/costs/components/settings/types/list.vue'),
                meta: {
                    requiredPermission: 'costs.types.view',
                },
            },
            {
                path: ':id',
                name: 'cost-details',
                component: () =>
                    import(/* webpackChunkName: "costsRoutes" */
                        '@/modules/costs/components/details/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'costs.view',
                },
            },
        ],
    },
];

export default routes;
