/* tslint:disable:no-console */

import {register} from 'register-service-worker';
import store from '@/store';
import {Snackbar} from '@/shared/types';

register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration: ServiceWorkerRegistration) {
        //
    },
    registered() {
        //
    },
    cached() {
        //
    },
    updatefound(registration) {
        //
    },
    updated(registration) {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', (event) => {
                if (event.target instanceof ServiceWorker && event.target.state === 'activated') {
                    store.commit('SHOW_SNACKBAR', {
                        type: 'info',
                        permanent: true,
                        action: () => {
                            window.location.reload();
                        },
                        text: 'Zaktualizowano aplikację',
                        actionText: 'Odśwież stronę',
                    } as Snackbar);
                }
            });

            waitingServiceWorker.postMessage({type: 'SKIP_WAITING'});
        }

    },
    offline() {
        //
    },
    error(error) {
        console.error('Error during service worker registration:', error);
    },
});

