import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import { RentCost } from '../models/rent-cost';
import { RentCostDataRequest } from '../requests/rent-cost-data-request';

const rentCostsProps = new ModuleProps('rentcosts',
    {one: 'koszt najmu',
        two: 'koszty najmu',
        five: 'kosztów najmów',
    });

rentCostsProps.hasDialog = false;
rentCostsProps.fullscreenForm = true;
rentCostsProps.addParentProperty = false;
rentCostsProps.permissionBaseName = 'rentcosts';
const rentCostsModule = new ModuleCreator<RentCost,
    RentCostDataRequest>(rentCostsProps);

rentCostsModule.additionalActionTypes = [
    'APPROVE',
];

rentCostsModule.additionalMutationTypes = [
    'UPDATE_EDIT_FORM_DIALOG',
    'APPROVE_REQUEST',
    'APPROVE_ERROR',
    'APPROVE_SUCCESS',
];

export {rentCostsModule};
