import store from '@/store';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {mutationTypes as authTypes} from '@/modules/auth/shared/state';
import router from '@/router';
import {logger} from '@/shared/services';
import {Snackbar} from '@/shared/types';


/**
 * Function to log out
 * @param response
 * @param mutationsTypes
 * @param snackbarData
 * @constructor
 */
export const ErrorHandler = (
    response: ErrorPayload,
    mutationsTypes?: { [k: string]: string } | string,
    snackbarData?: Snackbar,
) => {
    logger.error(response);
    if (mutationsTypes) {
        store.commit(typeof mutationsTypes !== 'string' ? mutationsTypes.DATA_ERROR : mutationsTypes, response);
    }

    if (snackbarData) {
        store.commit('SHOW_SNACKBAR', snackbarData);
    }

    if (response.message === 'Nieautoryzowany dostęp') {
        switch (response.status) {
            case 401:
                localStorage.removeItem('token');
                if (router.currentRoute.name !== 'login') {
                    router.push({name: 'login'}).then(() =>
                        store.commit(authTypes.CHECK_USER_ERROR, response),
                    );
                }
                break;
        }
    }
};
