import {visualizationsModule} from './module';
import {visualizationViewsModule} from '@/modules/visualizations/shared/state/submodules';
import {viewsMutations} from '@/modules/visualizations/shared/state/mutations/views';
import {ViewsState} from '@/modules/visualizations/shared/state/state/views';
import {visualizationsMutations} from '@/modules/visualizations/shared/state/mutations/visualizations';
import {visualizationsActions} from '@/modules/visualizations/shared/state/actions/visualizations';
import {VisualizationsState} from '@/modules/visualizations/shared/state/state/visualizations';

visualizationsModule.additionalActions = visualizationsActions;

visualizationsModule.additionalMutations = visualizationsMutations;

visualizationsModule.state = new VisualizationsState();

visualizationViewsModule.additionalMutations = viewsMutations;

visualizationViewsModule.state = new ViewsState();

const viewsState = visualizationViewsModule.module;

visualizationsModule.modules = {
    viewsState,
};

export default visualizationsModule.module;
