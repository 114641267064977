import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

import { authRoutes } from '@/modules/auth';
import { adminRoutes } from '@/modules/admin';
import { investmentsRoutes } from '@/modules/investments';
// import { contactsRoutes } from '@/modules/contacts';
import { departmentsRoutes } from '@/modules/departments';
import {personnelRoutes} from '@/modules/personnel';
import { settingsRoutes } from '@/modules/settings';
import { tasksRoutes } from '@/modules/tasks-lists';
import { contractorsRoutes } from '@/modules/contractors';
import { invoicesRoutes } from '@/modules/invoices';
import { valuationsRoutes } from '@/modules/valuations';
import { salesRoutes } from '@/modules/sales';
import { mediaRoutes } from '@/modules/media';
import { changesRoutes } from '@/modules/changes';
import documentationRoutes from '@/modules/tutorial/routes';
import { visualizationsRoutes } from '@/modules/visualizations';
import { TutorialController } from './modules/tutorial/shared/TutorialController';
import { ticketsRoutes } from '@/modules/tickets';
import { acceptancesRoutes } from '@/modules/acceptances';
import { costsRoutes } from './modules/costs';
import { documentsRoutes } from '@/modules/documents';
import { landsRoutes } from '@/modules/lands';
import { notificationsRoutes } from './modules/notifications';
import { clientRoutes } from '@/modules/client';
import { calendarRoutes } from '@/modules/calendar';
import { consentsRoutes } from './modules/consents';
import { rentsRoutes } from '@/modules/rents';
import { rentersRoutes } from '@/modules/renters';
import { rentCostsRoutes } from './modules/rent-costs';
import { wizardRoutes } from '@/modules/wizard';


Vue.use(Router);

const appRoutes = [
    {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "appRoutes" */ '@/views/Home.vue'),
        alias: '/index.html',
    },
    {
        path: '/payment-required',
        name: 'payment-required',
        component: () => import(/* webpackChunkName: "appRoutes" */ '@/shared/components/sites/payment-required.vue'),
    },
    {
        path: '*',
        name: 'page-not-found',
        component: () => import(/* webpackChunkName: "appRoutes" */ '@/shared/components/sites/page-not-found.vue'),
    },
];

const routes = [
    ...authRoutes,
    ...adminRoutes,
    ...changesRoutes,
    ...investmentsRoutes,
    // ...tasksRoutes,
    // ...contactsRoutes,
    ...departmentsRoutes,
    ...personnelRoutes,
    ...contractorsRoutes,
    ...invoicesRoutes,
    ...settingsRoutes,
    ...appRoutes,
    // ...valuationsRoutes,
    ...mediaRoutes,
    ...documentationRoutes,
    ...visualizationsRoutes,
    ...ticketsRoutes,
    ...acceptancesRoutes,
    ...salesRoutes,
    ...costsRoutes,
    ...documentsRoutes,
    ...landsRoutes,
    ...clientRoutes,
    ...notificationsRoutes,
    ...calendarRoutes,
    ...wizardRoutes,
    ...consentsRoutes,
    ...rentsRoutes,
    ...rentersRoutes,
    ...rentCostsRoutes,
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to: any, from: any, next: any) => {
    // login guard
    if (to.matched.some((record: any) => record.meta.dontRequiresAuth)) {
        if (store.getters.isLoggedIn && to.matched[0].name === 'auth') {
            next('/');
            return;
        }
        next();
    } else {
        if (store.getters.isLoggedIn) {
            // tutorials
            const tutorial = new TutorialController(to, store);
            tutorial.findModuleTutorial();

            // permissons guard
            const {requiredPermission} = to.meta;
            if (requiredPermission) {
                getPermissions((permissions) => {
                    if (!permissions.includes(requiredPermission)) {
                        next(from);
                        store.commit('SHOW_SNACKBAR', {
                            type: 'error',
                            text: `Nie posiadasz uprawnień dla "${window.location.host}${to.fullPath}"`,
                        });
                    } else {
                        next();
                    }
                });
            }
            next();
            return;
        }
        localStorage.removeItem('token');
        next('/auth/login');
    }
});


const getPermissions = (cb: (arg1: string[]) => void) => {
    const state = store.state as any;
    if (state.authState.user && state.authState.user.permissions) {
        cb(state.authState.user.permissions);
    } else {
        setTimeout(() => { getPermissions(cb); }, 10);
    }
};


export default router;
