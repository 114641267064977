import {actions, MediaState, mutations} from './shared/state';
import {Module} from 'vuex';
import {RootState} from '@/shared/types';

const state: MediaState = new MediaState();

const mediaModule: Module<MediaState, RootState> = {
    state,
    mutations,
    actions,
};

export default mediaModule;
