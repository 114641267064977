import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {WidgetType} from '@/modules/widgets/shared/models/widget-type';
import { DataRequest } from '@/shared/types';


const widgetTypesProps = new ModuleProps('types',
    {one: '',
        two: '',
        five: '',
    },
        ['widgets']);

const widgetTypesModule = new ModuleCreator<WidgetType, DataRequest>(widgetTypesProps);

widgetTypesModule.additionalMutationTypes = ['RESET_TYPE'];

export {widgetTypesModule};

