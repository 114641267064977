import {ActionTree} from 'vuex';
import {RootState} from '@/shared/types';
import {mutationsTypes} from '@/modules/contractors/shared/state/mutations';
import {searchContractors} from '@/modules/contractors/shared/services';
import {setGuardianForContractor} from '@/modules/contractors/shared/services/contractors/contractors';
import {ContractorsState} from './state';
import {SearchParams} from '@/modules/contractors/shared/models/search-params';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {GusResultsPayload} from '../payloads/gus-results-payload';
import {contractorsModule} from './module';
import {AxiosResponse} from 'axios';
import {ErrorHandler} from '@/shared/state/template/helpers';

const actionsTypes = contractorsModule.actionsTypes;

export const actions: ActionTree<ContractorsState, RootState> = {
    async [actionsTypes.SEARCH_CONTRACTORS](
        {commit},
        params: SearchParams,
    ) {
        commit(mutationsTypes.CONTRACTORS_SEARCH_REQUEST);

        const result: void | GusResultsPayload = await searchContractors(
            params,
        ).catch((response: ErrorPayload) => ErrorHandler(response.data, mutationsTypes.CONTRACTORS_SEARCH_ERROR));

        if (result) {
            commit(mutationsTypes.CONTRACTORS_SEARCH_SUCCESS, result);
        }

        return result;
    },
    async [actionsTypes.SET_GUARD_FOR_CONTRACTOR](
        {commit},
        params: { contractorId: number; guardianId: number },
    ) {
        const {contractorId, guardianId} = params;
        const result: void | AxiosResponse<any> = await setGuardianForContractor(
            contractorId,
            guardianId,
        ).catch((response: ErrorPayload) => ErrorHandler(
            response,
            '',
            {
                type: 'error',
                text: 'Nie zaktualizowano opiekuna',
            }),
        );

        if (result) {
            commit('SHOW_SNACKBAR', {
                type: 'success',
                text: 'Zaktualizowano opiekuna',
            });
        }

        return result;
    },
};
