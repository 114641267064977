
import {Component, Watch} from 'vue-property-decorator';
import {actionsTypes} from '@/modules/media/shared/state';
import {mdiEmail, mdiMagnifyPlus, mdiPhone} from '@mdi/js';
import AddressField from '@/shared/components/elements/address.vue';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import PdfViewer from '@/shared/components/documents/pdf-viewer.vue';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import mime from 'mime';
import {mediaPermissions} from '@/shared/config/media';

@Component({
    props: {
        id: String,
    },
    components: {
        PdfViewer,
        AddressField,
    },
})
export default class MediaDetails extends DetailsComponent<MediaFile> {
    public icons: object = {
        mdiPhone,
        mdiEmail,
        mdiMagnifyPlus,
    };
    public loadingItem: boolean = true;
    public player = null;
    public zoom: boolean = false;
    public showAction = actionsTypes.SHOW_MEDIA;
    public store = this.$store.state.mediaState;

    public bytesToSize(bytes: number) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) {
            return '0 Byte';
        }
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    }

    @Watch('id')
    public OnIdChanged(newVal: number, oldVal: number) {
        if (Number(this.id) > 0 && newVal !== oldVal) {
            this.loadingItem = true;
        }
    }

    get permissionType() {
        return mediaPermissions.find(({value}) => String(this.item.permission_type) === value);
    }

    get playerOptions() {
        return {
            sources: [{
                type: mime.getType(this.item.extension),
                src: this.item.video_url,
            }],
            fluid: true,
            poster: this.item.thumbnail ? this.item.thumbnail : '/assets/photo.png',
        };
    }

    public created() {
        //
    }
}
