import {httpClient} from '@/shared/services';

const setGuardianForRenter = (
    renterId: number,
    quardianId: number,
) => {
    return httpClient
        .post(`/api/v1/renters/${renterId}/user`, {
            user_id: quardianId,
        });
};


export { setGuardianForRenter };
