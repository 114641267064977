import { ModuleState } from '@/shared/state/template/module-state';
import { Catalog } from '../../models/catalog';
import { CatalogDataRequest } from '../../requests/catalog-data-request';

export class CatalogsState extends ModuleState<Catalog, CatalogDataRequest> {
    public addFormDialog: boolean = false;
    public formSendDialog: boolean = false;
    public addFormEditedItem: any = {};
    public downloadingItem: boolean = false;
    public sendingItem: boolean = false;
    public previewItem: boolean = false;
}
