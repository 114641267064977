export class MediaFileDataRequest {


    get formData() {
        const formData = new FormData();
        if (this.item) {
            formData.append('name', this.item.name);
            formData.append('file', this.item);
            if (this.permissionType) {
                formData.append('permission_type', String(this.permissionType));
            }
            return formData;
        }
        return null;
    }

    constructor(public id: string, public item: File, public permissionType?: string) {
    }
}
