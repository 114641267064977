import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {InvoiceItem} from '@/modules/invoices/shared/models/invoice-item';
import {InvoiceItemDataRequest} from '@/modules/invoices/shared/requests/invoice-item-data-request';
import {InvoiceLog} from '@/modules/invoices/shared/models/invoice-log';
import {DataRequest} from '@/shared/types';
import {OperationType} from '@/shared/state/template/module-types';

const invoiceItemsProps = new ModuleProps('items',
    {
        one: 'pozycja faktury',
        two: 'pozycje faktur',
        five: 'pozycji faktury',
        accusative: 'pozycję faktury',
    }, ['invoices']);
invoiceItemsProps.hasFormDialog = false;
invoiceItemsProps.loadListOnCreate = false;
invoiceItemsProps.replaceItem = true;
invoiceItemsProps.parentIdInURLOperations = [
    OperationType.Index,
    OperationType.Update,
    OperationType.Store,
    OperationType.Show,
    OperationType.Delete,
];
invoiceItemsProps.customStateName = 'itemsState';

const invoiceItemsModule = new ModuleCreator<InvoiceItem, InvoiceItemDataRequest>(invoiceItemsProps);

invoiceItemsModule.additionalMutationTypes = [
    'ADD_ITEM'
];

export {invoiceItemsModule};

const invoiceLogsProps = new ModuleProps('logs',
    {
        one: 'operacja na fakturze',
        two: 'operacje na fakturze',
        five: 'operacji na fakturze',
    }, ['invoices']);
invoiceLogsProps.readOnly = true;
invoiceLogsProps.parentIdInURLOperations = [OperationType.Index, OperationType.Show];
invoiceLogsProps.customStateName = 'logsState';

export const invoiceLogsModule = new ModuleCreator<InvoiceLog, DataRequest>(invoiceLogsProps);
