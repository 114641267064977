
import { httpClient } from '@/shared/services';
import moment from 'moment';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class TestModeSwitch extends Vue {
    public loading = false;

    public switchValue = false;

    get daysLeft() {
        if (!this.testMode) { return ''; }
        const testingEndsAt = moment(this.$store.state.authState.user.testing_ends_at).endOf('day');
        return -moment().diff(testingEndsAt, 'days');
    }

    get showDialog() {
        return (this.testMode === 'active') !== this.switchValue;
    }

    set showDialog(val) {
        this.switchValue = (this.testMode === 'active') !== val;
    }

    get testMode() {
        return this.$store.state.testMode;
    }

    set testMode(val: string) {
        this.$set(this.$store.state, 'testMode', val);
    }

    public switchMode() {
        this.loading = true;
        httpClient.post('/api/v1/admin/client/switch-test-mode').then((res) => {
            this.testMode = res.data;
            this.loading = false;
            this.showDialog = false;
        });
    }

    @Watch('testMode', { immediate: true })
    public onTestModeChange(val: string) {
        this.switchValue = val === 'active';
    }
}
