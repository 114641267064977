import {ModuleState, mutationTypes} from '@/modules/settings/shared/state';
import {MutationTree} from 'vuex';
import Vue from 'vue';
import {SearchResultsPayload} from '@/shared/payloads/search-results-payload';
import {ErrorPayload} from '@/shared/payloads/error-payload';

export const searchMutations: MutationTree<ModuleState> = {
    [mutationTypes.SEARCH_REQUEST](state: ModuleState): void  {
        Vue.set(state, 'searching', true);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
    },

    [mutationTypes.SEARCH_RESET](state: ModuleState): void  {
        Vue.set(state, 'searching', false);
        Vue.set(state, 'searchingResults', []);
    },

    [mutationTypes.SEARCH_ERROR](state: ModuleState, payload: ErrorPayload): void  {
        Vue.set(state, 'searching', false);
        Vue.set(state, 'error', payload.message);
        Vue.set(state, 'errorMessages', payload.errors);
    },

    [mutationTypes.SEARCH_SUCCESS](state: ModuleState, payload: SearchResultsPayload): void  {
        Vue.set(state, 'searching', false);
        Vue.set(state, 'searchingResults', payload.data);
    },
};
