import {investmentsModule} from './module';
import {
    investmentObjectMediaFilesModule,
    investmentObjectsModule,
    investmentObjectTypeIconsModule,
    investmentObjectTypesModule,
    investmentSubjectMediaFilesModule,
    investmentSubjectOperationsModule,
    investmentSubjectPropertiesModule,
    investmentSubjectPropertyTypesModule,
    investmentSubjectRoomsModule,
    investmentSubjectRoomTypesModule,
    investmentSubjectsModule,
    investmentSubjectTypesModule,
    investmentTagsModule,
    prospectSettingsModule,
    investmentSubjectPlansModule,
    investmentSubjectChangesValuationModule,
    investmentSubjectCatalogChangesModule,
    investmentSubjectReservationModule,
} from '@/modules/investments/shared/state/submodules';
import {InvestmentObjectsState} from '@/modules/investments/shared/state/state/objects';
import {InvestmentObject} from '@/modules/investments/shared/models/investment-object';
import {indexData} from '@/shared/helpers';
import {objectsMutations} from '@/modules/investments/shared/state/mutations/objects';
import {subjectsMutations} from '@/modules/investments/shared/state/mutations/subjects';
import {subjectOperationsMutations} from '@/modules/investments/shared/state/mutations/subject-operations';
import {subjectOperationsActions} from '@/modules/investments/shared/state/actions/subject-operations';
import {InvestmentSubjectsState} from '@/modules/investments/shared/state/state/subjects';
import {investmentsMutations} from '@/modules/investments/shared/state/mutations/investments';
import {investmentsActions} from '@/modules/investments/shared/state/actions/investments';
import {InvestmentSubjectOperationsState} from '@/modules/investments/shared/state/state/subject-operations';
import {InvestmentSubjectRoomsState} from '@/modules/investments/shared/state/state/rooms';
import {subjectRoomsMutations} from '@/modules/investments/shared/state/mutations/rooms';

investmentObjectsModule.state = new InvestmentObjectsState();

investmentObjectsModule.additionalMutations = objectsMutations;

// Objects Modules

const objectsState = investmentObjectsModule.module;

if (objectsState.mutations) {
    objectsState.mutations[investmentObjectsModule.mutationsTypes.DATA_SUCCESS] =
        (state: InvestmentObjectsState, {data, meta}) => {
            state.loading = false;

            if (data && state.current) {
                if (data[0] && data[0].investment_id !== state.current.investment_id) {
                    state.openObject = [];
                    state.activeObject = [{} as InvestmentObject];
                }
            }
            state.data = indexData(data, meta);
            state.meta = meta;
        };
}

const objectTypeIconsState = investmentObjectTypeIconsModule.module;

const objectTypesState = investmentObjectTypesModule.module;

// Subjects Modules

investmentSubjectsModule.additionalMutations = subjectsMutations;

investmentSubjectsModule.state = new InvestmentSubjectsState();

const subjectsState = investmentSubjectsModule.module;

investmentSubjectOperationsModule.additionalMutations = subjectOperationsMutations;

investmentSubjectOperationsModule.additionalActions = subjectOperationsActions;

investmentSubjectOperationsModule.state = new InvestmentSubjectOperationsState();

const subjectOperationsState = investmentSubjectOperationsModule.module;

const subjectTypesState = investmentSubjectTypesModule.module;

const subjectPropertiesState = investmentSubjectPropertiesModule.module;

const subjectPropertyTypesState = investmentSubjectPropertyTypesModule.module;

const prospectSettingsState = prospectSettingsModule.module;

investmentSubjectRoomsModule.state = new InvestmentSubjectRoomsState();

investmentSubjectRoomsModule.additionalMutations = subjectRoomsMutations;

const subjectRoomsState = investmentSubjectRoomsModule.module;

const subjectRoomTypesState = investmentSubjectRoomTypesModule.module;

const subjectPlansState = investmentSubjectPlansModule.module;

const subjectChangesValuationState = investmentSubjectChangesValuationModule.module;

const subjectReservationState = investmentSubjectReservationModule.module;

const subjectCatalogChangesState = investmentSubjectCatalogChangesModule.module;

// Settings
const subjectMediaFilesState = investmentSubjectMediaFilesModule.module;

const objectMediaFilesState = investmentObjectMediaFilesModule.module;

const investmentTagsState = investmentTagsModule.module;

investmentsModule.modules = {
    objectsState,
    objectTypesState,
    objectTypeIconsState,
    subjectsState,
    subjectOperationsState,
    subjectTypesState,
    subjectRoomsState,
    subjectRoomTypesState,
    subjectPropertyTypesState,
    investmentTagsState,
    subjectPropertiesState,
    subjectMediaFilesState,
    objectMediaFilesState,
    prospectSettingsState,
    subjectPlansState,
    subjectChangesValuationState,
    subjectReservationState,
    subjectCatalogChangesState,
};

investmentsModule.additionalActions = investmentsActions;

investmentsModule.additionalMutations = investmentsMutations;

const module = investmentsModule.module;

export default module;
