import { mdiBarcode, mdiHandCoinOutline, mdiHomeCity, mdiNumeric, mdiStairs } from '@mdi/js';
import { VariableFieldType } from '@/shared/types';

export const reservationDurations = [
    { value: 30, name: 'Miesiąc' },
    { value: 90, name: '3 miesiące' },
    { value: 0, name: 'Niestandardowy' }
];

export const publicStatuses = [
    { value: 'free', name: 'Wolny' },
    { value: 'reserved', name: 'Zarezerwowany' },
    { value: 'sold', name: 'Sprzedany' },
    { value: 'hidden', name: 'Ukryty' },
];

export const subjectPurposes = [
    { id: 'live', name: 'Mieszkalny' },
    { id: 'service', name: 'Usługowy' },
];

export const subjectEstateTypes = [
    { id: 'apartment', name: 'Lokal' },
    { id: 'building', name: 'Budynek' },
    { id: 'stop', name: 'Miejsce parkingowe lub garażowe' },
];

export const prospectTexts = {
    valoritzationNotAllowed: 'Waloryzacja nie jest dopuszczalna',
    lawStatusNotApplicable: 'Nie dotyczy',
    landAddress: '{1}\nDziałka nr {2}',
    landRegisterNumber: 'Księga wieczysta nr: {1}',
    pricePerMeter: '{1} PLN brutto (słownie: {2} złotych {3}/100)',
    plannedBuildDates: '<b>Planowany termin rozpoczęcia:</b> {1}<br><b>Planowany termin zakończenia:</b> {2}',
    transferOfOwnershipDate: 'Przeniesienie własności nieruchomości i to w wykonaniu umowy deweloperskiej na rzecz Nabywcy nastąpi po uzyskaniu przez Dewelopera ostatecznej decyzji o pozwoleniu na użytkowanie nieruchomości, lecz nie później niż do dnia <b>{1}</b> r. pod warunkiem u regulowania przez Nabywcę na rzecz Dewelopera wszelkich zobowiązań finansowych wynikających z zawartej umowy deweloperskiej.',
};

export const prospectSettingsNames = {
    completed_investment_address: 'UKOŃCZONE PRZEDSIĘWZIĘCIE DEWELOPERSKIE - Adres',
    completed_investment_start_date: 'UKOŃCZONE PRZEDSIĘWZIĘCIE DEWELOPERSKIE - Data rozpoczęcia',
    completed_investment_end_date: 'UKOŃCZONE PRZEDSIĘWZIĘCIE DEWELOPERSKIE - Data wydania ostatecznego pozwolenia na użytkowanie',

    another_completed_investment_address: 'PRZYKŁAD INNEGO UKOŃCZONEGO PRZEDSIĘWZIĘCIA DEWELOPERSKIEGO - Adres',
    another_completed_investment_start_date: 'PRZYKŁAD INNEGO UKOŃCZONEGO PRZEDSIĘWZIĘCIA DEWELOPERSKIEGO - Data rozpoczęcia',
    another_completed_investment_end_date: 'PRZYKŁAD INNEGO UKOŃCZONEGO PRZEDSIĘWZIĘCIA DEWELOPERSKIEGO - Data wydania ostatecznego pozwolenia na użytkowanie',

    last_completed_investment_address: 'OSTATNIE UKOŃCZONE PRZEDSIĘWZIĘCIE DEWELOPERSKIE - Adres',
    last_completed_investment_start_date: 'OSTATNIE UKOŃCZONE PRZEDSIĘWZIĘCIE DEWELOPERSKIE - Data rozpoczęcia',
    last_completed_investment_end_date: 'OSTATNIE UKOŃCZONE PRZEDSIĘWZIĘCIE DEWELOPERSKIE - Data wydania ostatecznego pozwolenia na użytkowanie',

    is_enforcement_proceedings: 'Czy przeciwko deweloperowi prowadzono (lub prowadzi się) postępowania egzekucyjne na kwotę powyżej 100 000 zł',
    bank_consent_information: 'Informacja o zgodzie banku finansującego',

    readable_documents: 'Dokumenty z którymi może się zapoznać osoba zainteresowana',
    readable_documents_description: 'Opis dokumentów z którymi może się zapoznać osoba zainteresowana',

    attachments: 'Załączniki',

    existing_mortgage_load: 'Istniejące obciążenia hipoteczne nieruchomości lub wnioski o wpis w dziale czwartym księgi wieczystej',
    count_of_flats: 'Liczba lokali w budynku',
};

export const prospectMediaAvailable = [
    'Woda',
    'Prąd',
    'Kanalizacja',
    'Gaz',
];

export const prospectDefaultAttachments = [
    'Rzut kondygnacji z zaznaczeniem lokalu mieszkalnego',
    'Wzór umowy deweloperskiej',
];

export const privateStatuses = [
    { value: 'free', name: 'Wolny' },
    { value: 'reserved', name: 'Zarezerwowany' },
    { value: 'sold', name: 'Sprzedany' },
];

export const propertyActions = [
    { action: 'replace', label: 'Dodaj (Nadpisz)' },
    { action: 'add', label: 'Dodaj (Zostaw istniejące)' },
    { action: 'change-value', label: 'Zmień wartość' },
    { action: 'change-public', label: 'Zmień widoczność' },
    { action: 'remove', label: 'Usuń' },
];

export const mediaActions = [
    { action: 'replace', label: 'Zastąp istniejące' },
    { action: 'remove', label: 'Usuń zaznaczone' },
    { action: 'add', label: 'Dodaj do istniejących' },
];

export const objectVariables: VariableFieldType[] = [
    {
        icon: mdiNumeric,
        text: 'Numer obiektu',
        value: 'numer_kategorii',
    },
];

export const levelVariables: VariableFieldType[] = [
    {
        icon: mdiStairs,
        text: 'Numer poziomu',
        value: 'numer_poziomu',
    },
];

export const subjectNameVariables: VariableFieldType[] = [
    {
        icon: mdiNumeric,
        text: 'Numer przedmiotu',
        value: 'numer_przedmiotu',
    },
];

export const subjectCodeVariables: VariableFieldType[] = [

    {
        icon: mdiNumeric,
        text: 'Numer przedmiotu',
        value: 'numer_przedmiotu',
    },
    {
        icon: mdiHandCoinOutline,
        text: 'Id Inwestycji',
        value: 'id_inwestycji',
    }
];

export const knownFieldNames = {
    name: 'Nazwa',
    investment_subject_type_id: 'Typ',
    description: 'Opis',
    code: 'Identyfikator',
    price: 'Cena',
    show_price: 'Pokazuj cenę',
    show_price_per_meter: 'Pokazuj cenę za m²',
    private_status: 'Status prywatny',
    public_status: 'Status publiczny',
    properties: 'Właściwości',
    media: 'Media',
};
