import {rentCostsModule} from '@/modules/rent-costs/shared/state/module';
import {ActionTree, MutationTree} from 'vuex';
import {ModuleState} from '@/shared/state/template/module-state';
import {RootState} from '@/shared/types';
import {AcceptanceDataRequest} from '@/modules/acceptances/shared/requests/acceptance-data-request';
import {Acceptance} from '@/modules/acceptances/shared/models/acceptance';


export const actionsTypes = rentCostsModule.actionsTypes;

export const mutationTypes = rentCostsModule.mutationsTypes;

export const rentCostsActions: ActionTree<ModuleState<Acceptance,
    AcceptanceDataRequest>, RootState> = {
    /**/
  };

