import { RootState } from '@/shared/types';
import { ModuleState } from '@/shared/state/template/module-state';
import { ActionTree } from 'vuex';
import { Notification } from '../../models/notification';
import { NotificationDataRequest } from '../../requests/notification-data-request';
import { notificationsModule } from '../module';
import { fetchByGroups } from '../../services/fetch-by-groups';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ErrorHandler } from '@/shared/state/template/helpers';
import { fetchGroupNotifications } from '../../services/fetch-group-notifications';
import { disableSettings, enableSettings } from '../../services/settings';

export const actionTypes = notificationsModule.actionsTypes;

export const mutationsTypes = notificationsModule.mutationsTypes;

export const notificationsActions: ActionTree<ModuleState<Notification, NotificationDataRequest>, RootState> = {
    async [actionTypes.FETCH_NOTIFICATION_GROUPS]({ commit }, params) {
        commit(mutationsTypes.FETCH_NOTIFICATION_GROUPS_REQUEST, params.filters.read);

        const result: any = await fetchByGroups(params).catch((e: ErrorPayload) => {
            ErrorHandler(e, mutationsTypes.FETCH_NOTIFICATION_GROUPS_ERROR);
        });

        if (result.data && result.status === 200) {
            commit(mutationsTypes.FETCH_NOTIFICATION_GROUPS_SUCCESS, { res: result.data, read: params.filters.read });
        }
        return result;
    },

    async [actionTypes.FETCH_EXTRA_NOTIFICATION_GROUPS]({ commit }, params) {
        commit(mutationsTypes.FETCH_EXTRA_NOTIFICATION_GROUPS_REQUEST, params.filters.group);

        const result: any = await fetchByGroups(params).catch((e: ErrorPayload) => {
            ErrorHandler(e);
            commit(mutationsTypes.FETCH_EXTRA_NOTIFICATION_GROUPS_ERROR, { ...e, ...params.filters.group });
        });

        if (result.data && result.status === 200) {
            commit(mutationsTypes.FETCH_EXTRA_NOTIFICATION_GROUPS_SUCCESS, {
                res: result.data,
                ...params.filters.group,
                read: params.filters.read,
            });
        }
        return result.data;
    },

    async [actionTypes.FETCH_EXTRA_NOTIFICATIONS]({ commit }, { params, read }) {
        commit(mutationsTypes.FETCH_EXTRA_NOTIFICATIONS_REQUEST, params.filters.group);

        const result: any = await fetchGroupNotifications(params).catch((e: ErrorPayload) => {
            ErrorHandler(e);
            commit(mutationsTypes.FETCH_EXTRA_NOTIFICATIONS_ERROR, { ...e, ...params.filters.group });
        });

        if (result.data && result.status === 200) {
            commit(mutationsTypes.FETCH_EXTRA_NOTIFICATIONS_SUCCESS, {
                res: result.data,
                ...params.filters.group,
                read,
            });
        }
        return result.data;
    },

    async [actionTypes.ON_USER_LOGGED]({ commit }, user) {
        commit(mutationsTypes.UPDATE_COUNTER, user.new_notifications_count);
    },

    async [actionTypes.ENABLE_SETTINGS]({commit}, data: any) {

        commit(mutationsTypes.SETTINGS_REQUEST);

        const result: any = await enableSettings(data).catch((e: ErrorPayload) => {
            ErrorHandler(e);
            commit(mutationsTypes.SETTINGS_REQUEST_ERROR, e);
        });

        if (result.status === 204) {
            commit(mutationsTypes.SETTINGS_REQUEST_SUCCESS);
        }
        return result;
    },
    async [actionTypes.DISABLE_SETTINGS]({commit}, data: any) {

        commit(mutationsTypes.SETTINGS_REQUEST);

        const result: any = await disableSettings(data).catch((e: ErrorPayload) => {
            ErrorHandler(e);
            commit(mutationsTypes.SETTINGS_REQUEST_ERROR, e);
        });

        if (result.status === 204) {
            commit(mutationsTypes.SETTINGS_REQUEST_SUCCESS);
        }
        return result;
    },

    async [actionTypes.ON_USER_LOGGED]({ commit }, user) {
        commit(mutationsTypes.UPDATE_COUNTER, user.new_notifications_count);
    },
};
