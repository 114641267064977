import Vue from 'vue';
import {MutationTree} from 'vuex';
import {SearchResultsPayload} from '@/shared/payloads/search-results-payload';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {InvoicesState} from '@/modules/invoices/shared/state/state';
import {invoicesModule} from '../module';

export const mutationTypes = invoicesModule.mutationsTypes;

export const invoicesMutations: MutationTree<InvoicesState> = {
    [mutationTypes.SEARCH_REQUEST](state: InvoicesState): void {
        state.searching = true;
    },

    [mutationTypes.SEARCH_RESET](state: InvoicesState): void {
        state.searchingResults = [];
    },

    [mutationTypes.SEARCH_SUCCESS](state: InvoicesState, payload: SearchResultsPayload): void {
        state.searching = false;
        state.searchingResults = payload.data;
    },

    [mutationTypes.ADDRESS_UPDATE_REQUEST](state: InvoicesState): void {
        state.loadingAddress = true;
    },

    [mutationTypes.ADDRESS_UPDATE_SUCCESS](state: InvoicesState): void {
        state.loadingAddress = false;
    },

    [mutationTypes.APPROVE_REQUEST](state: InvoicesState): void {
        state.approvingItem = true;
        state.itemsErrors = {};
    },

    [mutationTypes.APPROVE_SUCCESS](state: InvoicesState): void {
        state.approvingItem = false;
        state.itemsErrors = {};
    },

    [mutationTypes.APPROVE_ERROR](state: InvoicesState, payload: ErrorPayload): void {
        state.approvingItem = false;
        state.itemsErrors = payload.data.item_errors;
    },


    [mutationTypes.SEND_REQUEST](state: InvoicesState): void {
        state.sendingItem = true;
    },

    [mutationTypes.SEND_SUCCESS](state: InvoicesState): void {
        state.sendingItem = false;
    },

    [mutationTypes.SEND_ERROR](state: InvoicesState, payload: ErrorPayload): void {
        state.sendingItem = false;
        state.error = payload.message ? payload.message : 'Wystąpił błąd';
        state.errorMessages = payload.errors;
    },

    [mutationTypes.DOWNLOAD_REQUEST](state: InvoicesState): void {
        state.downloadingItem = true;
    },

    [mutationTypes.DOWNLOAD_SUCCESS](state: InvoicesState): void {
        state.downloadingItem = false;
    },

    [mutationTypes.DOWNLOAD_ERROR](state: InvoicesState): void {
        state.downloadingItem = false;
    },
};
