import { Investment } from '@/modules/investments/shared/models/investment';
import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { OperationType } from '@/shared/state/template/module-types';
import { RelatedInvestmentDataRequest } from '../../requests/related-investments-data-request';

const relatedInvestmentsProps = new ModuleProps(
    'relatedInvestments',
    {
        one: 'powiązana inwestycja',
        two: 'powiązane inwestycje',
        five: 'powiązanych inwestycji',
        accusative: 'powiązanie'
    },
    ['contractors'],
);

relatedInvestmentsProps.customStateName = 'relatedInvestmentsState';
relatedInvestmentsProps.customDetailsRoute = 'investment-details';
relatedInvestmentsProps.parentIdInURLOperations = [OperationType.Index, OperationType.Update, OperationType.Delete, ];
relatedInvestmentsProps.mockURLName = 'investments';
relatedInvestmentsProps.hasDialog = false;
relatedInvestmentsProps.permissionBaseName = null;
relatedInvestmentsProps.additionalPermissionBaseNames = ['contractors', 'investments'];

const relatedInvestmentsModule = new ModuleCreator<Investment, RelatedInvestmentDataRequest>(
    relatedInvestmentsProps,
);

export {relatedInvestmentsModule};
