const routes = [
    {
        path: '/contractors',
        component: () => import(/* webpackChunkName: "contractorsRoutes" */ '@/modules/contractors/contractors.vue'),
        children: [
            {
                path: 'notes',
                name: 'contractor-notes',
                component: () =>
                    import(
                        /* webpackChunkName: "contractorsRoutes" */ '@/modules/contractors/components/notes/allNotesList.vue'
                    ),
                meta: {
                    requiredPermission: 'contractors.notes.view',
                },
            },
            {
                path: 'notes/:idNote',
                name: 'contractor-notes-details',
                component: () =>
                    import(
                        /* webpackChunkName: "contractorsRoutes" */ '@/modules/contractors/components/notes/allNotesList.vue'
                    ),
                meta: {
                    requiredPermission: 'contractors.notes.view',
                },
            },
            {
                path: ':id',
                name: 'contractor-details',
                component: () =>
                    import(/* webpackChunkName: "contractorsRoutes" */ '@/modules/contractors/components/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'contractors.view',
                },
            },
            {
                path: ':id/notes/:idNote',
                name: 'contractor-details-note-details',
                component: () =>
                    import(/* webpackChunkName: "contractorsRoutes" */ '@/modules/contractors/components/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'contractors.notes.view',
                },
            },
            {
                path: 'settings/contractors-types',
                name: 'contractors-types',
                component: () =>
                    import(
                        /* webpackChunkName: "contractorsRoutes" */ '@/modules/contractors/components/settings/contractor-types/list.vue'
                    ),
                meta: {
                    requiredPermission: 'contractors.types.view',
                },
            },
            {
                path: 'settings/position-types',
                name: 'position-types',
                component: () =>
                    import(
                        /* webpackChunkName: "contractorsRoutes" */ '@/modules/contractors/components/settings/position-types/list.vue'
                    ),
                meta: {
                    requiredPermission: 'contractors.contacts.positions.view',
                },
            },
            {
                path: 'settings/contractors-specializations',
                name: 'contractors-specializations',
                component: () =>
                    import(
                        /* webpackChunkName: "contractorsRoutes" */ '@/modules/contractors/components/settings/contractor-specializations/list.vue'
                    ),
                meta: {
                    requiredPermission: 'contractors.specializations.view',
                },
            },
            {
                path: ':idContractor/contacts/:idContact',
                name: 'contact-details',
                component: () =>
                    import(
                        /* webpackChunkName: "contractorsRoutes" */ '@/modules/contractors/components/contacts/details.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.idContact),
                    };
                },
                meta: {
                    requiredPermission: 'contractors.contacts.view',
                },
            },
            {
                path: ':idContractor/contacts/:idContact/notes/:idNote',
                name: 'contact-details-note-details',
                component: () =>
                    import(
                        /* webpackChunkName: "contractorsRoutes" */ '@/modules/contractors/components/contacts/details.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.idContact),
                    };
                },
                meta: {
                    requiredPermission: 'contractors.notes.view',
                },
            },
            {
                path: '',
                name: 'contractors-list',
                component: () =>
                    import(/* webpackChunkName: "investmentsRoutes" */ '@/modules/contractors/components/list.vue'),
                meta: {
                    requiredPermission: 'contractors.view',
                },
            },
        ],
    },
];

export default routes;
