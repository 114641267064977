import {httpClient} from '@/shared/services';

import {apiConstants} from '../config';
import {UserDataRequest} from '@/modules/admin/shared/requests/user-data-request';
import {FetchParams} from '@/shared/types';
import {objectToQueryString} from '@/shared/helpers';

const fetchUsers = (params: FetchParams) => {
    const url = apiConstants.users + '?' + objectToQueryString(params);

    return httpClient.get(url).then((res) => res.data);
};

const getUser = (id: number) => {
    const url = apiConstants.users + '/' + id;

    return httpClient.get(url).then((res) => res.data);
};

const updateUser = (id: number, data: UserDataRequest) => {
    const url = apiConstants.users + '/' + id;

    return httpClient.put(url, data).then((res) => res.data);
};

const storeUser = (data: UserDataRequest) => {
    const url = apiConstants.users;

    return httpClient.post(url, data).then((res) => res.data);
};

const removeUser = (id: number) => {
    const url = apiConstants.users + '/' + id;

    return httpClient.delete(url).then((res) => res.status);
};

const restoreUser = (id: number) => {
    const url = apiConstants.users + '/' + id + '/restore';

    return httpClient.patch(url).then((res) => res.status);
};

export {fetchUsers, getUser, storeUser, removeUser, updateUser, restoreUser};
