import { HeaderFieldType } from '@/shared/types';

export const investmentsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Data rozpoczęcia', value: 'start_date', align: 'center' },
    { text: 'Data zakończenia', value: 'end_date', align: 'center' },
    { text: 'Data planowanego rozpoczęcia', value: 'planned_start_date', align: 'center' },
    { text: 'Data planowanego zakończenia', value: 'planned_end_date', align: 'center' },
    { text: 'Adres inwestycji', value: 'address', align: 'center' },
    { text: 'Tagi', value: 'tags', align: 'center' },
    { text: 'Suma kosztów', value: 'total_cost', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const investmentsObjectsListHeaders: HeaderFieldType[] = [
    { text: 'Nazwa', value: 'name' },
];

export const investmentSubjectOperationsListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Typ', value: 'investment_subject_type_id' },
    { text: 'Inwestycja', value: 'investment' },
    { text: 'Kategoria', value: 'object' },
    { text: 'Powierzchnia', value: 'area' },
    { text: 'Status prywatny', value: 'private_status' },
    { text: 'Status publiczny', value: 'public_status' },
    { text: 'Cena', value: 'price' },
    { text: 'Identyfikator przedmiotu', value: 'code'},
    { text: 'Adres', value: 'address'},
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const relatedInvestmentSubjectListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Status prywatny', value: 'private_status' },
    { text: 'Status publiczny', value: 'public_status' },
    { text: 'Cena', value: 'price' },
    { text: 'Identyfikator przedmiotu', value: 'code'},
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const investmentSubjectsListHeaders: HeaderFieldType[] = [
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const prospectSettingsHeaders: HeaderFieldType[] = [
    { text: 'Nazwa pola', value: 'key', except: true },
    { text: 'Powiązany element', value: 'assignable' },
    { text: 'Akcje', value: 'actions', align: 'right'},
];

export const investmentSubjectsTypeListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const investmentSubjectPropertyTypesListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const investmentSubjectRoomsListHeaders: HeaderFieldType[] = [
    { text: 'Identyfikator', value: 'code' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Typ', value: 'type' },
    { text: 'Piętro', value: 'storey', align: 'right' },
    { text: 'Powierzchnia', value: 'area', align: 'right' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const investmentSubjectCatalogChangesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Kod', value: 'code' },
    { text: 'Jednostka', value: 'unit' },
    { text: 'Liczba', value: 'count'},
    { text: 'Cena netto', value: 'price'},
    { text: 'Cena brutto', value: 'price_with_tax'},
];

// Generator

export const investmentSubjectRoomsListGeneratorHeaders: HeaderFieldType[] = [
    { text: 'Typ', value: 'investment_subject_room_type_id', },
    { text: 'Nazwa', value: 'name' },
    { text: 'Powierzchnia', value: 'area' },
    { text: 'Kubatura', value: 'volume' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const investmentSubjectRoomsListGeneratorEditorHeaders: HeaderFieldType[] = [
    { text: 'Typ', value: 'investment_subject_room_type_id', },
    { text: 'Nazwa', value: 'name' },
    { text: 'Poziom', value: 'storey' },
    { text: 'Powierzchnia', value: 'area' },
    { text: 'Kubatura', value: 'volume' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const investmentSubjectRoomsListGeneratorEditorHeadersTab: HeaderFieldType[] = [
    { text: 'Typ', value: 'investment_subject_room_type_id', },
    { text: 'Nazwa', value: 'name' },
    { text: 'Poziom', value: 'storey' },
    { text: 'Powierzchnia', value: 'area' },
    { text: 'Kubatura', value: 'volume' },
];

export const investmentSubjectPropertiesListGeneratorHeaders: HeaderFieldType[] = [
    { text: 'Typ', value: 'investment_subject_property_type_id', },
    { text: 'Wartość', value: 'value' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

// Settings

export const tagsListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const objectTypesListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const roomTypesListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];
