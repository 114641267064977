import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {InvoiceDataRequest} from '@/modules/invoices/shared/requests/invoice-data-request';
import {Invoice} from '@/modules/invoices/shared/models/invoice';
import {InvoicesState} from '@/modules/invoices/shared/state/state';

const invoicesProps = new ModuleProps('invoices',
    {
        one: 'faktura',
        two: 'faktury',
        five: 'faktur',
        accusative: 'fakturę',
    });

const invoicesModule = new ModuleCreator<Invoice, InvoiceDataRequest>(invoicesProps);

invoicesModule.state = new InvoicesState();

invoicesModule.additionalMutationTypes = [
    'ADDRESS_UPDATE_REQUEST',
    'ADDRESS_UPDATE_SUCCESS',

    'SEND_REQUEST',
    'SEND_ERROR',
    'SEND_SUCCESS',

    'APPROVE_REQUEST',
    'APPROVE_ERROR',
    'APPROVE_SUCCESS',

    'DOWNLOAD_REQUEST',
    'DOWNLOAD_SUCCESS',
    'DOWNLOAD_ERROR',
];

invoicesModule.additionalActionTypes = [
    'DOWNLOAD_LIST',
    'UPDATE_BUYER',
    'UPDATE_SELLER',
    'DOWNLOAD',
    'SEND_EMAIL',
    'APPROVE',
];

export {invoicesModule};

