import { objectToQueryString } from '@/shared/helpers';
import { httpClient } from '@/shared/services';
import { FetchParams } from '@/shared/types';

export const fetchGroupNotifications = (params: FetchParams) => {
    const url = `/api/v1/notifications?${objectToQueryString(params)}`;
    return httpClient.get(url);

};

