import { rentCostsModule} from './module';
import {
    rentCostAttachmentsModule,
    rentCostCategoriesModule,
    rentCostExamplePositionsModule,
    rentCostExampleIssuersModule,
    rentCostPositionsModule, rentCostTypesModule,

} from '@/modules/rent-costs/shared/state/submodules';
import { RentcostsState } from './state';
import {rentCostsActions} from '@/modules/rent-costs/shared/state/actions/rent-costs';
import {rentCostsMutations} from '@/modules/rent-costs/shared/state/mutations/rent-costs';

const categoriesState = rentCostCategoriesModule.module;
const examplePositionsState = rentCostExamplePositionsModule.module;
const exampleIssuersState = rentCostExampleIssuersModule.module;
const attachmentsState = rentCostAttachmentsModule.module;
const typesState = rentCostTypesModule.module;
const positionsState = rentCostPositionsModule.module;

rentCostsModule.state = new RentcostsState();

rentCostsModule.additionalActions = rentCostsActions;

rentCostsModule.additionalMutations = rentCostsMutations;

rentCostsModule.modules = {
    categoriesState,
    examplePositionsState,
    exampleIssuersState,
    attachmentsState,
    typesState,
    positionsState,
};

export default rentCostsModule.module;
