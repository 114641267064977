import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { TicketTag, TicketTagDataRequest } from '@/modules/tickets/shared';

const tagsProps = new ModuleProps(
    'tags',
    {
        one: 'tag',
        two: 'tagi',
        five: 'tagów',
    },
    ['tickets'],
);

tagsProps.customStateName = 'tagsState';

const tagsModule = new ModuleCreator<TicketTag, TicketTagDataRequest>(tagsProps);

export { tagsModule };
