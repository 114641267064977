import { ErrorPayload } from '@/shared/payloads/error-payload';
import { MutationTree } from 'vuex';
import { DocumentsState } from '../state/documents';
import { documentsModule } from '../module';

export const mutationTypes = documentsModule.mutationsTypes;

export const documentsMutations: MutationTree<DocumentsState> = {

    [mutationTypes.DOWNLOAD_REQUEST](state: DocumentsState): void {
        state.downloadingItem = true;
    },

    [mutationTypes.DOWNLOAD_SUCCESS](state: DocumentsState): void {
        state.downloadingItem = false;
    },

    [mutationTypes.DOWNLOAD_ERROR](state: DocumentsState): void {
        state.downloadingItem = false;
    },
};
