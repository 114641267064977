import {salesModule, statusesModule, tasksListsModule, tasksListTasksModule, tasksModule} from '@/modules/sales/shared';
import { salesActions } from './actions/sale';
import { salesMutations } from './mutations/sale';
import { SalesState } from './state';

const statusesState = statusesModule.module;
const tasksListsState = tasksListsModule.module;
const tasksListTasksState = tasksListTasksModule.module;
const tasksState = tasksModule.module;

salesModule.state = new SalesState();
salesModule.additionalActions = salesActions;
salesModule.additionalMutations = salesMutations;


salesModule.modules = { statusesState, tasksListsState, tasksListTasksState, tasksState };

const module = salesModule.module;

export default module;
