
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
@Component({})
export default class TooltipBtn extends Vue {
    @Prop({type: Boolean, default: false}) public tooltipDisabled!: boolean;
    @Prop({type: Object, default: () => ({})}) public tooltipAttrs!: any;
    @Prop(String) public tooltipText!: string;
    @Prop([String, Object]) public contentClass!: any;
    @Prop([String, Object]) public wrapperClass!: any;

}
