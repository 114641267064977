import { catalogsActions } from './actions/catalogs';
import {changesModule} from './module';
import { catalogsMutations } from './mutations/catalogs';
import { CatalogsState } from './state/catalogs';
import { categoriesModule } from './submodules';
import { catalogsModule } from './submodules';

import { catalogChangesModule } from './submodules';

const categoriesState = categoriesModule.module;

catalogsModule.state = new CatalogsState();

catalogsModule.additionalMutations = catalogsMutations;

catalogsModule.additionalActions = catalogsActions;

const catalogsState = catalogsModule.module;

const catalogChangesState = catalogChangesModule.module;

changesModule.modules = {
    catalogsState,
    categoriesState,
    catalogChangesState
};

const module = changesModule.module;
export default module;

