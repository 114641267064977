import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { SaleStatus } from '@/modules/sales/shared/models/status';
import { SaleStatusDataRequest } from '@/modules/sales/shared/requests/status-data-request';

const statusesProps = new ModuleProps(
    'statuses',
    {
        one: 'Status',
        two: 'Statusy',
        five: 'Statusów',
        accusative: 'Status',
    },
    ['sales'],
);

statusesProps.customStateName = 'statusesState';

const statusesModule = new ModuleCreator<SaleStatus, SaleStatusDataRequest>(statusesProps);

export { statusesModule };
