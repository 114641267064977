
import Vue from 'vue';
// import {privateStatuses, publicStatuses} from '@/shared/config/investments';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
// import { parsePrice} from '@/shared/helpers';


@Component({})
export default class ContractorSearchDetails extends Vue {
    @Prop(Object) public item!: null | any;

    public zipWithSeparator(zip: string) {
        if (zip && typeof zip === 'string') {
            return [zip.slice(0, 2), zip.slice(2)].join('-');
        }
    }
    // public parsePrice = parsePrice;

    //  get itemPrivateStatus() {
    //     return privateStatuses.find(
    //         (status) => status.value === this.item.private_status,
    //     );
    // }

    // get itemPublicStatus() {
    //     return publicStatuses.find(
    //         (status) => status.value === this.item.public_status,
    //     );
    // }
}
