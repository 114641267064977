export const readMutationTypes: string[] = [
    'DATA_SUCCESS',
    'DATA_LIST_SUCCESS',
    'DATA_REQUEST',
    'DATA_ERROR',
    'PICKER_DATA_SUCCESS',
    'PICKER_DATA_REQUEST',
    'PICKER_DATA_ERROR',
    'GET_ERROR',
    'GET_SUCCESS',
    'GET_REQUEST',
];

export const writeMutationTypes: string[] = [
    'UPDATE_ERROR',
    'UPDATE_SUCCESS',
    'UPDATE_REQUEST',
    'STORE_ERROR',
    'STORE_SUCCESS',
    'STORE_REQUEST',
    'REMOVE_REQUEST',
    'REMOVE_ERROR',
    'REMOVE_SUCCESS',
];

export const readActionTypes: string[] = [
    'FETCH_DATA',
    'SHOW_ITEM',
    'FETCH_PICKER',
];

export const writeActionTypes: string[] = [
    'UPDATE_ITEM',
    'STORE_ITEM',
    'REMOVE_ITEM',
];

export const coreMutationTypes: string[] = [
    'CHANGE_PAGE',
    'UPDATE_PARAMS',
    'UPDATE_EDITED',
    'UPDATE_CURRENT',
    'UPDATE_ERROR_TITLE',
    'UPDATE_ERROR_MESSAGES',
    'UPDATE_LIST_ERROR_TITLE',
    'UPDATE_ERROR_MESSAGES',
    'UPDATE_FORM_DIALOG',
    'UPDATE_SHOW_DIALOG',
    'UPDATE_REMOVE_DIALOG',
    'UPDATE_RELATED_DIALOG',
    'UPDATE_RESTORE_DIALOG',
    'REGISTER_PICKER',
    'UNREGISTER_PICKER',
];

export enum OperationType {
    Index,
    Show,
    Store,
    Update,
    Delete,
}
