
import {Component, Prop, Vue} from 'vue-property-decorator';
import {errorsToArray} from '@/shared/services';
import {appDebug, environment} from '@/environment/environment';
import {Environment, RootState} from '@/shared/types';

@Component
export default class ErrorBoundary extends Vue {
    @Prop(String) public error!: string;
    @Prop(Object) public errorMessages!: object;
    public environment = environment;
    public Environment = Environment;
    public appDebug = appDebug;
    private store: RootState = this.$store.state;

    get errors() {
        return errorsToArray(this.errorMessages);
    }

    get err(): Error|null {
        return this.store.err;
    }

    public clearErrors(value: boolean) {
        if (!value && this.err) {
            setTimeout(
                () => {
                    this.$store.commit('VUE_ERROR', null);
                },
                200
            );
        }
    }
}
