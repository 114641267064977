import {
    mdiAccountEdit,
    mdiCheck,
    mdiEmail,
    mdiFileDocument,
    mdiFileDocumentEdit,
    mdiPlaylistEdit,
    mdiPlaylistMinus,
    mdiPlaylistPlus,
} from '@mdi/js';

export const invoiceStatuses = [
    {id: 'new', name: 'Nowa', color: 'default'},
    {id: 'approved', name: 'Zatwierdzona', color: 'success'},
    {id: 'sent', name: 'Wysłana', color: 'info'},
    {id: 'rejected', name: 'Odrzucona', color: 'error'},
];

export const invoiceLogNames = [
    {id: 'create', name: 'Utworzenie faktury', icon: mdiFileDocument},
    {id: 'update', name: 'Aktualizacja danych', icon: mdiFileDocumentEdit},
    {id: 'update-seller-address', name: 'Aktualizacja danych sprzedającego', icon: mdiAccountEdit},
    {id: 'update-buyer-address', name: 'Aktualizacja danych kupującego', icon: mdiAccountEdit},
    {id: 'approve', name: 'Zatwierdzona', icon: mdiCheck},
    {id: 'sent', name: 'Wysłana', icon: mdiEmail},
    {id: 'add-item', name: 'Dodanie pozycji do faktury', icon: mdiPlaylistPlus},
    {id: 'add-items', name: 'Dodanie kilku pozycji do faktury', icon: mdiPlaylistPlus},
    {id: 'remove-item', name: 'Usunięcie pozycji faktury', icon: mdiPlaylistMinus},
    {id: 'update-item', name: 'Edycja pozycji faktury', icon: mdiPlaylistEdit},
];


export const invoiceTypes = [
    {id: 'vat', name: 'Faktura'},
];

export const invoicePaymentMethods = [
    {id: 'transfer', name: 'Przelew', main: true},
    {id: 'cash', name: 'Gotówka', main: false},
];

export const invoicePaymentDeadlines = [
    {name: '1 dzień', value: 1},
    {name: '3 dni', value: 3},
    {name: '5 dni', value: 5},
    {name: '7 dni', value: 7},
    {name: '14 dni', value: 14},
    {name: '21 dni', value: 21},
    {name: '30 dni', value: 30},
    {name: '45 dni', value: 45},
    {name: '60 dni', value: 60},
    {name: '75 dni', value: 75},
    {name: '90 dni', value: 90},
    {name: 'natychmiast', value: 0},
];
