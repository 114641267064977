import Vue from 'vue';
import { reflectKeys } from '@/shared/services';
import { MutationTree } from 'vuex';
import { ContractorsState } from './state';
import { GusResultsPayload } from '@/modules/contractors/shared/payloads/gus-results-payload';
import { contractorsModule } from './module';

export const mutationsTypes = contractorsModule.mutationsTypes;

export const mutations: MutationTree<ContractorsState> = {
    [mutationsTypes.CONTRACTORS_SEARCH_REQUEST](state: ContractorsState): void {
        Vue.set(state, 'searching', true);
        Vue.set(state, 'searchingResults', []);
        Vue.set(state, 'searchError', '');
    },

    [mutationsTypes.CONTRACTORS_SEARCH_RESET](state: ContractorsState): void {
        Vue.set(state, 'searching', false);
        Vue.set(state, 'searchingResults', []);
        Vue.set(state, 'searchError', '');
    },

    [mutationsTypes.CONTRACTORS_SEARCH_ERROR](
        state: ContractorsState,
        payload: GusResultsPayload,
    ): void {
        Vue.set(state, 'searching', false);
        Vue.set(state, 'searchError', payload.error);
        Vue.set(state, 'searchingResults', []);
    },

    [mutationsTypes.CONTRACTORS_SEARCH_SUCCESS](
        state: ContractorsState,
        payload: GusResultsPayload,
    ): void {
        Vue.set(state, 'searching', false);
        Vue.set(state, 'searchingResults', [payload.data]);
    },
};
