
/**
 *  Module routes:
 *  - module router
 *      - list
 *      - details
 *  TODO: - add routes to main-navigation
 */
const routes = [
    {
        path: '/documents',
        component: () => import(/* webpackChunkName: "documentsRoutes" */ './documents.vue'),
        children: [
            {
                path: '',
                name: 'documents-list',
                component: () =>
                    import(/* webpackChunkName: "documentsRoutes" */ './components/list/list.vue'),
                meta: {
                    requiredPermission: 'documents.view',
                },
            },
            {
                path: 'categories',
                name: 'documents-categories-list',
                component: () =>
                    import(/* webpackChunkName: "costsRoutes" */ '@/modules/documents/components/settings/categories/list.vue'),
                meta: {
                    requiredPermission: 'documents.categories.view',
                },
            },
            {
                path: 'templates',
                name: 'documents-templates-list',
                component: () =>
                    import(/* webpackChunkName: "costsRoutes" */ '@/modules/documents/components/templates/list/list.vue'),
                meta: {
                    requiredPermission: 'documents.templates.view',
                },
            },
            {
                path: 'templates/:id',
                name: 'documents-templates-details',
                component: () =>
                    import(/* webpackChunkName: "costsRoutes" */ '@/modules/documents/components/templates/details/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'documents.templates.view',
                },
            },
            {
                path: 'tags',
                name: 'documents-tags-list',
                component: () =>
                    import(
                        /* webpackChunkName: "costsRoutes" */ '@/modules/documents/components/settings/tags/list.vue'),
                meta: {
                    requiredPermission: 'documents.tags.view',
                },
            },
            {
                path: ':id',
                name: 'document-details',
                component: () =>
                    import(/* webpackChunkName: "documentsRoutes" */ './components/details/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'documents.view',
                },
            },
        ],
    },
];

export default routes;

