const routes = [
    {
        path: '/calendar',
        component: () => import(/* webpackChunkName: "calendarRoutes" */ '@/modules/calendar/calendar.vue'),
        children: [
            {
                path: '/calendar',
                name: 'calendar-list',
                component: () =>
                    import(/* webpackChunkName: "calendarRoutes" */ '@/modules/calendar/components/calendar.vue'),
                props: true,
                meta: {
                    expandView: true,
                    hideSearchBar: true,
                },
            },

        ],
    },
];

export default routes;
