import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { Investment } from '../models/investment';
import { InvestmentDataRequest } from '../requests/investment-data-request';

const investmentsProps = new ModuleProps('investments', {
  one: 'inwestycja',
  two: 'inwestycje',
  five: 'inwestycji',
  accusative: 'inwestycję',
});
investmentsProps.loadListOnCreate = false;
investmentsProps.fullscreenForm = true;
investmentsProps.hasDialog = false;

const investmentsModule =  new ModuleCreator<Investment, InvestmentDataRequest>(investmentsProps);

investmentsModule.additionalMutationTypes = [
  'GENERATOR_REQUEST',
  'GENERATOR_ERROR',
  'GENERATOR_SUCCESS',
];

investmentsModule.additionalActionTypes = [
    'GENERATE_INVESTMENT',
];

export {investmentsModule};
