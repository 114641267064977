export const fileNumerals = {
    one: 'plik',
    two: 'pliki',
    five: 'plików',
};

export const mediaPermissions = [
    {
        icon: 'mdi-earth',
        name: 'Publiczny',
        value: '2',
    },
    {
        icon: 'mdi-account-group',
        name: 'Dostępny dla innych',
        value: '1',
    },
    {
        icon: 'mdi-account',
        name: 'Prywatny',
        value: '0',
    },
];

export const acceptedExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',

    'pdf',

    'doc',
    'docx', // (Microsoft Word Document)

    'xls',
    'xlsx', // (Microsoft Excel Document)

    'zip', // (Archive File Format)

    'xml',

    'csv',

    'odt',

    'ppt',
    'pptx',
    'pps',
    'ppsx',
    'key',

    'mp3',
    'm4a',
    'ogg',
    'wav',

    // Video

    'mp4',
    'm4v',
    'mov',
    'wmv',
    'avi',
    'mpg',
    'ogv',
    '3gp',
    '3g2',
];
