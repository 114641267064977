import {MutationTree} from 'vuex';
import {acceptancesModule} from '@/modules/acceptances/shared/state/module';
import { AcceptanceDataRequest } from '../../requests/acceptance-data-request';
import {Acceptance} from '@/modules/acceptances/shared/models/acceptance';
import {ModuleState} from '@/shared/state/template/module-state';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {errorsToArray} from '@/shared/services';

export const mutationsTypes = acceptancesModule.mutationsTypes;

export const acceptancesMutations: MutationTree<any> = {
    [mutationsTypes.APPROVE_REQUEST](state: ModuleState<Acceptance, AcceptanceDataRequest>) {
        state.loadingItem = true;
        state.error = '';
        state.errorMessages = {};
    },

    [mutationsTypes.APPROVE_ERROR](
        state: ModuleState<Acceptance, AcceptanceDataRequest>,
        payload: ErrorPayload,
    ) {
        state.error = 'Wystąpił błąd';
        state.errorMessages = payload.errors;
        state.loadingItem = false;
    },

    [mutationsTypes.APPROVE_SUCCESS](state: ModuleState<Acceptance, AcceptanceDataRequest>) {
        state.loadingItem = false;
    },
};
