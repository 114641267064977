
import {Component, Vue} from 'vue-property-decorator';

@Component({
    props: {
        data: Object,
    },
})
export default class AddressField extends Vue {
    public data!: object;
}
