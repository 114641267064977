import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {Acceptance} from '@/modules/acceptances/shared/models/acceptance';
import {AcceptanceDataRequest} from '@/modules/acceptances/shared/requests/acceptance-data-request';

const acceptancesProps = new ModuleProps('acceptances',
    {one: 'protokół odbioru',
        two: 'protokoły odbioru',
        five: 'protokołów odbioru',
    });

acceptancesProps.hasDialog = false;
acceptancesProps.fullscreenForm = true;

const acceptancesModule = new ModuleCreator<Acceptance, AcceptanceDataRequest>(acceptancesProps);

acceptancesModule.additionalActionTypes = [
    'APPROVE',
];

acceptancesModule.additionalMutationTypes = [
    'APPROVE_REQUEST',
    'APPROVE_ERROR',
    'APPROVE_SUCCESS',
];

export {acceptancesModule};
