const routes = [
    {
        path: '/admin/users',
        name: 'users-list',
        component: () => import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/users/list.vue'),
        meta: {
            requiredPermission: 'admin.users.view',
        },
    },
    {
        path: '/admin/invites',
        name: 'invites-list',
        component: () => import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/invites/list.vue'),
        meta: {
            requiredPermission: 'admin.invites.view',
        },
    },
    {
        path: '/admin/users/user-roles',
        name: 'users-roles',
        component: () => import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/permissions/roles/list.vue'),
        meta: {
            requiredPermission: 'admin.roles.view',
        },
    },
    {
        path: '/admin/users/:id',
        name: 'user-details',
        component: () => import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/users/details.vue'),
        props: (query: any) => {
            return {
                id: String(query.params.id),
            };
        },
        meta: {
            requiredPermission: 'admin.users.view',
        },
    },
    {
        path: '/admin/users/user-roles/:id',
        name: 'role-details',
        component: () => import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/permissions/roles/details.vue'),
        props: (query: any) => {
            return {
                id: String(query.params.id),
            };
        },
        meta: {
            requiredPermission: 'admin.roles.view',
        },
    },
    {
        path: '/admin/subscriptions',
        component: () => import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/admin.vue'),
        children: [
            {
                path: 'buy',
                name: 'new-subscription',
                component: () =>
                    import(
                        /* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/subscriptions/form.vue'
                        ),
                meta: {
                    requiredPermission: 'admin.subscriptions.view',
                    expandView: true,
                },
                props: true,
            },
            {
                path: 'rebuy/:subscriptionId',
                name: 're-buy-plan',
                component: () =>
                    import(
                        /* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/subscriptions/form.vue'
                        ),
                meta: {
                    requiredPermission: 'admin.subscriptions.view',
                    expandView: true,
                },
                props: (query: any) => {
                    return {
                        subscriptionId: Number(query.params.subscriptionId),
                    };
                },
            },

            {
                path: '',
                name: 'subscriptions-list',
                component: () =>
                    import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/subscriptions/list.vue'),
                meta: {
                    requiredPermission: 'admin.subscriptions.view',
                },
            },
        ],
    },
    {
        path: '/payments',
        name: 'payment-result',
        component: () =>
            import(
                /* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/subscriptions/payment-confirm.vue'
                ),
        meta: {
            requiredPermission: 'admin.subscriptions.view',
        },
    },
    {
        path: '/payments/success',
        name: 'payment-success',
        component: () =>
            import(
                /* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/subscriptions/payment-confirm.vue'
                ),
        meta: {
            requiredPermission: 'admin.subscriptions.view',
        },
    },
    {
        path: '/payments/failed',
        name: 'payment-failed',
        props: {
            staticStatus: 3,
        },
        component: () =>
            import(
                /* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/subscriptions/payment-confirm.vue'
                ),
        meta: {
            requiredPermission: 'admin.subscriptions.view',
        },
    },
    {
        path: '/admin/orders',
        name: 'orders-list',
        component: () =>
            import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/orders/components/order-list.vue'),
        meta: {
            requiredPermission: 'admin.orders.view',
        },
    },
    {
        path: '/admin/orders/:id',
        name: 'orders-list-details',
        component: () =>
            import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/orders/components/order-list.vue'),
        props: (query: any) => {
            return {
                orderId: String(query.params.id),
            };
        },
        meta: {
            requiredPermission: 'admin.orders.view',
        },
    },
    {
        path: '/admin/footers-and-headers',
        name: 'footers-and-headers-form',
        component: () =>
            import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/footers-and-headers/form.vue'),
        meta: {
            requiredPermission: 'admin.client.edit',
        },
    },
    {
        path: '/admin/payment-data',
        component: () =>
            import(/* webpackChunkName: "adminRoutes" */ '@/modules/admin/components/payment-data/payment-data.vue'),
        props: (route: any) => {
            return {
                id: route.query.order_id,
            };
        },
        meta: {
            requiredPermission: 'admin.client.edit',
        },
    },
];

export default routes;
