import {logger, reflectKeys} from '@/shared/services';
import {ActionTree} from 'vuex';
import {FetchParams, RootState} from '@/shared/types';
import {fetchRoles, getRole, removeRole, storeRole, updateRole} from '@/modules/admin/shared/services';
import {mutationTypes} from '@/modules/admin/shared/state/types';
import {actionsTypes} from '@/modules/admin/shared/state/types';
import {RolesPayload} from '@/modules/admin/shared/payloads/roles-payload';
import {ModuleState} from '@/modules/admin/shared/state';
import {ShowRolePayload} from '@/modules/admin/shared/payloads/show-role-payload';
import {RoleDataRequest} from '@/modules/admin/shared/requests/role-data-request';
import {ErrorPayload} from '@/shared/payloads/error-payload';

/**
 * Roles data admin
 */
export const rolesActions: ActionTree<ModuleState, RootState> = {
    /** fetch role data */
    async [actionsTypes.FETCH_ROLE_DATA]({commit}, params: FetchParams) {
        commit(mutationTypes.ROLES_DATA_REQUEST, params);

        const result: RolesPayload = await fetchRoles(params).catch((e: ErrorPayload) => {
            commit(mutationTypes.ROLES_DATA_ERROR, e);
        });

        if (result) {
            result.simple = params.simple;
            commit(mutationTypes.ROLES_DATA_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.SHOW_ROLE]({commit}, id: number) {
        commit(mutationTypes.ROLE_GET_REQUEST);

        const result: ShowRolePayload = await getRole(id).catch((e: ErrorPayload) => {
            commit(mutationTypes.ROLE_GET_ERROR, e);
        });

        if (result) {
            commit(mutationTypes.ROLE_GET_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.UPDATE_ROLE]({commit}, data: RoleDataRequest) {
        commit(mutationTypes.ROLE_GET_REQUEST);

        const result: ShowRolePayload = await updateRole(data.id, data).catch((e: ErrorPayload) => {
            commit(mutationTypes.ROLE_GET_ERROR, e);
        });

        if (result) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Zaktualizowano użytkownika' });
            commit(mutationTypes.ROLE_GET_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.STORE_ROLE]({commit}, data: RoleDataRequest) {
        commit(mutationTypes.ROLE_GET_REQUEST);

        const result: ShowRolePayload = await storeRole(data).catch((e: ErrorPayload) => {
            commit(mutationTypes.ROLE_GET_ERROR, e);
        });

        if (result) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Utworzono użytkownika' });
            commit(mutationTypes.ROLE_GET_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.REMOVE_ROLE]({commit}, id: number) {
        commit(mutationTypes.ROLE_REMOVE_REQUEST);

        const result: number|void = await removeRole(id).catch((e: ErrorPayload) => {
            logger.error(e);
            commit(mutationTypes.ROLE_REMOVE_ERROR, e);
        });

        if (result < 300) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Usunięto użytkownika' });
            commit(mutationTypes.ROLE_REMOVE_SUCCESS, result);
        }

        return result;
    },
};
