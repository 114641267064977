import { render, staticRenderFns } from "./selectable-table.vue?vue&type=template&id=222101eb&scoped=true&"
import script from "./selectable-table.vue?vue&type=script&lang=ts&"
export * from "./selectable-table.vue?vue&type=script&lang=ts&"
import style0 from "./selectable-table.vue?vue&type=style&index=0&id=222101eb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "222101eb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VScrollXTransition } from 'vuetify/lib/components/transitions';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VChip,VIcon,VItem,VItemGroup,VScrollXTransition,VSimpleTable})
