import {MutationTree} from 'vuex';
import {ViewsState} from '@/modules/visualizations/shared/state/state/views';
import {visualizationViewsModule} from '@/modules/visualizations/shared/state/submodules';
import {VisualizationViewDataRequest} from '@/modules/visualizations/shared/requests/visualization-view-data-request';
import {VisualizationView} from '@/modules/visualizations/shared/models/visualization-view';

const mutationTypes = visualizationViewsModule.mutationsTypes;

export const viewsMutations: MutationTree<ViewsState> = {
    [mutationTypes.UPDATE_EDITOR_DIALOG](state: ViewsState, value: boolean): void {
        state.editorDialog = value;
    },

    [mutationTypes.UPDATE_HISTORY](state: ViewsState, value: string[]): void {
        state.changeHistory = value;
    },

    [mutationTypes.UPDATE_EDITED_EDITOR](
        state: ViewsState,
        newVal: VisualizationView | VisualizationViewDataRequest
    ): void {
        state.editedItemEditor = newVal as VisualizationViewDataRequest;
    },

    [mutationTypes.UPDATE_VIEW](state: ViewsState, value: number): void {
        state.currentView = value;
    },
};
