
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { parseDate } from '@/shared/helpers';
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import TotalCosts from '@/modules/investments/components/total-costs.vue';
import { costStatuses } from '@/shared/config/costs';

@Component({
    components: {
        InvestmentTagChip,
        TotalCosts,
    },
})
export default class InvestmentSearchDetails extends Vue {
    @Prop(Object) public item!: null | any;
    public costStatuses = costStatuses.filter((status) => ['new', 'approved', 'settled'].includes(status.id));
    public parseDate = parseDate;
}
