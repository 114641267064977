import MediaList from '@/modules/media/components/list.vue';
import MediaPage from '@/modules/media/media.vue';

const routes = [
    {
        path: '/media',
        component: MediaPage,
        children: [
            {
                path: '',
                name: 'media-list',
                component: MediaList,
            },
        ],
    },
];

export default routes;
