export class TutorialController {
    constructor(private to: any, private store: any) {}

    public async findModuleTutorial() {

        // fetch urls if state hasn't urls
        if (!this.stateHasTutorialUrls()) {
            await this.fetchUrls();
            if (!this.stateHasTutorialUrls()) {
                this.setUrls(null);
                this.setModuleTutorials(null);
                return;
            }
        }

        // fetch tutorials for module
        const moduleTutorials = this.findModuleTutorialsForThisUrl();
        if (moduleTutorials) {
            await this.setModuleTutorials(moduleTutorials.tutorials);
        } else {
            this.setModuleTutorials(null);
        }
    }

    private get currentUrl() {
        return this.to.path;
    }

    //  STATE
    private fetchUrls() {
        return this.store.dispatch('fetchUrls');
    }

    private fetchModuleTutorials(id: number) {
        return this.store.dispatch('fetchModuleTutorials', id);
    }

    private setUrls(urls: any) {
        return this.store.commit('[TUTORIAL] SET_URLS', urls);
    }
    private setModuleTutorials(tutorials: any) {
        return this.store.commit('[TUTORIAL] SET_MODULE_TUTORIALS', tutorials);
    }

    private get state() {
        return this.store.state.tutorialState;
    }

    private get tutorialUrls() {
        return this.state.tutorialUrls;
    }

    // HELPERS
    private stateHasTutorialUrls() {
        return this.tutorialUrls && this.tutorialUrls.length > 0;
    }

    private findModuleTutorialsForThisUrl() {
        const tutorial = this.tutorialUrls.find((tutorials: any) => tutorials.path === this.currentUrl);
        return tutorial ? tutorial : null;
    }
}
