export interface RootState {
    version: string|null;
    versionUrl: string|null;
    snackbars: Snackbar[];
    environment: Environment;
    err: Error | null;
    hideNavigationBar: boolean;
    navigationBarVariant: boolean;
    loadingScreen: boolean;
    loadingTitle: string | null;
    loadingSubtitle: string | null;
    testMode: TestModeState | null;
    switchingTestMode: boolean;
}

export enum EnvironmentColor {
    Staging = '#DF542F',
    Testing = '#dab600',
    Development = '#1E90FF',
    Production = '#777777',
}

export enum Environment {
    Staging = 'Staging',
    Testing = 'Testing',
    Development = 'Development',
    Production = 'Production',
}

export enum TestModeState {
    Active = 'active',
    Unactive = 'unactive',
    Switching = 'switching',
}

export interface HeaderFieldType {
    text: string;
    value: string;
    align?: string;
    except?: boolean;
    maxWidth?: string;
    width?: string;
    sortable?: boolean;
}

export interface VariableFieldType {
    icon: string;
    text: string;
    value: string;
    overwrite?: boolean;
}

export interface SearchItem {
    id: number;
    name: string;
    object_type?: string;
    currency: string;
    sell_price: number;
    tax_rate: number;
    unit: string;
    sku: string;
}

export interface ListFilterDataItem {
    name: string;
    label?: string;
    value: false;
    description?: string;
}

export interface ListFilter {
    name: string;
    type: string; // select, date, range, switch, search
    label?: string;
    chip?: boolean;
    tree?: boolean;
    treeParent?: string;
    disabled?: boolean;
    description?: string;
    data?: ListFilterDataItem[];
}

export interface GetParams {
    page?: number;
    search?: string;
}

export interface SearchItem {
    id: number;
    name: string;
}

export interface ModuleConjugation {
    one: string;
    two: string;
    five: string;
    accusative?: string;
}

export interface FetchParams {
    [key: string]: any;

    location?: string;
    id?: number;
    second_id?: number;
    simple?: boolean;
    filters?: any;
    page: number;
}

export interface FetchLinks {
    first: string;
    last: string;
    next?: string;
    prev?: string;
}

export interface MetaItem {
    pagination_key: string;
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
    path: string;
}

export interface FooterProps {
    nextIcon: string;
    prevIcon: string;
    disableItemsPerPage?: boolean;
    disablePagination?: boolean;
    itemsPerPageText?: string;
    itemsPerPageOptions: number[];
}

export interface SaveResponse<T> {
    next?: boolean;
    edit: boolean;
    data: T;
}

export interface StatusType {
    id: string;
    name: string;
    icon?: string;
    color?: string;
    returning?: boolean;
}

export interface LoadingScreenPayload {
    loadingScreen: boolean;
    loadingTitle: string | null;
    loadingSubtitle: string | null;
}

export interface Snackbar {
    type: string;
    text: string;
    href?: string;
    permanent?: boolean;
    errors?: object;
    websocket?: true;
    show?: boolean;
    action?: () => {};
    duration?: number;
    actionText?: string;
}

export interface Names {
    one: string;
    two: string;
    five: string;
    accusative?: string;
}

export interface ItemData {
    index?: number;
    id: number;
}

export class DataRequest {
    [key: string]: any;

    public id?: number | string = 0;
}

// tslint:disable-next-line:max-classes-per-file
export class PickerData {
    public id: string;
    public pickerLoading: boolean = false;
    public pickerData: any[] = [];
    public pickerMeta?: MetaItem;
    public pickerError: string = '';
    public pickerErrorMessages: { [k: string]: string } = {};
    public pickerLastPage: number = 0;

    constructor(id: string) {
        this.id = id;
    }
}
