
import { Sale } from '@/modules/sales/shared';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({})
export default class SalesList extends Vue {
    @Prop(Array) public items!: Sale[];
    @Prop(Number) public value!: number;
    get index() {
        return this.value;
    }
    set index(val: any) {
        this.$emit('input', val);
    }
    get statuses() {
        if (!this.items) { return []; }
        return this._.uniqBy(
            this.items.map((el) => el.status),
            'id',
        );
    }
    get sortedItems() {
        if (!this.statuses) { return {}; } else {
            const items = {} as any;
            for (const status of this.statuses) {
                items[status.id] = this.items.filter((el) => el.sale_status_id === status.id);
            }
            return items;
        }
    }
}
