import {httpClient} from '@/shared/services';
import {apiConstants} from '@/modules/investments/shared/config';
import {InvestmentSubjectEditOperationDataRequest} from '@/modules/investments/shared/requests/subjects/operations/investment-subject-edit-operation-data-request';
import {InvestmentSubjectMoveOperationDataRequest} from '@/modules/investments/shared/requests/subjects/operations/investment-subject-move-operation-data-request';
import {InvestmentSubjectOperationDataRequest} from '@/modules/investments/shared/requests/subjects/investment-subject-operation-data-request';
import {InvestmentSubjectCopyOperationDataRequest} from '@/modules/investments/shared/requests/subjects/operations/investment-subject-copy-operation-data-request';

export const moveSubjects = (data: InvestmentSubjectMoveOperationDataRequest, check?: boolean) => {
    const url = apiConstants.subjectOperations + '/move' + (check ? '/check' : '');
    return httpClient.post(url, data).then((response) => response.status);
};

export const copySubjects = (data: InvestmentSubjectCopyOperationDataRequest, check?: boolean) => {
    const url = apiConstants.subjectOperations + '/copy' + (check ? '/check' : '');
    return httpClient.post(url, data).then((response) => response);
};

export const editSubjects = (data: InvestmentSubjectEditOperationDataRequest, check?: boolean) => {
    const url = apiConstants.subjectOperations + '/edit' + (check ? '/check' : '');
    return httpClient.post(url, data).then((response) => response.status);
};

export const removeSubjects = (data: InvestmentSubjectOperationDataRequest, check?: boolean) => {
    const url = apiConstants.subjectOperations + '/remove' + (check ? '/check' : '');
    return httpClient.post(url, data).then((response) => response.status);
};
