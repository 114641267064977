import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { DataRequest } from '@/shared/types';
import { OrderItemResponse } from './module';

const ordersProps = new ModuleProps('orders', {
    one: 'transakcja',
    two: 'transakcje',
    five: 'transakcji',
});

ordersProps.readOnly = true;



export const ordersModule = new ModuleCreator<OrderItemResponse, DataRequest>(ordersProps);
