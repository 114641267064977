import { httpClient } from '@/shared/services';

export class TutorialServices {
    public static fetchTutorialsUrls() {
        return httpClient.get('/api/v1/tutorials/pages/check').then((res) => res.data.data);
    }
    public static async fetchModuleTutorialsForThisId(id: number) {
        return httpClient.get(`/api/v1/tutorials/pages/${id}`).then((res) => res.data.data.tutorials);
    }
    public static async fetchModuleTutorial(id: number) {
        return httpClient.get(`/api/v1/tutorials/${id}`).then((res) => res.data.data);
    }
}
