import {ActionTree, MutationTree} from 'vuex';
import {costsModule} from '@/modules/costs/shared/state/module';
import {ModuleState} from '@/shared/state/template/module-state';
import {RootState} from '@/shared/types';
import {AcceptanceDataRequest} from '@/modules/acceptances/shared/requests/acceptance-data-request';
import {Acceptance} from '@/modules/acceptances/shared/models/acceptance';


export const actionsTypes = costsModule.actionsTypes;

export const mutationTypes = costsModule.mutationsTypes;

export const costsActions: ActionTree<ModuleState<Acceptance, AcceptanceDataRequest>, RootState> = {
  /**/
};
