

import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class SystemBar extends Vue {
    @Prop(Boolean) private dragged!: boolean;
    @Prop(Boolean) private lock!: boolean;
    @Prop(Number) private x!: number;
    @Prop(Number) private y!: number;
    @Prop(Boolean) private hideclose!: boolean;
    private movementX = 0;
    private movementY = 0;
    private clientX = this.cardX;
    private clientY = this.cardY;

    private startDrag(e: MouseEvent) {
        e.preventDefault();
        if (!this.lock) {
            this.cardDragged = true;
            this.clientX = e.clientX;
            this.clientY = e.clientY;
            document.onmousemove = this.mouseMove;
            document.onmouseup = this.stopDrag;
        }
    }

    private stopDrag() {
        this.clientX = 0;
        this.clientY = 0;
        this.cardDragged = false;
        document.onmousemove = null;
        document.onmouseup = null;
    }

    private closed() {
        this.clientX = 0;
        this.clientX = 0;
        this.$emit('closed');
    }

    get cardDragged() {
        return this.dragged;
    }

    set cardDragged(value) {
        this.$emit('update:dragged', value);
    }

    get cardX() {
        return this.x;
    }

    set cardX(value) {
        this.$emit('update:x', value);
    }

    get cardY() {
        return this.y;
    }

    set cardY(value) {
        this.$emit('update:y', value);
    }

    private mounted() {
        window.onresize = () => {
            this.clientX = 0;
            this.clientY = 0;
        };
    }

    private mouseMove(e: MouseEvent) {
        e.preventDefault();
        this.movementX = this.clientX - e.clientX;
        this.movementY = this.clientY - e.clientY;
        this.clientX = e.clientX;
        this.clientY = e.clientY;
        this.cardX = this.cardX - this.movementX;
        this.cardY = this.cardY - this.movementY;
    }
}

