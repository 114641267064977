
import { Cost } from '@/modules/costs/shared/models/cost';
import { Component } from 'vue-property-decorator';
import SearchResult from '../search-result';
import SearchDetailsItem from '../../details/details-item.vue';
import CostSearchDetails from './cost.vue';
import SelectableTable from '@/shared/components/search/details/selectable-table.vue';
import { callSuper } from '@/shared/helpers';


@Component({
    components: {
        SearchDetailsItem,
        SelectableTable,
        CostSearchDetails ,
    },
})
export default class CostSearchResult extends SearchResult<Cost> {
    public itemKey = 'cost';
    public detailsList = ['positions'];
    public selectableRefNames = ['item-details', 'itemDetailsSelectables'];





     public getRealName(name: string) {
        if (name === 'positions') { return 'costPositions'; } else { return name; }
    }
}
