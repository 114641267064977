

import {Component, Vue} from 'vue-property-decorator';

@Component({
    props: {
        maxWidth: {
            type: [String, Number],
            default: '200px',
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    }
})
export default class NameReduce extends Vue {
    public maxWidth!: string;
    private value: string = '';

    public onTooltipEnter(on: any, e: Event) {
        const nameComponent = this.$refs.name as HTMLParagraphElement;
        if (nameComponent && nameComponent.clientWidth && nameComponent.clientWidth === parseInt(this.maxWidth, 10)) {
            this.value = nameComponent.innerText;
            on.mouseenter(e);
        }
    }
}

