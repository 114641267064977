
import {FormRules} from '@/shared/validation/form-rules';
import {Component, Ref, Vue} from 'vue-property-decorator';
import {mutationTypes} from '@/modules/auth/shared/state/confirm-password/mutations';
import {httpClient} from '@/shared/services';

@Component
export default class ConfirmPasswordDialog extends Vue {
    public valid = false;
    public password = '';
    public formRules = new FormRules();
    public loading = false;
    public serverError: null | string = null;
    @Ref() public form!: HTMLFormElement;

    public async submitForm() {
        const form: any = this.$refs.form;
        form.validate();
        if (!this.valid) {
            return;
        }
        this.loading = true;

        try {
            await httpClient.post('/api/v1/confirm-password', {password: this.password});
            this.password = '';
            this.dialog = false;
        } catch (e) {
            //
            this.serverError = 'Hasło niepoprawne';
        }

        this.loading = false;
    }

    public closeDialog() {
        this.form.reset();
        this.serverError = '';
        this.dialog = false;
    }

    get dialog() {
        return this.$store.state.authState.confirmPasswordState.dialog;
    }

    set dialog(value) {
        this.$store.commit(mutationTypes.SET_DIALOG_VISIBILITY, value);
    }
}
