import {userInvitesModule} from '@/modules/admin/shared/state/invites';
import {userInvitesMutations} from '@/modules/admin/shared/state/mutations/invites';
import {userInvitesActions} from '@/modules/admin/shared/state/actions/invites';

export { mutations } from './mutations';
export { mutationTypes } from './types';
export { actions } from './actions';
export { actionsTypes } from './types';
export type { ModuleState } from './state';

userInvitesModule.additionalActions = userInvitesActions;

userInvitesModule.additionalMutations = userInvitesMutations;

const userInvitesState = userInvitesModule.module;

export { userInvitesState };
