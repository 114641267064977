import Vue from 'vue';
import {reflectKeys} from '@/shared/services';
import {MutationTree} from 'vuex';
import {MediaFilesPayload} from '@/modules/media/shared/payloads/media-files-payload';
import {ShowMediaFilePayload} from '@/modules/media/shared/payloads/show-media-file-payload';
import {MediaState} from './state';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {indexData} from '@/shared/helpers';
import {FileProgress} from '@/modules/media/shared/models/file-progress';
import {MediaUploadErrorPayload} from '@/modules/media/shared/payloads/media-upload-error-payload';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import {MediaFileDataRequest} from '@/modules/media/shared/requests/media-file-data-request';
import {FetchParams} from '@/shared/types';

/** Prefix for mutation types and actiontypes */
const namespacedPrefix = '[MEDIA]';

/**
 * Mutation types
 */
export const mutationTypes = reflectKeys(
    [
        'MEDIA_DATA_SUCCESS',
        'MEDIA_DATA_REQUEST',
        'MEDIA_DATA_ERROR',
        'MEDIA_DATA_RESET',
        'MEDIA_ERROR_RESET',
        'MEDIA_GET_ERROR',
        'MEDIA_GET_REQUEST',
        'MEDIA_GET_SUCCESS',
        'MEDIA_REMOVE_REQUEST',
        'MEDIA_REMOVE_ERROR',
        'MEDIA_UPLOAD_PROGRESS',
        'MEDIA_REMOVE_PROGRESS',
        'MEDIA_UPLOAD_ERROR',
        'MEDIA_REMOVE_SUCCESS',
        'UPDATE_EDITED',
        'UPDATE_CURRENT',
        'CHANGE_PAGE',
        'UPDATE_PARAMS',
        'UPDATE_FORM_DIALOG',
        'UPDATE_SHOW_DIALOG',
        'UPDATE_REMOVE_DIALOG',
        'UPDATE_ERROR_TITLE',
        'UPDATE_LIST_ERROR_TITLE',
        'UPDATE_LIST_ERROR_MESSAGES',
        'UPDATE_ERROR_MESSAGES',
        'UPDATE_PARAMS',
        'UPDATE_RELATED_DIALOG',
    ],
    namespacedPrefix,
);

/**
 * Users data mutations
 */
export const mutations: MutationTree<MediaState> = {
    [mutationTypes.MEDIA_DATA_REQUEST](state: MediaState): void {
        Vue.set(state, 'loading', true);
        Vue.set(state, 'data', []);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
    },

    [mutationTypes.MEDIA_UPLOAD_PROGRESS](state: MediaState, payload: FileProgress): void {
        const id = state.filesQueue.map((e: FileProgress) => e.id).indexOf(payload.id);
        if (id !== -1) {
            state.filesQueue.splice(id, 1);
            state.filesQueue.push(payload);
        } else {
            state.filesQueue.push(payload);
        }
    },

    [mutationTypes.UPDATE_PARAMS](state: MediaState, newVal: FetchParams): void {
        state.fetchParams = newVal;
    },

    [mutationTypes.MEDIA_REMOVE_PROGRESS](state: MediaState): void {
        state.removeProgress++;
    },

    [mutationTypes.MEDIA_DATA_SUCCESS](state: MediaState, payload: MediaFilesPayload): void {
        const data = payload.meta ? indexData(payload.data, payload.meta) : payload.data;
        Vue.set(state, 'loading', false);
        Vue.set(state, payload.simple ? 'dataList' : 'data', data || []);
        Vue.set(state, 'meta', payload.meta);
    },

    [mutationTypes.MEDIA_DATA_ERROR](state: MediaState, payload: ErrorPayload): void {
        Vue.set(state, 'loading', false);
        Vue.set(state, 'data', []);
        Vue.set(state, 'error', payload.message);
        Vue.set(state, 'errorMessages', payload.errors);
    },

    [mutationTypes.MEDIA_GET_SUCCESS](state: MediaState, payload: ShowMediaFilePayload): void {
        Vue.set(state, 'loadingItem', false);
        Vue.set(state, 'current', payload ? payload.data : state.current);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
    },

    [mutationTypes.MEDIA_GET_REQUEST](state: MediaState): void {
        Vue.set(state, 'loadingItem', true);
        Vue.set(state, 'filesQueue', []);
        Vue.set(state, 'fileErrors', {});
        Vue.set(state, 'current', null);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
    },

    [mutationTypes.MEDIA_GET_ERROR](state: MediaState, payload: ErrorPayload): void {
        Vue.set(state, 'loadingItem', false);
        Vue.set(state, 'error', payload.message);
        Vue.set(state, 'errorMessages', payload.errors);
    },

    [mutationTypes.MEDIA_UPLOAD_ERROR](state: MediaState, payload: MediaUploadErrorPayload): void {
        const id = state.filesQueue.map((e: FileProgress) => e.id).indexOf(payload.id);

        state.filesQueue[id] = {id: payload.id, loading: false};
        state.fileErrors[payload.id] = payload.error;
    },

    [mutationTypes.MEDIA_REMOVE_REQUEST](state: MediaState): void {
        Vue.set(state, 'removing', true);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
    },

    [mutationTypes.MEDIA_REMOVE_ERROR](state: MediaState, payload: ErrorPayload): void {
        Vue.set(state, 'removing', false);
        Vue.set(state, 'error', payload.message);
        Vue.set(state, 'errorMessages', payload.errors);
    },

    [mutationTypes.MEDIA_REMOVE_SUCCESS](state: MediaState): void {
        Vue.set(state, 'removing', false);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
        Vue.set(state, 'removeProgress', 0);
    },

    [mutationTypes.UPDATE_ERROR_TITLE](state: MediaState, newVal: string): void {
        state.error = newVal;
    },

    [mutationTypes.UPDATE_ERROR_MESSAGES](state: MediaState, newVal: { [k: string]: string }): void {
        state.errorMessages = newVal;
    },

    [mutationTypes.UPDATE_LIST_ERROR_TITLE](state: MediaState, newVal: string): void {
        state.error = newVal;
    },

    [mutationTypes.UPDATE_LIST_ERROR_MESSAGES](state: MediaState, newVal: { [k: string]: string }): void {
        state.listErrorMessages = newVal;
    },

    [mutationTypes.CHANGE_PAGE](state: MediaState, newVal: number): void {
        state.page = newVal;
    },

    [mutationTypes.UPDATE_PARAMS](state: MediaState, newVal: FetchParams): void {
        state.fetchParams = newVal;
    },

    [mutationTypes.UPDATE_EDITED](state: MediaState, newVal?: MediaFile | MediaFileDataRequest): void {
        state.editedItem = newVal;
    },

    [mutationTypes.UPDATE_CURRENT](state: MediaState, newVal?: MediaFile | null): void {
        state.current = newVal;
    },

    [mutationTypes.UPDATE_FORM_DIALOG](state: MediaState, newVal: boolean): void {
        state.formDialog = newVal;
    },

    [mutationTypes.UPDATE_SHOW_DIALOG](state: MediaState, newVal: boolean): void {
        state.showDialog = newVal;
    },

    [mutationTypes.UPDATE_REMOVE_DIALOG](state: MediaState, newVal: boolean): void {
        state.removeDialog = newVal;
    },

    [mutationTypes.UPDATE_RELATED_DIALOG](state: MediaState, newVal: boolean): void {
        state.relatedDialog = newVal;
    },
};
