
/**
 *  Place where we should assign sub-modules VueXstate to main-module VueXstate
 */
import { employeesModule } from './module';
import { employmentTypesModule } from './settings/employment-types';
import { rolesModule } from './settings/roles';


/**
 * This module we will use to declare state. Check ../../index.ts and @/store.ts!
 */
const employmentTypesState = employmentTypesModule.module;
const rolesState = rolesModule.module;



const personnelState = employeesModule.module;

personnelState.modules = {
    employmentTypesState,
    rolesState,
};


export default personnelState;
