export class TicketPriorityDataRequest {
    public id: number = 0;
    public name: string = '';
    public description: string = '';
    public default: boolean = false;
    public color: string|object = '';
    public order: number = 0;
    // tslint:disable-next-line:variable-name
    public ticket_priority_icon_id: number = 0;
}
