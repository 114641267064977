import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { TicketStatus, TicketStatusDataRequest } from '@/modules/tickets/shared';

const statusesProps = new ModuleProps(
    'statuses',
    {
        one: 'status',
        two: 'statusy',
        five: 'statusów',
        accusative: 'status',
    },
    ['tickets'],
);

statusesProps.customStateName = 'statusesState';

const statusesModule = new ModuleCreator<TicketStatus, TicketStatusDataRequest>(statusesProps);

export { statusesModule };
