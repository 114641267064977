import { TutorialServices } from '@/modules/tutorial/shared/TutorialServices';
import { TutorialData, TutorialState, TutorialUrl } from './tutorialStateTypes';

// state

const state: TutorialState = {
    tutorialUrls: null,
    moduleTutorials: null,
    currentTutorial: null,
    showDocumentation: false,
    minimalized: false,
    isDocked: false,
    filterByCurrentModule: false,
    tutorials: [],
    activeTabs: [],
    activeTab: 0,
};

const mutations = {
    ['[TUTORIAL] SET_URLS'](context: TutorialState, payload: [TutorialUrl]) {
        context.tutorialUrls = payload;
    },
    ['[TUTORIAL] SET_MODULE_TUTORIALS'](context: TutorialState, payload: [TutorialData]) {
        context.moduleTutorials = payload;
        context.currentTutorial = null;
    },
    ['[TUTORIAL] SET_CURRENT_TUTORIAL'](context: TutorialState, payload: TutorialData) {
        context.currentTutorial = payload;
    },
};

const actions = {
    async fetchUrls(context: any) {
        await TutorialServices.fetchTutorialsUrls()
            .then((res) => {
                context.commit('[TUTORIAL] SET_URLS', res);
            })
            .catch((error) => {
                context.commit('[TUTORIAL] SET_URLS', null);
            });
    },
    async fetchModuleTutorials(context: any, id: number) {
        await TutorialServices.fetchModuleTutorialsForThisId(id)
            .then((res) => {
                context.commit('[TUTORIAL] SET_MODULE_TUTORIALS', res);
            })
            .catch((error) => {
                context.commit('[TUTORIAL] SET_MODULE_TUTORIALS', null);
            });
    },
    async fetchModuleTutorial(context: any, id: number) {
        await TutorialServices.fetchModuleTutorial(id)
            .then((res) => {
                context.commit('[TUTORIAL] SET_CURRENT_TUTORIAL', res);
            })
            .catch((error) => {
                context.commit('[TUTORIAL] SET_CURRENT_TUTORIAL', null);
            });
    },
};

export const tutorialState = {
    state,
    mutations,
    actions,
};
