import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { InvestmentTag } from '@/modules/investments/shared/models/investment-tag';
import { InvestmentTagDataRequest } from '@/modules/investments/shared/requests/investment-tag-data-request';
import {OperationType} from '@/shared/state/template/module-types';

const investmentTagsProps = new ModuleProps(
    'tags',
    {
        one: 'tag',
        two: 'tagi',
        five: 'tagów',
    },
    ['investments'],
);

investmentTagsProps.parentIdInURLOperations = [OperationType.Index, OperationType.Store];

export const investmentTagsModule = new ModuleCreator<InvestmentTag, InvestmentTagDataRequest>(investmentTagsProps);
