import { HeaderFieldType } from '@/shared/types';

export const usersListHeaders: HeaderFieldType[]  = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Imię i nazwisko', value: 'name' },
    { text: 'E-mail', value: 'email' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const userInvitesListHeaders: HeaderFieldType[]  = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'E-mail', value: 'email' },
    { text: 'Data wygaśnięcia', value: 'expires_at', align: 'right' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const rolesListHeaders: HeaderFieldType[] = [
    { text: 'Nazwa', value: 'name' },
    { text: 'Utworzono', value: 'created_at' },
    { text: 'Zmodyfikowano', value: 'updated_at' },
    { text: 'Akcje', value: 'actions',  align: 'right'  },
];
