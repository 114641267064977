import { httpClient } from '@/shared/services';
import { apiConstants } from '../config';
import {FetchParams} from '@/shared/types';
import {fetchData} from '@/shared/services/http-client/http-services';

const fetchUnits = (params: FetchParams) => {
    return fetchData(apiConstants.units, params);
};

const fetchTaxRates = (params: FetchParams) => {
    return fetchData(apiConstants.taxRates, params);
};

export { fetchUnits, fetchTaxRates };
