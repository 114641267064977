var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('search-details-item',{staticClass:"search__selectable",class:{ 'search__selectable-selected': _vm.isActive },attrs:{"title":_vm.config.title,"routeText":_vm.config.routeText || 'zobacz wszystko',"active":_vm.isActive,"loading":_vm.loading},on:{"selection":function($event){return _vm.redirect(_vm.parentRoute)}},nativeOn:{"toggle":function($event){return _vm.toggleItem.apply(null, arguments)},"click":function($event){return _vm.$emit('parentClick', [$event, _vm.toggleItem])},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.redirect(_vm.parentRoute)}}},[_c('div',[_c('v-item-group',{attrs:{"active-class":"primary--text"},model:{value:(_vm.index),callback:function ($$v) {_vm.index=$$v},expression:"index"}},[_c('v-simple-table',{staticClass:"py-4",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.config.headers),function(header){return _c('th',{key:header.value},[_vm._v(_vm._s(header.text))])}),0)]),_c('tbody',[_vm._l((_vm.items),function(item,index){return [_c('v-item',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [(index !== 4 || (index === 4 && _vm.items.length < 5))?_c(_vm.rowComponent,{key:_vm.name + index,ref:_vm.name,refInFor:true,tag:"component",staticClass:"search__selectable my-2",class:{ 'search__selectable-selected': active, 'primary--text': active },attrs:{"tabindex":"0","item":item,"itemsHeaders":_vm.config.headers},nativeOn:{"toggle":function($event){$event.stopPropagation();return toggle.apply(null, arguments)},"click":function($event){$event.stopPropagation();_vm.redirect(
                                            _vm.config.childrenRoute ? _vm.parseRoute(_vm.config.childrenRoute, item.id) : '',
                                        )},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();_vm.redirect(
                                            _vm.config.childrenRoute ? _vm.parseRoute(_vm.config.childrenRoute, item.id) : '',
                                        )}}}):_vm._e()]}}],null,true)})]}),(_vm.items.length > 4)?_c('v-item',{key:"show-more",scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('tr',[_c('td',{staticClass:"pa-0",attrs:{"colspan":_vm.config.headers.length}},[_c('v-card',{ref:"showMore",staticClass:"search__selectable py-2 px-4",class:{
                                            'search__selectable-selected': active,
                                            'primary--text': active,
                                        },attrs:{"flat":"","color":"transparent"},nativeOn:{"toggle":function($event){$event.stopPropagation();return toggle.apply(null, arguments)},"click":function($event){$event.stopPropagation();return _vm.redirect(_vm.parentRoute)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();return _vm.redirect(_vm.parentRoute)}}},[_vm._v(" ... "),_c('v-scroll-x-transition',{attrs:{"mode":"out-in"}},[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(active),expression:"active"}],staticClass:"ml-2",attrs:{"small":"","color":"primary","outlined":"","pill":""}},[_c('v-icon',{attrs:{"size":"16","left":""}},[_vm._v(" mdi-keyboard-return ")]),_vm._v(" Pokaż wszystko ")],1)],1)],1)],1)])]}}],null,false,777817844)}):(_vm.items.length === 0)?_c('no-results',{attrs:{"headers":_vm.config.headers}}):_vm._e()],2)]},proxy:true}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }