import {FetchParams} from '@/shared/types';
import {objectToQueryString} from '@/shared/helpers';
import {httpClient} from '@/shared/services';

const fetchData = (url: string, params: FetchParams) => {
    const paramsStr = objectToQueryString(params);

    url += params && params.simple ? '/list' : '';
    url += (params ? '?' + paramsStr.toString() : '');

    return httpClient.get(url).then((res) => res.data);
};

export { fetchData };
