
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Tutorial } from '../../shared/models/Tutorial';
import { TutorialServices } from '../../shared/TutorialServices';

@Component({})
export default class DocumentationPage extends Vue {
    @Prop(Number) public id!: number;
    public loading!: boolean;

    public fullscreenPreviewImage = '';

    get tutorial() {
        return this.$store.state.tutorialState.tutorials.find((el: Tutorial) => el.id === this.id);
    }

    get showImagePreview() {
        return this.fullscreenPreviewImage !== '';
    }

    set showImagePreview(val: boolean) {
        if (val) {
            return;
        }

        this.fullscreenPreviewImage = '';
    }

    public onContentClick(e: PointerEvent) {
        const { target } = e;

        if (target instanceof HTMLImageElement) {
            this.fullscreenPreviewImage = target.src;
        }
    }

    public created() {
        if (!this.tutorial) {
            this.fetchData();
        }
    }

    public fetchData() {
        this.loading = true;
        TutorialServices.fetchModuleTutorial(this.id)
            .then((res) => {
                this.$store.state.tutorialState.tutorials.push(res);
            })
            .finally(() => {
                this.loading = false;
            });
    }
}
