import { MutationTree } from 'vuex';
import { consentsModule } from '../module';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ConsentsState } from '../state';

export const mutationsTypes = consentsModule.mutationsTypes;

export const consentsMutations: MutationTree<any> = {
    [mutationsTypes.FETCH_VERSIONS_REQUEST](state: ConsentsState) {
        state.loadingVersions = true;
        state.versionsError = '';
        state.versionsErrorMessages = {};
    },
    [mutationsTypes.FETCH_VERSIONS_ERROR](state: ConsentsState, payload: ErrorPayload) {
        state.versionsError = payload.message;
        state.versionsErrorMessages = payload.errors;
        state.loadingVersions = false;
    },
    [mutationsTypes.FETCH_VERSIONS_SUCCESS](state: ConsentsState, payload: any) {
        state.loadingVersions = false;
        state.constentVersions = payload.res.data;
        state.versionsMeta = payload.res.meta;
    },
    [mutationsTypes.SHOW_DATA_TRANSFER_AGREEMENT_DIALOG](state: ConsentsState, payload: any) {
        state.dataTransferAgreementDialog = true;
    },
    [mutationsTypes.SHOW_REQUIRED_CONSENTS_DIALOG](state: ConsentsState) {
        state.requiredConsentsDialog = true;
    },
};
