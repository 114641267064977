import { logger, reflectKeys } from '@/shared/services';
import { ActionTree } from 'vuex';
import { FetchParams, RootState } from '@/shared/types';
import { mutationTypes } from '@/modules/media/shared/state/mutations';
import {
    downloadFile,
    fetchMediaFiles,
    getMediaFile,
    removeMediaFile,
    storeMediaFile,
    updateMediaFile,
} from '@/modules/media/shared/services';
import { MediaState } from './state';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ShowMediaFilePayload } from '@/modules/media/shared/payloads/show-media-file-payload';
import { MediaFilesPayload } from '@/modules/media/shared/payloads/media-files-payload';
import { MediaFileDataRequest } from '@/modules/media/shared/requests/media-file-data-request';
import { FileProgress } from '@/modules/media/shared/models/file-progress';
import { numeralVariations } from '@/shared/helpers';
import { fileNumerals } from '@/shared/config/media';
import { ModuleShowPayload } from '@/shared/state/template/module-payloads';
import { MediaFile } from '@/modules/media/shared/models/media-file';
import { ErrorHandler } from '@/shared/state/template/helpers';
import { Axios, AxiosResponse } from 'axios';

/** Prefix for mutation types and actiontypes */
const namespacedPrefix = '[MEDIA]';

/** Actions types constants */
export const actionsTypes = reflectKeys([
    'FETCH_MEDIA_DATA',
    'SHOW_MEDIA',
    'UPLOAD_MEDIA',
    'UPDATE_MEDIA',
    'REMOVE_MEDIA',
    'REMOVE_MEDIA_FILES',
    'DOWNLOAD_FILE',
], namespacedPrefix);

/**
 * Users data actions
 */
export const actions: ActionTree<MediaState, RootState> = {
    async [actionsTypes.FETCH_MEDIA_DATA]({ commit }, params: FetchParams) {
        commit(mutationTypes.MEDIA_DATA_REQUEST);

        const result: MediaFilesPayload = await fetchMediaFiles(params)
            .catch((response: ErrorPayload) => ErrorHandler(response, mutationTypes.MEDIA_DATA_ERROR));

        if (result) {
            result.simple = params.simple;
            commit(mutationTypes.MEDIA_DATA_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.SHOW_MEDIA]({ commit }, id: number) {
        commit(mutationTypes.MEDIA_GET_REQUEST);

        const result: ShowMediaFilePayload = await getMediaFile(id)
            .catch((response: ErrorPayload) => ErrorHandler(response, mutationTypes.MEDIA_GET_ERROR));

        if (result) {
            commit(mutationTypes.MEDIA_GET_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.UPDATE_MEDIA]({ commit }, data: MediaFileDataRequest) {
        commit(mutationTypes.MEDIA_GET_REQUEST);

        const result: ShowMediaFilePayload = await updateMediaFile(data.id, data)
            .catch((response: ErrorPayload) => ErrorHandler(response, mutationTypes.MEDIA_UPDATE_ERROR));

        if (result) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Zaktualizowano plik' });
            commit(mutationTypes.MEDIA_GET_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.UPLOAD_MEDIA]({ commit }, files: MediaFileDataRequest[]) {
        commit(mutationTypes.MEDIA_GET_REQUEST);

        const uploadQueue: Array<Promise<ModuleShowPayload<MediaFile> | undefined>> = [];

        for (const data of files) {
            uploadQueue.push(
                new Promise((resolve) => {
                    commit(mutationTypes.MEDIA_UPLOAD_PROGRESS, new FileProgress(data.id));
                    resolve(storeMediaFile(data).catch((e: any) => {
                        commit(mutationTypes.MEDIA_UPLOAD_ERROR, { id: data.id, error: e });
                    }));
                }),
            );
        }
        const result = await Promise.all(uploadQueue);

        if (!result.includes(undefined)) {
            commit('SHOW_SNACKBAR', {
                type: 'success',
                text: `Wysłano ${numeralVariations(uploadQueue.length, fileNumerals)}`,
            });
            commit(mutationTypes.MEDIA_GET_SUCCESS);
        } else {
            commit(mutationTypes.MEDIA_GET_ERROR, result);
        }

        return result.map((e) => e ? e.data : null);
    },

    async [actionsTypes.DOWNLOAD_FILE]({ commit }, url: string) {
        commit(mutationTypes.MEDIA_GET_REQUEST);

        const result: void | Blob = await downloadFile(url).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.MEDIA_GET_ERROR, {
                type: 'error',
                text: 'Błąd podczas pobierania pliku',
                permanent: true,
                errors: e.errors,
            })
        );

        if (result) {
            commit(mutationTypes.MEDIA_GET_SUCCESS);
        }

        return result;
    },

    async [actionsTypes.REMOVE_MEDIA]({ commit }, id: number) {
        commit(mutationTypes.MEDIA_REMOVE_REQUEST);

        const result: AxiosResponse | void  = await removeMediaFile(id).catch((e: ErrorPayload) => {

            if (e.data.data) {
                throw e;
            }

            ErrorHandler(e, mutationTypes.MEDIA_REMOVE_ERROR, {
                type: 'error',
                text: 'Błąd podczas usuwania pliku',
                permanent: true,
                errors: e.errors,
            });

        }
        );

        if (result && result.status < 300) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Usunięto plik' });
            commit(mutationTypes.MEDIA_REMOVE_SUCCESS, result);
        }

        return result;
    },

    async[actionsTypes.REMOVE_MEDIA_FILES]({ commit }, ids: number[]) {
        commit(mutationTypes.MEDIA_REMOVE_REQUEST);

        const results: any[] = [];

        const protectors = [];

        for (const id of ids) {
            results.push(
                new Promise((resolve) => {
                    resolve(
                        removeMediaFile(id).catch((e: ErrorPayload) => {
                            if (e.data && e.data.data && e.data.data.length > 0) {
                                protectors.push(...e.data.data);
                                return e;
                            }
                            logger.error(e);
                        }),
                    );
                }).then((e) => {
                    commit(mutationTypes.MEDIA_REMOVE_PROGRESS);
                    return e;
                })
            );
        }

        const result = await Promise.all(results);

        if (!result.includes(undefined) && !result.some((el) => el.status === 403)) {
            commit('SHOW_SNACKBAR',
                { type: 'success', text: `Usunięto ${numeralVariations(ids.length, fileNumerals)}` });
            commit(mutationTypes.MEDIA_REMOVE_SUCCESS, results);
        }

        return result;
    },
};
