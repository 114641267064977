
import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { DocumentCategory } from '../models/document-category';
import { DocumentTag } from '../models/document-tag';
import { DocumentTemplate } from '../models/document-template';
import { DocumentCategoryDataRequest } from '../requests/document-category-data-request';
import { DocumentTagDataRequest } from '../requests/document-tag-data-request';
import { DocumentTemplateDataRequest } from '../requests/document-template-data-request';
import { documentTemplatesMutations } from './mutations/documentTemplates';
import { DocumentTemplatesState } from './state/documentTemplates';

const documentsCategoriesProps = new ModuleProps('categories', {
    one: 'kategoria',
    two: 'kategorie',
    five: 'kategorii',
    accusative: 'kategorię',
},
    ['documents']);
documentsCategoriesProps.customStateName = 'categoriesState';

export const documentCategoriesModule =
    new ModuleCreator<DocumentCategory, DocumentCategoryDataRequest>(documentsCategoriesProps);

const documentsTagsProps = new ModuleProps('tags', {
    one: 'tag',
    two: 'tagi',
    five: 'tagów',
    accusative: 'tag',
},
    ['documents']);
documentsTagsProps.customStateName = 'tagsState';

export const documentTagsModule =
    new ModuleCreator<DocumentTag, DocumentTagDataRequest>(documentsTagsProps);

const documentTemplatesProps = new ModuleProps('templates', {
    one: 'szablon',
    two: 'szablony',
    five: 'szablonów',
    accusative: 'szablon',
},
    ['documents']);
documentTemplatesProps.customStateName = 'templatesState';
documentTemplatesProps.fullscreenForm = true;
documentTemplatesProps.hasDialog = false;
documentTemplatesProps.customDetailsRoute = 'documents-templates-details';


const documentTemplatesModule =
    new ModuleCreator<DocumentTemplate, DocumentTemplateDataRequest>(documentTemplatesProps);
documentTemplatesModule.additionalMutationTypes = [
        'START_EDIT_DOCUMENT_TEMPLATE'
    ];

export { documentTemplatesModule };
