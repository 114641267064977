
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class LoadingScreen extends Vue {
    get isLight() {
        return !this.$vuetify.theme.dark;
    }

    get user() {
        return this.$store.state.authState.user.color_theme;
    }

    get loadingTitle() {
        return this.$store.state.loadingTitle;
    }

    get loadingSubtitle() {
        return this.$store.state.loadingSubtitle;
    }
}
