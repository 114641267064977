interface RGB {
    blue: number;
    green: number;
    red: number;
}

export const hexToRgb = (hex: string): RGB => {
    if (!hex || hex === '') {
        return {} as RGB;
    }

    const result: RegExpExecArray | null =
        /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
    } : {} as RGB;
};

const luminance = (r: number, g: number, b: number) => {
    const a = [r, g, b].map((v) => {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const contrast = (rgb1: RGB, rgb2: RGB) => {
    const lum1 = luminance(rgb1.red, rgb1.green, rgb1.blue);
    const lum2 = luminance(rgb2.red, rgb2.green, rgb2.blue);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05)
         / (darkest + 0.05);
};
