import { httpClient } from '@/shared/services';

import { apiConstants } from '../../config';
import { LoginRequest } from '@/modules/auth/shared/requests/login-request';

const checkUser = () => {
    const url = apiConstants.checkToken;

    return httpClient.get(url).then((res) => res.data);
};

const checkUserExtend = () => {
    const url = apiConstants.checkTokenExtend;

    return httpClient.get(url).then((res) => res.data);
};

const loginUser = (data: LoginRequest) => {
  const url = apiConstants.login;

  return httpClient.post(url, data).then((res) => res.data);
};

const logoutUser = () => {
    const url = apiConstants.logout;

    return httpClient.get(url).then((res) => res.data);
};

export { checkUser, checkUserExtend, loginUser, logoutUser };
