

/**
 *  Dependencies list:
 *  - shared
 *  - module types
 */
import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';

import { EmploymentType } from '../../models/employment-type';
import { EmploymentTypeDataRequest } from '../../requests/employment-type-data-request';

const employmentTypesProps = new ModuleProps('employment-types', {
  one: 'forma zatrudnienia',
  two: 'formy zatrudnienia',
  five: 'form zatrudnienia',
}, ['personnel']);
employmentTypesProps.hasDialog = false;

employmentTypesProps.customStateName = 'employmentTypesState';
const employmentTypesModule = new ModuleCreator<EmploymentType, EmploymentTypeDataRequest>(employmentTypesProps);

export { employmentTypesModule };
