
    import Vue from 'vue';
    import {Component} from 'vue-property-decorator';
    import {Snackbar} from '@/shared/types';

    @Component
    export default class SnackbarsStack extends Vue {
        public notificationSound = new Audio('/notification.ogg');
        get snackbars() {
            return this.$store.state.snackbars;
        }

        // @Socket('snackbar')
        // public onSnackbarReceived(data: Snackbar) {
        //     this.notificationSound.play();
        //     this.$store.commit('SHOW_SNACKBAR', {...data, websocket: true});
        // }
    }
