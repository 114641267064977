import {ActionTree} from 'vuex';
import {actionsTypes, ModuleState, mutationTypes} from '@/modules/settings/shared/state';
import {FetchParams, RootState} from '@/shared/types';
import {SearchResultsPayload} from '@/shared/payloads/search-results-payload';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {fetchSearchResults} from '@/modules/settings/shared/services';

export const searchActions: ActionTree<ModuleState, RootState> = {
    async [actionsTypes.SEARCH_ITEMS]({commit}, params: FetchParams) {
        commit(mutationTypes.SEARCH_REQUEST);

        const result: SearchResultsPayload = await fetchSearchResults(params).catch((response: ErrorPayload) => {
            commit(mutationTypes.SEARCH_ERROR, response);
            commit(mutationTypes.SEARCH_RESET, response);
        });

        if (result) {
            commit(mutationTypes.SEARCH_SUCCESS, result);
        }

        return result;
    },
};
