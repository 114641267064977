import axios, {AxiosRequestConfig} from 'axios';

import {apiBaseUrl, apiVersion, environment} from '@/environment/environment';
import {chceckforBuffer} from '@/shared/services';
import {mutationTypes} from '@/modules/auth/shared/state/confirm-password/mutations';
import {Store} from 'vuex';
import {RootState} from '@/shared/types';
import {dontRequiresAuthRoutes} from '@/shared/config/http-client';
import Router from 'vue-router';


const controller: AbortController = new AbortController();
const hostname = localStorage.getItem('hostname');
const config = {
    baseURL: hostname ? (`https://${hostname}.d360.pl`) : apiBaseUrl,
    signal: controller.signal,
};

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need to consume APIs from more than single server,
 * So, may need to create multiple http client with different config
 * Only this client will be used rather than axios in the application
 */
const httpClient = axios.create(config.baseURL ? config : {});

const defaultInterceptor = (defaultConfig: AxiosRequestConfig) => {
    if (!defaultConfig || !defaultConfig.headers) {
        return;
    }
    defaultConfig.headers.Accept = 'application/json';
    defaultConfig.headers['Accept-Language'] = 'pl-PL';

    return defaultConfig;
};

/**
 * Auth interceptors
 * @description Configuration related to AUTH token can be done in interceptors.
 * Currenlty it is just doing nothing but idea to to show the capability of axios and its interceptors
 * In future, interceptors can be created into separate files and consumed into multiple http clients
 * @param {*} authConfig
 */
const authInterceptor = (authConfig: AxiosRequestConfig) => {
    /** add auth token */
    const token = localStorage.getItem('token');

    if (
        authConfig &&
        authConfig.headers &&
        authConfig.url
    ) {
        const url = authConfig.url;

        if (!dontRequiresAuthRoutes.includes(url)) {
            if (
                token && token !== ''
            ) {
                authConfig.headers.Authorization = 'Bearer ' + token;
            } else {
                controller.abort();
            }
        }
    }

    return authConfig;
};

// To add / at the beginning of url
const putDashAtTheBeginning = (axiosRequestConfig: AxiosRequestConfig) => {
    const {url} = axiosRequestConfig;
    if (url) {
        axiosRequestConfig.url = url[0] === '/' ? url : '/' + url;
    }
    return axiosRequestConfig;
};

const loggerInterceptor = (loggerConfig: object) => {
    /** Add logging here */
    return loggerConfig;
};

function getErrorMessage(error: any) {
    const data = chceckforBuffer(error);

    if (error.message === 'canceled') {
        return 'Anulowano';
    }

    if (error.response.data && error.response.data.message === 'Unauthenticated.') {
        return 'Nieautoryzowany dostęp';
    }

    if (data && String(data.message) !== '' && data.message !== 'The given data was invalid.') {
        return data.message;
    }

    if (error.response.statusText === 'Unprocessable Entity') {
        return 'Nieprawidłowe dane';
    }

    if (data && String(error.response.statusText) !== '') {
        return error.response.statusText;
    }

    return 'Błąd wykonywania operacji';
}

function getErrorsList(error: any) {
    const data = chceckforBuffer(error);

    if (data && data.errors !== undefined) {
        return data.errors;
    }

    return {};
}

/** Adding the request interceptors */

export const interceptorsSetup = (store: Store<RootState>, router: Router) => {
    httpClient.interceptors.request.use(defaultInterceptor);
    httpClient.interceptors.request.use(authInterceptor);
    httpClient.interceptors.request.use(loggerInterceptor);
    httpClient.interceptors.request.use(putDashAtTheBeginning);
    /** Adding the response interceptors */
    httpClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response && Number(error.response.status) === 402) {
                router.push({name: 'payment-required'});
            }

            if (error.response && Number(error.response.status) === 423) {
                store.commit(mutationTypes.SET_DIALOG_VISIBILITY, true);
            }

            if (error.response && Number(error.response.status) === 401) {
                localStorage.removeItem('token');
                sessionStorage.removeItem('token');
                controller.abort();

                if (!window.location.href.endsWith('/auth/login')) {
                    window.location.replace('/auth/login');
                }
            }

            /** Do something with response error */
            return Promise.reject({
                status: error.response && error.response.status ? Number(error.response.status) : 503,
                message: getErrorMessage(error),
                errors: getErrorsList(error),
                data: error.response ? error.response.data : null,
            });
        },
    );
};


export {httpClient};
