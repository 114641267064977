import { ticketsModule, statusesModule, prioritiesModule, tagsModule, categoriesModule} from '@/modules/tickets/shared';
import { ticketsActions } from './actions/ticket';
import { ticketsMutations } from './mutations/ticket';
import { TicketsState } from './state';

const statusesState = statusesModule.module;
const prioritiesState = prioritiesModule.module;
const tagsState = tagsModule.module;
const categoriesState = categoriesModule.module;

ticketsModule.state = new TicketsState();
ticketsModule.additionalActions = ticketsActions;
ticketsModule.additionalMutations = ticketsMutations;


ticketsModule.modules = { statusesState, prioritiesState, tagsState, categoriesState };

const module = ticketsModule.module;

export default module;
