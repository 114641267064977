import {ActionTree} from 'vuex';
import {RootState} from '@/shared/types';
import {ModuleState} from '@/shared/state/template/module-state';
import {Rent} from '@/modules/rents/shared/models/rent';
import {rentsModule} from '@/modules/rents/shared/state/state';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {
    editRents,
    removeRents,
} from '@/modules/rents/shared/services/rents';
import {
    RentEditDataRequest,
} from '@/modules/rents/shared/requests/rent-edit-data-request';
import {RentDataRequest} from '@/modules/rents/shared/requests/rent-data-request';
import router from '@/router';
import {mutationTypes as authTypes} from '@/modules/auth/shared/state';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import {AxiosResponse} from 'axios';

export const actionsTypes = rentsModule.actionsTypes;

export const mutationTypes = rentsModule.mutationsTypes;

export const rentsActions: ActionTree<ModuleState<Rent, RentDataRequest>,
    RootState> = {
    async [actionsTypes.EDIT]({commit}, data: RentEditDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: number | void = await editRents(data)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 402:
                            router.push({name: 'payment-required'}).then(() => commit(authTypes.PAYMENT_AUTH));
                            break;
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            });

        if (result && result === 204) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.REMOVE]({commit}, data: RentDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: number | void = await removeRents(data)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 402:
                            router.push({name: 'payment-required'}).then(() => commit(authTypes.PAYMENT_AUTH));
                            break;
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            });


        if (result && result === 204) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.EDIT_CHECK]({commit}, data: RentEditDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: number | void = await editRents(data, true)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 402:
                            router.push({name: 'payment-required'}).then(() => commit(authTypes.PAYMENT_AUTH));
                            break;
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            });

        if (result && result === 200) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.REMOVE_CHECK]({commit}, data: RentDataRequest) {
        commit(mutationTypes.OPERATION_REQUEST);

        const result: number | void = await removeRents(data, true)
            .catch((response: ErrorPayload) => {
                commit(mutationTypes.OPERATION_REMOVE_CHECK_ERROR, response);
                if (response.message === 'Nieautoryzowany dostęp') {
                    switch (response.status) {
                        case 402:
                            router.push({name: 'payment-required'}).then(() => commit(authTypes.PAYMENT_AUTH));
                            break;
                        case 401:
                            router.push({name: 'login'}).then(() =>
                                commit(authTypes.CHECK_USER_ERROR, response),
                            );
                            break;
                    }
                }
            });


        if (result && result === 200) {
            commit(mutationTypes.OPERATION_SUCCESS, result);
        }

        return result;
    },

};
