import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { Renter } from '../models/renter';
import { RenterDataRequest } from '../requests/renter-data-request';
import { RentersState } from './state';

const rentersProps = new ModuleProps('renters', {
    one: 'najemca',
    two: 'najemcy',
    five: 'najemców',
    accusative: 'najemcy',
});

rentersProps.hasDialog = false;

const rentersModule = new ModuleCreator<
    Renter,
    RenterDataRequest,
    RentersState
>(rentersProps);

rentersModule.state = new RentersState();

rentersModule.additionalMutationTypes = [
    'RENTERS_SEARCH_SUCCESS',
    'RENTERS_SEARCH_REQUEST',
    'RENTERS_SEARCH_ERROR',
    'RENTERS_SEARCH_RESET',
];

rentersModule.additionalActionTypes = [
    'SEARCH_RENTERS',
    'SET_GUARD_FOR_RENTERS',
];

export {rentersModule};
