export const errorsToArray = (errors: any) => {
    const list: string[] = [];

    for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
            if (errors[key] instanceof Array) {
                // tslint:disable-next-line:forin
                for (const i in errors[key]) {
                    list.push(errors[key][i]);
                }
            }
        }
    }

    return list;
};

export const chceckforBuffer = (error: any) => {
    if (error.response) {
        if (error.response.data instanceof ArrayBuffer) {
            // @ts-ignore
            const decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
            return JSON.parse(decodedString);
        }
        return error.response.data;
    }
    return null;
};
