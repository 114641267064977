const routes = [
    {
        path: '/auth',
        name: 'auth',
        component: () => import(/* webpackChunkName: "authRoutes" */ '@/modules/auth/login-page.vue'),
        children: [
            {
                path: 'activate',
                name: 'activate-user',
                // tslint:disable-next-line:max-line-length
                component: () => import(/* webpackChunkName: "authRoutes" */ '@/modules/auth/components/activate.vue'),
                meta: {
                    dontRequiresAuth: true,
                },
                props: (route: any) => {
                    return {
                        email: route.query.email,
                        token: route.query.token,
                    };
                },
            },
            {
                path: 'login',
                name: 'login',
                component: () => import(/* webpackChunkName: "authRoutes" */ '@/modules/auth/components/login.vue'),
                meta: {
                    dontRequiresAuth: true,
                },
                props: (route: any) => {
                    return {
                        callback: route.query.callback,
                    };
                },
            },
            {
                path: 'reset-password',
                name: 'reset-password',
                // tslint:disable-next-line:max-line-length
                component: () => import(/* webpackChunkName: "authRoutes" */ '@/modules/auth/components/reset-password.vue'),
                meta: {
                    dontRequiresAuth: true,
                },
                props: (route: any) => {
                    return {
                        callback: route.query.callback,
                    };
                },
            },
            {
                path: 'new-password',
                name: 'new-password',
                // tslint:disable-next-line:max-line-length
                component: () => import(/* webpackChunkName: "authRoutes" */ '@/modules/auth/components/new-password.vue'),
                meta: {
                    dontRequiresAuth: true,
                },
                props: (route: any) => {
                    return {
                        email: route.query.email,
                        token: route.query.token,
                    };
                },
            },
            {
                path: 'hostname',
                name: 'hostname',
                // tslint:disable-next-line:max-line-length
                component: () => import(/* webpackChunkName: "authRoutes" */ '@/modules/auth/components/hostname.vue'),
                meta: {
                    dontRequiresAuth: true,
                },
                props: (route: any) => {
                    return {
                        email: route.query.email,
                        token: route.query.token,
                    };
                },
            },
        ]
    },
];

export default routes;
