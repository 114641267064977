
import {httpClient} from '@/shared/services';
import {FormRules} from '@/shared/validation/form-rules';
import {Component, VModel, Vue, Prop} from 'vue-property-decorator';
import {AxiosResponse} from 'axios';
import {ListPayload} from '@/shared/state/template/module-payloads';
import {SelectListItem} from '@/shared/models/select-list-item';

@Component
export default class IssueDialog extends Vue {
    @VModel({type: Object}) public dataRequest!: any;
    @Prop(Boolean) public dialog: boolean = false;
    public formRules = new FormRules();
    public form: any = null;
    public valid: boolean = false;
    public categories: SelectListItem[] = [];
    public loadingCategories = false;
    public loadingSend = false;
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' );

    public mounted() {
        this.form = this.$refs.issueForm;
    }

    public created() {
        this.fetchCategories();
    }

    public async fetchCategories() {
        this.loadingCategories = true;
        httpClient
            .get('/api/v1/admin/issues/categories/list')
            .then((res: AxiosResponse<ListPayload>) => res.data)
            .then((response: ListPayload) => {
                this.loadingCategories = false;

                if (response.data) {
                    this.categories = response.data;
                }
            });
    }

    public closeDialog() {
        this.form.reset();
        this.$emit('close');
    }

    public async sendIssue() {
        this.loadingSend = true;
        this.form.validate();
        if (this.valid) {
            try {
                await httpClient.post('/api/v1/admin/issues', {...this.dataRequest});
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Zgłoszenie wysłane',
                });
                this.$emit('close');
            } catch (error) {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Błąd podczas wysyłania zgłoszenia',
                });
            }
        }
        this.loadingSend = false;
    }
}
