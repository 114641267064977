import {numeralVariations} from '@/shared/helpers';
import {ModuleConjugation} from '@/shared/types';
import {OperationType} from '@/shared/state/template/module-types';


/**
 * Part of {@link ModuleCreator}
 * Class used for defining properties of Module
 */
export class ModuleProps {

    private static morphToSingular(name: string) {
        return !name.endsWith('ies') ? name.slice(0, -1) : name.replace('ies', 'y');
    }

    /** Variable for adding parent id property in setDefaultData() function */
    public addParentProperty: boolean = false;

    /** Variable for setting if module has Details dialog */
    public hasDialog: boolean = true;

    /** Variable for setting if module has Form dialog */
    public hasFormDialog: boolean = true;

    /** Variable for setting if module has protected increment */
    public hasProtectedIncrement: boolean = false;

    /** Variable for defining operations which has parent id in URL, remember to use {@link OperationType} */
    public parentIdInURLOperations: OperationType[] = [OperationType.Index];

    /** Variable for defining operations which has snackbar hidden, remember to use {@link OperationType} */
    public hideSnackbarOperations: OperationType[] = [];

    /** Variable for defining operations which don't require authetication, remember to use {@link OperationType} */
    public skipAuthOperations: OperationType[] = [];

    /** Variable for setting if module's Details dialog loads on show */
    public loadDialog: boolean = false;

    /** Variable for setting fullscreen details */
    public fullscreenForm: boolean = false;

    /** Variable for setting fullscreen details */
    public fullscreenDetails: boolean = false;

    /** Variable for setting custom details route */
    public customDetailsRoute: string = '';

    /** Variable for setting custom state name */
    public customStateName?: string;

    /** for defining submodule's parent name */
    public customParentStateName?: string;

    /** Variable for defining if list has to load on show */
    public loadListOnCreate: boolean = true;

    /** Variable for setting if item should be replaced after update action */
    public replaceItem: boolean = false;

    /** Variable for defining operations which are linked to first parent, remember to use {@link OperationType} */
    public rootParentOperations: OperationType[] = [];

    /** Variable for defining if module only has GET requests */
    public readOnly: boolean = false;

    /** Variable for defining mock URL name if real name is not ready on API */
    public mockURLName!: string;

    /** Variable for defining mock URL parents if real name is not ready on API */
    public mockParents?: string[];

    // ** adds multiple permissionbaseNames*/
    public additionalPermissionBaseNames: string[] = [];

    // ** This module specific permission base name, set to null when module does not have its own permission name  */
    public permissionBaseName?: string | null;

    /**
     * Create props for new Module
     * @param {string} name - plural name of module in URL
     * @param {ModuleConjugation} names - polish variants of module name
     * @param {string[]} parents - array of parent modules
     */
    constructor(public name: string, public names: ModuleConjugation, public parents?: string[]) {
    }


    /**
     * Method for generating url for services
     * @param operation - name of operation
     * @param id - id of parent
     */
    public url(operation: OperationType, id?: number) {
        let url = '';
        if (this.parents) {
            url += '/';
            if (!operation || (this.parents.length > 1 && !this.rootParentOperations.includes(operation))) {
                url += this.mockParents ? this.mockParents.join('/') : this.parents.join('/');
            } else {
                url += this.mockParents ? this.mockParents[0] : this.parents[0];
            }
        }

        if (id && this.parentIdInURLOperations.includes(operation) && !this.rootParentOperations.includes(operation)) {
            url += ('/' + id);
        }

        url += '/' + (this.mockURLName ? this.mockURLName : this.name);

        return url;
    }

    /**
     * Get parent id property name
     */
    get parentId() {
        if (!this.parents) {
            return '';
        }

        const parents = this.parents.map((item) => item.slice(0, -1).replaceAll('-', '_'));

        return parents.join('_') + '_id';
    }

    /**
     * check if module has parent
     */
    get hasParent() {
        return !!(this.parents && this.parents.length > 0);
    }

    /**
     * get name of state based on structure of module or return customStateName
     */
    get stateName() {
        let name = '';

        if (this.customStateName) {
            return this.customStateName;
        }

        if (this.parents && this.rootParentOperations.length < 1) {
            if (this.parents.length > 2) {
                name += ModuleProps.morphToSingular(this.parents[1]);
                name += this.parents.slice(2).map(
                    (item) => ModuleProps.morphToSingular(item.charAt(0).toUpperCase() + item.slice(1)),
                ).join('');
            } else {
                name += ModuleProps.morphToSingular(this.parents[this.parents.length - 1]);
            }
        }

        if (this.parents && this.rootParentOperations.length < 1) {
            name += this.name.charAt(0).toUpperCase() + this.name.slice(1);
        } else {
            name += this.name;
        }

        return (name + 'State');
    }


    /**
     * get root parent name or return customParentStateName
     */
    get parentStateName(): string {
        return this.customParentStateName || ((this.parents ? this.parents[0] : '') + 'State');
    }

    /**
     * get prefix for generating
     */
    get prefix() {
        return (this.parents ?
            (this.rootParentOperations.length < 1 ?
                this.parents.map((item) => item.slice(0, -1)).join('_') :
                this.parents[0]) + '_' : '') + this.name;
    }

    /**
     *
     * @param {number} count - number of things
     * @param {number} show - return numeral with number
     */
    public nameNumeral(count: number, show: boolean = true) {
        return numeralVariations(count, this.names, show);
    }


    /**
     * get singular name of module
     */

    get singleName(): string {
        return ModuleProps.morphToSingular(this.name);
    }

    /**
     * get singular name of module parent
     */

    get parentSingleName(): string {
        return this.parents ? ModuleProps.morphToSingular(this.parents[0]) : '';
    }


    /**
     * get base for permissons within module
     */
    get permissionBaseNames() {
            return [...this.getPermissionBaseName(), ...this.additionalPermissionBaseNames];
    }

    private getPermissionBaseName() {
        if (this.permissionBaseName) {
            return [this.permissionBaseName];
        } else if (this.permissionBaseName === null) {
            return [];
        }
        return [[...this.parents ? this.parents : [], this.name].join('.')];
    }
}
