import { MutationTree } from 'vuex';
import { ModuleState } from '@/modules/admin/shared/state/state';
import { usersMutations } from '@/modules/admin/shared/state/mutations/users';
import { rolesMutations } from '@/modules/admin/shared/state/mutations/roles';

/**
 * Data admin
 */
export const mutations: MutationTree<ModuleState> = {
    ...usersMutations,
    ...rolesMutations,
};
