import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { Note } from '../../models/notes';
import { NoteDataRequest } from '../../requests/note-data-dequest';

const notesProps = new ModuleProps(
    'notes',
    {
        one: 'notatka',
        two: 'notatki',
        five: 'notatek',
    },
    ['renters'],
);

notesProps.customStateName = 'notesTypesModuleState';

notesProps.loadListOnCreate = false;

export const notesTypesModule = new ModuleCreator<Note, NoteDataRequest>(
    notesProps,
);
