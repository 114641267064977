import Vue from 'vue';
import {MutationTree} from 'vuex';
import {LoadingScreenPayload, RootState, Snackbar, TestModeState} from '@/shared/types';
import {errorsToArray} from '@/shared/services';

/**
 * Data mutations
 */
export const mutations: MutationTree<RootState> = {
    SHOW_SNACKBAR(state: RootState, snackbar: Snackbar): void {
        snackbar.show = true;
        setTimeout(() => {
            if (!snackbar.permanent && !snackbar.href) {
                snackbar.show = false;
            }
        }, snackbar.duration ? snackbar.duration : 6000);
        snackbar.errors = snackbar.errors ? errorsToArray(snackbar.errors) : {};
        Vue.set(state, 'snackbars', [...state.snackbars, snackbar]);
    },

    LOADING_SCREEN(state: RootState, payload: LoadingScreenPayload): void {
        state.loadingScreen = payload.loadingScreen;
        state.loadingTitle = payload.loadingTitle ? payload.loadingTitle : null;
        state.loadingSubtitle = payload.loadingSubtitle ? payload.loadingSubtitle : null;
    },

    VUE_ERROR(state: RootState, err: Error) {
        state.err = err;
    },

    TEST_MODE(state: RootState, value: TestModeState) {
        state.testMode = value;
    },

    SWITCHING_TEST_MODE(state: RootState, value: boolean) {
        state.switchingTestMode = value;
    },

    UPDATE_NAVIGATION_BAR(state: RootState, value: boolean) {
        state.hideNavigationBar = value;
    },

    UPDATE_NAVIGATION_BAR_VERSION(state: RootState, value: boolean) {
        state.navigationBarVariant = value;
    },

    HIDE_SNACKBAR(state: RootState): void {
        Vue.set(state, 'snackbars', true);
    },
};
