import Vue from 'vue';
import {MutationTree} from 'vuex';
import {ModuleState, mutationTypes} from '@/modules/settings/shared/state';
import {TaxRatesPayload} from '@/modules/settings/shared/payloads/tax-rates-payload';
import {ListPayload} from '@/shared/state/template/module-payloads';

export const dictionariesMutations: MutationTree<ModuleState> = {
    [mutationTypes.FETCH_UNITS_REQUEST](state: ModuleState): void {
        Vue.set(state, 'loadingUnits', true);
    },

    [mutationTypes.FETCH_UNITS_ERROR](state: ModuleState): void {
        Vue.set(state, 'loadingUnits', false);
    },

    [mutationTypes.FETCH_UNITS_SUCCESS](state: ModuleState, payload: ListPayload): void {
        Vue.set(state, 'loadingUnits', false);
        Vue.set(state, 'units', payload.data);
    },

    [mutationTypes.FETCH_TAX_RATES_REQUEST](state: ModuleState): void {
        Vue.set(state, 'loadingTaxRates', true);
    },

    [mutationTypes.FETCH_TAX_RATES_ERROR](state: ModuleState): void {
        Vue.set(state, 'loadingTaxRates', false);
    },

    [mutationTypes.FETCH_TAX_RATES_SUCCESS](state: ModuleState, payload: TaxRatesPayload): void {
        Vue.set(state, 'loadingTaxRates', false);
        Vue.set(state, 'taxRates', payload.data);
    },
};
