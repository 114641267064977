
import Component from 'vue-class-component';
import SearchDetailsItem from '../details-item.vue';
import SearchDetails from '../search-detail';
import SalesList from '@/shared/components/search/details/sales/sales-list.vue';
import { Sale } from '@/modules/sales/shared';

@Component({
    components: {
        SearchDetailsItem,
        // Had to lazy load component because of webpack issues otherwise
        SaleItem: () => import('@/modules/sales/components/list/item.vue'),
        SalesList,
    },
})
export default class CostsSearchDetails extends SearchDetails<Sale> {
    get sales() {
        if (!this.items) { return []; } else { return this.items; }
    }
}
