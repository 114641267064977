import {SaleStatus} from '@/modules/sales/shared';

export class SaleDataRequest {
    public id: number = 0;
    public name: string = '';

    public phone: string = '';
    public email: string = '';
    public source: string = '';

    public status: SaleStatus = {} as SaleStatus;
    // tslint:disable-next-line:variable-name
    public assigned_user_id: number = 0;
    // tslint:disable-next-line:variable-name
    public due_date: string = '';
    // tslint:disable-next-line:variable-name
    public sale_status_id: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_id: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_subject_id: number = 0;
}
