

/**
 *  Dependencies list:
 *  - shared
 *  - module types
 */
import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';

import { Employee } from '../models/employee';
import { EmployeeDataRequest } from '../requests/employee-data-request';

const employeesProps = new ModuleProps('employees', {
  one: 'Pracownik',
  two: 'Pracownicy',
  five: 'Pracowników',
  accusative: 'Pracownika',
});

employeesProps.hasDialog = false;
employeesProps.mockURLName = 'personnel/employees';
employeesProps.permissionBaseName = 'personnel.employees';
employeesProps.customStateName = 'personnelState';

const employeesModule = new ModuleCreator<Employee, EmployeeDataRequest>(employeesProps);

export { employeesModule };
