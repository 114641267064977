
import { parsePrice } from '@/shared/helpers';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AddressField from '@/shared/components/elements/address.vue';
import { invoicePaymentMethods, invoiceTypes } from '@/shared/config/invoices';

@Component({
    components: {
        AddressField,
    },
})
export default class InvoiceSearchDetails extends Vue {
    @Prop(Object) public item!: null | any;
    public parsePrice = parsePrice;

    get invoiceType() {
        // if (!this.item) return {};
        return invoiceTypes.find((obj) => obj.id === this.item.type);
    }

    get paymentMethod() {
        return invoicePaymentMethods.find((obj) => obj.id === this.item.payment_method);
    }
}
