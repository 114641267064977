import { Component, Inject, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { logger } from '@/shared/services';
import { FormRules } from '@/shared/validation/form-rules';
import { parseDate, parseDateTime } from '@/shared/helpers';

@Component
export default class FormComponent<T extends object> extends Vue {
    @Inject() public readonly permissionCheck!: (arg1: string) => boolean;
    @Prop(Boolean) public edit!: boolean;
    @Prop(Boolean) public show!: boolean;
    @Prop({ type: Boolean, default: true }) public next!: boolean;
    @Prop(Object) public itemData!: T;

    @Prop({ type: Boolean, default: false }) public openFromAutocomplete!: boolean;
    @Prop(String) public autocompleteParentName!: string;

    public requestData!: T;
    public valid: boolean = false;
    public busy: boolean = false;
    public parseDate = parseDate;
    public parseDateTime = parseDateTime;
    public formRules = new FormRules();
    public form: any = null;

    public baseDataItem!: T | null;

    public store!: any; // Abstract
    public storeAction!: string; // Abstract
    public updateAction!: string; // Abstract

    get formTitle(): string {
        throw new Error('formTitle not implemented');
    }
    get errorMessages() {
        return this.store ? this.store.errorMessages : {};
    }

    get requestParams() {
        if (!this.requestData) {
            return this.itemData;
        }

        const requestData: T = this._.cloneDeep(this.requestData);

        for (const item in requestData) {
            if (requestData.hasOwnProperty(item)) {
                requestData[item] = this.itemData[item];
            }
        }

        return requestData;
    }

    get loading() {
        return this.store ? this.store.loadingItem : false;
    }

    get error() {
        return this.store ? this.store.error : '';
    }

    public created() {
        // fetch form extra data
    }

    public mounted(): any {
        this.initForm();
        this.baseDataItem = this._.cloneDeep(this.requestParams);
        this.$watch('show', this.OnShowChange);
        window.addEventListener('beforeunload', this.handleUnload.bind(this));
    }

    public beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleUnload.bind(this));
    }

    /*
        If we want to add beforeUnload alert we should add these lines:

        public initForm() {
            this.form = this.$refs.createInvestmentObjectForm;    << set form ref
        }

    */

    public datePicked(field: string, value: any) {
        const item: any = this.itemData;
        item[field] = value;
        this.itemData = item;

        this.clearError(field);
    }

    public handleUnload(event: any) {
        if (!this._.isEqual(this.requestParams, this.baseDataItem) && this.show) {
            event.returnValue = true;
            return true;
        }

        return;
    }

    public OnShowChange(value: boolean) {
        if (!value) {
            this.baseDataItem = null;
        } else {
            this.baseDataItem = this._.cloneDeep(this.requestParams);
        }

        if (this.form) {
            this.form.resetValidation();
        }

        if (value) {
            this.initFocus();
        }
    }

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            delete this.errorMessages[name];
            if (this.errorMessages.length > 0) {
                return;
            }
            return (this.store.error = '');
        }
    }

    public cancelForm() {
        this.$emit('canceled');
    }

    public submitAndNext(e?: any) {
        this.submitForm(e, !this.edit).catch((error) => logger.error(error));
    }

    public async submitForm(e: any, next?: boolean) {
        if (!this.form || this.busy) {
            return;
        }

        await this.form.validate();

        if (this.valid) {
            const action = this.edit ? this.updateAction : this.storeAction;
            this.busy = true;
            this.$store
                .dispatch(action, this.requestParams)
                .then((response) => {
                    if (
                        (response && response.data && response.data.id) ||
                        (response && response.hasOwnProperty('status') && response.status)
                    ) {
                        this.valid = true;
                        this.form.reset();
                        this.$emit('saved', { data: response.data, next, edit: action === this.updateAction });

                        if (next) {
                            this.$emit('next', response.data);
                            this.initFocus();
                            this.formNextCallback(response.data);
                        } else {
                            this.formCallback(response.data);
                        }
                    }
                })
                .catch((err) => logger.error(err))
                .then(() => {
                    this.busy = false;
                });
        }
    }

    public initForm() {
        //
    }

    public initFocus() {
        //
    }

    public formNextCallback(data: T) {
        //
    }

    public formCallback(data: T) {
        //
    }
}
