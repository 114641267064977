const routes = [
    {
        path: '/rents',
        component: () =>
            import(
                /* webpackChunkName: "rentsRoutes" */ '@/modules/rents/rents.vue'
            ),
        children: [
            {
                path: '',
                name: 'rents-list',
                component: () =>
                    import(
                        /* webpackChunkName: "rentsRoutes" */ '@/modules/rents/components/list.vue'
                    ),
                    meta: {
                        requiredPermission: 'rents.view',
                    },

            },
            {
                path: ':rentId/documents/:id',
                name: 'rent-document-details',
                component: () =>
                    import(
                        /* webpackChunkName: "rentsRoutes" */ '@/modules/rents/components/documents/details/details.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                        rentId: String(query.params.rentId),
                    };
                },
                meta: {
                    requiredPermission: 'rents.view',
                },

            },
            {
                path: ':id',
                name: 'rent-details',
                component: () =>
                    import(
                        /* webpackChunkName: "rentsRoutes" */ '@/modules/rents/components/details.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'rents.view',
                },

            },
            {
                path: 'settings/rooms',
                name: 'rent-setting-rooms',
                component: () =>
                    import(
                        /* webpackChunkName: "rentsRoutes" */ '@/modules/rents/components/settings/rooms/list.vue'
                    ),
                meta: {
                    requiredPermission: 'rents.rooms.types.view',
                },

            },
            {
                path: 'settings/properties',
                name: 'rent-setting-properties',
                component: () =>
                    import(
                        /* webpackChunkName: "rentsRoutes" */ '@/modules/rents/components/settings/rent-properties/list.vue'
                    ),
                meta: {
                    requiredPermission: 'rents.properties.types.view',
                },

            },
            {
                path: 'settings/types',
                name: 'rent-setting-types',
                component: () =>
                    import(
                        /* webpackChunkName: "rentsRoutes" */ '@/modules/rents/components/settings/rent-types/list.vue'
                    ),
                meta: {
                    requiredPermission: 'rents.types.view',
                },

            },
            {
                path: 'settings/document-categories',
                name: 'rent-document-setting-categories',
                component: () =>
                    import(
                        /* webpackChunkName: "rentsRoutes" */ '@/modules/rents/components/documents/categories/list.vue'
                    ),
                meta: {
                    requiredPermission: 'rents.documents.categories.view',
                },

            },
        ],
    },
];

export default routes;
