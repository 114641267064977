import { ModuleState } from '@/modules/auth/shared/state';
import { RootState } from '@/shared/types';
import { ActionTree } from 'vuex';
import { clientModule } from './module';
import { mutationsTypes } from '@/modules/client/shared/state/mutations';
import { getClient, updateClient } from '../services';
import { ErrorHandler } from '@/shared/state/template/helpers';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ClientRequest } from 'http';

const actionTypes = clientModule.actionsTypes;

export const clientActions: ActionTree<ModuleState, RootState> = {
    async [actionTypes.GET_CLIENT]({ commit }) {
        commit(mutationsTypes.GET_REQUEST);

        const result = await getClient().catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationsTypes.GET_ERROR, {
                type: 'error',
                text: 'Coś posszło nie tak',
                permanent: true,
                errors: e.errors,
            }),
        );


        if (result) {
            commit(mutationsTypes.GET_SUCCESS, result);
        }

        return result;

    },
    async [actionTypes.UPDATE_CLIENT]({ commit }, params: ClientRequest) {
        commit(mutationsTypes.UPDATE_REQUEST);

        const result = await updateClient(params).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationsTypes.GET_ERROR, {
                type: 'error',
                text: 'Coś posszło nie tak',
                permanent: true,
                errors: e.errors,
            }),
        );


        if (result) {
            commit(mutationsTypes.UPDATE_SUCCESS, result);
            commit('SHOW_SNACKBAR', {
                type: 'success',
                text: 'Dane zostały zmienione',
            });
        }

        return result;

    }
};
