import { actions } from './actions';
import { mutations } from './mutations';
import { contractorsModule } from './module';
import { contractorsTypesModule } from './settings/contractor-types/module';
import { positionTypesModule } from './settings/position-types/module';
import { contractorsSpecializationsModule } from './settings/contractor-specializations/module';
import { notesTypesModule } from './notes/module';
import { contactModule } from './contacts/module';
import { relatedInvestmentsModule } from './related-investments/module';
import { relatedInvestmentSubjectsModule } from './related-subjects/module';

const positionTypesModuleState = positionTypesModule.module;
const contractorsTypesModuleState = contractorsTypesModule.module;
const contactsState = contactModule.module;
const notesTypesModuleState = notesTypesModule.module;
const contractorsSpecializationsModuleState = contractorsSpecializationsModule.module;
const relatedInvestmentsState = relatedInvestmentsModule.module;
const relatedInvestmentSubjectsState = relatedInvestmentSubjectsModule.module;

contractorsModule.modules = {
    contactsState,
    contractorsTypesModuleState,
    positionTypesModuleState,
    notesTypesModuleState,
    contractorsSpecializationsModuleState,
    relatedInvestmentsState,
    relatedInvestmentSubjectsState,
};

const module = contractorsModule.module;

module.actions = {
    ...module.actions,
    ...actions,
};

module.mutations = {
    ...module.mutations,
    ...mutations,
};

export default module;
