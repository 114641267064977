import {HeaderFieldType} from '@/shared/types';

export const invoicesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {text: 'Nr', value: 'nr'},
    {text: 'Nazwa firmy', value: 'recipient_name'},
    {text: 'Kwota', value: 'total_price_tax_excl'},
    {text: 'Kwota z VAT', value: 'total_price'},
    {text: 'Termin płatności', value: 'payment_deadline_date', align: 'center'},
    {text: 'Metoda płatności', value: 'payment_method', align: 'center'},
    {text: 'Data płatności', value: 'payed_at', align: 'center'},
    {text: 'Status', value: 'status', align: 'center'},
    {text: 'Opłacona', value: 'paid', align: 'center'},
    {text: 'Akcje', value: 'actions', align: 'right'},
];

export const invoiceItemsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {text: 'Nazwa', value: 'name'},
    {text: 'Ilość', value: 'quantity'},
    {text: 'Jdn', value: 'unit'},
    {text: 'VAT', value: 'tax_rate'},
];

export const invoiceLogListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {text: 'Nazwa', value: 'title'},
    {text: 'Data', value: 'updated_at', align: 'right'},
];
