/*eslint no-console: ["off"] */
import {environment} from '@/environment/environment';
import {Environment} from '@/shared/types';
import { httpClient } from '..';
import _ from 'lodash';

/**
 * @description Logger class
 * This is responsible for logging of all kind of stuff in the application
 * Default, we are using the console api for logging and this provides the basic level of logging such as
 * you can use the available method of console in developement but in production these will be replaced
 * with empty methods. This can be extended with the help of adding Log level functionality
 */
class AppLogger {
    public log: any;
    public debug: any;
    public info: any;
    public warn: any;
    public error: any;
    public logToServer: any;
    public router: any;
    public store: any;
    public errorLogsSent: string[];

    /**
     * @constructor AppLogger
     */
    constructor() {
        /** Initializing the configuration of logger */
        this.initLogger();
        this.errorLogsSent = [];
        import('@/router').then((router) => {
            this.router = router.default;
        });
    }

    /**
     * @description Initializing the configuration such as if environment is production then all log method
     * will be replaced with empty methods except logToServer, which will be responsible for logging
     * the important stuff on server
     */
    public initLogger() {
        /** Checking the environment */
        if (environment !== Environment.Production) {
            // tslint:disable-next-line:no-console
            this.log = console.log.bind(console);

            this.debug = console.debug.bind(console);

            this.info = console.info.bind(console);

            this.warn = console.warn.bind(console);

            this.error = console.error.bind(console);

            this.logToServer = this.error;
        } else {
            /** In case of production replace the functions definition */
            this.log = this.debug = this.info = this.warn = this.error = () => {
                // TODO: send to API
            };

        }

        this.logToServer = (err: Error) => {
            const { name } = this.router.currentRoute;
            const path = window.location.toString();
            const groupCode = err.name + err.message + name;

            if (this.errorLogsSent.includes(groupCode)) {
                return;
            }
            this.errorLogsSent.push(groupCode);

            httpClient.post('/api/v1/error_logs', {
                message: err.message,
                stack: err.stack,
                name: err.name,
                route_name: name,
                route_path: path,
            });
        };
    }
}

/** Creating the instance of logger */
const logger = new AppLogger();

export { logger };
