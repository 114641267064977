import { ActionTree } from 'vuex';
import { RootState } from '@/shared/types';
import { ModuleState } from '@/shared/state/template/module-state';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ErrorHandler } from '@/shared/state/template/helpers';
import { Consent } from '@/modules/consents/shared/models/consent';
import { fetchItems } from '../../services/fetch-consents-process-items';
import { consentProcessesModule } from '../submodules';

export const actionTypes = consentProcessesModule.actionsTypes;

export const mutationsTypes = consentProcessesModule.mutationsTypes;

export const consentProcessesActions: ActionTree<ModuleState<Consent, any>, RootState> = {
    async[actionTypes.FETCH_ITEMS]({ commit }, id: number) {
        commit(mutationsTypes.FETCH_ITEMS_REQUEST);

        const result: any = await fetchItems(id).catch((e: ErrorPayload) => {
            ErrorHandler(e, mutationsTypes.FETCH_ITEMS_ERROR);
        });

        if (result.data && result.status === 200) {
            commit(mutationsTypes.FETCH_ITEMS_SUCCESS, { res: result.data, id });
        }

        return result.data;
    },
};
