import {MutationTree} from 'vuex';
import {widgetTypesModule} from '@/modules/widgets/shared/state/submodules';
import {ModuleState} from '@/shared/state/template/module-state';
import {WidgetType} from '@/modules/widgets/shared/models/widget-type';
import {DataRequest} from '@/shared/types';

export const widgetTypesMutationTypes = widgetTypesModule.mutationsTypes;

export const widgetTypesMutations: MutationTree<ModuleState<WidgetType, DataRequest>> = {
    [widgetTypesMutationTypes.RESET_TYPE](state: ModuleState<WidgetType, DataRequest>, id: number): void {
        const type = state.data.find((e) => e.id === id);
        if (type) {
            type.already_in_use = false;
        }
    },

};
