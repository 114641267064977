import { ModuleState } from '@/shared/state/template/module-state';
import {UserInvite} from '@/modules/admin/shared/models/user-invite';
import {UserInviteDataRequest} from '@/modules/admin/shared/requests/user-invite-data-request';

export class UserInviteState extends ModuleState<UserInvite, UserInviteDataRequest> {
    public resendingItem: boolean = false;
    public resendError: string = '';
    public resendErrorMessages: object = {};
    public resendDialog: boolean = false;
}
