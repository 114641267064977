import Vue from 'vue';
import { reflectKeys } from '@/shared/services';
import { MutationTree } from 'vuex';
import { RentersState } from './state';
import { rentersModule } from './module';

export const mutationsTypes = rentersModule.mutationsTypes;

export const mutations: MutationTree<RentersState> = {
    [mutationsTypes.RENTERS_SEARCH_REQUEST](state: RentersState): void {
        Vue.set(state, 'searching', true);
        Vue.set(state, 'searchingResults', []);
        Vue.set(state, 'searchError', '');
    },

    [mutationsTypes.RENTERS_SEARCH_RESET](state: RentersState): void {
        Vue.set(state, 'searching', false);
        Vue.set(state, 'searchingResults', []);
        Vue.set(state, 'searchError', '');
    },
};
