
import Vue from 'vue';
import Component from 'vue-class-component';
import { parsePrice } from '@/shared/helpers';
import { costStatuses } from '@/shared/config/costs';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        NameReduce,
    },
})
export default class TotalCosts extends Vue {
    @Prop(Boolean) public alwaysShown!: boolean;
    @Prop(Boolean) public small!: boolean;
    @Prop(Boolean) public asList!: boolean;
    @Prop(Number) public selected!: number;
    @Prop(Array) public costStatuses!: any[];
    @Prop(Object) public item!: any;

    public parsePrice = parsePrice;
    private getStatus(statusId: string) {
        return costStatuses.find((status: any) => status.id === statusId);
    }

    get selectedStatusIndex() {
        return this.selected;
    }

    set selectedStatusIndex(value) {
        this.$emit('update:selected', value);
    }

    get selectedStatus() {
        return this.costStatuses[this.selectedStatusIndex];
    }
}
