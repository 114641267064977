
    import Component from 'vue-class-component';
    import {Vue} from 'vue-property-decorator';

    @Component({
        props: {
            available: Boolean,
            to: Object,
            isAdmin: Boolean,
            exact: Boolean,
            disabled: Boolean,
            dense: Boolean,
            icon: String,
        },
    })
    export default class MenuItem extends Vue {
        //
    }
