import {acceptancesModule} from './module';
import {
    acceptanceNotesModule,
    acceptancePointsCategoriesModule,
    acceptanceTemplatePointsModule,
    acceptanceTemplatesModule,
    acceptanceTemplateListModule,
    acceptanceListModule,
    acceptancePointsModule,
} from '@/modules/acceptances/shared/state/submodules';
import {  AcceptanceState } from './state';
import {acceptancesActions} from '@/modules/acceptances/shared/state/actions/acceptances';
import {acceptancesMutations} from '@/modules/acceptances/shared/state/mutations/acceptances';

const templatesState = acceptanceTemplatesModule.module;
const templatePointsState = acceptanceTemplatePointsModule.module;
const acceptancePointsCategoriesState = acceptancePointsCategoriesModule.module;
const acceptanceNotesState = acceptanceNotesModule.module;
const acceptanceTemplateListState = acceptanceTemplateListModule.module;
const acceptanceListState = acceptanceListModule.module;
const acceptancePointsState = acceptancePointsModule.module;

acceptancesModule.state = new AcceptanceState();

acceptancesModule.additionalActions = acceptancesActions;

acceptancesModule.additionalMutations = acceptancesMutations;

acceptancesModule.modules = {
    templatesState,
    templatePointsState,
    acceptancePointsCategoriesState,
    acceptanceNotesState,
    acceptanceTemplateListState,
    acceptanceListState,
    acceptancePointsState,
};

export default acceptancesModule.module;
