import {visualizationsModule} from '@/modules/visualizations/shared/state/module';
import {VisualizationsState} from '@/modules/visualizations/shared/state/state/visualizations';
import {MutationTree} from 'vuex';

export const mutationTypes = visualizationsModule.mutationsTypes;

export const visualizationsMutations: MutationTree<VisualizationsState> = {
    [mutationTypes.VISIBILITY_REQUEST](state: VisualizationsState): void {
        state.changingVisibility = true;
    },

    [mutationTypes.VISIBILITY_SUCCESS](state: VisualizationsState): void {
        state.changingVisibility = false;
    },

    [mutationTypes.VISIBILITY_ERROR](state: VisualizationsState): void {
        state.changingVisibility = false;
    },

    [mutationTypes.UPDATE_SHARE](state: VisualizationsState, value: boolean): void {
        state.shareDialog = value;
    },

    [mutationTypes.UPDATE_CODE](state: VisualizationsState, value: boolean): void {
        state.codeDialog = value;
    },
};
