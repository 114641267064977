import {invoicesModule} from './module';
import {invoiceItemsModule, invoiceLogsModule} from './submodules';
import {actions} from '@/modules/invoices/shared/state/actions';
import {invoicesMutations} from './mutations/invoices';
import {invoiceItemsMutations} from './mutations/items';

invoicesModule.additionalActions = actions;

invoicesModule.additionalMutations = invoicesMutations;

invoiceItemsModule.additionalMutations = invoiceItemsMutations;


const itemsState = invoiceItemsModule.module;

const logsState = invoiceLogsModule.module;

invoicesModule.modules = {
    itemsState,
    logsState,
};

export default invoicesModule.module;
