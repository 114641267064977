import {MutationTree} from 'vuex';
import {rentRoomsModule} from '@/modules/rents/shared/state/state';
import {RentRoomsState} from '@/modules/rents/shared/state/state/rooms';

export const mutationsTypes = rentRoomsModule.mutationsTypes;

export const rentRoomsMutations: MutationTree<RentRoomsState> = {
    [mutationsTypes.UPDATE_POINT_SELECTING](state: RentRoomsState, newVal: boolean) {
        state.pointSelecting = newVal;
    },
};
