import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { Contractor } from '../models/contractor';
import { ContractorDataRequest } from '../requests/contractor-data-request';
import { ContractorsState } from './state';

const contractorsProps = new ModuleProps('contractors', {
    one: 'kontrahent',
    two: 'kontrahenci',
    five: 'kontrahentów',
    accusative: 'kontrahenta',
});

contractorsProps.hasDialog = false;

const contractorsModule = new ModuleCreator<
    Contractor,
    ContractorDataRequest,
    ContractorsState
>(contractorsProps);

contractorsModule.state = new ContractorsState();

contractorsModule.additionalMutationTypes = [
    'CONTRACTORS_SEARCH_SUCCESS',
    'CONTRACTORS_SEARCH_REQUEST',
    'CONTRACTORS_SEARCH_ERROR',
    'CONTRACTORS_SEARCH_RESET',
];

contractorsModule.additionalActionTypes = [
    'SEARCH_CONTRACTORS',
    'SET_GUARD_FOR_CONTRACTOR',
];

export {contractorsModule};
