import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { ConsentProcess } from '../models/consent-process';
import ConsentProcessDataRequest from '../requests/consent-process-data-request';

const consentProcessesProps = new ModuleProps('processes', {
    one: 'proces',
    two: 'procesy',
    five: 'procesów',
    accusative: 'proces',
},
    ['consents']);

consentProcessesProps.customStateName = 'processesState';
consentProcessesProps.parentIdInURLOperations = [];

const consentProcessesModule = new ModuleCreator<ConsentProcess, ConsentProcessDataRequest>(consentProcessesProps);

consentProcessesModule.additionalMutationTypes = [
    'FETCH_ITEMS_REQUEST',
    'FETCH_ITEMS_ERROR',
    'FETCH_ITEMS_SUCCESS',
];

consentProcessesModule.additionalActionTypes = [
    'FETCH_ITEMS',
];

export {consentProcessesModule};
