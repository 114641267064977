
/**
 *  Module routes:
 *  - module router
 *      - list
 *      - details
 *  TODO: - add routes to main-navigation
 */
const routes = [
    {
        path: '/notifications',
        component: () => import(/* webpackChunkName: "notificationRoutes" */ './notification.vue'),
        children: [
            {
                path: '',
                name: 'notifications-list',
                component: () =>
                    import(/* webpackChunkName: "notificationRoutes" */ './components/notifications.vue'),
            },
            {
                path: 'settings',
                name: 'notifications-settings',
                component: () =>
                    import(/* webpackChunkName: "notificationRoutes" */ './components/settings/settings.vue'),
                },
            ],
            scrollBehavior() {
                // always scroll to top
                return { top: 0 };
            },
    },
];

export default routes;
