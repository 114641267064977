

/**
 *  Dependencies list:
 *  - shared
 *  - module types
 */
import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';

import { Consent } from '../models/consent';
import { ConsentDataRequest } from '../requests/consent-data-request';

const consentsProps = new ModuleProps('consents', {
    one: 'Zgoda',
    two: 'Zgody',
    five: 'Zgód',
    accusative: 'Zgodę',
});

const consentsModule = new ModuleCreator<Consent, ConsentDataRequest>(consentsProps);
consentsModule.additionalMutationTypes = [
    'FETCH_VERSIONS_REQUEST',
    'FETCH_VERSIONS_ERROR',
    'FETCH_VERSIONS_SUCCESS',
    'SHOW_DATA_TRANSFER_AGREEMENT_DIALOG',
    'SHOW_REQUIRED_CONSENTS_DIALOG',
];

consentsModule.additionalActionTypes = [
    'FETCH_VERSIONS',
    'ON_USER_LOGGED',
];

export { consentsModule };

