import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {VisualizationView} from '@/modules/visualizations/shared/models/visualization-view';
import {VisualizationViewDataRequest} from '@/modules/visualizations/shared/requests/visualization-view-data-request';


const visualizationViewsProps = new ModuleProps('views',
    {
        one: 'widok',
        two: 'widoki',
        five: 'widoków',
    }, ['visualizations']);

visualizationViewsProps.hasDialog = false;


const visualizationViewsModule = new ModuleCreator<VisualizationView,
    VisualizationViewDataRequest>(visualizationViewsProps);

visualizationViewsModule.additionalMutationTypes = [
    'UPDATE_EDITED_EDITOR',
    'UPDATE_EDITOR_DIALOG',
    'UPDATE_HISTORY',
    'UPDATE_VIEW',
];

export {visualizationViewsModule};
