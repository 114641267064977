import {ActionTree} from 'vuex';
import {RootState} from '@/shared/types';
import {ModuleState} from '@/shared/state/template/module-state';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {editSubjects, removeSubjects} from '@/modules/investments/shared/services/subjects/subjects';
import {ErrorHandler} from '@/shared/state/template/helpers';
import {Investment} from '@/modules/investments/shared/models/investment';
import {InvestmentDataRequest} from '@/modules/investments/shared/requests/investment-data-request';
import {investmentsModule} from '@/modules/investments/shared/state/module';
import {InvestmentGeneratorDataRequest} from '@/modules/investments/shared/requests/investment-generator-data-request';
import {generateInvestment} from '@/modules/investments/shared/services/generator/generator';
import {AxiosResponse} from 'axios';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';

export const actionsTypes = investmentsModule.actionsTypes;

export const mutationTypes = investmentsModule.mutationsTypes;

export const investmentsActions: ActionTree<ModuleState<Investment, InvestmentDataRequest>, RootState> = {
    async [actionsTypes.GENERATE_INVESTMENT]({commit}, data: InvestmentGeneratorDataRequest) {
        commit(mutationTypes.GENERATOR_REQUEST);

        const result: void | AxiosResponse<
            ModuleShowPayload<Investment>
        > = await generateInvestment(data).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.GENERATOR_ERROR),
        );


        if (result && result.status === 201) {
            commit(mutationTypes.GENERATOR_SUCCESS, result.data);

            return result.data;
        }

        return null;
    },
};
