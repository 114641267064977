import { MutationTree } from 'vuex';
import { DocumentsState } from '../state/documents';
import { DocumentTemplatesState } from '../state/documentTemplates';
import { documentTemplatesModule } from '../submodules';

export const mutationTypes = documentTemplatesModule.mutationsTypes;

export const documentTemplatesMutations: MutationTree<DocumentsState> = {

};
