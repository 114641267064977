import {FetchParams} from '@/shared/types';
import {objectToQueryString} from '@/shared/helpers';
import {apiConstants} from '@/modules/settings/shared/config';
import {httpClient} from '@/shared/services';

const fetchSearchResults = (params?: FetchParams) => {
    const searchQuery = Object.assign({ filters: params?.filters });
    const paramsStr = objectToQueryString(searchQuery);

    let url = params && params.location ? params.location : apiConstants.search;

    url += (params ? '?' + paramsStr.toString() : '');

    return httpClient.get(url).then((res) => res.data);
};

export {
    fetchSearchResults,
};
