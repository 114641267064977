import {ListFilter} from '@/shared/types';

export const investmentsListFilters: ListFilter[] = [
    { name: 'start_date', type: 'date', label: 'Data rozpoczęcia', description: 'Wybierz zakres dat (od - do)' },
    { name: 'end_date', type: 'date', label: 'Data zakończenia', description: 'Wybierz zakres dat (od - do)' },
];

export const investmentSubjectOperationsListFilters: ListFilter[] = [
    {
        name: 'public_status',
        type: 'select',
        label: 'Status prywatny',
        description: 'Wybierz status publiczny',
        data: [
            {name: 'free', label: 'Wolny', value: false},
            {name: 'reserved', label: 'Zarezerwowany', value: false},
            {name: 'sold', label: 'Sprzedany', value: false},
        ],
    },
    {
        name: 'private_status',
        type: 'select',
        label: 'Status publiczny',
        description: 'Wybierz status prywatny',
        data: [
            {name: 'free', label: 'Wolny', value: false},
            {name: 'reserved', label: 'Zarezerwowany', value: false},
            {name: 'sold', label: 'Sprzedany', value: false},
        ],
    },
    {
        name: 'price', type: 'range', label: 'Koszt', description: 'Wybierz zakres ceny',
    },
];
