
import { HeaderFieldType } from '@/shared/types';

/**
 *  Options to show fields on list
 *  TODO: - add fields
 */
export const consentListHeaders: HeaderFieldType[] = [
    { text: 'Nazwa', value: 'name' },
    { text: 'Symbol', value: 'symbol', align: 'left' },
    { text: 'Wymagana', value: 'is_required', align: 'center' },
    { text: 'Ostatnia modyfikacja', value: 'updated_at', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'right', sortable: false },
];

export const consentProcessListHeaders: HeaderFieldType[] = [
    { text: 'Nazwa', value: 'name' },
    { text: 'Slug', value: 'slug', align: 'left' },
    { text: 'Ostatnia modyfikacja', value: 'updated_at', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'right', sortable: false },
];
