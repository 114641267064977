const routes = [
    {
        path: '/sales',
        component: () => import(/* webpackChunkName: "salesRoutes" */ '@/modules/sales/sales.vue'),
        children: [
            {
                path: '',
                name: 'sales-list',
                component: () =>
                    import(/* webpackChunkName: "salesRoutes" */ '@/modules/sales/components/sales.vue'),
                // import(/* webpackChunkName: "salesRoutes" */ '@/modules/sales/components/list.vue'),
                meta: {
                    requiredPermission: 'sales.view',
                },
            },
            {
                path: ':id',
                name: 'sale-details',
                component: () =>
                    import(/* webpackChunkName: "salesRoutes" */ '@/modules/sales/components/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'sales.view',
                },
            },
            {
                path: 'settings/statuses',
                name: 'sale-statuses-list',
                component: () =>
                    import(
                        /* webpackChunkName: "salesRoutes" */ '@/modules/sales/components/settings/status/list.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'sales.statuses.view',
                },
            },
            {
                path: 'settings/tasks-lists',
                name: 'sale-tasks-lists-list',
                component: () =>
                    import(
                        /* webpackChunkName: "salesRoutes" */ '@/modules/sales/components/settings/tasks-list/list.vue'
                    ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'sales.tasks-lists.view',
                },
            },
            {
                path: 'settings/tasks-lists/:id',
                name: 'tasks-list-details',
                component: () =>
                    import(/* webpackChunkName: "salesRoutes" */ '@/modules/sales/components/settings/tasks-list/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'sales.tasks-lists.view',
                },
            },
        ],
    },
];

export default routes;
