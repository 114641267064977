
import { Component } from 'vue-property-decorator';
import { actionsTypes, mutationTypes } from '../shared/state';
import { ErrorBoundary } from '@/shared/components';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import MediaDetails from '@/modules/media/components/details.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import { numeralVariations } from '@/shared/helpers';
import { mediaListHeaders } from '@/modules/media/shared/config';
import { MediaFile, MediaMetaItem } from '@/modules/media/shared/models/media-file';
import MediaFileUploadForm from '@/modules/media/components/form.vue';
import { fileNumerals } from '@/shared/config/media';
import { MediaFileDataRequest } from '@/modules/media/shared/requests/media-file-data-request';
import { bytesToSize, getIcon } from '@/modules/media/shared/helpers';
import { mdiDotsVertical, mdiDownload, mdiFileDocument, mdiFormatListBulleted, mdiViewGrid } from '@mdi/js';
import { ModuleProps } from '@/shared/state/template/module-props';
import { mediaProps } from '@/modules/media/shared/state/state';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import {mediaPermissions} from '@/shared/config/media';
import MediaPermissionEditForm from './media-permission-edit-form.vue';


// @ts-ignore-next-line
import VueFullScreenFileDrop from 'vue-full-screen-file-drop';

import 'vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css';
import DeleteProtectedDialog from './delete-protected-dialog.vue';
import { AxiosResponse } from 'axios';

@Component({
    components: {
        ErrorBoundary,
        ActionConfirmDialog,
        MediaFileUploadForm,
        MediaDetails,
        VueFullScreenFileDrop,
        ListToolbar,
        TooltipBtn,
        DeleteProtectedDialog,
        MediaPermissionEditForm,
    },
})
export default class MediaList extends ListComponent<MediaFile, MediaFileDataRequest> {
    public headers: object[] = mediaListHeaders;
    public store = this.$store.state.mediaState;
    public mutationTypes = mutationTypes;
    public fileNumerals = fileNumerals;
    public numeralVariations = numeralVariations;
    public getIcon = getIcon;
    public bytesToSize = bytesToSize;
    public props: ModuleProps = mediaProps;
    public menuX: number = 0;
    public menuY: number = 0;
    public menuShow: boolean = false;
    public fetchAction = actionsTypes.FETCH_MEDIA_DATA;
    public removeAction = actionsTypes.REMOVE_MEDIA;
    public removeItemsAction = actionsTypes.REMOVE_MEDIA_FILES;
    public showDeleteProtectedDialog = false;
    public protectorsDialogData: any[] = [];
    public editFormDialog = false;
    public icons: object = {
        mdiFileDocument,
        mdiDownload,
        mdiViewGrid,
        mdiFormatListBulleted,
        mdiDotsVertical,
    };

    get files() {
        return this.store.data.map((file: any) => {
            if (file.owner_id !== this.loggedUserId) {
                file.isSelectable = false;
            }
            return file;
        });
    }

    get loggedUserId() {
        return this.$store.state.authState.user ? this.$store.state.authState.user.id : 0;
    }

    get loggedUserIsAdmin() {
        return this.$store.state.authState.user ? this.$store.state.authState.user.is_admin : false;
    }

    get removeText() {
        return (
            'Czy na pewno usunąć ' +
            (this.select ? numeralVariations(this.selected.length, fileNumerals) : 'ten plik') +
            '?'
        );
    }

    get bytesToGB() {
        return this.meta && this.meta.used_media_size_bytes ? this.meta.used_media_size_bytes * Math.pow(10, -9) : 0;
    }

    get meta() {
        return this.store.meta as MediaMetaItem;
    }

    get usedStorageBar() {
        return (this.meta.used_media_size_bytes / this.meta.max_media_size_bytes) * 100;
    }

    get isMiniatureView() {
        return this.per_page === 12;
    }

    public created() {
        this.fetchData();
    }

    public permissionType(type: any) {
        return mediaPermissions.find(({value}) => Number(value) === Number(type));
    }

    public editItem(item: any) {
        this.editedItem = Object.assign({}, item);
        this.editFormDialog = true;

    }
    public removeItems() {
        this.select = true;
        this.store.removeDialog = true;
    }

    public openDialog(e: MouseEvent, item: any) {
        this.editedItem = item;
        this.menuShow = false;

        setTimeout(() => {
            this.menuX = e.clientX;
            this.menuY = e.clientY;
            this.menuShow = true;
        }, 200);
    }

    public onDrop(formData: FormData, files: FileList) {
        if (this.store.formDialog) {
            this.store.files = [...this.store.files, ...Array.from(files)];
        } else {
            this.store.files = Array.from(files);
        }
        this.store.formDialog = true;
    }

    public removeItemRequest(callback?: (response: number) => void) {
        if (this.removeAction) {
            this.$store
                .dispatch(this.removeAction, this.destroyObject ? this.store.current : Number(this.currentId))
                .then((response) => {
                    if (response && (response.status < 300 || (Number(response) < 300 && Number(response) > 0))) {
                        this.store.removeDialog = false;

                        if (!callback) {
                            if (this.meta && this.meta.to - this.meta.from < 1 && this.page === this.last_page) {
                                this.page--;
                            }

                            this.fetchData();
                        } else {
                            callback(response);
                        }
                    }
                    if (!response) {
                        this.store.removeDialog = false;
                        this.store.removing = false;
                        this.fetchData();
                    }
                })
                .catch((err) => {
                    if (err.data && err.data.data && err.data.data.length > 0) {
                        this.showDeleteProtectedDialog = true;
                        this.protectorsDialogData = [err.data];
                        this.store.removeDialog = false;
                        this.store.removing = false;
                        return;
                    }
                    this.store.removeDialog = false;
                    this.store.removing = false;
                });
        }
    }

    public removeItemsRequest() {
        if (this.removeItemsAction) {
            this.protectorsDialogData = [];
            this.$store.dispatch(this.removeItemsAction, this.selectedIds).then((response) => {
                if (response) {
                    this.store.removeDialog = false;
                    this.select = false;
                    this.selected = [];

                    if (response.some((el: AxiosResponse) => el.status === 403)) {
                        response.forEach((el: AxiosResponse) => {
                            if (el.status && el.status === 403) {
                                this.protectorsDialogData.push(el.data);
                            }
                        });

                        this.store.removing = false;
                        this.showDeleteProtectedDialog = true;

                        return;
                    }

                    this.page = 1;

                    this.fetchData();
                }
            });
        }
    }
}
