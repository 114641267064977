
import {Component, Prop, PropSync, Vue} from 'vue-property-decorator';
import {errorsToArray} from '@/shared/services';

@Component
export default class ActionConfirmDialog extends Vue {
    @PropSync('active', Boolean) private activeSync!: boolean;
    @Prop(String) private title!: string;
    @Prop(String) private name!: string;
    @Prop(String) private error!: string;
    @Prop(String) private warning!: string;
    @Prop(Object) private errorMessages!: object;
    @Prop(String) private confirmTitle!: string;
    @Prop(String) private cancelTitle!: string;
    @Prop(Boolean) private hideCancelButton!: boolean;
    @Prop(Boolean) private loading!: boolean;
    @Prop(Boolean) private disabled!: boolean;
    @Prop(String) private size!: string;

    get errors() {
        return errorsToArray(this.errorMessages as any);
    }

    get dialogSize() {
        switch (this.size) {
            case 'medium':
                return '700px';
            case 'big':
                return '900px';
            default:
                return '520px';
        }
    }

    public confirmAction() {
        this.$emit('confirmed');
    }

    public cancelAction() {
        this.$emit('canceled');
    }
}
