import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { PositionType } from '../../../models/settings/positions-type';
import { PositionTypeDataRequest } from '../../../requests/settings/positions-type-data-request';

const positionProps = new ModuleProps(
    'positions',
    {
        one: 'typ stanowiska',
        two: 'typy stanowiska',
        five: 'typów stanowisk',
    },
    ['contractors', 'contacts'],
);

positionProps.customStateName = 'positionTypesModuleState';

export const positionTypesModule = new ModuleCreator<
    PositionType,
    PositionTypeDataRequest
>(positionProps);
