/** Prefix for mutation types and actiontypes */
import { reflectKeys } from '@/shared/services';

const namespacedPrefix = '[ADMIN]';

/** Actions types constants */
export const actionsTypes = reflectKeys(
    [
        'FETCH_USER_DATA',
        'SHOW_USER',
        'UPDATE_USER',
        'STORE_USER',
        'REMOVE_USER',
        'RESTORE_USER',

        'SNACKBAR_TRIGGER',

        'FETCH_ROLE_DATA',
        'SHOW_ROLE',
        'UPDATE_ROLE',
        'STORE_ROLE',
        'REMOVE_ROLE',
        'ON_USER_LOGGED'
    ],
    namespacedPrefix,
);

/**
 * Mutation types
 */
export const mutationTypes = reflectKeys(
    [
        'USERS_DATA_SUCCESS',
        'USERS_DATA_REQUEST',
        'USERS_DATA_ERROR',
        'USERS_DATA_RESET',

        'USER_GET_REQUEST',
        'USER_GET_ERROR',
        'USER_GET_SUCCESS',

        'USER_REMOVE_REQUEST',
        'USER_REMOVE_ERROR',
        'USER_REMOVE_SUCCESS',

        'USER_RESTORE_REQUEST',
        'USER_RESTORE_ERROR',
        'USER_RESTORE_SUCCESS',

        'ROLES_DATA_SUCCESS',
        'ROLES_DATA_REQUEST',
        'ROLES_DATA_ERROR',
        'ROLES_DATA_RESET',

        'ROLE_GET_REQUEST',
        'ROLE_GET_ERROR',
        'ROLE_GET_SUCCESS',

        'ROLE_REMOVE_REQUEST',
        'ROLE_REMOVE_ERROR',
        'ROLE_REMOVE_SUCCESS',
    ],
    namespacedPrefix,
);
