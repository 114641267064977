import {MutationTree} from 'vuex';
import {rentsModule} from '@/modules/rents/shared/state/state';
import {RentState} from '@/modules/rents/shared/state/state/state';
import {ErrorPayload} from '@/shared/payloads/error-payload';

export const mutationsTypes = rentsModule.mutationsTypes;

export const rentsMutations: MutationTree<RentState> = {
    [mutationsTypes.OPERATION_REQUEST](state: RentState) {
        state.loadingItem = true;
        state.progress = 1;
        state.error = '';
        state.errorMessages = {};
    },

    [mutationsTypes.OPERATION_ERROR](
        state: RentState,
        payload: ErrorPayload,
    ) {
        state.error = payload.message;
        state.errorMessages = payload.errors;
        state.loadingItem = false;
    },

    [mutationsTypes.OPERATION_REMOVE_CHECK_ERROR](
        state: RentState,
        payload: ErrorPayload,
    ) {
        state.listError = payload.message;
        state.listErrorMessages = payload.errors;
        state.loadingItem = false;
    },

    [mutationsTypes.OPERATION_SUCCESS](state: RentState) {
        state.loadingItem = false;
    },

    'LARAVEL-ECHO_.rent_edit_step'(
        state: RentState,
        {current, total}: {current: number, total: number}
    ) {
        state.progress = (current / total) * 100;
    },
};
