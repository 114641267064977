import {MutationTree} from 'vuex';
import {invoiceItemsModule} from '../submodules';
import {InvoiceItem} from '@/modules/invoices/shared/models/invoice-item';
import {InvoiceItemDataRequest} from '@/modules/invoices/shared/requests/invoice-item-data-request';
import {ModuleState} from '@/shared/state/template/module-state';

export const mutationTypes = invoiceItemsModule.mutationsTypes;

export const invoiceItemsMutations: MutationTree<ModuleState<InvoiceItem, InvoiceItemDataRequest>> = {

    [mutationTypes.ADD_ITEM](state: ModuleState<InvoiceItem, InvoiceItemDataRequest>, item: InvoiceItem): void {
        if (state.meta && state.meta.total < 1) {
            state.meta.total = 1;
        }
        state.data.push(item);
    },
};
