import SettingsPage from '@/modules/settings/settings.vue';

const routes = [
    {
        path: '/settings',
        component: SettingsPage,
        children: [
        ],
    },
];

export default routes;
