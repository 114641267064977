import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { UserInvite } from '../models/user-invite';
import { UserInviteDataRequest } from '../requests/user-invite-data-request';
import {UserInviteState} from '@/modules/admin/shared/state/state/invite';
import {userInvitesActions} from '@/modules/admin/shared/state/actions/invites';
import {userInvitesMutations} from '@/modules/admin/shared/state/mutations/invites';

const userInvitesProps = new ModuleProps('invites', {
  one: 'zaproszenie',
  two: 'zaproszenia',
  five: 'zaproszeń',
}, ['users']);

userInvitesProps.customParentStateName = 'adminState';

userInvitesProps.hasDialog = false;

userInvitesProps.permissionBaseName = 'admin.invites';

const userInvitesModule = new ModuleCreator<UserInvite, UserInviteDataRequest, UserInviteState>(userInvitesProps);

userInvitesModule.additionalActionTypes = [
    'RESEND'
];

userInvitesModule.additionalMutationTypes = [
    'RESEND_REQUEST',
    'RESEND_ERROR',
    'RESEND_SUCCESS',
    'UPDATE_RESEND_DIALOG',
];

userInvitesModule.state = new UserInviteState();

export { userInvitesModule };
