import {httpClient} from '@/shared/services';
import {apiConstants} from '../config';

const changeVisibility = (id: number, active: boolean) => {
    const url = apiConstants.visualizations + '/' + id;

    return httpClient.put(url, {active}).then((res) => res.data);
};

export {
    changeVisibility,
};
