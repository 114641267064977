
import _ from 'lodash';
import Vue from 'vue';
import { Component, Prop, PropSync, Ref, VModel, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import TooltipBtn from './tooltip-btn.vue';

@Component({
    components: {
        TooltipBtn,
        draggable,
    },
})
export default class DraggableTabs extends Vue {
    @VModel({ type: Number }) public currentTab!: number;
    @Ref('tabsHeader') public tabsHeader!: Vue;
    @PropSync('tabs', Array) public tabsSync!: any[];
    @Prop({ type: Boolean, default: false }) public large!: boolean;
    @Prop({ type: Boolean, default: false }) public disableDrag!: boolean;
    public tabsOverflows = false;
    public currentTabIdSnapshot = 0;

    public resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            const tabsHeader = entry.target;
            this.setTabsOverflows(tabsHeader as HTMLElement);
        });
    });

    get currentView() {
        return this.tabsSync[this.currentTab];
    }

    public mounted() {
        this.$nextTick(() => {
            this.resizeObserver.observe(this.tabsHeader.$el);
        });
    }

    public switchToTab(index: number) {
        this.$set(this, 'currentTab', index);
        this.$forceUpdate();
    }

    public setTabsOverflows(tabsHeader: HTMLElement) {
        this.$set(this, 'tabsOverflows', tabsHeader.scrollWidth > tabsHeader.offsetWidth);
        this.$forceUpdate();
    }

    public sideScroll(direction: 'left' | 'right') {
        this.$nextTick(() => {
            if (!this.tabsHeader) {
                return;
            }

            const element = this.tabsHeader.$el;

            if (direction === 'left') {
                element.scrollLeft -= 200;
            } else {
                element.scrollLeft += 200;
            }
        });
    }

    public removeTab(itemId: number) {
        const currentId = this.currentView.id;
        this.tabsSync = this.tabsSync.filter((el) => el.id !== itemId);

        this.$forceUpdate();

        this.$nextTick(() => {
            const newIndex = this.tabsSync.findIndex((el) => el.id === currentId);

            if (newIndex !== -1) {
                this.currentTab = newIndex;
            } else {
                this.currentTab = this.tabsSync.length - 1;
            }
        });
    }

    public onTabsChange() {
        this.currentTab = 0;

        this.$nextTick(() => {
            const activeTabIndex = this.tabsSync.findIndex((el) => el.id === this.currentTabIdSnapshot);
            this.currentTab = activeTabIndex;
        });
    }

    public onClone() {
        if (!this.tabsSync[this.currentTab]) {
            return;
        }
        this.currentTabIdSnapshot = _.clone(this.tabsSync[this.currentTab].id);
    }

    @Watch('tabsSync', { deep: true })
    public onTabsSyncChange() {
        this.$nextTick(() => {
            this.setTabsOverflows(this.tabsHeader.$el as HTMLElement);
        });
    }

    @Watch('currentTab')
    public onCurrentTabChange(val: number) {
        if (val > this.tabsSync.length - 1) {
            this.currentTab = this.tabsSync.length - 1;
        }
    }
}
