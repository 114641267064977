const routes = [
    {
        path: '/admin/developer-profile',
        name: 'developer-profile',
        component: () =>
            import(/* webpackChunkName: "adminRoutes" */ '@/modules/client/components/developer-profile/developer-profile.vue'),
        meta: {
            requiredPermission: 'admin.client.edit',
        },
    },

];

export default routes;
