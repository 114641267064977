const routes = [
    {
        path: '/documentation',
        name: 'documentation-list',
        component: () =>
            import(
                /* webpackChunkName: "departmentsRoutes" */ '@/modules/tutorial/components/documentation/Documentation.vue'
            ),
        meta: {
            hideNavigationBar: true,
        },
        children: [
            {
                path: ':tutorialId',
                name: 'documentation-details',
                component: () =>
                    import(
                        /* webpackChunkName: "departmentsRoutes" */ '@/modules/tutorial/components/documentation/Documentation.vue'
                    ),
                meta: {
                    hideNavigationBar: true,
                },
            },
        ],
    },
];

export default routes;
