import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuetify from 'vuetify/lib';
import './service-worker/register';
import '@/plugins/exception-handler';
import '@mdi/font/css/materialdesignicons.css';
import { ClickOutside, Intersect, Resize, Ripple } from 'vuetify/lib/directives';
import pl from 'vuetify/src/locale/pl';
// @ts-ignore
import VueVideoPlayer from 'vue-video-player';

// @ts-ignore
import vMask from 'vue-the-mask';

import 'video.js/dist/video-js.css';

import VueLodash from 'vue-lodash';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isEqualWith from 'lodash/isEqualWith';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import groupBy from 'lodash/groupBy';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import filter from 'lodash/filter';
import negate from 'lodash/negate';
import matches from 'lodash/matches';
import range from 'lodash/range';
import round from 'lodash/round';
import orderBy from 'lodash/orderBy';

// @ts-ignore
import ShortKey from 'vue-shortkey';
import VueKonva from 'vue-konva';

import { interceptorsSetup } from '@/shared/services';

// import { FloaraD360 } from 'froala-d360-plugin';
// import WysiwygPresets from './shared/config/wysiwyg';
// import 'froala-d360-plugin/dist/themes/dark-d360.scss';
// import 'froala-d360-plugin/dist/themes/light-d360.scss';
// import 'froala-d360-plugin/dist/plugins/editable-areas/editable-areas';
// import 'froala-d360-plugin/dist/plugins/editable-areas/editable-areas.scss';
// import 'froala-d360-plugin/dist/plugins/page-break/page-break';
// import 'froala-d360-plugin/dist/plugins/page-break/page-break.scss';
// @ts-ignore-next-line
import VueEcho from 'vue-echo-laravel';

// @ts-ignore
// import VueFroala from 'vue-froala-wysiwyg';
// import 'froala-editor/css/froala_editor.pkgd.min.css';
// import 'froala-editor/js/languages/pl.js';
// import 'froala-editor/js/plugins.pkgd.min';
// import 'froala-editor/js/plugins.pkgd.min.js';
// import 'froala-editor/css/froala_editor.pkgd.min.css';
// import 'froala-editor/css/themes/dark.min.css';
// import 'froala-editor/js/languages/pl.js';
import echoInstance from './echo';

import { VContainer } from 'vuetify/lib';

// Makes it useable with vuedraggable component
Vue.component('v-container', VContainer);

Vue.use(VueEcho, echoInstance);

// Vue.use(VueFroala);
// Vue.use(FloaraD360, WysiwygPresets);

Vue.use(VueLodash, {
    lodash: {
        isEqualWith,
        uniq,
        uniqBy,
        groupBy,
        orderBy,
        clone,
        cloneDeep,
        isEqual,
        isEmpty,
        range,
        round,
        filter,
        matches,
        negate,
        difference,
    }
});

Vue.use(ShortKey, { prevent: ['.search__selectable', '.ProseMirror' ] });

Vue.use(VueKonva);

Vue.config.productionTip = false;

const vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: { pl },
        current: 'pl',
    },
    theme: {
        themes: {
            light: {
                primary: '#A54DFF',
                secondary: '#424242',
                third: '#FFFFFF',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            },
            dark: {
                primary: '#A54DFF',
                secondary: '#424242',
                third: '#000000',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            },
        },
        options: {
            customProperties: true,
        },
    },
});



Vue.use(Vuetify, {
    directives: {
        Resize,
        Ripple,
        Intersect,
        ClickOutside,
    }
});

Vue.use(VueVideoPlayer);
Vue.use(vMask);

import moment from 'moment';
import { clickDelayDirective } from './plugins/click-delay-directive';


const localLocale = moment;
localLocale.locale('pl');

Vue.prototype.moment = localLocale;

Vue.directive('clickDelay', clickDelayDirective);

interceptorsSetup(store, router);

new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
