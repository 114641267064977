import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { TicketPriority, TicketPriorityDataRequest } from '@/modules/tickets/shared';

const prioritiesProps = new ModuleProps('priorities', {
    one: 'priorytet',
    two: 'priorytety',
    five: 'priorytetów',
}, ['tickets']);

prioritiesProps.customStateName = 'prioritiesState';
prioritiesProps.hasProtectedIncrement = true;

const prioritiesModule = new ModuleCreator<TicketPriority, TicketPriorityDataRequest>(prioritiesProps);

export { prioritiesModule };
