import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SearchDetail<t> extends Vue {
    @Prop(Number) public parentId!: number;
    @Prop(Array) public items!: t[];
    @Prop(Function) public toggleItem!: any;
    @Prop(Boolean) public isActive!: boolean;
    @Prop(Boolean) public loading!: boolean;
    @Prop(Number) public value!: number;

    get index() {
        return this.value;
    }
    set index(val: number) {
        this.$emit('input', val);
    }

    public redirect(link: string) {
        this.$router.push(link);
        this.$emit('redirection');
    }

}
