
import {Component, Vue} from 'vue-property-decorator';
import {contrast, hexToRgb} from '@/shared/helpers/colors';
import {InvestmentTag} from '@/modules/investments/shared/models/investment-tag';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
@Component({
    props: {
        value: [Object, String, Number],
        close: Boolean,
        disabled: Boolean,
        small: Boolean,
    },
    components: {
        NameReduce
    }
})
export default class InvestmentTagChip extends Vue {
    public value!: InvestmentTag;
    public small!: boolean;
    public whiteColor = hexToRgb('#ffffff');
    public blackColor = hexToRgb('#000000');

    public tagStyle: object = {
        maxWidth: (!this.small ? '450px' : '150px')
    };

    get isDark() {
        return contrast(hexToRgb(this.value.color && (typeof this.value.color !== 'string') ?
            this.value.color.hex :
            this.value.color,
            ),
            this.whiteColor) > 2;
    }

    get isLight() {
        return contrast(hexToRgb(this.value.color && (typeof this.value.color !== 'string') ?
            this.value.color.hex :
            this.value.color,
            ),
            this.blackColor) > 2;
    }
}

