import { reflectKeys } from '@/shared/services';
import { MutationTree } from 'vuex';
import { ConfirmPasswordStoreState } from './state';

/** Prefix for mutation types and actiontypes */
const namespacedPrefix = '[AUTH]';

/**
 * Mutation types
 */
export const mutationTypes = {
    SET_DIALOG_VISIBILITY: `${namespacedPrefix} SET DIALOG VISIBILITY`,
};
/**
 * Users data mutations
 */
export const mutations: MutationTree<ConfirmPasswordStoreState> = (() => {
    const { SET_DIALOG_VISIBILITY } = mutationTypes;

    return ({
        [SET_DIALOG_VISIBILITY](state: ConfirmPasswordStoreState, payload: boolean): void {
            state.dialog = payload;
        },
    });

})();
