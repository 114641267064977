import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import { Cost } from '../models/cost';
import { CostDataRequest } from '../requests/cost-data-request';

const costsProps = new ModuleProps('costs',
    {one: 'koszt inwestycji',
        two: 'koszty inwestycji',
        five: 'kosztów inwestycji',
    });

costsProps.hasDialog = false;
costsProps.fullscreenForm = true;

const costsModule = new ModuleCreator<Cost, CostDataRequest>(costsProps);

costsModule.additionalActionTypes = [
    'APPROVE',
];

costsModule.additionalMutationTypes = [
    'UPDATE_EDIT_FORM_DIALOG',
    'APPROVE_REQUEST',
    'APPROVE_ERROR',
    'APPROVE_SUCCESS',
];

export {costsModule};
