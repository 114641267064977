import {ErrorPayload} from '@/shared/payloads/error-payload';
import {FileProgress} from '@/modules/media/shared/models/file-progress';
import {ModuleState} from '@/shared/state/template/module-state';
import {MediaFileDataRequest} from '@/modules/media/shared/requests/media-file-data-request';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import {ModuleProps} from '@/shared/state/template/module-props';

export const mediaProps: ModuleProps = new ModuleProps('media', {
    one: 'plik',
    two: 'pliki',
    five: 'plików',
    accusative: 'pliku',
});

export class MediaState extends ModuleState<MediaFile, MediaFileDataRequest> {
    public filesQueue: FileProgress[] = [];
    public files: File[] = [];
    public fileErrors: { [key: string]: ErrorPayload } = {};
    public fileProgresses: number[] = [];
    public removeProgress: number = 0;
    public protectedFiles = [];
}
