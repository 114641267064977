import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { Contact } from '../../models/contact/contact';
import { ContactDataRequest } from '../../requests/contact/contact-data-request';

const contactProps = new ModuleProps(
    'contacts',
    {
        one: 'kontakt',
        two: 'kontakty',
        five: 'kontaktów',
    },
    ['contractors'],
);

contactProps.customStateName = 'contactsState';
contactProps.addParentProperty = true;

export const contactModule = new ModuleCreator<
    Contact,
    ContactDataRequest
>(contactProps);
