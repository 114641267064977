
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { httpClient, logger } from '@/shared/services';
import { Consent } from '../../shared';
import { objectToQueryString } from '@/shared/helpers';
import { AxiosResponse } from 'axios';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { actionsTypes } from '@/modules/auth/shared/state';

@Component({
    components: {
        TooltipBtn,
    },
})
export default class RequiredConsents extends Vue {
    public currentConsent = 1;
    public consents: Consent[] = [];
    public requestData: any = [];
    public completed: any = [];
    public loadingSave = false;
    public consentsLoading = false;

    get canContinue() {
        return !this.completed.some((el: boolean) => el === false);
    }

    get parsedRequestData() {
        return {
            consents: this.requestData.map((el: any) => {
                return {
                    id: el.id,
                    is_accepted: el.is_accepted ? 1 : 0,
                };
            }),
        };
    }

    get showRequiredConsentsDialog() {
        return this.$store.state.consentsState.requiredConsentsDialog;
    }
    set showRequiredConsentsDialog(val: boolean) {
        this.$set(this.$store.state.consentsState, 'requiredConsentsDialog', val);
    }

    public created() {
        this.consentsLoading = true;
        this.fetchConsents(() => {
            this.requestData = this.consents.map((consent) => {
                return {
                    id: consent.id,
                    is_accepted: false,
                };
            });

            this.completed = this.consents.map(() => {
                return false;
            });

            this.consentsLoading = false;
        });
    }

    public isDeclined(index: number) {
        return this.completed[index] && !this.requestData[index].is_accepted;
    }

    public getStepColor(index: number) {
        if (!this.completed[index]) {
            return 'grey';
        } else {
            return this.isDeclined(index) ? 'red' : 'green';
        }
    }

    public decline(index: number) {
        this.currentConsent++;
        this.$set(this.requestData[index], 'is_accepted', false);
        this.$set(this.completed, index, true);
    }
    public accept(index: number) {
        this.currentConsent++;
        this.$set(this.requestData[index], 'is_accepted', true);
        this.$set(this.completed, index, true);
    }

    public continueClick() {
        if (!this.canContinue) { return; }
        this.saveAnswers();
    }

    public saveAnswers() {
        this.loadingSave = true;
        httpClient
            .post(`/api/v1/admin/consents/answers?${objectToQueryString(this.parsedRequestData)}`)
            .then((res: AxiosResponse) => {
                if (res.status === 204) {
                    this.showRequiredConsentsDialog = false;
                } else {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
                }
            })
            .catch((err) => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
            })
            .finally(() => {
                this.loadingSave = false;
            });
    }

    public logout() {
        this.$store
            .dispatch(actionsTypes.LOGOUT_USER)
            .then(() => {
                this.$router.push('/auth/login').catch((err) => logger.error(err));
            })
            .catch((err) => logger.error(err));
    }

    public fetchConsents(
        cb = () => {
            /**/
        },
    ) {
        httpClient.get('/api/v1/admin/consents/processes/developer_user_registration/consents').then((res) => {
            this.consents = res.data.data;
            cb();
        });
    }
}
