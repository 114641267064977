
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { SearchHit } from '@/shared/models/search-hit';
import { hitTypes } from '@/shared/config/search';
@Component({})
export default class SearchHitsList extends Vue {
    @Prop(Array) public hits!: Array<SearchHit<any>>;
    @Prop(Number) public value!: number;
    @Prop(Number) public hitsAmount!: number;
    @Prop(Boolean) public isActive!: boolean;
    @Prop(Boolean) public isMobile!: boolean;
    @Prop(Boolean) public loading!: boolean;
    public placeholders = 8;

    get currentHit() {
        return this.value;
    }

    set currentHit(val: number) {
        this.$emit('input', val);
    }

    public getHitType(type: string) {
        return hitTypes.find((el) => el.id === type);
    }

    public scrollToElement(el: any) {
        let offset = 0;
        let element: any = el instanceof Vue ? el.$el : el;
        this.$nextTick(() => {
            const container = document.querySelector('.search-hits-container');

            while (element.parentNode && !element.parentNode.classList.contains('search-hits-container')) {
                offset += element.offsetTop;
                element = element.parentNode;
            }
            container?.scroll({ top: offset - 20, behavior: 'smooth' });
        });
    }

    @Watch('loading')
    public onLoading() {
        this.$forceUpdate();
    }

    @Watch('currentHit')
    public onCurrentHitChange(val: number) {
        if (
            !this.$refs.hits ||
            (this.$refs.hits as any[]).length === 0 ||
            !(this.$refs.hits as any[])[val !== 0 ? val - 1 : val]
        ) {
            return;
        }
        this.scrollToElement((this.$refs.hits as any[])[val !== 0 ? val - 1 : val].$el);
    }
}
