import {visualizationsModule} from '@/modules/visualizations/shared/state/module';
import {ActionTree} from 'vuex';
import {VisualizationDataRequest} from '@/modules/visualizations/shared/requests/visualization-data-request';
import {Visualization} from '@/modules/visualizations/shared/models/visualization';
import {RootState} from '@/shared/types';
import {mutationTypes} from '@/modules/visualizations/shared/state/mutations/visualizations';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';
import {VisualizationsState} from '@/modules/visualizations/shared/state/state/visualizations';
import {changeVisibility} from '@/modules/visualizations/shared/services';
import {ErrorHandler} from '@/shared/state/template/helpers';
import {ErrorPayload} from '@/shared/payloads/error-payload';

const actionsTypes = visualizationsModule.actionsTypes;

export const visualizationsActions: ActionTree<VisualizationsState, RootState> = {
    async [actionsTypes.CHANGE_VISIBILITY]({commit}, data: VisualizationDataRequest) {
        commit(mutationTypes.VISIBILITY_REQUEST, true);

        const result: ModuleShowPayload<Visualization> = await changeVisibility(data.id, data.active)
            .catch((response: ErrorPayload) => ErrorHandler(response, mutationTypes.VISIBILITY_ERROR));

        if (result && result.data) {
            commit(mutationTypes.VISIBILITY_SUCCESS, result);
            commit('SHOW_SNACKBAR', {
                type: 'success',
                text: `${!result.data.active ? 'Ukryto' : 'Opublikowano'} ${visualizationsModule.moduleProps.names.accusative}`,
            });
        }

        return result;
    },
};

