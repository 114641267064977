import { HeaderFieldType } from '@/shared/types';

export const rentsListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Typ', value: 'rent_type_id' },
    { text: 'Powierzchnia', value: 'area' },
    { text: 'Czynsz', value: 'price' },
    { text: 'Adres', value: 'address'},
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const rentsTypeListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const rentPropertyTypesListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const rentRoomsListHeaders: HeaderFieldType[] = [
    { text: 'Nr', value: 'code'},
    { text: 'Nazwa', value: 'name' },
    { text: 'Typ', value: 'type' },
    { text: 'Piętro', value: 'storey', align: 'right' },
    { text: 'Powierzchnia', value: 'area', align: 'right' },
    { text: 'Akcje', value: 'actions', align: 'right' },
];

export const roomTypesListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const rentEquipmentListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false, width: '10%' },
];

export const rentDocumentsListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Status', value: 'status' },
    { text: 'Przegląd', value: 'revision_status' },
    { text: 'Kategoria', value: 'category' },
    { text: 'Data ważności do', value: 'due_date' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false, width: '10%' },
];

export const rentDocumentNotesListHeaders: HeaderFieldType[] = [
    { text: 'Lp', value: 'index' },
    { text: 'Nazwa', value: 'name' },
    { text: 'Twórca', value: 'user' },
    { text: 'Utworzono', value: 'created_at' },
    { text: 'Zaktualizowano', value: 'updated_at' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false, width: '10%' },
];


export const rentDocumentLogsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {text: 'Nazwa', value: 'title'},
    {text: 'Użytkownik', value: 'user'},
    {text: 'Data', value: 'updated_at', align: 'right'},
];

export const rentDocumentCategoryListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {text: 'Nazwa', value: 'name'},
    {text: 'Wymagane przeglądy', value: 'has_revisions'},
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false, width: '10%' },
];
