
    import {Component, Vue} from 'vue-property-decorator';
    import {errorsToArray} from '@/shared/services';

    @Component({
        props: {
            edit: Boolean,
            block: Boolean,
            small: {
                type: Boolean,
                default: false,
            },
            next: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            cancel: {
                type: Boolean,
                default: true,
            },

            submitButton: {
                type: Boolean,
                default: true,
            },

            error: String,
            errorMessages: Object,
            loading: Boolean,
            createTitle: {
                type: String,
                default: 'Utwórz',
            },
            updateTitle: {
                type: String,
                default: 'Aktualizuj',
            },
            nextTitle: {
                type: String,
                default: 'Utwórz + Kolejny',
            },
            fixed: Boolean,
        },
    })
    export default class FormActions extends Vue {
        private errorMessages!: object;

        public cancelForm() {
            this.$emit('canceled');
        }

        get errors() {
            return errorsToArray(this.errorMessages);
        }

        public submitForm() {
            this.$emit('submited');
        }
    }
