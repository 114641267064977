import { costPositionsListHeaders, costsListHeaders } from '@/modules/costs/shared/config';
import { acceptancesListHeaders } from '@/modules/acceptances/shared/config/data-settings';
import { contactsListHeaders } from '@/modules/contacts/shared/config/data-settings';

import { notesListHeaders } from '@/modules/contractors/shared/config';
import { invoiceItemsListHeaders, invoiceLogListHeaders, invoicesListHeaders } from '@/modules/invoices/shared/config';

const currency = 'PLN';

export const hitTypes = [
    { id: 'investments', name: 'Inwestycja', icon: 'mdi-home-city' },
    { id: 'investment_subjects', name: 'Przedmiot Inwestycji', icon: 'mdi-home-variant' },
    { id: 'contractors', name: 'Kontrahent', icon: 'mdi-badge-account-horizontal' },
    { id: 'invoices', name: 'Faktura', icon: 'mdi-file-document-outline' },
    { id: 'costs', name: 'Koszt Inwestycji', icon: 'mdi-cash-100' }
];

export const detailsConfig = {
    costs: {
        title: 'Koszty',
        headers: costsListHeaders.filter(
            (header) => !['index', 'investment', 'actions', 'categories'].includes(header.value),
        ),
        childrenRoute: '/costs/$id$',
        parentRoute: '/costs?filters.$parentName$_id=$parentId$',
        routeText: 'Przejdź do kosztów',
    },
    lands: {
        title: 'Grunty',
        headers: [
            { text: 'Nazwa gruntu', value: 'name', align: 'center' },
            { text: 'Adres ewidencyjny', value: 'plot_address', align: 'center' },
            { text: 'Numer ewidencyjny', value: 'plot_number', align: 'center' },
            { text: 'Powierzchnia działki', value: 'plot_area', align: 'center' },
        ],
        childrenRoute: '/lands/$id$',
        parentRoute: '/lands?filters.$parentName$_id=$parentId$',
        routeText: 'Przejdź do gruntów',
    },
    visualizations: {
        title: 'Wizualizacje',
        headers: [
            { text: 'Nazwa', value: 'name', align: 'center' },
        ],
        childrenRoute: '/visualizations/$id$',
        parentRoute: '/visualizations?filters.$parentName$_id=$parentId$',
        routeText: 'Przejdź do wizualizacji',
    },
    sales: {

    },
    acceptances: {
        title: 'Odbiory',
        headers: acceptancesListHeaders.filter(
            (header) => !['index', 'investment', 'actions', 'categories'].includes(header.value),
        ),
        childrenRoute: '/acceptances/$id$',
        parentRoute: '/acceptances?filters.$parentName$_id=$parentId$',
        routeText: 'Przejdź do odbiorów',
    },
    contacts: {
        title: 'Kontakty',
        headers: [...contactsListHeaders.filter(
            (header) => !['index', 'investment', 'actions', 'categories'].includes(header.value),
        ), { text: 'Stanowisko', value: 'position' }],
        childrenRoute: '/$parentName$/$parentId$/contacts/$id$',
        parentRoute: '/$parentName$/$parentId$/?currentTab=contacts',
        routeText: 'Przejdź do kontaktów',
    },
    contractorNotes: {
        title: 'Notatki',
        headers: notesListHeaders.filter(
            (header) => !['index', 'investment', 'actions', 'categories'].includes(header.value),
        ),
        childrenRoute: '/$parentName$/$parentId$/notes/$id$',
        parentRoute: '/$parentName$/$parentId$/?currentTab=notes',
        routeText: 'Przejdź do notatek',

    },
    costPositions: {
        title: 'Pozycje',
        headers: costPositionsListHeaders.filter(
            (header) => !['index', 'investment', 'actions', 'categories'].includes(header.value),
            ),
            parentRoute: '/costs/$parentId$',
            childrenRoute: '/costs/$parentId$',
            routeText: 'Przejdź do pozycji',
        },
        invoiceItems: {
            title: 'Pozycje',
            headers: [...invoiceItemsListHeaders.filter(
                (header) => !['index', 'investment', 'actions', 'categories', 'unit'].includes(header.value),
                ), { text: 'Cena netto' + ` (${currency})`, value: 'price' },
                { text: 'Cena netto' + ` (${currency})`, value: 'total_price_tax_excl' },
                { text: 'Kwota VAT' + ` (${currency})`, value: 'tax_total' },
                { text: 'Kwota brutto' + ` (${currency})`, value: 'total_price_tax_incl' }, ],
                parentRoute: '/invoices/$parentId$',
                childrenRoute: '/invoices/$parentId$'
                ,
        routeText: 'Przejdź do pozycji',
            },
            invoiceLogs: {
                title: 'Ostatnie operacje',
                headers: invoiceLogListHeaders.filter(
                    (header) => !['index', 'investment', 'actions', 'categories', 'unit'].includes(header.value)),
                    parentRoute: '/invoices/$parentId$',
                    childrenRoute: '/invoices/$parentId$'
                    ,
        routeText: 'Przejdź do histori operacji',
    },
    invoices: {
        title: 'Faktury',
        headers: invoicesListHeaders.filter(
            (header) => !['index', 'recipient_name', 'actions'].includes(header.value),
        ),
        parentRoute: '/invoices/$parentId$?filters.$parentName$_id=$parentId$',
        childrenRoute: '/invoices/$id$',
        routeText: 'Przejdź do faktur',
    },
};
