const routes = [
    {
        path: '/acceptances',
        component: () =>
            import(/* webpackChunkName: "acceptancesRoutes" */ '@/modules/acceptances/acceptances.vue'),
        meta: {
            requiredPermission: 'acceptances.view',
        },
        children: [
            {
                path: 'templates',
                name: 'acceptance-templates-list',
                component: () =>
                    import(/* webpackChunkName: "acceptancesRoutes" */ '@/modules/acceptances/components/templates/list.vue'),
                meta: {
                    requiredPermission: 'acceptances.templates.view',
                },
            },
            {
                path: 'templates/:id',
                name: 'acceptance-template-details',
                component: () =>
                    import(/* webpackChunkName: "acceptancesRoutes" */ '@/modules/acceptances/components/templates/details.vue'),
                props: true,
                meta: {
                    requiredPermission: 'acceptances.templates.view',
                },
            },
            {
                path: ':id',
                name: 'acceptance-details',
                component: () =>
                    import(/* webpackChunkName: "acceptancesRoutes" */ '@/modules/acceptances/components/details.vue'),
                props: true,
                meta: {
                    requiredPermission: 'acceptances.view',
                },
            },
            {
                path: '',
                name: 'acceptances-list',
                component: () => import(/* webpackChunkName: "acceptancesRoutes" */ '@/modules/acceptances/components/list.vue'),
                props: true,
                meta: {
                    requiredPermission: 'acceptances.view',
                },
            },
            {
                path: 'settings/points',
                name: 'acceptances-settings-points',
                component: () => import(/* webpackChunkName: "acceptancesRoutes" */ '@/modules/acceptances/components/templates/settings/points/list.vue'),
                props: true,
                meta: {
                    requiredPermission: 'acceptances.templates.points.view',
                },
            },
            {
                path: 'settings/points-categories',
                name: 'acceptances-settings-points-categories',
                component: () => import(/* webpackChunkName: "acceptancesRoutes" */ '@/modules/acceptances/components/templates/settings/points-categories/list.vue'),
                props: true,
                meta: {
                    requiredPermission: 'acceptances.point-categories.view',
                },
            },
        ],
    },
];

export default routes;
