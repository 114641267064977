import { ErrorPayload } from '@/shared/payloads/error-payload';
import { MutationTree } from 'vuex';
import { CatalogsState } from '../state/catalogs';
import { catalogsModule } from '../submodules';


export const mutationTypes = catalogsModule.mutationsTypes;

export const catalogsMutations: MutationTree<CatalogsState> = {
    [mutationTypes.UPDATE_ADD_FORM_DIALOG](state: CatalogsState, value: boolean): void {
        state.addFormDialog = value;
    },

    [mutationTypes.UPDATE_FORM_CHANGE_DIALOG](state: CatalogsState, value: boolean): void {
        state.formSendDialog = value;
    },

    [mutationTypes.UPDATE_ADD_FORM_EDITED](state: CatalogsState, value: any): void {
        state.addFormEditedItem = value;
    },

    [mutationTypes.DOWNLOAD_REQUEST](state: CatalogsState): void {
        state.downloadingItem = true;
    },

    [mutationTypes.DOWNLOAD_SUCCESS](state: CatalogsState): void {
        state.downloadingItem = false;
    },

    [mutationTypes.DOWNLOAD_ERROR](state: CatalogsState): void {
        state.downloadingItem = false;
    },

    [mutationTypes.SEND_REQUEST](state: CatalogsState): void {
        state.sendingItem = true;
    },

    [mutationTypes.SEND_SUCCESS](state: CatalogsState): void {
        state.sendingItem = false;
    },

    [mutationTypes.SEND_ERROR](state: CatalogsState, payload: ErrorPayload): void {
        state.sendingItem = false;
        state.error = payload.message ? payload.message : 'Wystąpił błąd';
        state.errorMessages = payload.errors;
    },

    [mutationTypes.PREVIEW_REQUEST](state: CatalogsState): void {
        state.previewItem = true;
    },

    [mutationTypes.PREVIEW_SUCCESS](state: CatalogsState): void {
        state.previewItem = false;
    },

    [mutationTypes.PREVIEW_ERROR](state: CatalogsState): void {
        state.previewItem = false;
    },
};
