

/**
 *  Dependencies list:
 *  - shared
 *  - module types
 */
import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';

import { Document } from '../models/document';
import { DocumentDataRequest } from '../requests/document-data-request';

/**
 *  ModuleProps instance
 *  TODO: - Change names
 */
const documentsProps = new ModuleProps('documents', {
    one: 'Dokument',
    two: 'Dokumenty',
    five: 'Dokumentów',
    accusative: 'Dokument',
});



/**
 *  Additional options.
 *  After declaration ModuleProps we can pass additional options like:
 *  - hasDialog
 *  - hasFormDialog
 *  - customStateName
 *
 *  example:
 *  documentsProps.hasDialog = false;
 *
 */
documentsProps.hasDialog = false;
documentsProps.fullscreenForm = true;


/**
 *  ModuleCreator instance.
 *  This instance create for us state, mutations, actions, and more.
 *
 *  example:
 *  From documentsModule we can retrieve actions types.
 *  documentsModule.actionsTypes
 *
 *  remember:
 *  ModuleCreator creates for us property 'module'. This property we use to
 *  declare VueX state. Check ./index.ts!
 *
 */
const documentsModule = new ModuleCreator<Document, DocumentDataRequest>(documentsProps);
documentsModule.additionalActionTypes = [
    'DOWNLOAD'
];
documentsModule.additionalMutationTypes = [
    'DOWNLOAD_REQUEST',
    'DOWNLOAD_SUCCESS',
    'DOWNLOAD_ERROR',
    'START_EDIT_DOCUMENT'
];

export { documentsModule };


