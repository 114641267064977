import { ModuleState } from '@/shared/state/template/module-state';
import { MetaItem } from '@/shared/types';
import { Consent, ConsentDataRequest } from '..';
import { ConsentVersion } from '../models/consent-version';
import { ConsentProcessesState } from './states/consent-processes';

export class ConsentsState extends ModuleState <Consent, ConsentDataRequest> {
    public currentlyViewedVersion: ConsentVersion | null = null;
    public activeVersionNumber?: number;
    public constentVersions: ConsentVersion[] = [];
    public loadingVersions = false;
    public versionsError: string = '';
    public versionsErrorMessages: { [k: string]: string } = {};
    public versionsMeta?: MetaItem;
    public processesState!: ConsentProcessesState;
    public dataTransferAgreementDialog = false;
    public requiredConsentsDialog = false;
}
