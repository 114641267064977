
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SearchInput extends Vue {
    @Prop(String) public value!: string;
    public search = '';
    public isFocused = false;

    public tooggleFocus() {
        this.isFocused = !this.isFocused;
        if (this.isFocused) {
            this.$emit('focus');
        }
        this.$forceUpdate();
    }
}
