import {ActionTree} from 'vuex';
import {RootState} from '@/shared/types';
import {setGuardianForRenter} from '@/modules/renters/shared/services/renters/renters';
import {RentersState} from './state';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {rentersModule} from './module';
import {AxiosResponse} from 'axios';
import {ErrorHandler} from '@/shared/state/template/helpers';

const actionsTypes = rentersModule.actionsTypes;

export const actions: ActionTree<RentersState, RootState> = {
    async [actionsTypes.SET_GUARD_FOR_RENTER](
        {commit},
        params: { renterId: number; guardianId: number },
    ) {
        const {renterId, guardianId} = params;
        const result: void | AxiosResponse<any> = await setGuardianForRenter(
            renterId,
            guardianId,
        ).catch((response: ErrorPayload) => ErrorHandler(
            response,
            '',
            {
                type: 'error',
                text: 'Nie zaktualizowano opiekuna',
            }),
        );

        if (result) {
            commit('SHOW_SNACKBAR', {
                type: 'success',
                text: 'Zaktualizowano opiekuna',
            });
        }

        return result;
    },
};
