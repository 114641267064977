import { ActionTree } from 'vuex';
import { RootState } from '@/shared/types';
import { ModuleState } from '@/modules/admin/shared/state/state';
import { usersActions } from '@/modules/admin/shared/state/actions/users';
import { rolesActions } from '@/modules/admin/shared/state/actions/roles';
import { subscriptionsActions } from './actions/subscriptions';
import { permissionsActions } from './actions/permissions';
/**
 * Users data admin
 */
export const actions: ActionTree<ModuleState, RootState> = {
    ...usersActions,
    ...rolesActions,
    ...subscriptionsActions,
    ...permissionsActions,
};
