import {httpClient} from '@/shared/services';

import {apiConstants} from '../../config';
import {InvoiceDataRequest} from '@/modules/invoices/shared/requests/invoice-data-request';
import {FetchParams} from '@/shared/types';
import {objectToQueryString} from '@/shared/helpers';
import {AxiosRequestConfig} from 'axios';
import {InvoiceAddressDataRequest} from '@/modules/invoices/shared/requests/invoice-address-data-request';
import {InvoiceEmailRequest} from '@/modules/invoices/shared/requests/invoice-email-request';

const fetchInvoices = (params: FetchParams) => {
    const url = apiConstants.invoices + '?' + objectToQueryString(params);

    return httpClient.get(url).then((res) => res.data);
};

const getInvoice = (id: number) => {
    const url = apiConstants.invoices + '/' + id;

    return httpClient.get(url).then((res) => res.data);
};

const downloadInvoice = (id: number) => {
    const url = apiConstants.invoices + '/' + id + '/print/pdf';
    const config: AxiosRequestConfig = {
        responseType: 'arraybuffer',
        headers: {
            Accept: 'application/pdf',
        },
    };

    return httpClient.post(url, {}, config).then((res) => res.data);
};

const downloadInvoicesList = (params: FetchParams) => {
    const url = apiConstants.invoices + '/export/' + params.location + '?' + objectToQueryString(params);
    const config: AxiosRequestConfig = {
        responseType: 'arraybuffer',
    };

    return httpClient.post(url, {}, config).then((res) => res.data);
};

const sendInvoice = (data: InvoiceEmailRequest) => {
    const url = apiConstants.invoices + '/' + data.id + '/send';

    return httpClient.post(url, data).then((res) => res.data);
};

const approveInvoice = (id: number) => {
    const url = apiConstants.invoices + '/' + id + '/approve';

    return httpClient.post(url).then((res) => res.data);
};

const updateInvoice = (id: number, data: InvoiceDataRequest) => {
    const url = apiConstants.invoices + '/' + id;

    return httpClient.put(url, data).then((res) => res.data);
};

const updateSeller = (id: number, data: InvoiceAddressDataRequest) => {
    const url = apiConstants.invoices + '/' + id + '/seller-address';

    return httpClient.post(url, data).then((res) => res.data);
};

const updateBuyer = (id: number, data: InvoiceAddressDataRequest) => {
    const url = apiConstants.invoices + '/' + id + '/buyer-address';

    return httpClient.post(url, data).then((res) => res.data);
};

const storeInvoice = (data: InvoiceDataRequest) => {
    const url = apiConstants.invoices;

    return httpClient.post(url, data).then((res) => res.data);
};

const removeInvoice = (id: number) => {
    const url = apiConstants.invoices + '/' + id;

    return httpClient.delete(url).then((res) => res.status);
};

export {
    fetchInvoices,
    getInvoice,
    storeInvoice,
    removeInvoice,
    updateInvoice,
    approveInvoice,
    downloadInvoice,
    downloadInvoicesList,
    sendInvoice,
    updateBuyer,
    updateSeller,
};
