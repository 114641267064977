const routes = [
    {
        path: '/renters',
        component: () => import(/* webpackChunkName: "rentersRoutes" */ '@/modules/renters/renters.vue'),
        children: [
            {
                path: 'notes',
                name: 'renters-notes',
                component: () =>
                    import(
                        /* webpackChunkName: "rentersRoutes" */ '@/modules/renters/components/notes/allNotesList.vue'
                    ),
                meta: {
                    requiredPermission: 'renters.notes.view',
                },
            },
            {
                path: 'notes/:idNote',
                name: 'renter-notes-details',
                component: () =>
                    import(
                        /* webpackChunkName: "rentersRoutes" */ '@/modules/renters/components/notes/allNotesList.vue'
                    ),
                meta: {
                    requiredPermission: 'renters.notes.view',
                },
            },
            {
                path: ':id',
                name: 'renter-details',
                component: () =>
                    import(/* webpackChunkName: "rentersRoutes" */ '@/modules/renters/components/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'renters.view',
                },
            },
            {
                path: ':id/notes/:idNote',
                name: 'renter-details-note-details',
                component: () =>
                    import(/* webpackChunkName: "rentersRoutes" */ '@/modules/renters/components/details.vue'),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'renters.notes.view',
                },
            },
            {
                path: 'settings/renter-types',
                name: 'renter-types',
                component: () =>
                    import(
                        /* webpackChunkName: "rentersRoutes" */ '@/modules/renters/components/settings/renter-types/list.vue'
                    ),
                meta: {
                    requiredPermission: 'renters.types.view',
                },
            },
            {
                path: '',
                name: 'renters-list',
                component: () =>
                    import(/* webpackChunkName: "rentersRoutes" */ '@/modules/renters/components/list.vue'),
                meta: {
                    requiredPermission: 'renters.view',
                },
            },
        ],
    },
];

export default routes;
