import { ModuleState } from '@/shared/state/template/module-state';
import { NotificationDataRequest } from '../requests/notification-data-request';
import { Notification } from '..';
import { NotificationGroup } from '../models/notification-group';

export default class NotificationsState extends ModuleState<Notification, NotificationDataRequest> {
    public showReminderDialog = false;
    public reminderDialogItemId: number | null = null;
    public readNotifications: NotificationGroup[] = [];
    public unreadNotifications: NotificationGroup[] = [];
    public groupsLoading: Array<{ type: string; id: number }> = [];
    public notificationsQueue: Notification[] = [];
    public newNotificationsCount = 0;
    public settingsLoading = false;
    public settingsError: { [k: string]: string } = {};
    public settingsErrorMessages: { [k: string]: string } = {};
}
