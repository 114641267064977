
import { Component } from 'vue-property-decorator';
import SearchResult from '../search-result';
import SearchDetailsItem from '../../details/details-item.vue';
import InvestmentSubjectSearchDetails from './investment-subject.vue';
import { InvestmentSubject } from '@/modules/investments/shared/models/investment-subject';
import SalesDetails from '@/shared/components/search/details/sales/sales.vue';
import SelectableTable from '@/shared/components/search/details/selectable-table.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        SearchDetailsItem,
        SelectableTable,
        InvestmentSubjectSearchDetails,
        SalesDetails,
    },
})
export default class InvestmentSearchResult extends SearchResult<InvestmentSubject> {
    public selectableRefNames = ['item-details', 'breadcrumbs'];
    public itemKey = 'investment_subject';
    public detailsList = ['acceptances', 'sales'];

    get breadcrumbs() {
        if (!this.item) {
            return [];
        }
        const baseURL = `/investments/${this.item.investment_id}`;
        return [
            {
                name: this.item.investment.name,
                link: baseURL,
            },
            {
                name: this.item.object.name,
                link: `${baseURL}?object=${this.item.investment_object_id}`,
            },
            this.item.object.parent_id
                ? {
                      name: this.item.object.parent.name,
                      link: `${baseURL}?object=${this.item.object.parent_id}`,
                  }
                : null,
        ].filter((item) => item !== null);
    }




}
