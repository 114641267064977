import {widgetsModule} from './module';
import { widgetTypesModule } from './submodules';
import {WidgetsState} from '@/modules/widgets/shared/state/state';
import {widgetsMutations, widgetTypesMutations } from '@/modules/widgets/shared/state/mutations';

widgetTypesModule.additionalMutations = widgetTypesMutations;

const widgetTypesState = widgetTypesModule.module;

widgetsModule.modules = {
    widgetTypesState,
};

widgetsModule.state = new WidgetsState();

widgetsModule.additionalMutations = widgetsMutations;

export default widgetsModule.module;
