import {httpClient} from '@/shared/services';
import {apiVersion} from '@/environment/environment';
import {DataRequest, FetchParams, ItemData} from '@/shared/types';
import {objectToQueryString} from '@/shared/helpers';
import {ModuleFetchPayload, ModuleShowPayload} from '@/shared/state/template/module-payloads';
import {AxiosResponse} from 'axios';


/**
 * Part of {@link ModuleCreator}, used in {@link ModuleActions}
 */
export class ModuleServices<T extends ItemData, R extends DataRequest> {

    /**
     * Create class of ModuleServices
     * @param location
     * @param controller
     */
    constructor(private readonly location: string) {
    }

    public fetchItems(params: FetchParams) {
        delete params.id;

        let url = `/api/v${apiVersion}${this.location}`;

        url += params && params.simple ? '/list' : '';

        const queryString = objectToQueryString(params);

        url += (params ? '?' + queryString.toString() : '');

        return httpClient.get(url).then((res: AxiosResponse<ModuleFetchPayload<T>>) => res.data);
    }

    public getItem(id: number) {
        const url = `/api/v${apiVersion}${this.location}/${id}`;

        return httpClient.get(url).then((res: AxiosResponse<ModuleShowPayload<T>>) => res.data);
    }

    public updateItem(data: R) {
        const url = `/api/v${apiVersion}${this.location}${data.id ?  '/' + data.id : ''}`;

        return httpClient.put(url, data).then((res: AxiosResponse<ModuleShowPayload<T>>) => res.data);
    }

    public storeItem(data: R) {
        const url = `/api/v${apiVersion}${this.location}`;

        return httpClient.post(url, data).then((res: AxiosResponse<ModuleShowPayload<T>>) => res.data);
    }

    public removeItem(id: number) {
        const url = `/api/v${apiVersion}${this.location}/${id}`;

        return httpClient.delete(url).then((res: AxiosResponse<ModuleShowPayload<T>>) => res.status);
    }


}
