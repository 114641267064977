
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class ListToolbar extends Vue {
    @Prop({type: Array, default: () => ['ctrl', 'alt', 'n']}) public keyShortcut!: any;
    @Prop({type: Boolean, default: true}) public addButton!: boolean;
    @Prop({type: String, default: 'Dodaj'}) public addButtonTitle!: boolean;
    @Prop(Boolean) public disabled!: boolean;
    @Prop(String) public addButtonTooltip!: string;
    public search!: string;

    public getHandlers() {
        return [...arguments].reduce((seed, item) => {
            return {...item, ...seed};
        }, {});
    }
}
