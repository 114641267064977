import {reflectKeys} from '@/shared/services';

/** Prefix for mutation types and actiontypes */
const namespacedPrefix = '[SETTINGS]';

export const mutationTypes = reflectKeys(
    [
        'SETTING_GET_REQUEST',
        'SETTING_GET_ERROR',
        'SETTING_STORE_SUCCESS',

        'FETCH_UNITS_REQUEST',
        'FETCH_UNITS_ERROR',
        'FETCH_UNITS_SUCCESS',

        'FETCH_TAX_RATES_REQUEST',
        'FETCH_TAX_RATES_ERROR',
        'FETCH_TAX_RATES_SUCCESS',

        'SEARCH_REQUEST',
        'SEARCH_RESET',
        'SEARCH_SUCCESS',
        'SEARCH_ERROR',
    ],
    namespacedPrefix,
);

/** Actions types constants */
export const actionsTypes = reflectKeys(
    [
        'FETCH_SETTINGS_DATA',
        'FETCH_UNITS',
        'FETCH_TAX_RATES',
        'UPDATE_SETTING',
        'SEARCH_ITEMS',
    ], namespacedPrefix,
);
