import { ModuleState } from '@/shared/state/template/module-state';
import { MetaItem } from '@/shared/types';
import { Consent } from '../..';
import { ConsentProcess } from '../../models/consent-process';
import { ConsentProcessItem } from '../../models/consent_process-item';
import ConsentProcessDataRequest from '../../requests/consent-process-data-request';

export class ConsentProcessesState extends ModuleState <ConsentProcess, ConsentProcessDataRequest> {
    public currentItem: ConsentProcessItem | null = null;
    public items: ConsentProcessItem[] = [];
    public loadingItems = false;
    public itemsError: string = '';
    public itemsErrorMessages: { [k: string]: string } = {};
    public itemsMeta?: MetaItem;
    public lastUpdatedItem: Consent | null = null;
}
