import {ModuleState} from '@/shared/state/template/module-state';
import {Acceptance} from '@/modules/acceptances/shared/models/acceptance';
import {AcceptanceDataRequest} from '@/modules/acceptances/shared/requests/acceptance-data-request';
import { AcceptanceNote } from '../models/acceptance-note';

export class AcceptanceState extends ModuleState<Acceptance, AcceptanceDataRequest> {
    // avoids badges refresh
    public notes: AcceptanceNote[] = [];
    public approving = [];
}
