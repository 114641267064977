import {SelectListItem} from '@/shared/models/select-list-item';
import {DataRequest, FetchParams, ItemData, MetaItem, PickerData} from '@/shared/types';

/**
 * Default state of Module
 */
export class ModuleState<T extends ItemData, R extends DataRequest> {

    /** removing item loader */
    public removing: boolean = false;

    /** removing list loader */
    public loadingList: boolean = false;

    /** true whenever fetching extra data */
    public loadingExtra: boolean = false;

    /** removing items loader */
    public loading: boolean = false;

    /** restoring item loader */
    public restoring?: boolean = false;

    /** loading item loader */
    public loadingItem: boolean = false;

    /** currently showed item  */
    public current?: T|null = {} as T;

    /** currently edited item  */
    public editedItem?: R|T = {} as R;

    /** List of data */
    public data: T[] = [];

    /** List of data list */
    public dataList: SelectListItem[] = [];

    /** List of data for pickers */
    public pickerData: T[] = [];

    /** Meta info of Pagination from API */
    public meta?: MetaItem;

    /** Meta info of Pagination from API */
    public pickerMeta?: MetaItem;

    /** form error message */
    public error: string = '';

    /** form errors */
    public errorMessages: { [k: string]: string } = {};

    /** list error message */
    public listError: string = '';

    /** list errors */
    public listErrorMessages: { [k: string]: string } = {};

    /** form dialog state */
    public formDialog: boolean = false;

    /** show dialog */
    public showDialog: boolean = false;

    /** remove dialog */
    public removeDialog: boolean = false;

    /** restore dialog */
    public restoreDialog: boolean = false;

    /** related dialog */
    public relatedDialog: boolean = false;

    /** table page */
    public page: number = 1;

    /** table fetch params */
    public fetchParams: FetchParams = {} as FetchParams;

    public pickers: PickerData[] = [];
}
