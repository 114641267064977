import {ModuleCreator} from '@/shared/state/template/module';
import {InvestmentObject} from '@/modules/investments/shared/models/investment-object';
import {InvestmentObjectDataRequest} from '@/modules/investments/shared/requests/investment-object-data-request';
import {InvestmentObjectType} from '@/modules/investments/shared/models/objects/investment-object-type';
import {DataRequest, ModuleConjugation} from '@/shared/types';
import {InvestmentObjectTypeIcon} from '@/modules/investments/shared/models/objects/investment-object-type-icon';
import {ModuleProps} from '@/shared/state/template/module-props';
import {InvestmentObjectMediaFileDataRequest} from '@/modules/investments/shared/requests/objects/investment-object-media-file-data-request';
import {InvestmentObjectMediaFile} from '@/modules/investments/shared/models/objects/investment-object-media-file';
import {OperationType} from '@/shared/state/template/module-types';

const investmentObjectMediaFilesProps = new ModuleProps('mediaFiles',
    {
        one: 'kategoria inwestycji',
        two: 'kategorie inwestycji',
        five: 'kategorii inwestycji',
        accusative: 'kategorię inwestycji',
    }, ['investments', 'objects']);

investmentObjectMediaFilesProps.parentIdInURLOperations = [OperationType.Index, OperationType.Store];

const investmentObjectsProps = new ModuleProps('objects',
    {
        one: 'kategoria inwestycji',
        two: 'kategorie inwestycji',
        five: 'kategorii inwestycji',
        accusative: 'kategorię inwestycji',
    }, ['investments']);
investmentObjectsProps.addParentProperty = true;

const investmentObjectTypesProps = new ModuleProps(
    'types',
    {
        one: 'typ kategorii',
        two: 'typy kategorii',
        five: 'typów kategorii',
    } as ModuleConjugation,
    ['investments', 'objects'],
);

const investmentObjectTypeIconsProps = new ModuleProps(
    'icons',
    {} as ModuleConjugation,
    ['investments', 'objects', 'types'],
);

investmentObjectTypeIconsProps.readOnly = true;

const investmentObjectsModule = new ModuleCreator<InvestmentObject,
    InvestmentObjectDataRequest>(investmentObjectsProps);

investmentObjectsModule.additionalMutationTypes = [
    'UPDATE_ACTIVE',
    'UPDATE_OPEN',
];

export {investmentObjectsModule};

export const investmentObjectMediaFilesModule = new ModuleCreator<InvestmentObjectMediaFile,
    InvestmentObjectMediaFileDataRequest>(investmentObjectMediaFilesProps);

export const investmentObjectTypesModule = new ModuleCreator<InvestmentObjectType,
    DataRequest>(investmentObjectTypesProps);

export const investmentObjectTypeIconsModule = new ModuleCreator<InvestmentObjectTypeIcon,
    DataRequest>(investmentObjectTypeIconsProps);
