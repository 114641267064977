import {ModuleCreator} from '@/shared/state/template/module';
import {ModuleProps} from '@/shared/state/template/module-props';
import {Ticket} from '../models/ticket';
import {TicketDataRequest} from '../requests/ticket-data-request';
import {OperationType} from '@/shared/state/template/module-types';

const ticketsProps = new ModuleProps('tickets', {
  one: 'zadanie',
  two: 'zadania',
  five: 'zadań',
});
ticketsProps.hasDialog = false;

ticketsProps.hideSnackbarOperations = [
    OperationType.Update,
];

const ticketsModule = new ModuleCreator<Ticket, TicketDataRequest>(ticketsProps);

ticketsModule.additionalMutationTypes = [
  'FETCH_BY_STATUS_REQUEST',
  'FETCH_BY_STATUS_ERROR',
  'FETCH_BY_STATUS_SUCCESS',

  'SET_ANY_DRAGGED',
];

ticketsModule.additionalActionTypes = [
  'FETCH_BY_STATUS',
];

export { ticketsModule };
