import Vue from 'vue';
import {reflectKeys} from '@/shared/services';
import {LoginPayload} from '@/modules/auth/shared/payloads/login-payload';
import {LogoutPayload} from '@/modules/auth/shared/payloads/logout-payload';
import {MutationTree, Store} from 'vuex';
import {UserPayload} from '@/modules/auth/shared/payloads/user-payload';
import {ModuleState} from '@/modules/auth/shared/state/types';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {appDebug, environment} from '@/environment/environment';
import {Environment, RootState} from '@/shared/types';

/** Prefix for mutation types and actiontypes */
const namespacedPrefix = 'AUTH';

/**
 * Mutation types
 */
export const mutationTypes = reflectKeys(
    [
        'CHECK_USER_SUCCESS',
        'CHECK_USER_REQUEST',
        'AUTH_REQUEST',
        'AUTH_ERROR',
        'LOGIN_SUCCESS',
        'LOGOUT_SUCCESS',
    ],
    namespacedPrefix,
);

/**
 * Users data mutations
 */
export const mutations: MutationTree<ModuleState> = {
    /** login success */
    [mutationTypes.CHECK_USER_REQUEST](state: ModuleState): void {
        state.menuLoading = true;
        state.error = null;
    },

    [mutationTypes.CHECK_USER_SUCCESS](state: ModuleState, payload: UserPayload): void {
        state.menuLoading = false;
        state.user = payload.data;
    },

    /** login request */
    [mutationTypes.LOGIN_REQUEST](state: ModuleState): void {
        state.loading = true;
        state.error = null;
        state.loggedIn = false;
    },

    /** login success */
    [mutationTypes.LOGIN_SUCCESS](state: ModuleState, payload: LoginPayload): void {
        localStorage.setItem('token', payload.access_token);
        localStorage.setItem('tabs', '1');

        state.loading = false;
        state.user = payload.user;
        state.loggedIn = true;
    },

    [mutationTypes.AUTH_ERROR](state: ModuleState, payload: ErrorPayload): void {
        localStorage.removeItem('token');
        state.menuLoading = false;
        state.loading = false;
        let error = '';
        switch (payload.status) {
            case 429:
                error = 'Za dużo zapytań';
                state.calming = true;
                const interval = setInterval(() => {
                    state.timer -= 1;
                }, 1000);
                setTimeout(() => {
                    state.error = '';
                    state.calming = false;
                    clearInterval(interval);
                    state.timer = 60;
                }, 60000);
                break;
            case 503:
                error = 'Brak połączenia z serwerem';
                break;
            case 500:
                error = (environment !== Environment.Production || appDebug) ? payload.message : 'Błąd serwera';
                break;
            case 403:
                if (payload.message === 'Client banned') {
                    error = 'Twoje konto jest zablokowane. Skontaktuj się z administratorem';
                } else {
                    error = 'Nieprawidłowe dane logowania';
                }
                break;
            default:
                error = 'Nieprawidłowe dane logowania';
                break;
        }
        state.error = error;
    },

    /** logout success */
    [mutationTypes.LOGOUT_SUCCESS](state: ModuleState, payload: LogoutPayload): void {
        localStorage.removeItem('token');
        state.loading = false;

        import('@/store').then((importedModule: any) => {
            const globalState = importedModule.default.state;
            globalState.consentsState.dataTransferAgreementDialog = false;
            globalState.consentsState.requiredConsentsDialog = false;
        });

    },
};
