import {HeaderFieldType} from '@/shared/types';

export const acceptancesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Data utworzenia', value: 'created_at', align: 'center', sortable: false },
    { text: 'Ostatnia aktualizacja', value: 'updated_at', align: 'center', sortable: false },
    { text: 'Status', value: 'status', align: 'right', sortable: false },
];

export const acceptanceTemplatesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const acceptanceNotesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const acceptanceTemplatesPointsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        value: 'index',
    },
    { text: 'Nazwa', value: 'name', except: true, },
    { text: 'Kategoria', value: 'acceptance_point_category', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];
export const acceptancePointsCategoriesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        value: 'index',
    },
    { text: 'Nazwa', value: 'name', },
    { text: 'Ikona', value: 'category_icon', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];
