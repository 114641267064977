import { clientModule } from './module';
import {clientActions} from '@/modules/client/shared/state/actions';
import {clientMutations} from '@/modules/client/shared/state/mutations';


clientModule.additionalActions = clientActions;
clientModule.additionalMutations = clientMutations;

const module = clientModule.module;

export default module;
