
import { Invoice } from '@/modules/invoices/shared/models/invoice';
import { Component } from 'vue-property-decorator';
import SearchResult from '../search-result';
import InvoiceSearchDetails from './invoice.vue';
import SearchDetailsItem from '../../details/details-item.vue';
import SelectableTable from '@/shared/components/search/details/selectable-table.vue';
import { callSuper } from '@/shared/helpers';
@Component({
    components: {
        SearchDetailsItem,
        SelectableTable,
        InvoiceSearchDetails,
    },
})
export default class InvoiceSearchResult extends SearchResult<Invoice> {
    public itemKey = 'invoice';
    public detailsList = ['logs', 'items'];





    public getRealName(name: string) {
        if (name === 'logs') {
            return 'invoiceLogs';
        }
        if (name === 'items') {
            return 'invoiceItems';
        } else {
            return name;
        }
    }
}
