import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { RenterType } from '../../../models/settings/renters-type';
import { RenterTypeDataRequest } from '../../../requests/settings/renters-type-data-request';

const rentersProps = new ModuleProps(
    'types',
    {
        one: 'status najemcy',
        two: 'statusy najemcy',
        five: 'statusów najemców',
    },
    ['renters'],
);

rentersProps.customStateName = 'rentersTypesModuleState';

export const rentersTypesModule = new ModuleCreator<
    RenterType,
    RenterTypeDataRequest
>(rentersProps);
