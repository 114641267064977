import {httpClient} from '@/shared/services';
import {apiConstants} from '../config';
import {AxiosRequestConfig} from 'axios';
import {CatalogEmailRequest} from '../requests/catalog-email-request';
import {CatalogDataRequest} from '../requests/catalog-data-request';

const downloadCatalog = (id: number) => {
    const url = apiConstants.catalogs + '/' + id + '/print';
    const config: AxiosRequestConfig = {
        responseType: 'arraybuffer',
        headers: {
            Accept: 'application/pdf',
        },
    };

    return httpClient.get(url, config).then((res) => res.data);
};

const previewCatalog = (data: CatalogDataRequest) => {
    const url = apiConstants.catalogs + '/preview';
    const config: AxiosRequestConfig = {
        responseType: 'arraybuffer',
        headers: {
            Accept: 'application/pdf',
        },
    };

    return httpClient.post(url, data, config).then((res) => res.data);
};

const sendCatalog = (data: CatalogEmailRequest) => {
    const url = apiConstants.catalogs + '/' + data.id + '/send';

    return httpClient.post(url, data).then((res) => res.data);
};

export {
    downloadCatalog,
    previewCatalog,
    sendCatalog,
};
