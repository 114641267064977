import {httpClient} from '@/shared/services';

import {apiConstants} from '../../config';
import {MediaFileDataRequest} from '@/modules/media/shared/requests/media-file-data-request';
import {FetchParams} from '@/shared/types';
import {objectToQueryString} from '@/shared/helpers';
import store from '@/store';
import {mutationTypes} from '@/modules/media/shared/state';
import {AxiosRequestConfig, AxiosResponse} from 'axios';

const fetchMediaFiles = (params?: FetchParams) => {
    const url = apiConstants.media + '?' + objectToQueryString(params);

    return httpClient.get(url).then((res) => res.data);
};

const getMediaFile = (id: number) => {
    const url = apiConstants.media + '/' + id;

    return httpClient.get(url).then((res) => res.data);
};

const downloadFile = (id: string) => {
    const url = '/m/' + id;

    const config: AxiosRequestConfig = {
        responseType: 'arraybuffer',
        headers: {
            'Accept': '*/*',
            'Access-Control-Allow-Origin': '*',
        },
    };

    return httpClient.get(url, config).then((res: AxiosResponse<Blob>) => res.data);
};

const storeMediaFile = (data: MediaFileDataRequest) => {

    return httpClient.post(apiConstants.media, data.formData, {
        onUploadProgress(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (percentCompleted === 100) {
                store.commit(mutationTypes.MEDIA_UPLOAD_PROGRESS, {id: data.id, loading: false});
            }
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).then((res) => res.data);
};

const updateMediaFile = (id: string, data: MediaFileDataRequest) => {
    const url = apiConstants.media + '/' + id;

    return httpClient.put(url, data).then((res) => res.data);
};

const removeMediaFile = (id: number) => {
    const url = apiConstants.media + '/' + id;

    return httpClient.delete(url).then((res) => res);
};

export {fetchMediaFiles, getMediaFile, storeMediaFile, removeMediaFile, updateMediaFile, downloadFile};
