import {Environment} from '@/shared/types';
import _ from 'lodash';

const {
    VUE_APP_API_BASE_URL,
    VUE_APP_LARAVEL_ECHO_CONFIG_BROADCASTER,
    VUE_APP_LARAVEL_ECHO_CONFIG_KEY,
    VUE_APP_LARAVEL_ECHO_CONFIG_CLUSTER,
    VUE_APP_LARAVEL_ECHO_CONFIG_AUTH_ENDPOINT,
    VUE_APP_LARAVEL_ECHO_CONFIG_FORCE_TLS,
    VUE_APP_API_VERSION = 1,
    VUE_APP_MAX_FILESIZE = 128,
    VUE_APP_FROALA_API_KEY,
    VUE_APP_G_TAG_ID,
    VUE_APP_DEBUG = false,
    VUE_APP_VERSION = 'dev',
    VUE_APP_VERSION_URL = '',
    VUE_APP_NAME,
    NODE_ENV = '',
} = process.env;

const appName = VUE_APP_NAME;
const appVersion = VUE_APP_VERSION.replaceAll('"', '');
const appVersionUrl = VUE_APP_VERSION_URL;

const apiVersion = VUE_APP_API_VERSION;
const appDebug = Boolean(VUE_APP_DEBUG);
const environment: Environment = Environment[_.startCase(NODE_ENV) as keyof typeof Environment];
const apiBaseUrl = VUE_APP_API_BASE_URL;
// const froalaApiKey = VUE_APP_FROALA_API_KEY;
const laravelEchoConfig = {
    broadcaster: VUE_APP_LARAVEL_ECHO_CONFIG_BROADCASTER,
    key: VUE_APP_LARAVEL_ECHO_CONFIG_KEY,
    cluster: VUE_APP_LARAVEL_ECHO_CONFIG_CLUSTER,
    authEndpoint: VUE_APP_LARAVEL_ECHO_CONFIG_AUTH_ENDPOINT,
    forceTLS: VUE_APP_LARAVEL_ECHO_CONFIG_FORCE_TLS === 'true',
};
const maxFileSize = Number(VUE_APP_MAX_FILESIZE) * Math.pow(2, 20);
const gTagId = VUE_APP_G_TAG_ID;

export {
    appName,
    appVersion,
    appVersionUrl,
    appDebug,
    // froalaApiKey,
    environment,
    apiBaseUrl,
    apiVersion,
    maxFileSize,
    gTagId,
    laravelEchoConfig,
};
