import { httpClient } from '@/shared/services';
import { ClientRequest } from 'http';

export const getClient = () => {
    const url = `api/v1/admin/client`;
    return httpClient.get(url).then((res) => res.data);
};

export const updateClient = (data: ClientRequest) => {
    // Binary data dont work on PUT request, but this makes it work
    const url = 'api/v1/admin/client?_method=PUT';
    return httpClient.post(url, data, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
};
