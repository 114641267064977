import { HeaderFieldType } from '@/shared/types';

export const ticketsListHeaders: HeaderFieldType[] = [
    {
        text: 'Nr',
        align: 'left',
        sortable: false,
        value: 'id',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Priorytet', value: 'priority', align: 'center' },
    { text: 'Osoba zgłaszająca', value: 'assigned_user_id', align: 'center' },
    { text: 'Osoba przypisana', value: 'reporter_id', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const priorityListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Domyślny', value: 'default', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const statusListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Domyślny', value: 'default', align: 'center' },
    { text: 'Zamykający', value: 'is_open', align: 'center' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const tagListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const categoryListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];

export const kanbanTables: HeaderFieldType[] = [
    {
        text: 'Do zrobienia', value: 'tickets'
    },
    {
        text: 'W trakcie realizacji', value: 'tickets'
    },
    {
        text: 'Ukończone', value: 'tickets'
    },
];

