const routes = [
    {
        path: '/departments',
        name: 'departments-list',
        component: () =>
            import(/* webpackChunkName: "departmentsRoutes" */ '@/modules/departments/components/list.vue'),
    },
];

export default routes;
