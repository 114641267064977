import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {Department} from '@/modules/departments/shared/models/department';
import {DepartmentDataRequest} from '@/modules/departments/shared/requests/department-data-request';


const departmentsProps = new ModuleProps('departments',
    {one: 'oddział',
        two: 'oddziały',
        five: 'oddziałów',
    });

export const departmentsModule = new ModuleCreator<Department, DepartmentDataRequest>(departmentsProps);
