import {ActionTree} from 'vuex';
import {actionsTypes, ModuleState, mutationTypes} from '@/modules/settings/shared/state';
import {RootState} from '@/shared/types';
import {SettingsDataRequest} from '@/modules/settings/shared/requests/settings-data-request';
import {SettingsPayload} from '@/modules/settings/shared/payloads/settings-payload';
import {updateSetting} from '@/modules/settings/shared/services';
import {ErrorPayload} from '@/shared/payloads/error-payload';

export const mainActions: ActionTree<ModuleState, RootState> = {
    async [actionsTypes.UPDATE_SETTING]({commit}, data: SettingsDataRequest) {
        commit(mutationTypes.SETTING_GET_REQUEST);

        const result: SettingsPayload = await updateSetting(data).catch((response: ErrorPayload) => {
            commit(mutationTypes.SETTING_GET_ERROR, response);
        });

        if (result) {
            commit(mutationTypes.SETTING_STORE_SUCCESS, result);
        }

        return result;
    },
};
