import {ActionTree} from 'vuex';
import {FetchParams, RootState} from '@/shared/types';
import {
    approveInvoice,
    downloadInvoice,
    downloadInvoicesList,
    sendInvoice,
    updateBuyer,
    updateSeller,
} from '@/modules/invoices/shared/services';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {InvoiceAddressDataRequest} from '@/modules/invoices/shared/requests/invoice-address-data-request';
import {InvoicesState} from './state';
import {mutationTypes} from './mutations/invoices';
import {invoicesModule} from './module';
import {InvoiceEmailRequest} from '@/modules/invoices/shared/requests/invoice-email-request';
import {ErrorHandler} from '@/shared/state/template/helpers';

const actionsTypes = invoicesModule.actionsTypes;

export const actions: ActionTree<InvoicesState, RootState> = {
    async [actionsTypes.UPDATE_SELLER]({commit}, data: InvoiceAddressDataRequest) {
        commit(mutationTypes.ADDRESS_UPDATE_REQUEST);

        const result = await updateSeller(data.id, data)
            .catch((response: ErrorPayload) => ErrorHandler(response, mutationTypes.ADDRESS_UPDATE_ERROR));

        if (result) {
            commit('SHOW_SNACKBAR', {type: 'success', text: 'Zaktualizowano dane '});
            commit(mutationTypes.ADDRESS_UPDATE_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.UPDATE_BUYER]({commit}, data: InvoiceAddressDataRequest) {
        commit(mutationTypes.ADDRESS_UPDATE_REQUEST);

        const result = await updateBuyer(data.id, data)
            .catch((response: ErrorPayload) => ErrorHandler(response, mutationTypes.ADDRESS_UPDATE_ERROR));

        if (result) {
            commit('SHOW_SNACKBAR', {type: 'success', text: 'Zaktualizowano fakturę'});
            commit(mutationTypes.ADDRESS_UPDATE_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.APPROVE]({commit}, id: number) {
        commit(mutationTypes.APPROVE_REQUEST);

        const result = await approveInvoice(id).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.APPROVE_ERROR, {
                type: 'error',
                text: 'Wystąpił błąd',
                permanent: true,
                errors: e.errors,
            })
        );

        if (result) {
            commit('SHOW_SNACKBAR', {type: 'success', text: 'Pomyślnie zatwierdzono fakturę'});
            commit(mutationTypes.APPROVE_SUCCESS);
        }

        return result;
    },

    async [actionsTypes.SEND_EMAIL]({commit}, data: InvoiceEmailRequest) {
        commit(mutationTypes.SEND_REQUEST);

        const result = await sendInvoice(data).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.SEND_ERROR, {
                type: 'error',
                text: 'Błąd podczas wysyłania',
                permanent: true,
                errors: e.errors,
            }),
        );

        if (result) {
            commit('SHOW_SNACKBAR', {type: 'success', text: 'Pomyślnie wysłano fakturę'});
            commit(mutationTypes.SEND_SUCCESS);
        }

        return result;
    },

    async [actionsTypes.DOWNLOAD_LIST]({commit}, params: FetchParams) {
        commit(mutationTypes.DOWNLOAD_REQUEST);

        const result: Blob = await downloadInvoicesList(params).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.DOWNLOAD_ERROR, {
                type: 'error',
                text: e.message,
                permanent: true,
                errors: e.errors,
            }),
        );

        if (result) {
            commit('SHOW_SNACKBAR', {type: 'success', text: 'Pomyślnie pobrano listę faktur'});
            commit(mutationTypes.DOWNLOAD_SUCCESS);
        }

        return result;
    },

    async [actionsTypes.DOWNLOAD]({commit}, id: number) {
        commit(mutationTypes.DOWNLOAD_REQUEST);

        const result: Blob = await downloadInvoice(id).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.DOWNLOAD_ERROR, {
                type: 'error',
                text: 'Błąd podczas pobierania',
                permanent: true,
                errors: e.errors,
            }),
        );

        if (result) {
            commit('SHOW_SNACKBAR', {type: 'success', text: 'Pomyślnie pobrano fakturę'});
            commit(mutationTypes.DOWNLOAD_SUCCESS);
        }

        return result;
    },
};
