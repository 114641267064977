import { ActionTree } from 'vuex';
import { RootState } from '@/shared/types';
import {
    downloadDocument,
} from '@/modules/documents/shared/services/documents';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { DocumentsState } from '../state/documents';
import { mutationTypes } from '../mutations/documents';
import { documentsModule } from '../module';
import { ErrorHandler } from '@/shared/state/template/helpers';

const actionsTypes = documentsModule.actionsTypes;

export const documentsActions: ActionTree<DocumentsState, RootState> = {
    // async [actionsTypes.SEND_EMAIL]({commit}, data: DocumentEmailRequest) {
    //     commit(mutationTypes.SEND_REQUEST);

    //     const result = await sendDocument(data).catch((e: ErrorPayload) =>
    //         ErrorHandler(e, mutationTypes.SEND_ERROR, {
    //             type: 'error',
    //             text: 'Błąd podczas wysyłania',
    //             permanent: true,
    //             errors: e.errors,
    //         }),
    //     );

    //     if (result) {
    //         commit('SHOW_SNACKBAR', {type: 'success', text: 'Pomyślnie wysłano fakturę'});
    //         commit(mutationTypes.SEND_SUCCESS);
    //     }

    //     return result;
    // },

    async [actionsTypes.DOWNLOAD]({ commit }, id: number) {
        commit(mutationTypes.DOWNLOAD_REQUEST);

        const result: Blob = await downloadDocument(id).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.DOWNLOAD_ERROR, {
                type: 'error',
                text: 'Błąd podczas pobierania',
                permanent: true,
                errors: e.errors,
            }),
        );

        if (result) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Pomyślnie pobrano dokument' });
            commit(mutationTypes.DOWNLOAD_SUCCESS);
        }

        return result;
    },

    // async [actionsTypes.PREVIEW]({commit}, data: DocumentDataRequest) {
    //     commit(mutationTypes.PREVIEW_REQUEST);

    //     const result: Blob = await previewDocument(data).catch((e: ErrorPayload) =>
    //         ErrorHandler(e, mutationTypes.PREVIEW_ERROR, {
    //             type: 'error',
    //             text: 'Błąd podczas pobierania',
    //             permanent: true,
    //             errors: e.errors,
    //         }),
    //     );

    //     if (result) {
    //         commit('SHOW_SNACKBAR', {type: 'success', text: 'Pomyślnie pobrano podgląd katalogu'});
    //         commit(mutationTypes.PREVIEW_SUCCESS);
    //     }

    //     return result;
    // },
};
