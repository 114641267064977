import {ActionTree} from 'vuex';
import { RootState } from '@/shared/types';
import { ModuleState } from '@/shared/state/template/module-state';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ErrorHandler } from '@/shared/state/template/helpers';
import {Consent} from '@/modules/consents/shared/models/consent';
import { consentsModule } from '../module';
import { FetchParams } from '@/shared/types';
import { fetchVersions } from '../../services/fetch-consents-versions';

export const actionTypes = consentsModule.actionsTypes;

export const mutationsTypes = consentsModule.mutationsTypes;

export const consentsActions: ActionTree<ModuleState<Consent, any>, RootState> = {
    async [actionTypes.FETCH_VERSIONS]({commit}, id: number) {
        commit(mutationsTypes.FETCH_VERSIONS_REQUEST);

        const result: any = await fetchVersions(id).catch((e: ErrorPayload) => {
            ErrorHandler(e, mutationsTypes.FETCH_VERSIONS_ERROR);
        });

        if (result.data && result.status === 200) {
            commit(mutationsTypes.FETCH_VERSIONS_SUCCESS, {res: result.data, id});
        }

        return result.data;
    },
    async [actionTypes.ON_USER_LOGGED]({ commit }, user) {
        if (!user.has_required_consents) {
            commit(mutationsTypes.SHOW_REQUIRED_CONSENTS_DIALOG);
        }
    },
};
