
import { Investment } from '@/modules/investments/shared/models/investment';
import { Component } from 'vue-property-decorator';
import SearchResult from '../search-result';
import SearchDetailsItem from '../../details/details-item.vue';
import InvestmentSearchDetails from './investment.vue';

import SalesDetails from '@/shared/components/search/details/sales/sales.vue';

import SelectableTable from '@/shared/components/search/details/selectable-table.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        SearchDetailsItem,
        SelectableTable,
        InvestmentSearchDetails,
        SalesDetails,
    },
})
export default class InvestmentSearchResult extends SearchResult<Investment> {
    public itemKey = 'investment';
    public detailsList = ['costs', 'sales', 'lands', 'visualizations'];




}
