
import DraggableTabs from '@/shared/components/elements/draggable-tabs.vue';
import SystemBar from '@/shared/components/elements/system-bar.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import _ from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import { VDialog, VMenu } from 'vuetify/lib';
import { Tutorial } from '../../shared/models/Tutorial';
import { TutorialServices } from '../../shared/TutorialServices';
import DocumentationPage from './DocumentationPage.vue';

@Component({
    components: {
        SystemBar,
        TooltipBtn,
        DocumentationPage,
        DraggableTabs,
        VMenu,
        VDialog,
    },
})
export default class Documentation extends Vue {
    @Ref('documentation') public documentation!: HTMLElement;
    public isMApp = this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm';
    public x: number = 0;
    public y: number = 0;
    public cardX: number = 0;
    public cardY: number = 0;
    public isDragged: boolean = false;

    public loading: boolean = false;
    public pagesList: any = null;
    public currentTutorial: any = null;
    public tutorialLoading = false;
    public open: Tutorial[] = [];

    // public activeTabs: Tutorial[] = [];
    // public activeTab = 0;

    public search = '';

    public switchableMenu = false;
    public showMenu = false;
    public savedPosition: { x: number; y: number } | null = null;

    // Using id as active prop in treeview for tutorial pages would break
    // because pages and tutorials are indexed by theirs own id's
    public activeItemName: string[] = [];

    public componentWidth = 0;
    public componentHeight = 0;

    public resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            const width = entry.borderBoxSize[0].inlineSize;
            this.componentResizeHandler(width);
        });
    });

    public titleMouseOver = false;

    get isDocked() {
        return this.$store.state.tutorialState.isDocked;
    }
    set isDocked(val: boolean) {
        this.$set(this.$store.state.tutorialState, 'isDocked', val);
    }

    get minimalized() {
        return this.$store.state.tutorialState.minimalized;
    }
    set minimalized(val: boolean) {
        this.$set(this.$store.state.tutorialState, 'minimalized', val);
    }

    get fullscreen() {
        return this.$vuetify.breakpoint.smAndDown;
    }

    get showLeftPanel() {
        if (!this.switchableMenu || (this.switchableMenu && this.showMenu)) {
            return true;
        } else {
            return false;
        }
    }

    get activeTabs() {
        return this.$store.state.tutorialState.activeTabs;
    }
    set activeTabs(val: Tutorial[]) {
        this.$set(this.$store.state.tutorialState, 'activeTabs', val);
    }

    get activeTab() {
        return this.$store.state.tutorialState.activeTab;
    }
    set activeTab(val: number) {
        this.$set(this.$store.state.tutorialState, 'activeTab', val);
    }

    get filterByCurrentModule() {
        return this.$store.state.tutorialState.filterByCurrentModule;
    }

    set filterByCurrentModule(val: boolean) {
        this.$set(this.$store.state.tutorialState, 'filterByCurrentModule', val);
    }

    get showDialog() {
        return this.$store.state.tutorialState.showDocumentation;
    }

    set showDialog(val: boolean) {
        this.$set(this.$store.state.tutorialState, 'showDocumentation', val);
    }

    get tutorials() {
        return this.$store.state.tutorialState.tutorials;
    }

    set tutorials(val: any[]) {
        this.$set(this.$store.state.tutorialsState, 'tutorials', val);
    }

    get visiblePages() {
        if (!this.filterByCurrentModule) {
            return this.pagesList;
        }

        return this.pagesList.filter((el: any) => {
            return el.path + (el.path.endsWith('/') ? '' : '/') === this.$route.path;
        });
    }

    public mounted() {
        this.fetchCategories();
        window.addEventListener('resize', this.windowResizeHandler);
    }

    public destroyed() {
        window.removeEventListener('resize', this.windowResizeHandler);
    }

    public dock(savePosition = true) {
        if (this.fullscreen) {
            this.minimalized = false;
            return;
        }

        if (savePosition) {
            this.savedPosition = { x: this.x, y: this.y };
        }

        this.x = window.innerWidth - this.getDockedComponentWidth() - 40;
        this.y = window.innerHeight - this.getDockedComponentHeight() - 12;
        this.isDocked = true;
    }

    public unDock() {
        if (this.fullscreen) {
            this.isDocked = false;
            this.minimalized = false;
            return;
        }

        if (this.minimalized) {
            this.minimalized = false;
            this.$nextTick(this.unDock);
            return;
        }

        if (this.savedPosition) {
            this.x = this.savedPosition.x;
            this.y = this.savedPosition.y;
            this.savedPosition = null;
        }

        this.minimalized = false;
        this.isDocked = false;
    }

    public componentResizeHandler(width: number) {
        if (width >= 1050) {
            this.switchableMenu = false;
        } else {
            this.switchableMenu = true;
            if (this.activeTabs.length === 0) {
                this.showMenu = true;
            }
        }
    }

    public windowResizeHandler() {
        if (this.isDocked && !this.minimalized) {
            this.dock();
        } else if (this.isDocked) {
            this.x = window.innerWidth - this.getDockedComponentWidth() - 40;
        }

        if (this.fullscreen) {
            this.minimalized = false;
        }
    }

    public onChoice(e: any) {
        if (e && e.length > 0) {
            this.showTutorial(e[0]);
        }
    }

    public itemInUse(itemId: number) {
        return this.activeTabs.some((el) => el.id === itemId);
    }

    public addTab(item: Tutorial) {
        this.activeTabs.push(item);
    }

    public removeTab(itemId: number) {
        this.activeTabs = this.activeTabs.filter((el) => el.id !== itemId);
    }

    public fetchCategories() {
        this.loading = true;
        TutorialServices.fetchTutorialsUrls()
            .then((res) => {
                this.pagesList = res;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    public showTutorial(item: Tutorial) {
        const index = this.activeTabs.findIndex((el) => el.id === item.id);
        if (this.activeTabs.length === 0) {
            this.addTab(item);
            this.activeTab = 0;
            this.$forceUpdate();
            return;
        }
        if (index === -1) {
            const activeTabs = _.clone(this.activeTabs);

            activeTabs[this.activeTab] = item;

            this.activeTabs = activeTabs;

            this.$forceUpdate();

            this.$nextTick(() => {
                this.$set(
                    this,
                    'activeTab',
                    this.activeTabs.findIndex((el) => el.id === item.id),
                );
            });
            return;
        }

        this.activeTab = index;
    }

    public getDockedComponentHeight() {
        return Math.min(document.documentElement.clientHeight * 0.9, 800);
    }
    public getDockedComponentWidth() {
        return Math.min(document.documentElement.clientWidth * 0.8, 1050);
    }

    @Watch('showDialog')
    public onShowDialogChange(val: boolean) {
        if (val) {
            this.minimalized = false;
            this.$nextTick(() => {
                this.resizeObserver.observe(this.documentation);
            });
        } else if (this.documentation) {
            this.resizeObserver.unobserve(this.documentation);
        }
    }

    @Watch('activeItemName')
    public onActiveItemNameChange(val: string) {
        if (val.length === 0) {
            return;
        }

        if (!!this.pagesList.find((el: Tutorial) => el.name === val[0])) {
            this.activeItemName = [''];
        }

        if (this.switchableMenu) {
            this.showMenu = false;
        }
    }

    @Watch('minimalized')
    public onminimalized(val: boolean) {
        if (this.fullscreen) {
            if (val) {
                this.minimalized = false;
            }
            return;
        }
        if (!this.isDocked) {
            this.dock();
            this.$nextTick(() => this.onminimalized(val));
        }

        if (val && this.isDocked) {
            this.y = window.innerHeight - 36;
        } else if (!val) {
            this.dock(false);
        }
    }

    @Watch('titleMouseOver')
    public onTitleMouseOver(val: boolean) {
        if (!this.isDocked) {
            return;
        }

        if (val && this.minimalized) {
            this.y = window.innerHeight - 44;
        } else if (!val && this.minimalized) {
            this.y = window.innerHeight - 36;
        } else if (val && !this.minimalized) {
            this.y = window.innerHeight - this.getDockedComponentHeight() - 8;
        } else {
            this.y = window.innerHeight - this.getDockedComponentHeight() - 12;
        }
    }

    @Watch('fullscreen')
    public onFullscreen(val: boolean) {
        this.resizeObserver.disconnect();
        this.$nextTick(() => {
            if (this.documentation) {
                this.resizeObserver.observe(this.documentation);
            }
        });
    }

    @Watch('filterByCurrentModule')
    public onFilterByCurrentModule(val: boolean) {
        if (!val) {
            return;
        }

        const open = new Set(this.open);

        this.visiblePages.forEach((element: Tutorial) => {
            open.add(element);
        });

        this.$set(this, 'open' , [...open]);
    }
}
