import {ModuleProps} from '@/shared/state/template/module-props';
import {ModuleCreator} from '@/shared/state/template/module';
import {Change} from '@/modules/changes/shared/models/change';
import {ChangeDataRequest} from '@/modules/changes/shared/requests/change-data-request';


const changesProps = new ModuleProps('changes',
    {
        one: 'zmiana lokatorska',
        two: 'zmiany lokatorskie',
        five: 'zmian lokatorskich',
        accusative: 'zmianę lokatorską',
    });

changesProps.hasDialog = false;
export const changesModule = new ModuleCreator<Change, ChangeDataRequest>(changesProps);

