
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SearchDetailsItem from '../details/details-item.vue';
import NoResults from './rows/no-results.vue';

@Component({
    components: {
        SearchDetailsItem,
        NoResults,
        CostRow: () => import('@/shared/components/search/details/rows/cost-row.vue'),
        VisualizationRow: () => import('@/shared/components/search/details/rows/visualization-row.vue'),
        AcceptanceRow: () => import('@/shared/components/search/details/rows/acceptance-row.vue'),
        LandRow: () => import('@/shared/components/search/details/rows/land-row.vue'),
        ContactRow: () => import('@/shared/components/search/details/rows/contact-row.vue'),
        CostPositionRow: () => import('@/shared/components/search/details/rows/cost-position-row.vue'),
        ContractorNoteRow: () => import('@/shared/components/search/details/rows/contractor-note-row.vue'),
        InvoiceItemRow: () => import('@/shared/components/search/details/rows/invoice-item-row.vue'),
        InvoiceLogRow: () => import('@/shared/components/search/details/rows/invoice-log-row.vue'),
        InvoiceRow: () => import('@/shared/components/search/details/rows/invoice-row.vue'),
    },
})
export default class SearchDetailsSelectableTable extends Vue {
    @Prop({
        type: Array,
        default: () => {
            return [];
        },
    })
    public headers!: any[];
    @Prop(Number) public value!: number;
    @Prop(Object) public config!: any;
    @Prop(Array) public items!: any[];
    @Prop(Number) public parentId!: number;
    @Prop(String) public parentName!: string;
    @Prop(String) public name!: string;
    @Prop(Function) public toggleItem!: any;
    @Prop(Boolean) public isActive!: boolean;
    @Prop(Boolean) public loading!: boolean;

    get parentRoute() {
        if (this.config.parentRoute) {
            return this.parseRoute(this.config.parentRoute);
        }
        return '';
    }

    public parseRoute(route: string, id?: number) {
        route = route.replace('$parentName$', this.parentName);
        route = route.replace('$parentId$', `${this.parentId}`);
        if (id) {
            route = route.replace('$id$', `${id}`);
        }
        return route;
    }

    public redirect(link: string) {
        this.$router.push(link);
        this.$emit('redirection');
    }

    get index() {
        return this.value;
    }
    set index(val: any) {
        this.$emit('input', val);
    }

    get rowComponent() {
        return this.name.slice(0, -1) + 'Row';
    }
}
