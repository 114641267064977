import {ModuleProps} from '@/shared/state/template/module-props';
import {DataRequest, ModuleConjugation} from '@/shared/types';
import {ModuleCreator} from '@/shared/state/template/module';
import {RentType} from '@/modules/rents/shared/models/rent-type';
import {Rent} from '@/modules/rents/shared/models/rent';
import {RentDataRequest} from '@/modules/rents/shared/requests/rent-data-request';
import {RentRoom} from '@/modules/rents/shared/models/rent-room';
import { RentEquipment } from '../../models/rent-equipment';
import {RentRoomType} from '@/modules/rents/shared/models/rooms/rent-room-type';
import {
    RentMediaFileDataRequest
} from '@/modules/rents/shared/requests/rent-media-file-data-request';
import {RentProperty} from '@/modules/rents/shared/models/rent-property';
import {RentMediaFile} from '@/modules/rents/shared/models/rent-media-file';
import {
    RentPropertyDataRequest
} from '@/modules/rents/shared/requests/rent-property-data-request';
import {RentTypeDataRequest} from '../../requests/rent-type-data-request';
import {RentPlan} from '@/modules/rents/shared/models/rent-plan';

import {
    RentPlanDataRequest
} from '@/modules/rents/shared/requests/rent-plan-data-request';
import {OperationType} from '@/shared/state/template/module-types';
import { RentDocumentAttachment } from '../../models/documents/rent-document-attachment';
import { RentDocumentCategory } from '../../models/documents/rent-document-category';
import { RentDocumentCategoryDataRequest } from '../../requests/rent-document-category-data-request';
import { RentDocument } from '../../models/documents/rent-document';
import { RentDocumentNote } from '../../models/documents/rent-document-note';
import { RentDocumentNoteDataRequest } from '../../requests/rent-document-note-data-request';
import { RentDocumentLog } from '../../models/documents/rent-document-log';

const rentsProps = new ModuleProps('rents', {
    one: 'najem',
    two: 'najmy',
    five: 'najmów',
    accusative: 'najmy',
});
rentsProps.loadListOnCreate = false;
rentsProps.hasDialog = false;
rentsProps.fullscreenForm = true;
rentsProps.addParentProperty = false;
rentsProps.permissionBaseName = 'rents';
rentsProps.rootParentOperations = [
    OperationType.Store,
    OperationType.Update,
    OperationType.Show,
    OperationType.Delete,
];

const rentsModule = new ModuleCreator<Rent, RentDataRequest>(rentsProps);

rentsModule.additionalMutationTypes = [
    'OPERATION_REQUEST',
    'OPERATION_ERROR',
    'OPERATION_SUCCESS',
    'OPERATION_REMOVE_CHECK_ERROR',
];

rentsModule.additionalActionTypes = [
    'COPY',
    'EDIT',
    'REMOVE',
    'COPY_CHECK',
    'EDIT_CHECK',
    'REMOVE_CHECK',
];

const rentMediaFilesProps = new ModuleProps('mediaFiles',
    {
        one: 'najem',
        two: 'najmy',
        five: 'najmów',
    }, ['rents']);

rentMediaFilesProps.parentIdInURLOperations = [OperationType.Index, OperationType.Store];

const rentTypesProps = new ModuleProps('types',
    {
        one: 'typ najmu',
        two: 'typy najmu',
        five: 'typów najmów',
    } as ModuleConjugation,
    ['rents'],
);

rentTypesProps.parentIdInURLOperations = [];

const rentPropertyTypesProps = new ModuleProps('types',
    {
        one: 'typ właściwości najmu',
        two: 'typy właściwości najmu',
        five: 'typów właściwości najmu',
    } as ModuleConjugation,
    ['rents', 'properties'],
);

const rentPropertiesProps = new ModuleProps(
    'properties',
    {
        one: 'właściwość najmu',
        two: 'właściwości najmu',
        five: 'właściwości najmu',
    } as ModuleConjugation,
    ['rents'],
);

const rentEquipmentProps = new ModuleProps('equipment',
    {
        one: 'wyposażenie',
        two: 'wyposażenie',
        five: 'wyposażenia',
    } as ModuleConjugation,
    ['rents'],
);
rentEquipmentProps.addParentProperty = true;
rentEquipmentProps.permissionBaseName = 'rents';

const rentEquipmentModule = new ModuleCreator<RentEquipment, DataRequest>(rentEquipmentProps);


const rentRoomsProps = new ModuleProps('rooms',
    {
        one: 'pomieszczenie najmu',
        two: 'pomieszczenia najmu',
        five: 'pomieszczeń najmu',
    } as ModuleConjugation,
    ['rents'],
);

rentRoomsProps.addParentProperty = true;

const rentRoomsModule = new ModuleCreator<RentRoom, DataRequest>(rentRoomsProps);

rentRoomsModule.additionalMutationTypes = [
    'UPDATE_POINT_SELECTING',
];

const rentRoomTypesProps = new ModuleProps('types',
    {
        one: 'typ pomieszczenie',
        two: 'typy pomieszczenia',
        five: 'typów pomieszczeń',
    } as ModuleConjugation,
    ['rents', 'rooms'],
);

rentRoomTypesProps.parentIdInURLOperations = [];
rentRoomTypesProps.rootParentOperations = [];

const rentPlansProps = new ModuleProps('plans',
    {
        one: 'plan',
        two: 'plany',
        five: 'planów',
    },
    ['rents'],
);

const rentDocumentsProps = new ModuleProps('documents',
    {
        one: 'Wpis',
        two: 'Wpisy',
        five: 'Wpisów',
    } as ModuleConjugation,
    ['rents'],
);

rentDocumentsProps.addParentProperty = true;
rentDocumentsProps.permissionBaseName = 'rents.documents';
rentDocumentsProps.parentIdInURLOperations =
    [OperationType.Index, OperationType.Store, OperationType.Update, OperationType.Delete, OperationType.Show];

const rentDocumentsModule = new ModuleCreator<RentDocument, DataRequest>(rentDocumentsProps);

export { rentsModule };


const rentDocumentAttachmentsProps = new ModuleProps('attachments',
    {
        one: 'Załącznik',
        two: 'Załączniki',
        five: 'Załączników',
    } as ModuleConjugation,
    ['rents', 'documents']);

rentDocumentAttachmentsProps.addParentProperty = true;
rentDocumentAttachmentsProps.permissionBaseName = 'rents.attachments';
rentDocumentAttachmentsProps.parentIdInURLOperations = [OperationType.Index,
OperationType.Store, OperationType.Update, OperationType.Delete, OperationType.Show];

const rentDocumentAttachmentsModule =
    new ModuleCreator<RentDocumentAttachment, DataRequest>(rentDocumentAttachmentsProps);

export { rentDocumentAttachmentsModule };

const rentDocumentCategoriesProps = new ModuleProps('categories',
    {
        one: 'Kategoria',
        two: 'Kategorie',
        five: 'Kategori',
    } as ModuleConjugation,
    ['rents', 'documents']);

rentDocumentCategoriesProps.permissionBaseName = 'rents.documents.categories';
rentDocumentCategoriesProps.customStateName = 'rentDocumentCategoriesState';

const rentDocumentCategoriesModule =
    new ModuleCreator<RentDocumentCategory, RentDocumentCategoryDataRequest>(rentDocumentCategoriesProps);

export { rentDocumentCategoriesModule };

const rentDocumentNotesProps = new ModuleProps('notes',
    {
        one: 'Notatka',
        two: 'Notatki',
        five: 'Notatek',
    } as ModuleConjugation,
    ['rents', 'documents']);

rentDocumentNotesProps.addParentProperty = true;
rentDocumentNotesProps.permissionBaseName = 'rents.documents.notes';
rentDocumentNotesProps.parentIdInURLOperations = [OperationType.Index,
OperationType.Store, OperationType.Update, OperationType.Delete, OperationType.Show];
rentDocumentNotesProps.customStateName = 'rentDocumentNotesState';

const rentDocumentNotesModule =
    new ModuleCreator<RentDocumentNote, RentDocumentNoteDataRequest>(rentDocumentNotesProps);

export { rentDocumentNotesModule };

const rentDocumentLogsProps = new ModuleProps('logs',
    {
        one: 'Edycja wpisu',
        two: 'Edycje wpisu',
        five: 'Edycji wpisu',
    } as ModuleConjugation,
    ['rents', 'documents']);

rentDocumentLogsProps.readOnly = true;
rentDocumentLogsProps.parentIdInURLOperations = [OperationType.Index, OperationType.Show];
rentDocumentLogsProps.customStateName = 'rentDocumentLogsState';

const rentDocumentLogsModule =
    new ModuleCreator<RentDocumentLog, {}>(rentDocumentLogsProps);

export {rentDocumentLogsModule};

export const rentMediaFilesModule = new ModuleCreator<RentMediaFile,
    RentMediaFileDataRequest>(rentMediaFilesProps);

export const rentTypesModule = new ModuleCreator<RentType,
    RentTypeDataRequest>(rentTypesProps);

export const rentPropertiesModule = new ModuleCreator<RentProperty,
    RentPropertyDataRequest>(rentPropertiesProps);

export const rentPropertyTypesModule = new ModuleCreator<RentType,
    DataRequest>(rentPropertyTypesProps);

export { rentRoomsModule };
export { rentEquipmentModule };
export { rentDocumentsModule };

export const rentRoomTypesModule = new ModuleCreator<RentRoomType,
    DataRequest>(rentRoomTypesProps);

export const rentPlansModule = new ModuleCreator<RentPlan,
    RentPlanDataRequest>(rentPlansProps);



