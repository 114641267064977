import {HeaderFieldType} from '@/shared/types';

export const contactsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Imię i nazwisko', value: 'name' },
    { text: 'Telefon', value: 'phones' },
    { text: 'E-mail', value: 'emails' },
];
