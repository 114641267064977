import { MutationTree } from 'vuex';
import {UserInviteState} from '@/modules/admin/shared/state/state/invite';
import {userInvitesModule} from '@/modules/admin/shared/state/invites';
import {ErrorPayload} from '../../../../../shared/payloads/error-payload';


export const mutationTypes = userInvitesModule.mutationsTypes;

export const userInvitesMutations: MutationTree<UserInviteState> = {
    [mutationTypes.UPDATE_RESEND_DIALOG](state: UserInviteState, value: boolean): void {
        state.resendDialog = value;
    },

    [mutationTypes.RESEND_REQUEST](state: UserInviteState): void {
        state.resendingItem = true;
        state.resendError = '';
        state.resendErrorMessages = {};
    },

    [mutationTypes.RESEND_SUCCESS](state: UserInviteState): void {
        state.resendingItem = false;
    },

    [mutationTypes.RESEND_ERROR](state: UserInviteState, payload: ErrorPayload): void {
        state.resendingItem = false;
        state.resendError = payload.message;
        state.resendErrorMessages = payload.errors;
    },
};
