
import Vue from 'vue';
import SearchInput from '@/shared/components/search/search-input.vue';
import SearchHitsList from '@/shared/components/search/search-hits-list.vue';
import { httpClient } from '@/shared/services';
import { Component, Watch } from 'vue-property-decorator';
import { moveDown } from '@/modules/changes/shared/helpers/helpers';
import InvestmentSearchResult from './results/investments/investment-search-result.vue';
import InvestmentSubjectSearchResult from './results/investment-subjects/investment-subject-search-result.vue';
import ContractorSearchResult from './results/contractors/contractor-search-result.vue';
import InvoiceSearchResult from './results/invoices/invoice-search-result.vue';
import CostSearchResult from './results/costs/cost-search-result.vue';
import { hitTypes } from '@/shared/config/search';

let controller = new AbortController();

@Component({
    components: {
        SearchInput,
        SearchHitsList,
        InvestmentSearchResult,
        InvestmentSubjectSearchResult,
        ContractorSearchResult,
        InvoiceSearchResult,
        CostSearchResult,
    },
})
export default class Search extends Vue {
    public hitClone: any = null;
    public search = '';
    public dialog = false;
    public loading = true;
    public result: any = null;
    public timeout: any = null;
    public dialogTimeout: any = null;
    public activeNavigatableContainer = 'search';
    public activeHitIndex: number = 0;
    public activeDetailIndex = 0;
    public hitNavTimeout: any = null;
    public isMobile = false;
    public showResult = false;
    public detailsKey = Math.random();

    get resultComponent() {
        if (!this.hitClone) {
            return null;
        } else {
            switch (this.hitClone.type) {
                case 'investments':
                    return 'InvestmentSearchResult';
                    break;
                case 'investment_subjects':
                    return 'InvestmentSubjectSearchResult';
                    break;
                case 'contractors':
                    return 'ContractorSearchResult';
                    break;
                case 'invoices':
                    return 'InvoiceSearchResult';
                    break;
                case 'costs':
                    return 'CostSearchResult';
                    break;
                default:
                    return null;
            }
        }
    }
    get currentHit() {
        if (!this.result || !this.result.hits) {
            return null;
        }
        return this.result.hits[this.activeHitIndex] || null;
    }

    public created() {
        document.body.addEventListener('keydown', (evt) => {
            if (evt.key === 's') {
                if (this.$router.currentRoute.meta && this.$router.currentRoute.meta.hideSearchBar === true) {
                   //
                } else {
                    this.onKeyDownHandler(evt);
                }
            }
        });
    }

    public getSuggestions() {
        if (this.loading) {
            controller.abort();
            controller = new AbortController();
        }
        this.loading = true;

        if (this.currentHit) {
            this.hitClone = this._.cloneDeep(this.currentHit);
        }
        this.result = null;

        httpClient
            .get('/api/v1/search?text=' + this.search, { signal: controller.signal })
            .then((response) => {
                this.result = response.data;
                this.hitClone = this._.cloneDeep(this.result.hits[0]);
            })
            .catch((err) => {
                if (err.status !== 503) {
                    this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
                }
            })
            .finally(() => {
                this.activeHitIndex = this.isMobile ? -1 : 0;
                this.loading = false;
            });
    }

    public onScroll(evt: Event) {
        evt.preventDefault();
        evt.stopPropagation();
    }
    public onResize() {
        this.isMobile = window.innerWidth < 960;
    }
    public getHitType(typeId: string) {
        return hitTypes.find((el) => el.id === typeId);
    }
    public onHitsFocus(evt: any) {
        if (this.activeNavigatableContainer !== 'hits') {
            this.activeNavigatableContainer = 'hits';
        }
        (this.$refs.dialog as any).$el.focus();
    }
    public onDetailsFocus(evt: any) {
        if (this.activeNavigatableContainer !== 'details') {
            this.activeNavigatableContainer = 'details';
        }
    }
    public onInsideSearchFocus(evt: any) {
        this.activeNavigatableContainer = 'search';
    }

    // Global
    public onKeyDownHandler(evt: any) {
        const activeElement = document.activeElement;
        const isAppInFullscreen = !!document.querySelector('.v-dialog.v-dialog--active');

        if (activeElement?.classList) {
            // Return if aciveElement is Froala editor
            if (activeElement.classList.contains('fr-view')) { return; }
        }

        if (isAppInFullscreen || (activeElement && ['INPUT', 'TEXTAREA'].includes(activeElement.tagName as string))) {
            return;
        }
        evt.preventDefault();
        this.$nextTick(() => {
            (this.$refs.searchInput as any).$refs.searchInput.focus();
        });
    }

    // Inside dialog
    public keyboardNavigationHandler(evt: KeyboardEvent) {
        if (this.isMobile) {
            return;
        }
        switch (evt.key) {
            case 'ArrowUp': {
                evt.preventDefault();
                evt.stopPropagation();

                this.moveUp();
                break;
            }
            case 'ArrowDown': {
                evt.preventDefault();
                evt.stopPropagation();
                this.moveDown();
                break;
            }
            case 'ArrowLeft': {
                if (this.activeNavigatableContainer === 'details') {
                    this.moveToHits();
                }
                break;
            }
            case 'ArrowRight': {
                if (this.activeNavigatableContainer !== 'details') {
                    this.moveToDetails();
                }
                break;
            }
            case 'Tab': {
                evt.preventDefault();
                evt.stopPropagation();

                this.moveToNextHit();
                break;
            }
            case 'Enter': {
                break;
            }
            default: {
                if (evt.key.length === 1 || ['Backspace'].includes(evt.key)) {
                    this.$nextTick(() => {
                        this.activeNavigatableContainer = 'search';
                        (this.$refs.searchInputInside as any).$refs.searchInput.focus();
                    });
                }
            }
        }
    }

    private moveUp() {
        switch (this.activeNavigatableContainer) {
            case 'hits': {
                if (this.hitNavTimeout) {
                    return;
                }
                this.hitNavTimeout = setTimeout(() => {
                    if (this.activeHitIndex === 0) {
                        this.$nextTick(() => {
                            (this.$refs.dialog as any).$el.blur();
                            (this.$refs.searchInputInside as any).$refs.searchInput.focus();

                            this.activeNavigatableContainer = 'search';
                        });
                    } else {
                        (this.$refs.dialog as any).$el.focus();
                        this.activeHitIndex--;
                    }
                    this.hitNavTimeout = null;
                }, 50);
                break;
            }
            case 'details':
                {
                    if (this.activeDetailIndex <= 0) {
                        this.$nextTick(() => {
                            (this.$refs.searchInputInside as any).$refs.searchInput.focus();

                            this.$forceUpdate();
                            this.activeNavigatableContainer = 'search';
                        });
                    } else {
                        this.activeDetailIndex--;
                    }
                }
                break;
        }
    }
    private moveDown() {
        switch (this.activeNavigatableContainer) {
            case 'search': {
                this.$nextTick(() => {
                    (this.$refs.searchInputInside as any).$refs.searchInput.blur();
                    (this.$refs.dialog as any).$el.focus();
                    this.activeNavigatableContainer = 'details';
                    this.activeDetailIndex = 0;
                });
                break;
            }
            case 'hits': {
                if (this.activeHitIndex === this.result.hits.length - 1) {
                    return;
                }
                if (this.hitNavTimeout) {
                    return;
                }
                this.hitNavTimeout = setTimeout(() => {
                    (this.$refs.dialog as any).$el.focus();
                    this.activeHitIndex++;
                    this.hitNavTimeout = null;
                }, 50);
                break;
            }
            case 'details': {
                this.activeDetailIndex++;
                break;
            }
        }
    }
    private moveToHits() {
        this.showResult = false;
        this.$nextTick(() => {
            (this.$refs.searchInputInside as any).$refs.searchInput.blur();
            this.activeNavigatableContainer = 'hits';
        });
    }
    private moveToNextHit() {
        if (this.hitNavTimeout || this.result.hits.length < 2) {
            return;
        }
        this.hitNavTimeout = setTimeout(() => {
            this.activeHitIndex++;
            this.$nextTick(() => {
                (this.$refs.searchInputInside as any).$refs.searchInput.blur();
                this.$forceUpdate();
                this.$nextTick(() => {
                    this.activeNavigatableContainer = 'details';
                    this.activeDetailIndex = 0;
                });
            });
            this.hitNavTimeout = null;
        }, 50);
    }
    private moveToDetails() {
        this.showResult = true;
        if (this.activeNavigatableContainer === 'search') {
            return;
        }
        this.$nextTick(() => {
            (this.$refs.searchInputInside as any).$refs.searchInput.blur();

            if (!this.activeDetailIndex && this.activeDetailIndex !== 0) {
                this.activeDetailIndex = 0;
            }

            this.activeNavigatableContainer = 'details';
        });
    }

    @Watch('currentHit')
    private onCurrentHitChange(val: any, oldVal: any) {
        if (val && !this.loading) {
            this.hitClone = this._.cloneDeep(val);
            this.detailsKey += Math.random();
        }
    }
    @Watch('search')
    private onSearchChange(val: string) {
        this.dialog = val !== '';

        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }

        if (val !== '') {
            this.timeout = setTimeout(() => {
                this.getSuggestions();
            }, 100);
        }
    }
    @Watch('dialog')
    private onDialogChange(val: boolean) {
        if (!val) {
            this.$nextTick(() => {
                this.search = '';
                this.hitClone = null;
                this.activeHitIndex = -1;
                this.activeDetailIndex = -1;
                this.result = null;

                (this.$refs.searchInput as any).$refs.searchInput.focus();
            });
        }
    }

    @Watch('activeHitIndex')
    private onActiveHitChange(val: any) {
        if ((val || val === 0) && this.result && this.result.hits.length > 0) {
            this.showResult = true;
        } else {
            this.showResult = false;
        }
    }
}
