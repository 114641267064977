import {MutationTree} from 'vuex';
import {ModuleState} from '@/modules/settings/shared/state/state';
import {mainMutations} from '@/modules/settings/shared/state/mutations/main';
import {dictionariesMutations} from '@/modules/settings/shared/state/mutations/dictionaries';
import {searchMutations} from '@/modules/settings/shared/state/mutations/search';

export const mutations: MutationTree<ModuleState> = {
    ...mainMutations,
    ...dictionariesMutations,
    ...searchMutations,
};
