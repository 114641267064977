import {MutationTree} from 'vuex';
import {investmentsModule} from '@/modules/investments/shared/state/module';
import {InvestmentGeneratorDataRequest} from '@/modules/investments/shared/requests/investment-generator-data-request';
import {Investment} from '@/modules/investments/shared/models/investment';
import {ModuleState} from '@/shared/state/template/module-state';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';

export const mutationsTypes = investmentsModule.mutationsTypes;

export const investmentsMutations: MutationTree<any> = {

    [mutationsTypes.GENERATOR_REQUEST](state: ModuleState<Investment, InvestmentGeneratorDataRequest>) {
        state.loadingItem = true;
        state.current = null;
        state.error = '';
        state.errorMessages = {};
    },

    [mutationsTypes.GENERATOR_ERROR](
        state: ModuleState<Investment, InvestmentGeneratorDataRequest>,
        payload: ErrorPayload,
    ) {
        state.error = payload.message;
        state.errorMessages = payload.errors;
        state.loadingItem = false;
    },

    [mutationsTypes.GENERATOR_SUCCESS](
        state: ModuleState<Investment, InvestmentGeneratorDataRequest>,
        payload: ModuleShowPayload<Investment>
    ) {
        state.current = payload.data;
        state.loadingItem = false;
    },
};
