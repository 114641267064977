import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { TicketCategory, TicketCategoryDataRequest } from '@/modules/tickets/shared';

const categoriesProps = new ModuleProps('categories', {
    one: 'kategoria',
    two: 'kategorie',
    five: 'kategorii',
    accusative: 'kategorię',
}, ['tickets']);

categoriesProps.customStateName = 'categoriesState';

const categoriesModule = new ModuleCreator<TicketCategory, TicketCategoryDataRequest>(categoriesProps);

export { categoriesModule };
