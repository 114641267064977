import { render, staticRenderFns } from "./form-actions.vue?vue&type=template&id=56254c6f&scoped=true&"
import script from "./form-actions.vue?vue&type=script&lang=ts&"
export * from "./form-actions.vue?vue&type=script&lang=ts&"
import style0 from "./form-actions.vue?vue&type=style&index=0&id=56254c6f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56254c6f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCol,VContainer,VRow,VSheet,VSpacer})
