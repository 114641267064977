import { ModuleProps } from '@/shared/state/template/module-props';
import { ModuleCreator } from '@/shared/state/template/module';
import { CostCategory } from '../models/cost-category';
import { CostCategoryDataRequest } from '../requests/cost-category-data-request';
import { CostAttachmentDataRequest } from '../requests/cost-attachment-data-request';
import { CostAttachment } from '../models/cost-attachment';
import CostType from '../models/cost-type';
import { CostTypeDataRequest } from '../requests/cost-type-data-request';
import { CostPosition } from '../models/cost-position';
import { CostPositionDataRequest } from '../requests/cost-position-data-request';
import { OperationType } from '@/shared/state/template/module-types';
import { CostExamplePositionDataRequest } from '../requests/cost-example-position-data-request';

const costCategoriesProps = new ModuleProps('categories', {
    one: 'kategoria',
    two: 'kategorie',
    five: 'kategorii',
    accusative: 'kategorię',
},
    ['costs']);
costCategoriesProps.customStateName = 'categoriesState';

export const costCategoriesModule = new ModuleCreator<CostCategory, CostCategoryDataRequest>(costCategoriesProps);


const costExamplePositionsProps = new ModuleProps('example-positions', {
    one: 'słownik',
    two: 'slowniki',
    five: 'słowników',
},
    ['costs']
);
costExamplePositionsProps.customStateName = 'examplePositionsState';

export const costExamplePositionsModule =
    new ModuleCreator<CostExamplePositionDataRequest, CostExamplePositionDataRequest>(costExamplePositionsProps);


const costAttachmentsProps = new ModuleProps('attachments', {
    one: 'załącznik',
    two: 'załączniki',
    five: 'załączników',
}, ['costs']);
costAttachmentsProps.customStateName = 'attachmentsState';
costAttachmentsProps.parentIdInURLOperations = [OperationType.Store, OperationType.Index];
costAttachmentsProps.hideSnackbarOperations = [OperationType.Store];

export const costAttachmentsModule = new ModuleCreator<CostAttachment, CostAttachmentDataRequest>(costAttachmentsProps);


const costTypesProps = new ModuleProps('types', {
    one: 'rodzaj',
    two: 'rodzaje',
    five: 'rodzajów',
}, ['costs']);
costTypesProps.customStateName = 'typesState';

export const costTypesModule = new ModuleCreator<CostType, CostTypeDataRequest>(costTypesProps);


const costPositionsProps = new ModuleProps('positions', {
    one: 'pozycja',
    two: 'pozycje',
    five: 'pozycji',
    accusative: 'pozycję',
}, ['costs']);
costPositionsProps.customStateName = 'positionsState';
costPositionsProps.parentIdInURLOperations = [OperationType.Store, OperationType.Index];

export const costPositionsModule = new ModuleCreator<CostPosition, CostPositionDataRequest>(costPositionsProps);
