
import {Component, Prop} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {actionsTypes} from '@/modules/media/shared/state';
import ImageInput from '@/shared/components/elements/image-input.vue';
import {errorsToArray, logger} from '@/shared/services';
import {MediaFileDataRequest} from '@/modules/media/shared/requests/media-file-data-request';
import {FileProgress} from '@/modules/media/shared/models/file-progress';
import {mediaPermissions} from '@/shared/config/media';
import { bytesToSize } from '../shared/helpers';

import PreventUnload from '@/shared/PreventUnload.vue';
import { arrayBuffer } from 'stream/consumers';
import mime from 'mime';
import { MediaFile } from '../shared/models/media-file';

@Component({
    components: {
        ImageInput,
        FormActions,
        PreventUnload
    },
})
export default class MediaPermissionEditForm extends FormComponent<MediaFile> {
    public store = this.$store.state.mediaState;
    public fetchAction = actionsTypes.FETCH_MEDIA_DATA;
    public updateAction = actionsTypes.UPDATE_MEDIA;
    public bytesToSize = bytesToSize;
    public mediaPermissions = mediaPermissions;
    public editedIndex: number = 0;
    public showWarning: boolean = false;


    get files() {
        return this.store.files;
    }

    set files(val: File[]) {
        this.store.files = val;
    }

    get filesQueue() {
        return this.$store.state.mediaState.filesQueue;
    }

    public fileLoading(name: string) {
        const item = this.filesQueue.find((e: FileProgress) => e.id === name);
        return item ? item.loading : false;
    }

    public permissionType(id: string) {
        return mediaPermissions.find(({value}) => id === value);
    }

    public fileErrorMessages(name: string) {
        return this.store.fileErrors[name] ? errorsToArray(this.store.fileErrors[name].errors) : [];
    }

    get formTitle(): string {
        return 'Edytuj plik';
    }

    public initForm() {
        this.form = this.$refs.editMediaForm;
    }

    get editedFileName() {
        return this.files[this.editedIndex] ? this.files[this.editedIndex].name : '';
    }

    public async submitForm() {
        this.busy = true;


        if (!this.valid) {
            return;
        }

        this.showWarning = false;

        this.$store.dispatch(this.updateAction,
        { id: this.itemData.id, permission_type: this.itemData.permission_type })
        .then((res) => {
            if (res && res.data) {
                this.$emit('canceled');
                this.$store.dispatch(this.fetchAction, { simple: false });
            }
        });

    }

    public created() {
        //
    }

}
