import { ActionTree } from 'vuex';
import { RootState } from '@/shared/types';
import { actionsTypes } from '@/modules/admin/shared/state/types';
import { ModuleState } from '@/modules/admin/shared/state';
import { User } from '../../models/user';
import { fetchPermissionsConfigList } from '../../services/permissions-config';

export const permissionsActions: ActionTree<ModuleState, RootState> = {
    async [actionsTypes.ON_USER_LOGGED]({ commit, rootState }, user: User) {
        fetchPermissionsConfigList().then(({ data }) => {
            (rootState as any).adminState.permissionsConfigList = data;
        });
    },
};
