import {logger, reflectKeys} from '@/shared/services';
import {ActionTree} from 'vuex';
import {FetchParams, RootState} from '@/shared/types';
import {fetchUsers, getUser, removeUser, storeUser, updateUser, restoreUser} from '@/modules/admin/shared/services';
import {mutationTypes} from '@/modules/admin/shared/state/types';
import {actionsTypes} from '@/modules/admin/shared/state/types';
import {UsersPayload} from '@/modules/admin/shared/payloads/users-payload';
import {ModuleState} from '@/modules/admin/shared/state';
import {ShowUserPayload} from '@/modules/admin/shared/payloads/show-user-payload';
import {UserDataRequest} from '@/modules/admin/shared/requests/user-data-request';
import {ErrorPayload} from '@/shared/payloads/error-payload';

/**
 * Users data admin
 */
export const usersActions: ActionTree<ModuleState, RootState> = {
    /** fetch user data */
    async [actionsTypes.FETCH_USER_DATA]({commit}, params: FetchParams) {
        commit(mutationTypes.USERS_DATA_REQUEST);

        const result: UsersPayload = await fetchUsers(params).catch((e: ErrorPayload) => {
            commit(mutationTypes.USERS_DATA_ERROR, e);
        });

        if (result) {
            commit(mutationTypes.USERS_DATA_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.SHOW_USER]({commit}, id: number) {
        commit(mutationTypes.USER_GET_REQUEST);

        const result: ShowUserPayload = await getUser(id).catch((e: ErrorPayload) => {
            commit(mutationTypes.USER_GET_ERROR, e);
        });

        if (result) {
            commit(mutationTypes.USER_GET_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.UPDATE_USER]({commit}, data: UserDataRequest) {
        commit(mutationTypes.USER_GET_REQUEST);

        const result: ShowUserPayload = await updateUser(data.id, data).catch((e: ErrorPayload) => {
            commit(mutationTypes.USER_GET_ERROR, e);
        });

        if (result) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Zaktualizowano użytkownika' });
            commit(mutationTypes.USER_GET_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.STORE_USER]({commit}, data: UserDataRequest) {
        commit(mutationTypes.USER_GET_REQUEST);

        const result: ShowUserPayload = await storeUser(data).catch((e: ErrorPayload) => {
            commit(mutationTypes.USER_GET_ERROR, e);
        });

        if (result) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Utworzono użytkownika' });
            commit(mutationTypes.USER_GET_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.REMOVE_USER]({commit}, id: number) {
        commit(mutationTypes.USER_REMOVE_REQUEST);

        const result: number|void = await removeUser(id).catch((e: ErrorPayload) => {
            logger.error(e);
            commit(mutationTypes.USER_REMOVE_ERROR, e);
        });

        if (result < 300) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Usunięto użytkownika' });
            commit(mutationTypes.USER_REMOVE_SUCCESS, result);
        }

        return result;
    },

    async [actionsTypes.RESTORE_USER]({commit}, id: number) {
        commit(mutationTypes.USER_RESTORE_REQUEST);

        const result: number|void = await restoreUser(id).catch((e: ErrorPayload) => {
            logger.error(e);
            commit(mutationTypes.USER_RESTORE_ERROR, e);
        });

        if (result < 300) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Przywrócono użytkownika' });
            commit(mutationTypes.USER_RESTORE_SUCCESS, result);
        }

        return result;
    },
};
