
import { Component, Provide, Vue, Watch } from 'vue-property-decorator';
import { httpClient, logger } from '@/shared/services';
import { actionsTypes, mutationTypes as authMutations } from '@/modules/auth/shared/state';
import { actionsTypes as settingsActions } from '@/modules/settings/shared/state';
import MainNavigation from '@/shared/components/main-navigation.vue';
import NotificationBar from '@/shared/components/dialogs/notification-bar.vue';
import IssueDialog from '@/shared/components/dialogs/issue-dialog.vue';

import ConfirmPasswordDialog from '@/modules/auth/components/confirm-password-dialog.vue';
import UserMenu from '@/shared/components/dialogs/user-menu.vue';
import Search from '@/shared/components/search/search.vue';
import NotificationBell from '@/shared/components/elements/notifications-bell.vue';
import ImageInput from '@/shared/components/elements/image-input.vue';
import { dataURItoBlob } from './shared/helpers';
import { AxiosResponse } from 'axios';
import { UserPayload } from '@/modules/auth/shared/payloads/user-payload';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ErrorHandler } from '@/shared/state/template/helpers';
import DataTransferAgreement from './modules/consents/components/dialogs/data-transfer-agreement.vue';
import RequiredConsents from './modules/consents/components/dialogs/required-consents.vue';
import echoInstance from './echo';
import LoadingScreen from './shared/components/dialogs/loading-screen.vue';
import {Environment, EnvironmentColor, LoadingScreenPayload, TestModeState} from './shared/types';
import Documentation from './modules/tutorial/components/documentation/Documentation.vue';
import TutorialDialog from './modules/tutorial/components/TutorialDialog.vue';

@Component({
    components: {
        Search,
        UserMenu,
        MainNavigation,
        NotificationBar,
        Documentation,
        IssueDialog,
        ConfirmPasswordDialog,
        LoadingScreen,
        NotificationBell,
        ImageInput,
        DataTransferAgreement,
        RequiredConsents,
        TutorialDialog,
    },
})
export default class App extends Vue {
    public drawer: boolean = window.innerWidth >= 1250;
    public userMenu = false;
    public issueData = false;
    public issueDialog = false;
    public loginOff = false;
    public formDialog = false;
    public loadingAvatar = false;
    public cropDialog = false;
    public miniVariant = true;
    public btnMenuVariant = false;
    public loadingWizardPopup = false;

    public testModeBannerCheckbox = false;
    public showTestModeBanner = false;
    public showTestModeBannerAgain = true;
    public Environment = Environment;

    get environment() {
        return this.$store.state.environment;
    }

    get version() {
        return this.$store.state.version;
    }

    get versionUrl() {
        return this.$store.state.versionUrl;
    }

    get environmentColor(): EnvironmentColor {
        switch (this.environment) {
            case Environment.Staging:
                return EnvironmentColor.Staging;
            case Environment.Testing:
                return EnvironmentColor.Testing;
            case Environment.Development:
                return EnvironmentColor.Development;
            case Environment.Production:
                return EnvironmentColor.Production;
            default:
                throw new Error(`Nieznane środowisko: ${this.environment}`);
        }
    }

    get testMode() {
        return this.$store.state.testMode;
    }

    set testMode(value: TestModeState) {
        this.$store.commit('TEST_MODE', value);
    }

    get showDocumentation() {
        return this.$store.state.tutorialState.showDocumentation;
    }

    set showDocumentation(val: boolean) {
        if (!this.isLoggedIn) {
            return;
        }
        this.$set(this.$store.state.tutorialState, 'showDocumentation', val);
    }

    get areDocsDocked() {
        return this.$store.state.tutorialState.isDocked && this.showDocumentation;
    }

    get menuLoading() {
        return this.$store.state.authState.menuLoading;
    }

    get isLoggedIn() {
        return this.$store.getters.isLoggedIn;
    }

    get loading() {
        return this.$store.state.authState.loading;
    }

    get loadingScreen() {
        return this.$store.state.loadingScreen;
    }

    get authenticated() {
        return this.isLoggedIn && this.$route.name !== 'login';
    }

    get navMiniVariant() {
        return this.$store.state.navigationBarVariant;
    }

    set navMiniVariant(val: boolean) {
        this.$store.commit('UPDATE_NAVIGATION_BAR_VERSION', val);
    }

    get user() {
        return this.$store.state.authState.user;
    }

    get switchingTestMode(): boolean {
        return this.$store.state.switchingTestMode;
    }

    set switchingTestMode(value: boolean) {
        this.$store.commit('SWITCHING_TEST_MODE', value);
    }

    get testModeLoadingScreen(): LoadingScreenPayload {
        return {
            loadingScreen: this.switchingTestMode,
            loadingSubtitle: `Trwa ${
                !this.testMode || this.testMode === TestModeState.Unactive ? 'wyłączanie' : 'wczytywanie'
            } trybu testowego`,
            loadingTitle: 'Poczekaj chwilę',
        } as LoadingScreenPayload;
    }

    get wizardPopup() {
        return this.user ? this.user.first_login : false;
    }

    set wizardPopup(value) {
        this.loadingWizardPopup = true;
        httpClient
            .put('/api/v1/user', {
                first_login: value,
            })
            .then((response) => {
                if (response.data) {
                    this.$store.commit(authMutations.CHECK_USER_SUCCESS, response.data);
                }
                this.loadingWizardPopup = false;
            });
    }

    get formTooltips() {
        return this.$store.state.authState.user.form_tooltips;
    }

    set formTooltips(value) {
        this.$store
        .dispatch(settingsActions.UPDATE_SETTING, {
            key: 'form_tooltips_' + this.user.id,
            value,
        })
        .then((response) => {
            if (response.success) {
                this.$store.state.authState.user.form_tooltips = value;
                const snackbarText = 'Wskazówki w formularzach ' + (value ? 'włączone' : 'wyłączone');
                this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: snackbarText });

            }
        });
    }

    get isLight() {
        return !this.$vuetify.theme.dark;
    }

    set isLight(value) {
        this.$store
            .dispatch(settingsActions.UPDATE_SETTING, {
                key: 'dark_theme_' + this.user.id,
                value: !value,
            })
            .then((response) => {
                if (response.success) {
                    this.$vuetify.theme.dark = !value;
                    localStorage.setItem('dark_theme', String(!value));
                }
            });
    }

    public openIssueDialog(issueData?: any) {
        this.issueDialog = true;
        this.issueData = {
            ...issueData,
        };
    }

    get showDataTransferAgreementDialog() {
        return this.$store.state.consentsState.dataTransferAgreementDialog;
    }

    set showDataTransferAgreementDialog(val: boolean) {
        this.$set(this.$store.state.consentsState, 'dataTransferAgreementDialog', val);
    }

    get showRequiredConsentsDialog() {
        return this.$store.state.consentsState.requiredConsentsDialog;
    }

    set showRequiredConsentsDialog(val: boolean) {
        this.$set(this.$store.state.consentsState, 'requiredConsentsDialog', val);
    }

    public imageCropped(cropped: string) {
        const formData = new FormData();

        formData.append('avatar_photo', cropped ? dataURItoBlob(cropped, 'avatar.png') : '');

        this.loadingAvatar = true;
        httpClient
            .post('/api/v1/user', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    _method: 'PUT',
                },
            })
            .then((response: AxiosResponse<UserPayload>) => response.data)
            .then((response: UserPayload) => {
                this.loadingAvatar = false;
                if (response.data) {
                    this.$store.commit(authMutations.CHECK_USER_SUCCESS, response);
                    this.cropDialog = false;
                }
            })
            .catch((response: ErrorPayload) => {
                this.loadingAvatar = false;
                ErrorHandler(response, authMutations.CHECK_USER_ERROR);
            });
    }

    public closeCropDialog() {
        this.cropDialog = false;
    }

    public created() {
        this.$vuetify.theme.dark = localStorage.getItem('dark_theme') === 'true';
        localStorage.setItem('tabs', String(Number(localStorage.getItem('tabs') || 0) + 1));
        this.menuVariantCheck();
        window.onunload = () => {
            localStorage.setItem('tabs', String(Number(localStorage.getItem('tabs')) - 1));
        };

        if ((window as any).cordova && !localStorage.getItem('hostname')) {
            this.$router.push('/auth/hostname');
        }

        if (this.authenticated) {
            this.$store.dispatch(actionsTypes.CHECK_USER).then((res) => {
                if (!res) {
                    this.$router.push('/auth/login').catch((err) => logger.error(err));
                } else if (this.$router.currentRoute.name === 'login') {
                    this.$router.push({ name: 'home' }).catch((err) => logger.error(err));
                } else {
                    this.$vuetify.theme.dark = this.user.dark_theme;
                    localStorage.setItem('dark_theme', String(this.user.dark_theme));
                }

                if (this.user.test_mode === TestModeState.Switching) {
                    this.switchingTestMode = true;
                }
            });
        }
        const userWatcher = this.$watch(
            'user',
            (val) => {
                if (!val) {
                    return;
                }

                echoInstance
                    .channel(`client.${this.$store.state.authState.user.client_id}`)
                    .listen('.switching_test_mode', ({ switchingTo }: { switchingTo: TestModeState }) => {
                        this.testMode = switchingTo;
                        this.switchingTestMode = true;
                        this.$store.commit('LOADING_SCREEN', this.testModeLoadingScreen);
                    })
                    .listen('.switched_test_mode', ({ switchingTo }: { switchingTo: TestModeState }) => {
                        this.testMode = switchingTo;
                        this.switchingTestMode = false;
                        this.$store.commit('LOADING_SCREEN', this.switchingTestMode);
                        this.$store.dispatch(actionsTypes.CHECK_USER).finally(() => {
                            this.switchingTestMode = false;
                            this.testMode = switchingTo;
                        });
                    });

                userWatcher();
            },
            { immediate: true },
        );
    }


    public closeTestModeBanner() {
        if (this.testModeBannerCheckbox) {
            localStorage.setItem('showTestModeBannerAgain', 'false');
            this.showTestModeBannerAgain = false;
        }

        this.showTestModeBanner = false;
    }

    @Provide()
    public permissionCheck(...permissions: string[]) {
        if (!this.user || !this.user.permissions) {
            return false;
        }

        for (const actionName of permissions) {
            if (!this.user.permissions.includes(actionName)) {
                return false;
            }
        }

        return true;
    }

    public logoutUser() {
        this.userMenu = false;
        this.$store
            .dispatch(actionsTypes.LOGOUT_USER)
            .then(() => {
                this.$router.push('/auth/login').catch((err) => logger.error(err));
            })
            .catch((err) => logger.error(err));
    }

    public menuVariantCheck() {
        this.miniVariant =
            window.innerWidth >= 1264
                ? localStorage.getItem('menu_mini_variant') !== null
                    ? localStorage.getItem('menu_mini_variant') === 'true'
                    : true
                : false;
        this.btnMenuVariant = window.innerWidth >= 1264;
        this.navMiniVariant = window.innerWidth >= 1264 ? this.miniVariant : true;
    }

    public menuVariantSet() {
        this.navMiniVariant = !this.miniVariant;
        localStorage.setItem('menu_mini_variant', this.miniVariant ? 'false' : 'true');
        this.miniVariant = !this.miniVariant;
    }

    @Watch('testMode', { immediate: true })
    public onTestModeChange(val: string) {
        this.showTestModeBannerAgain = localStorage.getItem('showTestModeBannerAgain') === 'true';

        if (val === 'active') {
            this.showTestModeBanner = !!this.showTestModeBannerAgain;
        } else {
            this.showTestModeBanner = false;
        }
    }
}
