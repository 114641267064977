import {mdiChevronLeft, mdiChevronRight} from '@mdi/js';

export class FooterProps {
    public nextIcon: string = mdiChevronRight;
    public prevIcon: string = mdiChevronLeft;
    public disableItemsPerPage?: boolean = true;
    public disablePagination?: boolean = true;
    public itemsPerPageText?: string;

    constructor(public itemsPerPageOptions: number[] = [10, 25, 50]) {
    }
}
