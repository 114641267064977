import {MutationTree} from 'vuex';
import {investmentObjectsModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentObject} from '@/modules/investments/shared/models/investment-object';
import {InvestmentObjectsState} from '@/modules/investments/shared/state/state';

export const mutationsTypes = investmentObjectsModule.mutationsTypes;

export const objectsMutations: MutationTree<InvestmentObjectsState> = {
    [mutationsTypes.UPDATE_ACTIVE](state: InvestmentObjectsState, value: InvestmentObject[]): void {
        state.activeObject = value;
    },

    [mutationsTypes.UPDATE_OPEN](state: InvestmentObjectsState, value: InvestmentObject[]): void {
        state.openObject = value;
    },
};
