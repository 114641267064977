import { ModuleState } from '@/shared/state/template/module-state';
import { Document } from '../../models/document';
import { DocumentDataRequest } from '../../requests/document-data-request';

export class DocumentsState extends ModuleState<Document, DocumentDataRequest> {
    // public addFormDialog: boolean = false;
    // public formSendDialog: boolean = false;
    // public addFormEditedItem: any = {};
    public downloadingItem: boolean = false;
    public editedDocuments: any[] = [];
    // public sendingItem: boolean = false;
    // public previewItem: boolean = false;
}
