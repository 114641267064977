import { httpClient } from '@/shared/services';

import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import {SubscriptionsMeta} from '@/modules/admin/shared/models/subscriptions/subscriptions-meta';
import {Subscription} from '@/modules/admin/shared/models/subscriptions/subscription';
import {AxiosResponse} from 'axios';

export const getSubscriptions = () => {
    const url = `/api/v1/subscriptions`;
    return httpClient.get(url).then(
        (response: AxiosResponse<ModuleFetchPayload<Subscription, SubscriptionsMeta>>) => response.data
    );
};
