import {ActionTree} from 'vuex';
import { RootState } from '@/shared/types';
import { ModuleState } from '@/shared/state/template/module-state';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ErrorHandler } from '@/shared/state/template/helpers';
import {Sale} from '@/modules/sales/shared/models/sale';
import { salesModule } from '../module';
import { fetchByStatus } from '@/modules/sales/shared/services/fetch-by-status';

export const actionTypes = salesModule.actionsTypes;

export const mutationsTypes = salesModule.mutationsTypes;

export const salesActions: ActionTree<ModuleState<Sale, any>, RootState> = {
    async [actionTypes.FETCH_BY_STATUS]({commit}, id: number) {
        commit(mutationsTypes.FETCH_BY_STATUS_REQUEST);

        const result: any = await fetchByStatus(id).catch((e: ErrorPayload) => {
            ErrorHandler(e, mutationsTypes.FETCH_BY_STATUS_ERROR);
        });

        if (result.data && result.status === 200) {
            commit(mutationsTypes.FETCH_BY_STATUS_SUCCESS, {res: result.data, id});
        }
        return result;
    }
};
