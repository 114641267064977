
/**
 *  Place where we should assign sub-modules VueXstate to main-module VueXstate
 */
import { documentsActions } from './actions/documents';
import { documentsModule } from './module';
import { documentsMutations } from './mutations/documents';
import { documentTemplatesMutations } from './mutations/documentTemplates';
import { DocumentsState } from './state/documents';
import { DocumentTemplatesState } from './state/documentTemplates';
import { documentCategoriesModule, documentTagsModule, documentTemplatesModule } from './submodules';



documentsModule.additionalActions = documentsActions;
documentsModule.additionalMutations = documentsMutations;
documentsModule.state = new DocumentsState();

documentTemplatesModule.additionalMutations = documentTemplatesMutations;
documentTemplatesModule.state = new DocumentTemplatesState();

const categoriesState = documentCategoriesModule.module;
const tagsState = documentTagsModule.module;
const templatesState = documentTemplatesModule.module;
/**
 * This module we will use to declare state. Check ../../index.ts and @/store.ts!
 */

const module = documentsModule.module;
module.modules = {
    categoriesState,
    tagsState,
    templatesState,
};


export default module;


