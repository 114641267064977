import Vue from 'vue';

import {logger} from '@/shared/services/app-logger/app-logger';
import store from '@/store';
import { environment } from '@/environment/environment';
import {Environment} from '@/shared/types';

Vue.config.errorHandler = (err, vm, info) => {
    if (environment !== Environment.Production) {
        logger.error(err);
        store.commit('VUE_ERROR', err);
    }

    logger.logToServer(err);
};

window.onerror = (message, source, lineno, colno, error) => {
    logger.logToServer(error);
};
