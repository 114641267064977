import {
    mdiFile,
    mdiFileCode,
    mdiFileDocument,
    mdiFileExcel,
    mdiFileImage,
    mdiFileKey,
    mdiFileMusic,
    mdiFilePdfBox,
    mdiFileTable,
    mdiFileVideo,
    mdiFileWord,
    mdiZipBox,
} from '@mdi/js';

export const getIcon = (ext: string) => {
    switch (true) {
        case /(doc|docx)/.test(ext):
            return mdiFileWord;
        case /odt/.test(ext):
            return mdiFileDocument;
        case /zip/.test(ext):
            return mdiZipBox;
        case /(gif|jpe?g|bmp|png)/.test(ext):
            return mdiFileImage;
        case /(mp4|m4v|3g2|3gp|ogv|mpg|avi|mov|wmv)/.test(ext):
            return mdiFileVideo;
        case /(mp3)/.test(ext):
            return mdiFileMusic;
        case /pdf/.test(ext):
            return mdiFilePdfBox;
        case /(xls|xlsx)/.test(ext):
            return mdiFileExcel;
        case /(csv)/.test(ext):
            return mdiFileTable;
        case /(xml)/.test(ext):
            return mdiFileCode;
        case /(xades)/.test(ext):
            return mdiFileKey;
        default:
            return mdiFile;
    }
};


export const bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
        return '0 Byte';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};
