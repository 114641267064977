import { render, staticRenderFns } from "./DocumentationPage.vue?vue&type=template&id=39e32444&"
import script from "./DocumentationPage.vue?vue&type=script&lang=ts&"
export * from "./DocumentationPage.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentationPage.vue?vue&type=style&index=0&id=39e32444&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCard,VCardText,VCardTitle,VDialog,VExpandTransition,VImg,VProgressLinear,VSheet})
