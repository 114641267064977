import {MutationTree} from 'vuex';
import {investmentSubjectsModule} from '@/modules/investments/shared/state/submodules';
import {InvestmentSubjectsState} from '@/modules/investments/shared/state/state';
import {ProspectDataRequest} from '@/modules/investments/shared/requests/prospect-data-request';

export const mutationsTypes = investmentSubjectsModule.mutationsTypes;

export const subjectsMutations: MutationTree<InvestmentSubjectsState> = {
    [mutationsTypes.UPDATE_PROSPECT_EDITED](state: InvestmentSubjectsState, newVal: ProspectDataRequest) {
        state.editedProspect = newVal;
    },

    [mutationsTypes.UPDATE_PROSPECT_FORM_DIALOG](state: InvestmentSubjectsState, newVal: boolean) {
        state.prospectFormDialog = newVal;
    },
};
