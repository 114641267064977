import {MetaItem, Names} from '@/shared/types';
import {SelectListItem} from '@/shared/models/select-list-item';
import moment, {Moment} from 'moment';
import { VueClass } from 'vue-class-component/lib/declarations';
import { logger } from './services';

export const objectToQueryString = (obj: any, prefix?: string): string => {
    return obj === null || obj === undefined
        ? ''
        : Object.keys(obj as object)
              .map((objKey) => {
                  if (obj.hasOwnProperty(objKey)) {
                      const key = prefix ? `${prefix}[${objKey}]` : objKey;
                      const value = obj[objKey];

                      return typeof value === 'object'
                          ? objectToQueryString(value, key)
                          : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
                  }

                  return null;
              })
              .join('&');
};

export const parseMoment = (date: string | Date): Moment => moment(date, 'YYYY-MM-DD HH:mm:ss');

export const parseDate = (date: string | Date, format?: string): string => {
    if (date) {
        return moment(date, 'YYYY-MM-DD').format(format ? format : 'YYYY-MM-DD');
    }

    return '';
};

export const uniqueId = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
};

export const parseDateTime = (date: string | Date, format?: string): string => {
    if (date) {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format(format ? format : 'YYYY-MM-DD HH:mm:ss');
    }

    return '';
};

export const parsePrice = (price?: number, currency: string | null = 'PLN'): string => {
    if (!price) {
        return '0,00' + (currency ? ' ' + currency : '');
    }

    return (
        price.toLocaleString('pl-PL', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) +
        ' ' +
        (currency ? ' ' + currency : '')
    );
};

export const indexData = (data: any, meta?: MetaItem) => {
    if (meta) {
        for (let i = 0; i < data.length; i++) {
            data[i].index = meta.from + i;
        }
        return data;
    } else {
        return data;
    }
};

export const sortItemsTree = (data: SelectListItem[]) => {
    const tree: any[] = [];

    const getChildren = (item: SelectListItem, level: number = 0) => {
        const children: SelectListItem[] = item.children || [];

        if (children && children.length > 0) {
            for (const child of children) {
                const newItem: any = child;

                newItem.level = level;

                tree.push(newItem);
                getChildren(newItem, level + 1);
            }
        }
    };

    for (const item of data) {
        if (!item.parent_id) {
            tree.push(item);
            getChildren(item, 1);
        }
    }

    return tree;
};

export const phraseInitials = (text: string) => {
    return text
        .split(' ')
        .map((n) => n[0])
        .join('');
};

export const dataURItoBlob = (dataURI: string, name: string) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
    } else {
        byteString = unescape(dataURI.split(',')[1]);
    }
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], name, { type: mimeString });
};

export const showFile = (blob: Blob) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], {type: 'application/pdf'});

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    window.open(data);
};

export const datesCompare = (date1: string, date2: string = '') => {
    const first = moment(date1, 'DD.MM.YYYY');
    const second = date2 !== '' ? moment(date2, 'DD.MM.YYYY') : moment();

    return first > second;
};

export const numeralVariations =
    (value: number, numerals: Names, wovalue?: boolean) => {
        const t0 = value % 10;
        const t1 = value % 100;
        const vo = [];
        if (!wovalue) {
            vo.push(value);
        }
        if (value === 1 && numerals.one) {
            vo.push(numerals.one);
        } else if ((value === 0 ||
                (t0 >= 0 && t0 <= 1) ||
                (t0 >= 5 && t0 <= 9) ||
                (t1 > 10 && t1 < 20))
            && numerals.five) {
            vo.push(numerals.five);
        } else if (((t1 < 10 || t1 > 20) && t0 >= 2 && t0 <= 4) && numerals.two) {
            vo.push(numerals.two);
        }
        return vo.join(' ');
    };


export const snakeToPascal = (value: string) => {
    return value
        .split('/')
        .map((snake: string) =>
            snake
                .split('_')
                .map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
                .join(''),
        )
        .join('');
};

export const callSuper = (that: any, methodName: string, ...args: any[]) => {
    try {
        return that.constructor.super.options.methods[methodName].call(that, ...args);
    } catch (err) {
        logger.error(err);
     }
};

export let isMApp = (val: string) => {
    switch (val) {
        case 'xs':
            return true;
        case 'sm':
            return true;
        case 'md':
            return false;
        case 'lg':
            return false;
        case 'xl':
            return false;
    }
};
