import {HeaderFieldType} from '@/shared/types';

export const mediaListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa pliku', value: 'filename' },
    { text: 'Typ uprawnień', value: 'permission_type' },
    { text: 'Dodany przez', value: 'owner.name' },
    { text: 'Rozmiar pliku', value: 'size' },
    { text: 'Data dodania', value: 'created_at' },
    { text: 'Akcje', value: 'actions', align: 'center', sortable: false },
];
