import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { authState } from '@/modules/auth';
import { adminState, ordersState } from '@/modules/admin';
import { investmentsState } from '@/modules/investments';
import { rentsState } from '@/modules/rents';
import { rentcostsState } from './modules/rent-costs';
import { RootState } from '@/shared/types';
// import { contactsState } from '@/modules/contacts';
import { departmentsState } from '@/modules/departments';
import { changesState } from '@/modules/changes';
import { widgetsState } from '@/modules/widgets';
import { settingsState } from '@/modules/settings';
import { mutations } from '@/shared/state';
import { contractorsState } from '@/modules/contractors';
import { invoicesState } from '@/modules/invoices';
import { mediaState } from '@/modules/media';
import { visualizationsState } from '@/modules/visualizations';
import {personnelState} from '@/modules/personnel';
// import {tasksState} from '@/modules/tasks-lists';
// import {valuationsState} from '@/modules/valuations';
import { salesState } from '@/modules/sales';

import { tutorialState } from '@/modules/tutorial/shared/tutorialStore';
import { ticketsState } from '@/modules/tickets';
import { acceptancesState } from '@/modules/acceptances';
import { costsState } from '@/modules/costs';
import { documentsState } from '@/modules/documents';
import { landsState } from '@/modules/lands';
import { clientState} from '@/modules/client';
import { notificationsState } from '@/modules/notifications';
import { calendarState } from '@/modules/calendar';
import { consentsState } from './modules/consents';
import { wizardState} from '@/modules/wizard';
import { rentersState } from '@/modules/renters';
import {appVersion, appVersionUrl, environment} from '@/environment/environment';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    state: {
        version: appVersion,
        versionUrl: appVersionUrl,
        environment,
        snackbars: [],
        err: null,
        hideNavigationBar: false,
        navigationBarVariant: true,
        loadingSubtitle: null,
        loadingTitle: null,
        loadingScreen: false,
        testMode: null,
        switchingTestMode: false,
    },
    mutations,
    modules: {
        authState,
        adminState,
        ordersState,
        investmentsState,
        // contactsState,
        settingsState,
        departmentsState,
        contractorsState,
        invoicesState,
        mediaState,
        tutorialState,
        changesState,
        visualizationsState,
        widgetsState,
        clientState,
        // tasksState,
        personnelState,
        // valuationsState,
        acceptancesState,
        ticketsState,
        salesState,
        costsState,
        documentsState,
        landsState,
        notificationsState,
        calendarState,
        wizardState,
        consentsState,
        rentsState,
        rentersState,
        rentcostsState,
    },
};

export default new Vuex.Store<RootState>(store);
