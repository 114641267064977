import Vue from 'vue';
import {MutationTree} from 'vuex';
import {ModuleState} from '@/modules/admin/shared/state/state';
import {UsersPayload} from '@/modules/admin/shared/payloads/users-payload';
import {mutationTypes} from '@/modules/admin/shared/state/types';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {ShowUserPayload} from '@/modules/admin/shared/payloads/show-user-payload';
import {indexData} from '@/shared/helpers';
import {UserDataRequest} from '@/modules/admin/shared/requests/user-data-request';

/**
 * Users data admin
 */
export const usersMutations: MutationTree<ModuleState> = {
    /** users data request */
    [mutationTypes.USERS_DATA_REQUEST](state: ModuleState): void {
        Vue.set(state, 'loading', true);
        Vue.set(state, 'users', []);
        Vue.set(state, 'listError', null);
        Vue.set(state, 'listErrorMessages', {});
    },

    /** users data success */
    [mutationTypes.USERS_DATA_SUCCESS](state: ModuleState, payload: UsersPayload): void {
        Vue.set(state, 'loading', false);
        Vue.set(state, 'users', indexData(payload.data, payload.meta));
        Vue.set(state, 'meta', payload.meta);
    },

    /** users data error */
    [mutationTypes.USERS_DATA_ERROR](state: ModuleState, payload: ErrorPayload): void {
        Vue.set(state, 'loading', false);
        Vue.set(state, 'users', []);
        Vue.set(state, 'listError', payload.message);
        Vue.set(state, 'listErrorMessages', payload.errors);
    },

    [mutationTypes.USER_GET_REQUEST](state: ModuleState): void {
        Vue.set(state, 'loadingItem', true);
        Vue.set(state, 'current', null);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
    },

    [mutationTypes.USER_GET_SUCCESS](state: ModuleState, payload: ShowUserPayload): void {
        Vue.set(state, 'loadingItem', false);
        Vue.set(state, 'current', payload.data);
    },

    [mutationTypes.USER_GET_ERROR](state: ModuleState, payload: ErrorPayload): void {
        Vue.set(state, 'loadingItem', false);
        Vue.set(state, 'current', null);
        Vue.set(state, 'error', payload.message);
        Vue.set(state, 'errorMessages', payload.errors);
    },

    [mutationTypes.USER_REMOVE_REQUEST](state: ModuleState): void {
        Vue.set(state, 'removing', true);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
    },

    [mutationTypes.USER_REMOVE_ERROR](state: ModuleState, payload: ErrorPayload): void {
        Vue.set(state, 'removing', false);
        Vue.set(state, 'error', payload.message);
        Vue.set(state, 'errorMessages', payload.errors);
    },

    [mutationTypes.USER_REMOVE_SUCCESS](state: ModuleState, payload: number): void {
        Vue.set(state, 'removing', false);
    },

    [mutationTypes.USER_RESTORE_REQUEST](state: ModuleState): void {
        Vue.set(state, 'restoring', true);
        Vue.set(state, 'error', null);
        Vue.set(state, 'errorMessages', {});
    },

    [mutationTypes.USER_RESTORE_ERROR](state: ModuleState, payload: ErrorPayload): void {
        Vue.set(state, 'restoring', false);
        Vue.set(state, 'error', payload.message);
        Vue.set(state, 'errorMessages', payload.errors);
    },

    [mutationTypes.USER_RESTORE_SUCCESS](state: ModuleState, payload: number): void {
        Vue.set(state, 'restoring', false);
    },
};
