<template>
    <v-card>
        <v-card-title class="text-center mb-5 d-block">
            Przycinanie zdjęcia
        </v-card-title>
        <v-card-subtitle v-if="imageName" class="mt-1">
            <b>{{ imageName }}</b>
        </v-card-subtitle>
        <v-card-text>
            <v-container class="pa-0">
                <v-row v-if="uploadArea">
                    <v-col>
                        <v-file-input
                            label="Wybierz zdjęcie"
                            v-model="file"
                            class="pr-2"
                            prepend-icon="mdi-camera"
                            accept="image/*"
                            :error-messages="error"
                            @change="setImage"
                            clearable
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-row>
                            <v-col style="position: relative;" class="pa-auto">
                                <v-fade-transition>
                                    <v-overlay
                                        v-if="!cropperState"
                                        class="ma-3"
                                        :style="{background: `url(${imgSrc}) no-repeat`, backgroundSize: 'cover'}"
                                        absolute
                                    >
                                        <div v-if="imgSrc" class="pa-2 text-center">
                                            <v-progress-circular size="64" indeterminate/>
                                            <p class="pt-5">Wczytywanie</p>
                                        </div>
                                        <div v-else>
                                            Wybierz zdjęcie
                                        </div>
                                    </v-overlay>
                                </v-fade-transition>
                                <vue-cropper
                                    :style="{opacity: Number(cropperState), }"
                                    ref="cropper"
                                    :ready="cropperReady"
                                    :cropend="updateDimensions"
                                    class="cropper"
                                    :aspect-ratio="uploadArea ? 1 : (aspectRatioChained ? imageAspectRatio : 0)"
                                    :max-container-width="200"
                                    :max-container-height="200"
                                    :view-mode="1"
                                    :src="imgSrc"
                                    preview=".preview"
                                    data-cy="cropper"
                                />
                            </v-col>
                            <v-col v-if="!uploadArea && !hideSizes" class="text-center">
                                <v-text-field v-model="imageWidth"
                                              @change="imageHeight = aspectRatioChained ? Math.round(imageWidth / imageAspectRatio) : imageHeight"
                                              label="Szerokość obrazu"></v-text-field>
                                <v-btn class="chain" @click="changeAspectRatioChain" icon>
                                    <v-icon>{{ aspectRatioChained ? "mdi-link" : "mdi-link-off" }}</v-icon>
                                </v-btn>
                                <v-text-field class="mt-5" v-model="imageHeight"
                                              @change="imageWidth = aspectRatioChained ? Math.round(imageHeight * imageAspectRatio) : imageWidth"
                                              label="Wysokość obrazu"></v-text-field>
                                <v-select class="mt-10" :value="1" label="Proporcje" :disabled="!aspectRatioChained"
                                          v-model="imageAspectRatio" @change="updateAspectRatio" :items="aspectRatios"
                                          item-text="name" item-value="value"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-center">
                                <v-icon
                                    class="ma-1 ma-sm-2 ml-3"
                                    @click="reset"
                                >
                                    mdi-aspect-ratio
                                </v-icon>
                                <v-icon
                                    class="ma-1 ma-sm-2"
                                    @click="rotate(-90)"
                                >
                                    mdi-rotate-left
                                </v-icon>
                                <v-icon
                                    class="ma-1 ma-sm-2"
                                    @click="rotate(90)"
                                >
                                    mdi-rotate-right
                                </v-icon>
                                <v-icon
                                    class="ma-1 ma-sm-2"
                                    @click="flipY"
                                >
                                    mdi-flip-vertical
                                </v-icon>
                                <v-icon
                                    class="ma-1 ma-sm-2"
                                    @click="flipX"
                                >
                                    mdi-flip-horizontal
                                </v-icon>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-btn
                        :disabled="loading"
                        block
                        @click="clear"
                        color="error"
                        v-if="srcImg"
                    >
                        Usuń
                    </v-btn>
                    <v-btn
                        :disabled="loading"
                        block
                        @click="close"
                        :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'my-0' : 'my-2'"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        :loading="loading"
                        block
                        :disabled="loading || (!imgSrc && !cropperReady)"
                        color="primary"
                        @click="cropImage"
                        data-cy="sendphoto"
                    >Prześlij
                    </v-btn>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
    name: 'ImageInput',
    props: {
        srcImg: {
            type: [
                Object,
                String,
            ],
        },
        imageName: String,
        uploadArea: {
            type: Boolean,
            default: true,
        },
        loading: Boolean,
        hideSizes: Boolean,
        defaultImageWidth: Number,
        defaultImageHeight: Number,
        defaultAspectRatio: Number,
        skipClosed: Boolean,
        aspectRatios: {
            type: Array,
            default: () => [
            {
                name: 'Oryginalne',
                value: 0,
            },
            {
                name: '1:1',
                value: 1,
            },
            {
                name: '16:9',
                value: (16 / 9),
            },
            {
                name: '4:3',
                value: 4 / 3,
            },
        ],
        }
    },
    components: {
        VueCropper,
    },
    data: () => ({
        error: '',
        imgSrc: '',
        imgCrp: '',
        cropperState: false,
        aspectRatioChained: true,
        imageAspectRatio: 1,
        imageWidth: 0,
        imageHeight: 0,
        scale: 0,
        file: null,
    }),
    methods: {
        cropImage() {
            const cropImg = this.canvasData(
                this.defaultImageWidth || this.imageWidth,
                this.defaultImageHeight || this.imageHeight
            );
            if (cropImg) {
                this.$emit('saved', cropImg.toDataURL());
                this.scale = 0;
                if (!this.skipClosed) {
                    this.close();
                }
            } else {
                this.cropImage();
            }
        },
        clear() {
            this.$emit('saved', null);
            if (!this.skipClosed) {
                this.close();
            }
        },
        close() {
            if (this.imgSrc !== this.srcImg) {
                this.changeImage(this.srcImg);
            }
            this.cropperState = false;
            this.aspectRatioChained = true;
            this.file = null;

            this.$emit('closed');
        },
        flipX() {
            const scale = this.scale ? -this.scale : -1;
            this.$refs.cropper.scaleX(scale);
            this.scale = scale;
        },
        flipY() {
            const scale = this.scale ? -this.scale : -1;
            this.$refs.cropper.scaleY(scale);
            this.scale = scale;
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        cropperReady() {
            this.cropperState = true;
            if (!this.uploadArea) {
                this.updateProps();
            }
        },
        reset() {
            this.$refs.cropper.reset();
        },
        changeAspectRatioChain() {
            this.aspectRatioChained = !this.aspectRatioChained;
            this.updateAspectRatio();
        },
        updateAspectRatio() {
            this.$refs.cropper.setAspectRatio(
                this.uploadArea ? 1 : (this.aspectRatioChained ? this.imageAspectRatio : 0),
            );
        },
        updateDimensions() {
            this.imageHeight = this.data().height;
            this.imageWidth = this.data().width;
        },
        updateProps() {
            this.aspectRatios[0].value = this.defaultAspectRatio || this.imageData().aspectRatio;
            this.imageAspectRatio = this.defaultAspectRatio || this.imageData().aspectRatio;
            this.imageWidth = this.imageData().naturalWidth;
            this.imageHeight = this.imageData().naturalHeight;
            this.$refs.cropper.setAspectRatio(this.defaultAspectRatio || this.imageData().aspectRatio);
        },
        changeImage(image) {
            this.imgSrc = image;
            if (this.$refs.cropper) {
                this.$refs.cropper.replace(image);
            }
        },
        setImage(file) {
            this.error = '';
            if (!file) {
                this.error = 'Nie wybrano zdjęcia';
                return;
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.changeImage(event.target.result);
                };
                reader.readAsDataURL(file);
            } else {
                this.error = 'Sorry, FileReader API not supported';
            }
        },
        imageData() {
            return this.cropperState && this.$refs.cropper ? this.$refs.cropper.getImageData() : null;
        },
        canvasData(width, height) {
            return this.cropperState && this.$refs.cropper ? this.$refs.cropper.getCroppedCanvas({
                width,
                height,
            }) : null;
        },
        data() {
            return this.cropperState && this.$refs.cropper ? this.$refs.cropper.getData(true) : null;
        },
    },
    watch: {
        srcImg: {
            handler(value) {
                this.changeImage(value);
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
    .v-btn {
        width: 100%;
        margin: 8px 0 8px 0;
    }

    .cropper {
        max-width: 200px;
        max-height: 200px;
        margin-left: auto;
        margin-right: auto;
    }
}

.cropper {
    width: 400px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
}

.chain {
    transform: rotate(-90deg);
}
</style>
