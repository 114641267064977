const routes = [
    {
        path: '/visualizations',
        component: () => import(/* webpackChunkName: "visualizationsRoutes" */ '@/modules/visualizations/visualizations.vue'),
        children: [
            {
                path: '',
                name: 'visualizations-list',
                component: () => import(/* webpackChunkName: "visualizationsRoutes" */ '@/modules/visualizations/components/list.vue'),
                meta: {
                    requiredPermission: 'visualizations.view',
                },
            },
            {
                path: ':id',
                name: 'visualization-details',
                component: () =>
                    import(
                        /* webpackChunkName: "visualizationsRoutes" */ '@/modules/visualizations/components/details.vue'
                        ),
                props: (query: any) => {
                    return {
                        id: String(query.params.id),
                    };
                },
                meta: {
                    requiredPermission: 'visualizations.view',
                },
            },
        ],
    },

];

export default routes;
