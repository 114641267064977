// @ts-ignore-next-line
import Echo from 'laravel-echo';
import { httpClient } from './shared/services/http-client/http-client';

// @ts-ignore
import Pusher from 'pusher-js';
import { laravelEchoConfig } from './environment/environment';

const config: any = {
    ...laravelEchoConfig,
    authorizer: (channel: any , options: any) => {
        return {
            authorize: (socketId: string, callback: (arg0: boolean, arg1: any) => void) => {
                httpClient.post(options.authEndpoint, {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                .then((response) => {
                    callback(false, response.data);
                })
                .catch((error) => {
                    callback(true, error);
                });
            }
        };
    },
};

const echoInstance =  new Echo({...config, client: new Pusher(config.key, config)});

httpClient.interceptors.request.use((interceptorConfig) => {
    interceptorConfig.headers!['X-Socket-ID'] = echoInstance.socketId();
    return interceptorConfig;
});

export default echoInstance;
