
import {Component, Inject, Prop, Vue, Watch} from 'vue-property-decorator';
import {User} from '@/modules/auth/shared/models/user';
import MenuItem from '@/shared/components/elements/menu-item.vue';
import MenuListGroup from '@/shared/components/elements/menu-list-group.vue';
import TestModeSwitch from './elements/test-mode-switch.vue';

@Component({
    components: {MenuListGroup, MenuItem, TestModeSwitch},
})
export default class MainNavigation extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    public isMApp = this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm';
    @Prop(Boolean) public loading!: boolean;
    @Prop(Boolean) public miniVariant!: boolean;

    get testMode() {
        return this.$store.state.testMode === 'active';
    }

    get currentRouteName() {
        return this.$route.name;
    }

    get user(): User {
        return this.$store.state.authState.user;
    }

    get isAdmin() {
        return this.user ? this.$store.state.authState.user.is_admin : false;
    }

    get modules() {
        return this.user ? this.$store.state.authState.user.modules : [];
    }

    get showDocumentation() {
        return this.$store.state.tutorialState.showDocumentation;
    }

    set showDocumentation(val: boolean) {
        this.$set(this.$store.state.tutorialState, 'showDocumentation', val);
    }

    get containerStyle() {
        return `max-height: calc(100% - ${this.testMode ? '245px' : '200px'})`;
    }

    public current: string[] = [];
    public areModulesPermitted: boolean[] = [];
    public categories = [
        {
            name: 'admin',
            text: 'Administracja',
            icon: 'mdi-badge-account-horizontal-outline',
            route: {name: 'admin'},
            routes: [
                {
                    name: 'users',
                    text: `Użytkownicy`,
                    route: {name: 'users-list'},
                },
                {
                    name: 'invites',
                    text: `Zaproszenia`,
                    route: {name: 'invites-list'},
                },
                {
                    name: 'users',
                    text: `Role użytkowników`,
                    route: {name: 'users-roles'},
                },
                {
                    name: 'subscriptions',
                    text: 'Subskrypcje',
                    route: {name: 'subscriptions-list'},
                },
                {
                    name: 'orders',
                    text: 'Lista transakcji',
                    route: {name: 'orders-list'},
                },
                {
                    name: 'developer-profile',
                    text: 'Profil Firmy',
                    route: {name: 'developer-profile'},
                    permissionName: 'admin.client.edit',
                },
                {
                    name: 'footers-and-headers',
                    text: 'Stopki i nagłówki',
                    route: {name: 'footers-and-headers-form'},
                    permissionName: 'admin.client.edit',
                    hideOnMobile: true,
                },
                {
                    name: 'consents',
                    text: 'RODO',
                    route: {name: 'consents'},
                    permissionName: 'consents.view',
                    items: [
                        {
                            name: 'processes',
                            text: `Lista procesów`,
                            permissionName: 'consents.processes.view',
                            route: {name: 'consents-processes-list'},
                        },
                        {
                            name: '',
                            text: `Lista zgód`,
                            permissionName: 'consents.view',
                            route: {name: 'consents-list'},
                        },
                        {
                            name: 'data-transfer-agreement',
                            text: 'Powierzenie przetwarzania danych',
                            route: {name: 'data-transfer-agreement'},
                            permissionName: 'admin.client.edit',
                        },
                    ],
                },
            ],
        },
        {
            text: 'Finanse',
            icon: 'mdi-bank-outline',
            routes: [
                {
                    name: 'invoices',
                    text: 'Faktury',
                    available: this.isModuleAvailable('invoices'),
                    route: {name: 'invoices-list'},
                },
                {
                    name: 'costs',
                    text: 'Koszty Inwestycji',
                    available: this.isModuleAvailable('investments'),
                    items: [
                        {
                            name: '',
                            text: `Lista kosztów`,
                            route: {name: 'costs-list'},
                        },
                    ],
                    settings: [
                        {
                            name: 'example-positions',
                            text: 'Słowniki pozycji',
                            route: {name: 'costs-example-positionts-list'},
                        },
                        {
                            name: 'types',
                            text: 'Rodzaje',
                            route: {name: 'costs-types-list'},
                        },
                        {
                            name: 'categories',
                            text: 'Kategorie',
                            route: {name: 'costs-categories-list'},
                        },
                    ],
                },
            ],
        },
        {
            name: 'investments',
            text: 'Inwestycje',
            icon: 'mdi-hand-coin-outline',
            route: {name: 'investments'},
            routes: [
                {
                    name: '',
                    text: `Generator`,
                    route: {name: 'investment-generator', params: {step: '1'}},
                    permissionName: 'investments.generator',
                },
                {
                    name: '',
                    text: 'Lista Inwestycji',
                    route: {name: 'investments-list'},
                },
                {
                    name: 'subjects',
                    text: 'Lista Przedmiotów',
                    route: {name: 'subjects-list'},
                },
                /*{
                name: 'prospects',
                text: 'Prospekt informacyjny',
                route: { name: 'prospects-list' },
                permissionName: 'investments.subjects.generate-prospect',
            },*/
                {
                    name: 'lands',
                    text: 'Lista gruntów',
                    route: {name: 'lands-list'},
                    permissionName: 'lands.view',
                },
                {
                    name: 'valuations',
                    text: 'Wyceny',
                    available: this.isModuleAvailable('valuations'),
                    route: {name: 'valuations-list'},
                    permissionName: 'valuations.view',
                },
            ],
            settings: [
                {
                    name: 'tags',
                    text: 'Tagi',
                    route: {name: 'setting-tags'},
                },
                {
                    name: 'objects',
                    text: 'Kategorie',
                    route: {name: 'setting-objects'},
                    permissionName: 'investments.objects.types.view'
                },
                {
                    name: 'item-properties',
                    text: 'Właściwości przedmiotów',
                    route: {name: 'setting-items-properties'},
                    permissionName: 'investments.subjects.properties.view',
                },
                {
                    name: 'items',
                    text: 'Przedmioty',
                    route: {name: 'setting-items'},
                    permissionName: 'investments.subjects.view',
                },
                {
                    name: 'rooms',
                    text: 'Pomieszczenia',
                    route: {name: 'setting-rooms'},
                    permissionName: 'investments.subjects.rooms.view',
                },
                {
                    name: 'subjects.prospect-settings',
                    text: 'Pola Prospektu',
                    route: {name: 'prospect-settings'},
                    permissionName: 'investments.subjects.prospect-settings.view',
                },
            ],
        },
        {
            text: 'Budowa',
            icon: 'mdi-account-hard-hat-outline',
            routes: [
                {
                    name: 'tickets',
                    text: 'Zadania',
                    items: [
                        {
                            name: '',
                            text: `Lista zadań`,
                            route: {name: 'tickets-list'},
                        },
                    ],
                    settings: [
                        {
                            name: 'statuses',
                            text: 'Statusy',
                            route: {name: 'statuses-list'},
                        },
                        {
                            name: 'priorities',
                            text: 'Priorytety',
                            route: {name: 'priorities-list'},
                        },
                        {
                            name: 'tags',
                            text: 'Tagi',
                            route: {name: 'tags-list'},
                        },
                        {
                            name: 'categories',
                            text: 'Kategorie',
                            route: {name: 'tickets-categories-list'},
                        },
                    ],
                },
                {
                    name: 'acceptances',
                    text: 'Odbiory',
                    available: this.isModuleAvailable('investments'),
                    items: [
                        {
                            name: '',
                            text: `Protokoły odbioru`,
                            route: {name: 'acceptances-list'},
                        },
                        {
                            name: 'templates',
                            text: `Szablony protokołów`,
                            route: {name: 'acceptance-templates-list'},
                        },
                    ],
                    settings: [
                        {
                            name: 'points',
                            text: 'Punkty szablonu',
                            route: {name: 'acceptances-settings-points'},
                        },
                        {
                            name: 'point-categories',
                            text: 'Kategorie punktów',
                            route: {name: 'acceptances-settings-points-categories'},
                        },
                    ],
                },
            ],
        },
        {
            text: 'Organizacja',
            icon: 'mdi-clipboard-file-outline',
            routes: [
                {
                    name: 'documents',
                    text: 'Dokumenty',
                    items: [
                        {
                            name: '',
                            text: `Lista Dokumentów`,
                            route: {name: 'documents-list'},
                        },
                        {
                            name: 'templates',
                            text: `Szablony Dokumentów`,
                            route: {name: 'documents-templates-list'},
                        },
                    ],
                    settings: [
                        {
                            name: 'categories',
                            text: 'Kategorie',
                            route: {name: 'documents-categories-list'},
                        },
                        {
                            name: 'tags',
                            text: 'Tagi',
                            route: {name: 'documents-tags-list'},
                        },
                    ],
                },
                {
                    name: 'media',
                    text: 'Pliki',
                    skipPermission: true,
                    available: this.isModuleAvailable('media'),
                    route: {name: 'media-list'},
                },
                {
                    name: 'calendar',
                    text: 'Kalendarz',
                    skipPermission: true,
                    available: this.isModuleAvailable('calendar'),
                    route: {name: 'calendar-list'},
                },

                {
                    name: 'contractors',
                    text: 'Kontrahenci',
                    available: this.isModuleAvailable('contractors'),
                    items: [
                        {
                            name: '',
                            text: `Lista kontrahentów`,
                            route: {name: 'contractors-list'},
                        },
                        {
                            name: 'notes',
                            text: `Lista notatek`,
                            route: {name: 'contractor-notes'},
                        },
                    ],
                    settings: [
                        {
                            name: 'contractor-types',
                            text: 'Typy kontrahentów',
                            route: {name: 'contractors-types'},
                            permissionName: 'contractors.types.view',
                        },
                        {
                            name: 'position-types',
                            text: 'Stanowiska',
                            route: {name: 'position-types'},
                            permissionName: 'contractors.contacts.positions.view',
                        },
                        {
                            name: 'contractor-specializations',
                            text: 'Specjalizacje kontrahentów',
                            route: {name: 'contractors-specializations'},
                            permissionName: 'contractors.specializations.view',
                        },
                    ],
                },
                // {
                //     name: 'files',
                //     text: 'Media',
                //     // available: this.isModuleAvailable('media'),
                //      available: true,
                //     route: {name: 'media-list'},
                // },
                /*{
                name: 'contacts',
                text: 'Kontakty',
                available: this.isModuleAvailable('contacts'),
                route: { name: 'contacts-list' },
            },*/
            ],
        },
        {
            text: 'Sprzedaż',
            icon: 'mdi-storefront-outline',
            routes: [
                {
                    name: 'sales',
                    text: 'Sprzedaż',
                    available: this.isModuleAvailable('sales'),
                    items: [
                        {
                            name: '',
                            text: `Leady sprzedażowe`,
                            route: {name: 'sales-list'},
                        },
                    ],
                    settings: [
                        {
                            name: 'statuses',
                            text: 'Statusy',
                            route: {name: 'sale-statuses-list'},
                        },
                        {
                            name: 'task-lists',
                            text: 'Listy zadań',
                            route: {name: 'sale-tasks-lists-list'},
                            permissionName: 'sales.tasks-lists.view',
                        },
                    ],
                },
                {
                    name: 'visualizations',
                    text: 'Wizualizacje',
                    available: this.isModuleAvailable('visualizations'),
                    route: {name: 'visualizations-list'},
                },
                {
                    name: 'changes',
                    text: 'Zmiany Lokatorskie',
                    available: this.isModuleAvailable('investments'),
                    items: [
                        {
                            name: '',
                            text: `Lista zmian`,
                            route: {name: 'changes-list'},
                        },
                        {
                            name: 'categories',
                            text: 'Kategorie',
                            route: {name: 'categories-list'},
                        },
                        {
                            name: 'catalogs',
                            text: 'Katalogi',
                            route: {name: 'catalogs-list'},
                        },
                    ],
                },
            ],
        },
        {
            name: 'rents',
            text: 'Najmy',
            icon: 'mdi-home-city-outline',
            badge: true,
            route: {name: 'rents'},
            routes: [
                {
                    name: 'rentcosts',
                    text: 'Koszty',
                    skipPermission: true,
                    items: [
                        {
                            name: 'rent-costs-list',
                            text: 'Lista Kosztów',
                            route: {name: 'rent-costs-list'},
                            permissionName: 'rentcosts.view',
                        },
                    ],
                    settings: [
                        {
                            text: 'Kategorie',
                            route: {name: 'rent-costs-categories-list'},
                            permissionName: 'rentcosts.categories.view',
                        },
                        {
                            text: 'Rodzaje',
                            route: {name: 'rent-costs-types-list'},
                            permissionName: 'rentcosts.types.view',

                        },
                        {
                            text: 'Słownik pozycji',
                            route: {name: 'rent-costs-example-positionts-list'},
                            permissionName: 'rentcosts.example-positions.view',

                        },
                        {
                            text: 'Słownik wystawców',
                            route: {name: 'rent-costs-example-issuers-list'},
                            permissionName: 'rentcosts.example-issuers.view',

                        },
                    ]
                },
                {
                    name: '',
                    text: 'Przedmioty',
                    items: [
                        {
                            name: '',
                            text: `Lista Przedmiotów`,
                            route: {name: 'rents-list'},
                            permissionName: 'rents.view',

                        },
                    ],
                    settings: [
                        {
                            text: 'Pomieszczenia',
                            route: {name: 'rent-setting-rooms'},
                            permissionName: 'rents.rooms.types.view',
                        },
                        {
                            text: 'Właściwości',
                            route: {name: 'rent-setting-properties'},
                            permissionName: 'rents.properties.types.view',
                        },
                        {
                            text: 'Typy',
                            route: {name: 'rent-setting-types'},
                            permissionName: 'rents.types.view',
                        },
                    ]
                },
                {
                    name: 'renters',
                    text: 'Najemcy',
                    permissionName: 'rents.view',
                    items: [
                        {
                            name: 'renters-list',
                            text: 'Lista Najemców',
                            route: {name: 'renters-list'},
                            permissionName: 'rents.view',
                        },
                        {
                            name: 'renters-notes',
                            text: 'Lista Notatek',
                            route: {name: 'renters-notes'},
                            permissionName: 'rents.view',
                        },
                    ],
                    settings: [
                        {
                            text: 'Typy',
                            route: {name: 'renter-types'},
                            skipPermission: true,
                        },
                    ]
                },
            ],

        },
        {
            text: 'Kadry',
            name: 'personnel',
            icon: 'mdi-account-multiple',
            skipPermission: true,
            routes: [
                {
                    name: 'personnel-employees',
                    text: 'Pracownicy',
                    skipPermission: true,
                    route: {name: 'employees-list'},
                },
            ],
            settings: [
                {
                    skipPermission: true,
                    name: 'personnel-employment-types',
                    text: 'Formy zatrudnienia',
                    route: {name: 'employment-types-list'},
                },
                {
                    skipPermission: true,
                    name: 'personnel-roles',
                    text: 'Funkcje',
                    route: {name: 'roles-list'},
                },
            ],
        },
    ];

    @Watch('$route', {immediate: true})
    public onUrlChange(Route: any) {
        const routeParts = Route.path.split('/');
        this.current = routeParts.length > 0 ? routeParts : [];
    }

    public async mounted() {
        this.waitForPermissions(this.setupAreModulesPermitted);
    }

    public setupAreModulesPermitted(): void {
        this.areModulesPermitted = this.categories.map((category: any) => {
            if (category.hasOwnProperty('route')) {
                const itemsToCheck = [...(category.hasOwnProperty('routes') ? category.routes : [])];
                return itemsToCheck.map((item: any) => {
                    return this.isLinkPermitted(item, category);
                });
            }
            if (category.routes !== undefined) {
                return category.routes.map((route: any) => {
                    if (route.hasOwnProperty('route')) {
                        return this.isLinkPermitted(route);
                    }
                    const itemsToCheck = [...(route.hasOwnProperty('items') ? route.items : [])];
                    for (const item of itemsToCheck) {
                        if (this.isLinkPermitted(item, route)) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            return [false];
        });
        this.areModulesPermitted = this.areModulesPermitted.map((category: any) => {
            if (category.indexOf(true) !== -1) {
                return category;
            }
            return false;
        });
    }

    public isLinkPermitted(item: any, route?: any) {
        const permissionName = item.hasOwnProperty('permissionName')
            ? item.permissionName
            : `${route ? route.name + '.' : ''}${item.name ? item.name + '.' : ''}view`;
        if (
            item.hideOnMobile &&
            (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm')
        ) {
            return false;
        }
        return item.skipPermission ? item.skipPermission : this.permissionCheck(permissionName);
    }

    public isModuleAvailable(module: string) {
        this.$nextTick(() => {
            return this.modules.includes(module);
        });
    }

    public areSettingsAvailable(items: any, route?: any) {
        items = items.map((item: any) => {
            return this.isLinkPermitted(item, route);
        });
        if (items.indexOf(true) !== -1) {
            return true;
        }
        return false;
    }

    public isRouteOpen(routeData: any) {
        if (routeData.route && routeData.route.name === this.currentRouteName) {
            return true;
        }
        if (routeData.items) {
            for (const item of routeData.items) {
                if (item.route && item.route.name && item.route.name === this.currentRouteName) {
                    return true;
                }
            }
        }
        if (routeData.settings) {
            for (const item of routeData.settings) {
                if (item.route && item.route.name && item.route.name === this.currentRouteName) {
                    return true;
                }
            }
        }
        if (Array.isArray(routeData)) {
            for (const item of routeData) {
                if (item.route && item.route.name && item.route.name === this.currentRouteName) {
                    return true;
                }
            }
        }
        return false;
    }

    private waitForPermissions(cb: () => void) {
        if (this.user && this.user.permissions.length > 0) {
            cb();
        } else {
            setTimeout(() => {
                this.waitForPermissions(cb);
            }, 100);
        }
    }
}
