
import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';
import MenuItem from '@/shared/components/elements/menu-item.vue';

@Component({
    components: {MenuItem},
    props: {
        available: Boolean,
        value: Boolean,
        to: Object,
        isAdmin: Boolean,
        subGroup: Boolean,
        disabled: Boolean,
    },
})
export default class MenuListGroup extends Vue {
    //
}
