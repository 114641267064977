import { ModuleCreator } from '@/shared/state/template/module';
import { ModuleProps } from '@/shared/state/template/module-props';
import { Land } from '../models/land';
import { LandDataRequest } from '@/modules/lands/shared';

const landProps = new ModuleProps('lands', {
  one: 'grunt',
  two: 'grunty',
  five: 'gruntów',
});

landProps.fullscreenForm = true;

const landModule = new ModuleCreator<Land, LandDataRequest>(landProps);

export {landModule};
