import { ActionTree } from 'vuex';
import { RootState } from '@/shared/types';
import { ErrorPayload } from '@/shared/payloads/error-payload';
import { ErrorHandler } from '@/shared/state/template/helpers';
import {userInvitesModule} from '@/modules/admin/shared/state/invites';
import {UserInviteState} from '@/modules/admin/shared/state/state/invite';
import {mutationTypes} from '@/modules/admin/shared/state/mutations/invites';
import {resendInvite} from '../../services/user-invites';

const actionsTypes = userInvitesModule.actionsTypes;

export const userInvitesActions: ActionTree<UserInviteState, RootState> = {
    async [actionsTypes.RESEND]({ commit }, id: number) {
        commit(mutationTypes.RESEND_REQUEST);

        const result: Blob = await resendInvite(id).catch((e: ErrorPayload) =>
            ErrorHandler(e, mutationTypes.RESEND_ERROR, {
                type: 'error',
                text: 'Błąd podczas ponownego wysyłania',
                permanent: true,
                errors: e.errors,
            }),
        );

        if (result) {
            commit('SHOW_SNACKBAR', { type: 'success', text: 'Pomyślnie wysłano zaproszenie' });
            commit(mutationTypes.RESEND_SUCCESS);
        }

        return result;
    },
};
