import {HeaderFieldType} from '@/shared/types';

export const costsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {
        text: 'Nr',
        align: 'left',
        sortable: false,
        value: 'number',
    },
    {
        text: 'Inwestycja',
        align: 'left',
        sortable: false,
        value: 'investment',
    },
    {
        text: 'Kontrahent',
        align: 'left',
        sortable: false,
        value: 'contractor',
    },
    {
        text: 'Rodzaj',
        align: 'center',
        sortable: false,
        value: 'type',
    },
    {
        text: 'Kategorie',
        align: 'center',
        sortable: false,
        value: 'categories',
    },
    {
        text: 'Kwota',
        align: 'left',
        sortable: false,
        value: 'total_price_tax_excl',
    },
    {
        text: 'Kwota z Vat',
        align: 'left',
        sortable: false,
        value: 'total_price',
    },
    {
        text: 'Status',
        align: 'center',
        sortable: false,
        value: 'status',
    },
    {
        text: 'Akcje',
        align: 'center',
        sortable: false,
        value: 'actions'
    }

];

export const costsCategoriesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    { text: 'Nazwa', value: 'name' },
    {
        text: 'Akcje',
        align: 'right',
        sortable: false,
        value: 'actions',
    },
];

export const costPositionsListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {
        text: 'Nazwa',
        align: 'left',
        sortable: false,
        value: 'name',
    },
    {
        text: 'Powiązany element',
        align: 'left',
        sortable: false,
        value: 'investment_id'
    },
    {
        text: 'Ilość',
        align: 'left',
        sortable: false,
        value: 'quantity',
    },
    {
        text: 'Jednostka',
        align: 'left',
        sortable: false,
        value: 'unit',
    },
    {
        text: 'Cena',
        align: 'left',
        sortable: false,
        value: 'price',
    },
    {
        text: 'Podatek',
        align: 'left',
        sortable: false,
        value: 'tax_rate',
    },


];
export const costExamplePositionsListHeaders: HeaderFieldType[] = [
    {
        text: 'Nazwa',
        align: 'left',
        sortable: false,
        value: 'name',
    },
    {
        text: 'Jednostka',
        align: 'left',
        sortable: false,
        value: 'unit',
    },
    {
        text: 'Cena',
        align: 'left',
        sortable: false,
        value: 'price',
    },
    {
        text: 'Podatek',
        align: 'left',
        sortable: false,
        value: 'tax_rate',
    },
    {
        text: 'Akcje',
        align: 'right',
        sortable: false,
        value: 'actions',
    },


];

export const costTypesListHeaders: HeaderFieldType[] = [
    {
        text: 'Lp',
        align: 'left',
        sortable: false,
        value: 'index',
    },
    {
        text: 'Nazwa',
        align: 'left',
        sortable: false,
        value: 'name',
    },
    {
        text: 'Akcje',
        align: 'right',
        sortable: false,
        value: 'actions',
    },
];
